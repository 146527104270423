import { useCallback, useEffect, useState } from "react";
import "./Previous_question_paper.scss";
import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_79,
  url_83,
  url_105,
  url_205,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { useHistory } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userPyqTestsAction from "../../../../../../redux/pyq_test_exercises/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import PyqDailyChallengeActions from "../../../../../../redux/pyq_daily_challenge/action";

const Previous_question_paper = (props) => {
  let {
    userPreference,
    setPyqTopicData,
    setPyqTestsData,
    setPyqTestsResultData,
    setPyqJeeOrAdvance,
    startLoading,
    finishLoading,
    token,
    tokenType,
    setDay,
  } = props;

  let { targetDetails } = userPreference;
  let history = useHistory();

  const [state, setState] = useState({
    pyqPaperList: {},
    topicSubjectList: {},
    yearList: [],
    selectedYear: null,
    filterFlag: true,
    mainsAdvFlag: "jee-mains",
    daysListArr: [],
  });

  useEffect(() => {
    // Scroll to the top of the page after a short delay
    const scrollToTop = () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };

    // Scroll to the top when the component mounts
    scrollToTop();

    // Restore original scroll behavior when the component unmounts
    return () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };
  }, []);

  const pyqSubjectTopics = (elem) => {
    setPyqTopicData({ subId: elem.sub_id, subName: elem.sub_name });
    setPyqJeeOrAdvance(state.mainsAdvFlag);
    history.push("/Pyq_subjectTopic");
  };

  const pyqDailyChallenge = (day) => {
    setDay(day);
    history.push("/Pyq_dailyChallenge");
  };

  const onHandleTakeTest = (testData) => {
    if (!testData.is_attempt) {
      if (testData.paper_type.toLowerCase() === "paid") {
        alert("You have to purchase a plan from store");
        history.push("/store");
      } else {
        setPyqTestsData(testData);
        history.push("/PYQ_Test");
      }
    }
  };

  const onHandleTakeTest2 = (testData) => {
    if (!testData.is_attempt) {
      if (testData.paper_type.toLowerCase() === "paid") {
        alert("You have to purchase a plan from store");
        history.push("/store");
      } else {
        setPyqTestsData(testData);
        history.push("/PYQ_Practice_Test");
      }
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(10, 10);
    };

    requestAnimationFrame(scrollToTop);
  }, []);

  const onHandleSummary = (testData) => {
    setPyqTestsResultData(testData);
    history.push("/pyq-summary");
  };

  const getResponse = () => {
    getTopicSubjectList();
    setState((prevState) => ({
      ...prevState,
      filterFlag: false,
    }));
  };

  const getPyqDaysList = useCallback(async () => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_205, {}, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          daysListArr: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  }, [tokenType, token, finishLoading, startLoading]);

  const getPyqPaperList = useCallback(
    async (year) => {
      startLoading();
      try {
        const response = await axios.post(url_79, { year });
        if (response.data.status === 200) {
          finishLoading();

          if (targetDetails[0].target_id === "2") {
            let filteredArray = [];

            for (let i = 0; i < response.data.data.length; i++) {
              if (
                state.mainsAdvFlag === "jee-mains" &&
                response.data.data[i].format.toLowerCase().includes("main")
              ) {
                filteredArray.push(response.data.data[i]);
              } else if (
                state.mainsAdvFlag === "jee-adv" &&
                response.data.data[i].format.toLowerCase().includes("adv")
              ) {
                filteredArray.push(response.data.data[i]);
              }
            }

            setState((prevState) => ({
              ...prevState,
              pyqPaperList: filteredArray,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              pyqPaperList: response.data.data,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    },
    [state.mainsAdvFlag, targetDetails, finishLoading, startLoading]
  );

  const getTopicSubjectList = useCallback(async () => {
    let { courseDetails, classDetails } = userPreference;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_83, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  }, [userPreference, finishLoading, startLoading]);

  const getYearList = useCallback(async () => {
    try {
      const response = await axios.post(url_105);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          yearList: [...response.data.data.reverse()],
          selectedYear: response.data.data[0]?.year,
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  }, [finishLoading]);

  const handleSelectedYear = (event) => {
    setState((prevState) => ({
      ...prevState,
      selectedYear: event.target.value,
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      await getPyqPaperList(state.selectedYear);
    };

    fetchData();
  }, [getPyqPaperList, state.selectedYear]);

  const renderPyqFilter = () => {
    if (state.filterFlag === true) {
      return (
        <div className="pyq_filter_dropdown">
          <select
            className="pyq_dropdown"
            onChange={handleSelectedYear}
            value={state.selectedYear}
          >
            {!isEmpty(state.yearList)
              ? state.yearList.length
                ? state.yearList.map((element) => (
                    <option key={element.year} value={element.year}>
                      {element.year}
                    </option>
                  ))
                : null
              : null}
          </select>
        </div>
      );
    }
  };

  const handleFilterFlag = () => {
    setState((prevState) => ({
      ...prevState,
      filterFlag: true,
    }));
  };

  const jeeMainsAndAdv = () => {
    return (
      <div className="jee-mains-adv">
        <div className="jee-mains-adv-wrapper">
          <button
            className={`jee-btn ${
              state.mainsAdvFlag === "jee-mains" && "active"
            }`}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                mainsAdvFlag: "jee-mains",
              }))
            }
          >
            JEE Mains
          </button>
          <button
            className={`jee-btn ${
              state.mainsAdvFlag === "jee-adv" && "active"
            }`}
            onClick={() =>
              setState((prev) => ({
                ...prev,
                mainsAdvFlag: "jee-adv",
              }))
            }
          >
            JEE Advanced
          </button>
        </div>
      </div>
    );
  };
  useEffect(() => {
    getPyqDaysList();
    getYearList();
    getTopicSubjectList();
  }, [getPyqDaysList, getTopicSubjectList, getYearList]);

  useEffect(() => {
    if (state.selectedYear) {
      getPyqPaperList(state.selectedYear);
    }
  }, [state.selectedYear, getPyqPaperList]);

  useEffect(() => {
    getPyqPaperList(state.selectedYear);
  }, [state.mainsAdvFlag, getPyqPaperList, state.selectedYear]);

  return (
    <>
      <div className="pyq_detail_wrapper">
        {targetDetails[0]?.target_id == "2" && jeeMainsAndAdv()}

        <div className="pyqTabBox">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="paperWise-tab"
                data-bs-toggle="tab"
                data-bs-target="#paperWise"
                type="button"
                role="tab"
                aria-controls="paperWise"
                aria-selected="true"
                onClick={handleFilterFlag}
              >
                Paper Wise
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="topicWise-tab"
                data-bs-toggle="tab"
                data-bs-target="#topicWise"
                type="button"
                role="tab"
                aria-controls="topicWise"
                aria-selected="false"
                onClick={getResponse}
              >
                Topic Wise
              </button>
            </li>
            {targetDetails[0]?.target_id == "2" &&
              state.mainsAdvFlag == "jee-mains" && (
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pyqChallenge-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pyqChallenge"
                    type="button"
                    role="tab"
                    aria-controls="pyqChallenge"
                    aria-selected="false"
                    onClick={getResponse}
                  >
                    Daywise Challenge
                  </button>
                </li>
              )}
          </ul>

          {renderPyqFilter()}
        </div>
        <div className="pyqTabContent">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="paperWise"
              role="tabpanel"
              aria-labelledby="paperWise-tab"
            >
              <div className="upcomingExamWrapper">
                {!isEmpty(state.pyqPaperList) && state.pyqPaperList.length
                  ? state.pyqPaperList.map((element, index) => {
                      return (
                        <div key={index} className="upcomingExamBox">
                          <div className="pyqNameType">
                            <div className="pyqName">
                              <span>{element.paper_name}</span>
                            </div>
                          </div>
                          {/* <div className="pyqNameDateDuration">
                            <div className="pyqNameDate">
                              <p>{element.format}</p>
                            </div>
                          </div> */}

                          <div className="attemBtn">
                            {!element.is_attempted ? (
                              <button
                                className="attempt"
                                onClick={() => onHandleTakeTest(element)}
                              >
                                Take Test
                              </button>
                            ) : (
                              <>
                                <button
                                  className="practice"
                                  onClick={() => onHandleSummary(element)}
                                >
                                  Summary
                                </button>

                                <button
                                  className="practice"
                                  onClick={() => onHandleTakeTest(element)}
                                >
                                  Try Again
                                </button>
                              </>
                            )}
                            <button
                              className="practice"
                              onClick={() => onHandleTakeTest2(element)}
                            >
                              Practice
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="topicWise"
              role="tabpanel"
              aria-labelledby="topicWise-tab"
            >
              <div className="topicWiseWrapper">
                {!isEmpty(state.topicSubjectList)
                  ? state.topicSubjectList.subject.length
                    ? state.topicSubjectList.subject.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            className="topicBox"
                            onClick={() => pyqSubjectTopics(elem)}
                          >
                            <div className="topicNameImg">
                              <div className="img_wrp">
                                <img src={elem.icon1} alt="img" />
                              </div>
                              <h5>{elem.sub_name}</h5>
                            </div>
                            <div className="arrow">
                              <img src={images.right_Ar} alt="arrow" />
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pyqChallenge"
              role="tabpanel"
              aria-labelledby="pyqChallenge-tab"
            >
              <div className="topicWiseWrapper">
                {!isEmpty(state.daysListArr)
                  ? state.daysListArr.length
                    ? state.daysListArr.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            className="daysBox"
                            onClick={() => {
                              !elem.lock
                                ? pyqDailyChallenge(elem.schedule)
                                : alert("Please Wait to Unlock");
                            }}
                          >
                            <div className="topicNameImg">
                              <h5>{elem.schedule}</h5>
                            </div>
                            <div className="arrow">
                              {elem.lock ? (
                                <img
                                  className="lock_image"
                                  src={images.locked_symbol}
                                  alt="locked"
                                />
                              ) : (
                                <img
                                  className="arrow_image"
                                  src={images.right_Ar}
                                  alt="arrow"
                                />
                              )}
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};
const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPyqTopicData: (payload) => {
      dispatch(userActivityActions.setPyqTopicData(payload));
    },
    setPyqTestsData: (payload) => {
      dispatch(userPyqTestsAction.setPyqTestsData(payload));
    },
    setPyqTestsResultData: (payload) => {
      dispatch(userPyqTestsAction.setPyqTestsResultData(payload));
    },
    setPyqJeeOrAdvance: (payload) => {
      dispatch(userPyqTestsAction.setPyqJeeOrAdvance(payload));
    },

    setDay: (payload) => {
      dispatch(PyqDailyChallengeActions.setDay(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(Previous_question_paper);
