import React from "react";
import "./UnAnsweredDoubtItem.scss";

const UnAnsweredDoubtItem = ({
  index,
  element,
  handClick,
  convertDate,
  renderQuestion,
}) => {
  return (
    <div
      key={index}
      className="single_doubt_wrappr"
      onClick={() => handClick(element)}
    >
      <div className="single_doubt_innr-wrppr">
        <div className="doubt_date">
          {" "}
          {`Posted at ${convertDate(element.post_date)}`}
        </div>
        <div className="doubt"> {renderQuestion(element.question)}</div>

        <div className="view_doubt_text"> View Doubt...</div>
      </div>
    </div>
  );
};

export default UnAnsweredDoubtItem;
