import React, { useState, useEffect, Fragment } from "react";
import "./TopicWiseMarks.scss";
import axios from "axios";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import ProgressBar from "react-bootstrap/ProgressBar";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import uiActions from "../../../../.././../../../redux/ui/action";
import { connect } from "react-redux";

import {
  url_70,
  url_71,
  url_72,
} from "../../../../../../../../custom_utilities/api_services";

const TopicWiseMarks = (props) => {
  const { location, loader, startLoading, finishLoading } = props;
  const [state, setState] = useState({
    subjectList: {},
    topicAnalysis: {},
    currentSubject: null,
  });

  useEffect(() => {
    getTopicResult();
  }, [state.currentSubject]);

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = async () => {
    let data = {
      paper_id: location.state.reportDataDetails.PaperData.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_71, data);
      if (response.data.status === 200) {
        setState((preState) => ({
          ...preState,
          subjectList: response.data.data,
        }));
        response.data.data.All_Subject[0] &&
          setState((prevState) => ({
            ...prevState,
            currentSubject: response.data.data.All_Subject[0]?.subject,
          }));
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const getTopicResult = async () => {
    let data = {
      paper_id: location.state.reportDataDetails.PaperData.paper_id,
      subject: state.currentSubject,
    };
    startLoading();
    try {
      const response = await axios.post(url_72, data);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          topicAnalysis: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleChangeSubject = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      currentSubject: value,
    }));
  };

  return (
    <Fragment>
      <div className="topicWiseMarksBox">
        <div className="topicWiseAnalysis">
          <div className="subjectsListWrapper">
            <div className="subjectsList">
              {!isEmpty(state.subjectList)
                ? state.subjectList.All_Subject.length
                  ? state.subjectList.All_Subject.map((element, index) => {
                      return (
                        <div key={index} className="single_select_subject">
                          <input
                            type="radio"
                            id={element.subject}
                            name="subject"
                            value={element.subject}
                            checked={element.subject === state.currentSubject}
                            onChange={(event) => {
                              handleChangeSubject(event);
                            }}
                          />
                          <label htmlFor={element.subject}>
                            {element.subject}
                          </label>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
            {loader && <LoaderPopUp />}
          </div>
        </div>

        <div className="topicAnalysisData">
          {!isEmpty(state.topicAnalysis)
            ? state.topicAnalysis.TopicAnalysis.length
              ? state.topicAnalysis.TopicAnalysis.map((element, index) => {
                  return (
                    <div className="testProgressIndicators" key={index}>
                      <div className="topicDetail">
                        <h4>{element.Topic}</h4>
                        <h4 className="Qmark">
                          {`${element["Total Questions"] + " Questions "}`},{" "}
                          {`${element["Total Marks"] + " Marks "}`}
                        </h4>
                      </div>
                      <div className="ProgressBar_Lines">
                        <ProgressBar
                          now={
                            (100 / element["Total Marks"]) *
                              element["User Marks"] >
                            0
                              ? (100 / element["Total Marks"]) *
                                element["User Marks"]
                              : 0
                          }
                          className="progressBar"
                        />
                        <span
                          style={{
                            left: `${
                              (100 / element["Total Marks"]) *
                                element["User Marks"] >
                              0
                                ? (100 / element["Total Marks"]) *
                                  element["User Marks"]
                                : 0
                            }%`,
                          }}
                        >{`${element["User Marks"]}`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{element["Total Marks"]}</span>
                      </div>
                    </div>
                  );
                })
              : null
            : null}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicWiseMarks);


