import React, {  Fragment } from "react";
import {  connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CartProductsPage from "./cartproductspage/CartProductsPage";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import CartHeader from "./cartheader/CartHeader";
import "./CartAndPaymentPage.scss";
import images from "../../../../../../../utilities/images/images";
import CouponCoin from "./couponcoins/CouponCoin";
import useGetCartProducts from "../hooks/cart_products/useGetCartProducts";
import CouponCoinSingleProduct from "./couponcoins/CouponCoinSingleProduct";

const CartAndPaymentPage = ({ token, tokenType, tab }) => {
  const history = useHistory();
  const location = useLocation();

  const { cartProducts } = useGetCartProducts({ token, tokenType });

  console.log("tabbbbbbbbbb", tab);

  const goToStore = () => {
    history.push({
      pathname: "/store",
      state: { tab: "hand_books" },
    });
  };

  return (
    <Fragment>
      <HeaderComponent />
      <div className="cart_wrapper">
        <CartHeader />

        {tab == "cart" ? (
          cartProducts?.length !== 0 ? (
            <div className="cart_inner_wrapper">
              <CartProductsPage
                token={token}
                tokenType={tokenType}
                cartProducts={cartProducts}
                tab={tab}
              />
              <CouponCoin />
            </div>
          ) : (
            <div className="cart_empty_content_wrapper">
              <img src={images.handbookCartEmptyImg} alt="cart-empty-img" />
              <div className="  hd_text1">Your Cart is Empty</div>
              <div className="  hd_text2">
                Looks like y ou haven’t added anything to your cart yet
              </div>

              <div classNa me="gotostore_btn" onClick={goToStore}>
                <button>Go To Store</button>
              </div>
            </div>
          )
        ) : (
          <div className="cart_inner_wrapper">
            <CartProductsPage
              token={token}
              tokenType={tokenType}
              cartProducts={location.state.cartProducts}
              tab={tab}
            />
            <CouponCoinSingleProduct
              product={location?.state?.cartProducts[0]}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log("state.tabbbbb", state.handBookPaymentData);
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    tab: state.handBookPaymentData.tab,
  };
};

export default connect(mapStateToProps, null)(CartAndPaymentPage);
