import { Bar } from "react-chartjs-2";

const AttemptUnattemptDoughnutChart = (props) => {
  let { dataDetails } = props;

  const labels = [""];

  // this is for adding margin to the label( header) of the chart
  const legendMargin = [
    {
      id: "legendMargin",
      beforeInit(chart, legend, options) {
        chart.legend.options.position = "bottom";
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return (this.height += 20);
        };
      },
    },
  ];

  const data = {
    labels,

    datasets: [
      {
        label: "Attempted",
        data: [Number(dataDetails[0].Total)],
        backgroundColor: "#FBAB35",
        barThickness: 70,
      },
      {
        label: "Unattempted",
        data: [Number(dataDetails[1].Total)],
        backgroundColor: "#BDBDBD",
        barThickness: 70,
      },
    ],
  };

  return (
    <div className="sec_1_inner_wrapper" style={{ height: "350px" }}>
      <Bar
        options={{
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false, // Hide the values inside the bars
            },
          },
        }}
        plugins={legendMargin}
        data={data}
      />
    </div>
  );
};

export default AttemptUnattemptDoughnutChart;
