import { useEffect, useState } from "react";
import "./SubjectWiseDoughnut.scss";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";
//   // import {
//   //   CChart
//   // } from '@coreui/react-chartjs'
// // import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Label } from 'recharts';

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const SubjectWiseDoughnut = (props) => {
  let {  dataPoints } = props;
  // console.log('dataPoints',dataPoints)
  const [subjectType, setSubjectType] = useState(dataPoints[0].type);
  const [array, setArray] = useState([]);

  useEffect(() => {
    filterTypeWise();
  }, [subjectType]);

  const filterTypeWise = () => {
    dataPoints?.filter((element) => {
      if (element.type === subjectType) {
        setArray([element]);
        console.log("element",element,"[element]",[element])
      }
    });
    return
  };
  const data = () => {
    if(array[0]?.Biology == 0){
      return [
        {
          name: "Chemistry",
          value: array[0]?.Chemistry,
          color: "#FFAFCA",
        },
        {
          name: "Maths",
          value: array[0]?.Maths,
          color: "#819CFF",
        },
        {
          name: "Physics",
          value: array[0]?.Physics,
          color: "#FFC557",
        },
      ]
    } if(array[0]?.Maths == 0) {
      return [
        {name: "Biology",
        value: array[0]?.Biology,
        color: "#4EE3A4" },
        {
          name: "Chemistry",
          value: array[0]?.Chemistry,
          color: "#FFAFCA",
        },
        {
          name: "Physics",
          value: array[0]?.Physics,
          color: "#FFC557",
        },
      ]
    } else {
       return [
        {name: "Biology",
        value: array[0]?.Biology,
        color: "#4EE3A4" },
        {
          name: "Chemistry",
          value: array[0]?.Chemistry,
          color: "#FFAFCA",
        },
        {
          name: "Maths",
          value: array[0]?.Maths,
          color: "#819CFF",
        },
        {
          name: "Physics",
          value: array[0]?.Physics,
          color: "#FFC557",
        },
      ]
    }
  };


  // const options = {
  //   animationEnabled: true,
  //   // backgroundColor: "#f5f4f8",
  //   toolTip:{
  //     enabled: true,       //disable here
  //     animationEnabled: true, //disable here
  //     backgroundColor:'#4EE3A4'
  //   },
  //   subtitles: [
  //     {
  //       verticalAlign: "center",
  //       fontSize: 24,
  //       dockInsidePlotArea: true,
  //     },
  //   ],
  //   data: [
  //     {
  //       type: "doughnut",
  //       radius: "90%",
  //       innerRadius: "82%",
  //       indexLabel: "{name}: {y}",
  //       yValueFormatString: "#,###'%'",
  //       dataPoints: [
  //         {
  //           name: "Biology",
  //           y: array[0]?.Biology,
  //           color: "#4EE3A4",
  //           indexLabelBackgroundColor: "#4EE3A4",
  //         },
  //         {
  //           name: "Chemistry",
  //           y: array[0]?.Chemistry,
  //           color: "#FFAFCA",
  //           indexLabelBackgroundColor: "#FFAFCA",
  //         },
  //         {
  //           name: "Maths",
  //           y: array[0]?.Maths,
  //           color: "#819CFF",
  //           indexLabelBackgroundColor: "#819CFF",
  //         },
  //         {
  //           name: "Physics",
  //           y: array[0]?.Physics,
  //           color: "#FFC557",
  //           indexLabelBackgroundColor: "#FFC557",
  //         },
  //       ],
  //     },
  //   ],
  // };

  const handleChange = (event) => {
    setSubjectType(event.target.value);
  };


const chartData = {
    labels: data().map(item => item.name),
    datasets: [
        {
            data: data().map(item => subjectType == "Time Spent" ? Math.floor(item.value/60) :item.value),
            backgroundColor: data().map(item => item.color),
            hoverBackgroundColor: data().map(item => item.color),
        },
      ],
};
const options = {
  cutout: "80%",
  // circumference:270,
  maintainAspectRatio: false,

  layout: {
    padding: {
      top: 50,
      bottom: 50,
      left: 50,
      right: 50,
    },
  },
  plugins: {
    legend: {
      display: false, // Hide default legend
    },
    tooltip: {
      enabled: true,
    },
    doughnut: {
      spacing: 0, // Set spacing to 0 to remove the gap
  },
    datalabels: {
      display: true,
      formatter: (value, context) => {
        const label = context.chart.data.labels[context.dataIndex];
        return `${label}\n${value}${subjectType == "Time Spent" ? ' Min' : "%"}`;
      },
      color: "#000",
      borderWidth: 0,
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 5,
      padding: 6,
      align: "end",
      anchor: "end",
      font: {
        size: 12,
      },
    },
  },
};
 
  return (
    <div className="sec_1_inner_wrapper">
      <div className="heading-text">Subject Wise</div>
      <div className="type_collection_wrapper">
        {!isEmpty(dataPoints)
          ? dataPoints.length
            ? dataPoints.map((element, index) => {
                return (
                  <div key={index} className="single_select_type">
                    <input
                      type="radio"
                      id={element.type}
                      // name="individual_test_subject"
                      value={element.type}
                      checked={element.type === subjectType}
                      onChange={handleChange}
                    />
                    <label htmlFor={element.type}>{element.type}</label>
                  </div>
                );
              })
            : null
          : null}
      </div>
      {/* <CanvasJSChart
        // style={{ backgroundColor: "#ffffff",width: "10px",}}
        options={options}
      /> */}
      
      <div className="Doughnut-chart-container">
            <Doughnut data={chartData} options={options} />
        </div>


    </div>
  );
};

export default SubjectWiseDoughnut;
