import { Fragment, useState } from "react";
import "./package_offering_component.scss";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";

const PackageOfferingComponent = (props) => {
  let { allPackagesCollection, learnMoreBtn } = props;

  const [activeTab, setActiveTab] = useState(0);

  return (
    <Fragment>
      <div className="package_offering_component_wrapper">
        <div className="store_banner">
          <img src={images.new_store_banner} alt="store banner" />
        </div>
        <div className="package_filter_div d-flex">
          {!isEmpty(allPackagesCollection?.category_list) &&
            allPackagesCollection?.category_list.map((element, key) => {
              return (
                <div>
                  <button
                    onClick={() => {
                      setActiveTab(key);
                    }}
                    className={activeTab === key && `active`}
                    key={key}
                  >
                    {element.category_name}
                  </button>
                </div>
              );
            })}
        </div>
        <div className="package_offering_component_inner_wrapper">
          {!isEmpty(allPackagesCollection?.package)
            ? allPackagesCollection?.package
                .filter((packageItem) =>
                  packageItem.category_id.some(
                    (category) => category.category_id === activeTab + 1
                  )
                )
                .map((element, key) => {
                  return (
                    <div className="package_outer">
                      <div className="package_name_banner">
                        <img src={element.icon} alt="package banner" />
                        <p
                          style={{
                            backgroundColor: element.tag_background,
                            color: element.tag_textcolor,
                          }}
                        >
                          {element.thumbnail_tag}
                        </p>
                        <h1>{element.package_name}</h1>
                      </div>
                      <p className="package_description">
                        {element.description}
                      </p>
                      <div className="package_flex d-flex justify-content-between">
                        <div className="duration_div">
                          <div className="d-flex mb-2">
                            <img
                              src={images.user_activity_calendar}
                              alt="calender"
                            />
                            <p>{element.duration}</p>
                          </div>
                          {element.course_benefits_count !== 0 && (
                            <div className="d-flex">
                              <img src={images.new_store_tick} alt="tick" />
                              <p>{element.course_benefits_count}+ benefits</p>
                            </div>
                          )}
                        </div>
                        {!(
                          parseFloat(element.amount) -
                            parseFloat(element.discount) ===
                          parseFloat(element.amount)
                        ) && (
                          <div className="discount_div d-flex align-items-center">
                            <img src={images.store_offer} alt="offer" />
                            <p>
                              <span>
                                {Math.round(
                                  (parseFloat(element.discount) /
                                    parseFloat(element.amount)) *
                                    100
                                )}
                                %
                              </span>{" "}
                              Off
                            </p>
                          </div>
                        )}
                        <div className="price_div">
                          <p className="final_amount">
                            ₹
                            {parseFloat(element.amount) -
                              parseFloat(element.discount)}
                          </p>
                          {!(
                            parseFloat(element.amount) -
                              parseFloat(element.discount) ===
                            parseFloat(element.amount)
                          ) && (
                            <p className="initial_amount">
                              ₹
                              <span className="text-decoration-line-through">
                                {parseFloat(element.amount)}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="package_button_div">
                        <button
                          className="details"
                          style={{ width: element.is_subscribe && `100%` }}
                          onClick={() => learnMoreBtn(element)}
                        >
                          View Details
                        </button>
                        {!element.is_subscribe && (
                          <button
                            onClick={() => learnMoreBtn(element)}
                            className="buy"
                          >
                            Buy Now
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })
            : "No Courses available"}
        </div>
      </div>
    </Fragment>
  );
};

export default PackageOfferingComponent;
