import React from "react";
import { useHistory } from "react-router-dom";
import HandbookCart from "../handbookcart/HandbookCart";
import images from "../../../../../../../../utilities/images/images";
import "./CartHeader.scss";
const CartHeader = () => {
  const history = useHistory();

  const goBack = () => {
    history.push({
      pathname: "/store",
      state: { tab: "hand_books" },
    });
  };
  return (
    <div className="back_btn_and_cart">
      <div className="back_btn_and_cart_inner">
        <div className="storeBack" onClick={goBack}>
          <img src={images.storeBck} alt="back" />
          Back
        </div>

        <HandbookCart />
      </div>
    </div>
  );
};

export default CartHeader;
