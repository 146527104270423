import React, { useState } from "react";
import "./QuestionListPanel.scss";
import QuestionStatusLabel from "./questionStatusLabel/QueLabel";
import images from "../../../../../../../../../../utilities/images/images";

const QuestionListPanel = ({
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  questionsCollectionRef,
  lang,
  handleShow,
  handleSaveQuestion,
  handleSol,
  handleSolution,
  setVideoShow,
}) => {
  const [isList,setisList] = useState(true);

  return (
    <div className="questions-panel">
      <div className="questions-panel-header">
        <div className="header-text">All Questions</div>
        <button
          onClick={() => {
            handleSaveQuestion();
            handleShow();
          }}
        >
          Submit
        </button>
      </div>

      <div className="questions-panel-markings">
        <div className="d-flex justify-content-between align-items-center grid_list_switch">
          <p className="m-0">All Questions</p>
          <div className="d-flex gap-2">
            <button onClick={() => setisList(true)}>
              {isList ? (
                <img src={images.user_activity_list1} alt="list1" />
              ) : (
                <img src={images.user_activity_list} alt="list" />
              )}
            </button>
            <button onClick={() => setisList(false)}>
              {isList ? (
                <img src={images.user_activity_grid} alt="grid" />
              ) : (
                <img src={images.user_activity_grid1} alt="grid1" />
              )}
            </button>
          </div>
        </div>
        <div className="question-marking">
          <label className="marking-icon marking-icon-completed"></label>
          <span className="marking-text">Completed</span>
        </div>
        <div className="question-marking">
          <label className="marking-icon marking-icon-pending"></label>
          <span className="marking-text">Pending</span>
        </div>
      </div>

      <div className="questions-list" ref={questionsCollectionRef}>
        {testQuestionsDetails?.length
          ? testQuestionsDetails?.map((element, index) => (
              <QuestionStatusLabel
                key={index}
                question={element}
                index={index}
                currentQuestion={currentQuestion}
                handleQuestionJump={handleQuestionJump}
                testQuestionsDetails={testQuestionsDetails}
                lang={lang}
                handleSolution={handleSolution}
                handleSol={handleSol}
                setVideoShow={setVideoShow}
                isList = {isList}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default QuestionListPanel;
