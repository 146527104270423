import React from "react";
import images from "../../../../../../../utilities/images/images";
import "./AdaptiveCpsCreatePaper.scss";

const AdaptiveCpsCreatePaper = ({ handleStep }) => {
  return (
    <div className="d-flex align-items-center acps_banner">
      <div>
        <img src={images.acps_banner} alt="banner" />
      </div>
      <div>
        <p>Adaptive CPS</p>
        <p>
          Create your <strong>Own Customized Test & Practice</strong> unlimited
          times with <span>FREE</span> access
        </p>
      </div>
      <div>
        <button onClick={() => handleStep(2)}>
          <img src={images.acps_banner_plus} alt="plus" />
          Create Paper
        </button>
      </div>
    </div>
  );
};

export default AdaptiveCpsCreatePaper;
