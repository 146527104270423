import React, { useState, useEffect } from "react";
import "./AdaptiveCpsContent.scss";
import { useHistory } from "react-router";

import AdaptiveCpsBanner from "./adaptiveCpsBanner/AdaptiveCpsBanner";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import { connect } from "react-redux";
import images from "../../../../../../../utilities/images/images";
import axios from "axios";
import ZeroAttemptsAlert from "./zeroAttemptsAlert/ZeroAttemptsAlert";
import adaptiveCpsActions from "../../../../../../../redux/adaptive_cps/action";
import AdaptiveCpsSubjects from "./adaptiveCpsSubjects/AdaptiveCpsSubjects";
import CommonBannerComponent from "../../../../../PYQ_CPS_Exam/pyqCpsExamComponent/PyqCpsExamContentComponent/CommonBannerComponent";

const AdaptiveCpsContent = ({
  loader,
  handleStep,
  packageList,
  token,
  tokenType,
  setAdaptiveCpsSubjectAction,
  startLoading,
  finishLoading,
}) => {
  const history = useHistory();
  const [attemptCount, setAttemptCount] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  useEffect(() => {
    getAttemptCount();
  }, []);

  const getAttemptCount = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSInstructions",
        { headers }
      );

      if (response.data.status === 200) {
        finishLoading();
        setAttemptCount(response?.data?.TotalAttempt);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleSubjectClick = (sub_name) => {
    if (attemptCount === 0) {
      // Show the custom modal
      setAlertShow(true);
    } else {
      setAdaptiveCpsSubjectAction(sub_name);
      // history.push("/adaptive_cps_test");

      history.push({
        pathname: "/adaptive_cps_test",
        state: { attemptCount: attemptCount },
      });
    }
  };

  // const handleSubjectClick2 = (sub_name) => {
  //   setAdaptiveCpsSubjectAction(sub_name);
  //   history.push("/adaptive_cps_test");
  // };

  const handleSubjectClick2 = (sub_name) => {
    setAdaptiveCpsSubjectAction(sub_name);
    history.push({
      pathname: "/adaptive_cps_test",
      state: { attemptCount: attemptCount },
    });
  };

  const handleClose = () => {
    setAlertShow(false);
  };

  const handleRedirectToStore = () => {
    setAlertShow(false);
    history.push("/store");
  };

  return (
    <>
      <div className="acpsBackBtn" onClick={() => handleStep(1)}>
        <img src={images.m2_backBtn} alt="backBtn" />
      </div>
      <div>
        <div className="containerr">
          <div className="Acps_Wrapper">
            <div className="Acps_InrWrapper">
              <div className="Acps_contentWpr">
                <div className="Acps_header">
                  {/* <div className="Acps_heading">Adaptive CPS </div> */}
                  <p>
                    Based on the exams & practice test performances we suggested
                    you to keep focus on week topics.
                  </p>
                </div>
                <CommonBannerComponent tab="adaptive_cps" />
                <div className="adaptive-cps-containers">
                  {packageList.map((packageItem) => {
                    if (
                      packageItem.package_id === 228 ||
                      packageItem.package_id === 229
                    ) {
                      return (
                        <React.Fragment key={packageItem.package_id}>
                          <div className="adaptive-cps-subjects">
                            <AdaptiveCpsSubjects
                              handleSubjectClick2={handleSubjectClick2}
                              is_subscribe={packageItem.is_subscribe}
                              startLoading={startLoading}
                              finishLoading={finishLoading}
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <ZeroAttemptsAlert
                show={alertShow}
                handleClose={handleClose}
                handleRedirectToStore={handleRedirectToStore}
              />
            </div>
          </div>
        </div>
        {loader && <LoaderPopUp />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    packageList: state.auth.packageList.paidCoursePackages,
    token: state.auth.authToken,
    tokenType: state.auth.authType,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setAdaptiveCpsSubjectAction: (payload) => {
      dispatch(adaptiveCpsActions.setAdaptiveCpsSubject(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(AdaptiveCpsContent);

// export default AdaptiveCpsContent;
