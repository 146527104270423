import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { url_246 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useGetHandbookProductDetail = (productId) => {
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const getHandbookProductDetail = async () => {
    try {
      startLoading();
      const response = await axios.get(
        `${url_246}?product_id=${productId}`,
        headers
      );

      finishLoading();

      return response.data.data;
    } catch (err) {
      console.log(err.message);
      finishLoading();
    }
  };

  const { data: packageData } = useQuery({
    queryKey: ["handbook_product_detail"],
    queryFn: getHandbookProductDetail,
  });

  return { packageData };
};

export default useGetHandbookProductDetail;
