import React, { Fragment } from "react";
import images from "../../../utilities/images/images";
import "./AppDetails.scss";

const AppDetails = () => {
  return (
    <Fragment>
      <div className="app-details-inner-wrapper">
        <div className="content-and-btn-wrapper">
          <div className="content">
            <div className="heading">Download Motion Learning App</div>

            <div className="sub-heading">
              Prepare for India’s most prestigious exams from anytime anywhere!
            </div>
          </div>

          <div
            className="app-details-btn-wrapper"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.elearning.motion&hl=en",
                "_blank"
              )
            }
          >
            <img src={images.playStore} alt="playstore" />
            Play Store
          </div>
        </div>
      </div>
      <div className="mobile-app-details-inner-wrapper">
        <div className="content-and-btn-wrapper">
          <div className="content">
            <div className="heading">Download Motion Learning App</div>

            <div className="sub-heading">
              Prepare for India’s most prestigious exams from anytime anywhere!
            </div>
          </div>

          <div
            className="app-details-btn-wrapper"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.elearning.motion&hl=en",
                "_blank"
              )
            }
          >
            <img src={images.playStore} alt="playstore" />
            Play Store
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppDetails;
