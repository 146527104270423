import React from "react";
import {} from "./Header_left_new.scss";
import images from "../../../../../../utilities/images/images";
import { NavLink, useLocation } from "react-router-dom";
const Header_left_new = ({ userPreference }) => {
  const location = useLocation();

  return (
    <>
      <div className="header_left_wrapper">
        <div className="logo">
          <NavLink to="/user_dashboard">
            <img src={images.motion_logo_new} alt="" />
          </NavLink>
        </div>

        <div className="left_menu">
          <ul>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                exact
                to="/user_dashboard"
              >
                <span>Home</span>
              </NavLink>
            </li>
            <li className="dropdown">
              <a
                className={`dropdown-toggle menu-link ${
                  location.pathname === "/pyq" ||
                  location.pathname === "/cps" ||
                  location.pathname === "/exam" ||
                  location.pathname === "/ots" ||
                  location.pathname === "/exam_cracker" ||
                  location.pathname === "/mock_test" ||
                  location.pathname === "/bit_sat"
                    ? "current"
                    : ""
                }`}
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>Practice</span>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/pyq",
                    state: { tab: "pyq" },
                  }}
                >
                  <span>PYQ</span>
                </NavLink>
                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/cps",
                      state: { tab: "cps" },
                    }}
                  >
                    <span>CPS</span>
                  </NavLink>
                )}
                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/exam",
                      state: { tab: "exam" },
                    }}
                  >
                    <span>Exams</span>
                  </NavLink>
                )}

                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/ots",
                      state: { tab: "ots" },
                    }}
                  >
                    <span>Test Series</span>
                  </NavLink>
                )}
                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/exam_cracker",
                      state: { tab: "exam_cracker" },
                    }}
                  >
                    <span>Exam Cracker</span>
                  </NavLink>
                )}

                {userPreference?.targetDetails[0]?.target_id !== 5 &&
                  userPreference?.targetDetails[0]?.target_id !== 3 && (
                    <NavLink
                      activeClassName="current-dropdown"
                      className="dropdown-item menu-link-dropdown"
                      to={{
                        pathname: "/bit_sat",
                        state: { tab: "bit_sat" },
                      }}
                    >
                      <span>BITSAT</span>
                    </NavLink>
                  )}
                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/mock_test",
                      state: { tab: "mock_test" },
                    }}
                  >
                    <span>Mock Test</span>
                  </NavLink>
                )}
                {userPreference?.targetDetails[0]?.target_id !== 5 && (
                  <NavLink
                    activeClassName="current-dropdown"
                    className="dropdown-item menu-link-dropdown"
                    to={{
                      pathname: "/create-adaptive-cps",
                      state: { tab: "create-adaptive-cps" },
                    }}
                  >
                    <span>Adaptive CPS</span>
                  </NavLink>
                )}
              </div>
            </li>

            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to="/doubts"
              >
                <span>Doubt</span>
              </NavLink>
            </li>
            {(userPreference?.targetDetails[0].target_id == 1 ||
              userPreference?.targetDetails[0].target_id == 2 ||
              userPreference?.targetDetails[0].target_id == 3) && (
              <>
                <li>
                  <NavLink
                    activeClassName="current"
                    className="menu-link"
                    to="/question_bank"
                  >
                    <span>Question Bank</span>
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to="/user_bookmarks"
              >
                <span>Bookmarks</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to={{
                  pathname: "/store",
                  state: { tab: "online_learning" },
                }}
              >
                <span>Store</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header_left_new;
