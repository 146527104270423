import React from "react";
import VideoJsPlayer1 from "../../../../../custom_utilities/NewVideoPlayer1/videoshow";
import VideoJsPlayer from "../../../../../custom_utilities/NewVideoPlayer/videoshow";

const VideoDrmPlayer = ({ id_video, data }) => {
  return (
    <div>
      {data?.is_free_user ? (
        <VideoJsPlayer1 id_video={id_video} />
      ) : (
        <VideoJsPlayer id_video={id_video} />
      )}
    </div>
  );
};

export default VideoDrmPlayer;
