import React, { Fragment, useState, useEffect, useRef } from "react";
import "./QuestionViewContentComponent.scss";
import QuestionListPanel from "./questionListPanel/QuestionListPanel";
import QuestionDetailPanel from "./questionDetailPanel/QuestionDetailPanel";
import QuestionSwitcher from "./questionDetailPanel/questionSwitcher/QuestionSwitcher";

const QuestionViewContentComponent = React.memo((props) => {
  const {
    testQuestionsDetails,
    currentSolutionDetails,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userId,
  } = props;

  const questionsCollectionRef = useRef(null);

  const [videoShow, setVideoShow] = useState(false);

  useEffect(() => {
    console.log("currentSolutionDetails", currentSolutionDetails);
  }, [currentSolutionDetails]);

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current?.children?.[nextQuestionIndex];

    if (questionElement) {
      questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.warn(
        "No next question element found at index:",
        nextQuestionIndex
      );
    }
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current?.children?.[prevQuestionIndex];

    if (questionElement) {
      questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.warn(
        "No previous question element found at index:",
        prevQuestionIndex
      );
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to a particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimingDetails",
          JSON.stringify(videoTimingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
  };

  const renderVideo = () => {
    if (
      currentSolutionDetails.video_id === " " ||
      currentSolutionDetails.video_id === null
    ) {
      return null;
    } else {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            view video solution
          </button>

          {videoShow && (
            <iframe
              id="iframe"
              src={currentSolutionDetails?.video_id}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="questionListPanel">
                  <div className="sub_section_wrapper">
                    <QuestionDetailPanel
                      testQuestionsDetails={testQuestionsDetails}
                      currentQuestion={currentQuestion}
                      currentSolutionDetails={currentSolutionDetails}
                      scrollUp2={scrollUp2}
                      scrollUp={scrollUp}
                      handlePreviousQuestionBtn={handlePreviousQuestionBtn}
                      handleNextQuestionBtn={handleNextQuestionBtn}
                    />
                    <div>
                      <QuestionSwitcher
                        currentSolutionDetails={currentSolutionDetails}
                        currentQuestion={currentQuestion}
                        scrollUp2={scrollUp2}
                        scrollUp={scrollUp}
                        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
                        handleNextQuestionBtn={handleNextQuestionBtn}
                        testQuestionsDetails={testQuestionsDetails}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <QuestionListPanel
                    testQuestionsDetails={testQuestionsDetails}
                    currentQuestion={currentQuestion}
                    handleQuestionJump={handleQuestionJump}
                    questionsCollectionRef={questionsCollectionRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default QuestionViewContentComponent;
