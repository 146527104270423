import { Fragment, useState, useEffect } from "react";
import "./test_content_component.scss";
import TestInstructionsView from "./test_instructions_view/test_instructions_view";
import TestQuestionsView from "./test_questions_view/test_questions_view";
import userTestsExercisesAction from "../../../../../../redux/user_activity/action";
import { Analytics } from "aws-amplify";
import {
  url_24,
  url_26,
  url_81,
  url_88,
  url_76,
  url_39,
  url_174,
  url_175,
  url_25,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import RewardModal from "../../../../../../components/RewardModal/RewardModal";
import CoinUsesModal from "../../../../../../components/CoinUsesModal/CoinUsesModal";

const TestContentComponent = (props) => {
  let {
    history,
    userData,
    testData,
    topicData,
    setUserTestsResultData,
    userPreference,
    startLoading,
    finishLoading,
    subjectData,
    test_type,
  } = props;

  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testQuestionsDetails: [],
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: {},
    currentQuestion: 0,
    countDownTimer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    viewVideo: false,
    earnedCoins: 0,
    coinPopup: false,
    reason: "RewardModal",
    coinDetailsPopup: false,
    coinUsesPoints: [],
    textResultData: {},
  });
  const [selectedSubject, setSubject] = useState("");
  const [selectedSection, setSection] = useState("");

  const handleSubjectChange = (subject) => {
    const section = Object.keys(
      state.subjecSectiontWiseTestQuestions[subject]
    )[0];
    setSubject(subject);
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));
  };
  const handleSectionChange = (section) => {
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
        "matchIndex"
      ];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));
  };

  useEffect(() => {
    if (state.testQuestionsDetails.length) {
      setSubject(state.testQuestionsDetails[state.currentQuestion]["subject"]);
      setSection(
        state.testQuestionsDetails[state.currentQuestion]["section_name"]
      );
    }
  }, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
    coinUsesApi();
  }, []);

  useEffect(() => {
    if (state.step === 2) {
      createCountDownTimer(state.testDetails.duration_mins);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [state.step]);

  let timeInterval;
  const createCountDownTimer = (duration) => {
    let useTime = state.useTime ?? 0;
    useTime = useTime * 1000;

    // duration  is in minutes, first convert it to milliseconds
    let countdownTime = duration * 60 * 1000 + new Date().getTime();
    countdownTime = countdownTime - useTime;

    timeInterval = setInterval(() => {
      let now = new Date().getTime();
      let distance = countdownTime - now;
      if (distance < 0) {
        clearInterval(timeInterval);
      }
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setState((prevState) => ({
        ...prevState,
        countDownTimer: {
          ...prevState.countDownTimer,
          hours,
          minutes,
          seconds,
        },
      }));
    }, 1000);
  };

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestQuestionsList = async (testDetails) => {
    if (test_type.state.type === "dpp") {
      let data = {
        excersie_type: testDetails.excersie_type,
        course_id: userPreference.courseDetails[0].course_id,
        topic_id: topicData.topicId,
        video_id: testDetails.videoId,
      };
      startLoading();
      try {
        const response = await axios.post(url_24, data);
        if (response.data.status === 200) {
          Analytics.record({
            name: "web_dpp_start_test",
            attributes: {
              dpp_type_Name: data.excersie_type,
              topic_id: topicData.topicId,
              topic_name: topicData.topicName,
              subject_id: subjectData?.subjectId,
              subject_name: subjectData.subjectName,
              video_id: data.video_id,
            },
          });
          finishLoading();
          return {
            result: 1,
            data: { apiData: response.data },
          };
        } else {
          finishLoading();
          alert(response.data.message);
          history.push("/store");
          return {
            result: 0,
            data: { apiData: {} },
          };
        }
      } catch (error) {
        console.log(error);
        finishLoading();
        history.push("/store");
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } else if (test_type.state.type === "exercise") {
      let data = {
        excersie_type: testDetails.excersie_type,
        course_id: userPreference.courseDetails[0].course_id,
        topic_id: topicData.topicId,
        // answer_type_id: 4,
        // answer_type: "Subjective",
      };
      startLoading();
      try {
        const response = await axios.post(url_26, data);
        if (response.data.status === 200) {
          Analytics.record({
            name: "web_exercise_start_test",
            attributes: {
              Exercise_type_Name: data.excersie_type,
              topic_id: topicData.topicId,
              topic_name: topicData.topicName,
              subject_id: subjectData?.subjectId,
              subject_name: subjectData.subjectName,
            },
          });
          finishLoading();
          return {
            result: 1,
            data: { apiData: response.data },
          };
        } else {
          finishLoading();
          alert(response.data.message);
          history.push("/store");
          return {
            result: 0,
            data: { apiData: {} },
          };
        }
      } catch (error) {
        finishLoading();
        history.push("/store");
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    }
  };

  const getNowTime = () => {
    let d = new Date();
    return d.getTime();
  };
  const startBtn = async () => {
    let testDetails = { ...state.testDetails };
    if (!isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);
      console.log("returnedResponse", returnedResponse);
      if (returnedResponse.result) {
        returnedResponse.data.apiData.data.AllQuestionList.forEach(
          (element) => {
            element.isReview = false;
            // element.isSkipped = false;
            element.isAttempted = false;
            element.isAnswerGuessed = false;
            element.totalTimeTaken = 0;
            element.isAnswerCorrect = 0;
            element.correctAnswerIdCollection = [];
            element.wrongAnswerIdCollection = [];
            element.userAnswerCollection = [];
            if (element.solution !== null) {
              element.q_text = element.q_text
                ?.replaceAll(
                  'src="ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'src="/ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="/ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="./ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC= "ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                );
              element.solution = element.solution
                .replaceAll(
                  'src="ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'src="/ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="/ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="./ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC= "ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                );
            }
            element.option.forEach((opt) => {
              opt.option = opt.option
                .replaceAll(
                  'src="ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'src="/ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="/ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC="./ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                )
                .replaceAll(
                  'SRC= "ckfinder',
                  'SRC="http://quizmaster.motion.ac.in/ckfinder'
                );
            });
          }
        );
        let time = getNowTime();

        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails:
            returnedResponse.data.apiData.data.AllQuestionList,
          initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
        }));
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };

  const handlePreviousQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    let time = getNowTime();
    handleCheckQuestion();
    setViewVideoFalse();
    handleSaveQuestion();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
      }));
      window.scrollTo(0, 0);
    }
  };

  //check question
  const handleCheckQuestion = async () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let formatData =
      testDetails.format ===
      "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
        ? "MAIN"
        : testDetails.format === "NEET NEW PATTERN 2021 OTS"
        ? "NEET"
        : "OTHER";
    let data = {
      paper_id: crntQuestionDetail.paper_id,
      qid: crntQuestionDetail.qid,
      subject: crntQuestionDetail.subject,
      format: formatData,
      section: crntQuestionDetail.section_name,
    };
    startLoading();
    const response = await axios.post(url_76, data);
    if (response.data.status === 200) {
      setState((prevState) => ({
        ...prevState,
        checkQuestion: response.data.data,
      }));
      finishLoading();
      if (!response.data.data) {
        testQuestionsDetails[currentQuestion].integer_answer = "";
        setState((preState) => ({
          ...preState,
          testQuestionsDetails: testQuestionsDetails,
        }));
        alert(response.data.message);
        return;
      }
      handleSaveQuestion();
    } else if (response.data.status === 422) {
      setState((prevState) => ({
        ...prevState,
        checkQuestion: response.data.data,
      }));
      handleSaveQuestion();
    }
    finishLoading();
  };

  //save prticular question

  const handleSaveQuestion = async () => {
    let { currentQuestion, initialTime, testQuestionsDetails, testDetails } =
      state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let data = {
      negative_marks: crntQuestionDetail.negative_marks,
      option_id: crntQuestionDetail.userAnswerCollection[0]
        ? crntQuestionDetail.userAnswerCollection[0].option_id
        : crntQuestionDetail.answer_type == "Integer Correct"
        ? crntQuestionDetail.integer_answer
        : "",
      paper_id: crntQuestionDetail.paper_id,
      partial_marks: crntQuestionDetail.partial_marks,
      positive_marks: crntQuestionDetail.positive_marks,
      qid: crntQuestionDetail.qid,
      subject: crntQuestionDetail.subject,
      time: crntQuestionDetail.totalTimeTaken,
      topic_name: crntQuestionDetail.topic_name,
      toughness: crntQuestionDetail.toughness,
      course_id: userPreference.courseDetails[0].course_id,
      topic_id: topicData.topicId,

      type:
        test_type.state.type === "dpp" ? "Attending_DPP" : "Attending_EXERCISE",
      paper_type: test_type.state.type === "dpp" ? "DPP" : "EXERCISE",
      exercise_type: testDetails.excersie_type,
      course_id: userPreference.courseDetails[0].course_id,
      topic_id: topicData.topicId,
      video_id: testDetails.videoId,
      user_id: userData.user_id,
      is_saved: 0,
      total_time: crntQuestionDetail.totalTimeTaken,
      type_id: crntQuestionDetail.type_id,
      right_answer: true,
      wrong_answer: false,
    };
    startLoading();
    try {
      const api = test_type.state.type === "dpp" ? url_25 : url_88;
      const response = await axios.post(api, data);

      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    handleCheckQuestion();
    setViewVideoFalse();
    //newline add

    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      testQuestionsDetails[currentQuestion].option.filter(
        (m) => m.is_user_correct_ans
      ).length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));
    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = {
        option_id: optionId,
      };

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element.option_id === optionId;
    });

    if (findedIndex === -1) {
      let Obj = {
        option_id: optionId,
      };
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        Obj
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let value = event;
    let sanitizedValue = value.replace(/[^\d.-]/g, "");
    // Ensure there is at most one '-' at the beginning
    sanitizedValue = sanitizedValue
      .replace(/^-/, "$#$")
      .replace(/-/g, "")
      .replace("$#$", "-");
    // Ensure there is at most one '.' and only allow up to 2 decimal places
    const dotIndex = sanitizedValue.indexOf(".");
    if (dotIndex !== -1) {
      const integerPart = sanitizedValue.substring(0, dotIndex);
      const decimalPart = sanitizedValue.substring(dotIndex + 1, dotIndex + 3);
      sanitizedValue = integerPart + "." + decimalPart;
    }
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer =
      sanitizedValue.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };
  const reviewQuestion = async (currentQuestion) => {
    const { paper_id, qid, isAnswerGuessed } = currentQuestion;

    if (test_type.state.type == "dpp") {
      let data = {
        paper_id: paper_id,
        reference_id: qid,
        status: isAnswerGuessed ? 1 : 0,
        type: 2,
      };
      const response = await axios.post(url_39, data);
    } else if (test_type.state.type == "exercise") {
      let data = {
        paper_id: paper_id,
        reference_id: qid,
        status: isAnswerGuessed ? 1 : 0,
        type: 1,
      };
      const response = await axios.post(url_39, data);
    }
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      //bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      //bookmarkApi(data);
    }
  };
  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    //handleSaveQuestion();
    handleCheckQuestion();
    setViewVideoFalse();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      // currentQuestion:0,
    }));
  };
  //view video
  const handleViewVideo = (videoURL) => {
    setState((prevState) => ({
      ...prevState,
      viewVideo: !state.viewVideo,
    }));
  };

  const setViewVideoFalse = () => {
    setState((prevState) => ({
      ...prevState,
      viewVideo: false,
    }));
  };

  const submitExercise = () => {
    let testDetails = { ...state.testDetails };
    if (test_type.state.type === "dpp") {
      Analytics.record({
        name: "web_dpp_submit_test",
        attributes: {
          dpp_type_Name: testDetails.excersie_type,
          topic_id: topicData.topicId,
          topic_name: topicData.topicName,
          subject_id: subjectData?.subjectId,
          subject_name: subjectData.subjectName,
          video_id: testDetails.video_id,
        },
      });

      addCoinsOnSubmission();
    } else if (test_type.state.type === "exercise") {
      Analytics.record({
        name: "web_exercise_submit_test",
        attributes: {
          Exercise_type_Name: testDetails.excersie_type,
          topic_id: topicData.topicId,
          topic_name: topicData.topicName,
          subject_id: subjectData?.subjectId,
          subject_name: subjectData.subjectName,
        },
      });
      addCoinsOnSubmission();
    }
  };
  const handleSubmitTest = async () => {
    let { testDetails, testQuestionsDetails } = state;

    let questionArr = [];

    testQuestionsDetails.forEach((element) => {
      if (element.answer_type.toLowerCase() === "single correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "multiple correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "integer correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      }
    });
    startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse.result) {
      finishLoading();
      setUserTestsResultData(testDetails);
      addCoinsOnSubmission();
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      paper_id: testDetails.paper_id,
      time: 84,
    };

    try {
      const response = await axios.post(url_81, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const handleRedirectBtn = () => {
    history.push("/pyq_cps_exam");
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <TestInstructionsView
            testDetails={state.testDetails}
            startBtn={startBtn}
            handleRedirectBtn={handleRedirectBtn}
          />
        );
      case 2:
        return (
          <TestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            selectedSubject={selectedSubject}
            selectedSection={selectedSection}
            handleSubjectChange={handleSubjectChange}
            handleSectionChange={handleSectionChange}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={submitExercise}
            checkQuestion={state.checkQuestion}
            userData={userData}
            viewVideo={state.viewVideo}
            handleViewVideo={handleViewVideo}
            setViewVideoFalse={setViewVideoFalse}
          />
        );

      default:
        return null;
    }
  };

  const addCoinsOnSubmission = async () => {
    const { testDetails } = state;
    let data = {};

    if (test_type.state.type === "dpp") {
      data = {
        type: "Attending_DPP",
        paper_type: "DPP",
        exercise_type: testDetails.excersie_type,
        course_id: userPreference.courseDetails[0].course_id,
        topic_id: topicData.topicId,
        video_id: testDetails.videoId,
        user_id: userData.user_id,
      };
    } else {
      data = {
        type: "Attending_EXERCISE",
        paper_type: "Exercise",
        exercise_type: testDetails.excersie_type,
        course_id: userPreference.courseDetails[0].course_id,
        topic_id: topicData.topicId,
        video_id: testDetails.videoId,
        user_id: userData.user_id,
      };
    }

    try {
      const response = await axios.post(url_174, data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          setState((prev) => {
            return {
              ...prev,
              earnedCoins: response.data.data.coins,
              coinPopup: response.data.data.coins > 0 ? true : false,
              reason: response.data.data.description,
            };
          });
          if (response.data.data.coins === 0) {
            history.push({
              pathname: "/topic_component",
            });
          }
        } else {
          history.push({
            pathname: "/topic_component",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: [...response.data.data],
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <RewardModal
        show={state.coinPopup}
        onHide={() => {
          history.push({ pathname: "/topic_component" });
        }}
        earnMoreHandler={() =>
          setState((prev) => {
            return { ...prev, coinDetailsPopup: true, coinPopup: false };
          })
        }
        earnedCoins={state.earnedCoins}
        reason={state.reason}
      />
      <CoinUsesModal
        show={state.coinDetailsPopup}
        onHide={() => {
          history.push({ pathname: "/topic_component" });
        }}
        data={state.coinUsesPoints}
      />

      <div className="Exercise_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.userActivity.exerciseData,
    userPreference: state.userPreference,
    topicData: state.userActivity.topicData,
    subjectData: state.userActivity.subjectData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestContentComponent);
