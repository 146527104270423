import { React, useEffect, useState } from "react";
import "./CpsSubjectSubTopicContent.scss";
import images from "../../../../../../utilities/images/images";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { url_91 } from "../../../../../../custom_utilities/api_services";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userPyqTestsAction from "../../../../../../redux/pyq_test_exercises/action";
import { Modal, ModalBody } from "react-bootstrap";

const CpsSubjectSubTopicContent = (props) => {
  let {
    userPreference,
    pyqtopicData,
    startLoading,
    finishLoading,
    setPyqTestsData,
    CommonBannerData,
  } = props;
  const location = useLocation();
  let subTopic = location.state.subTopic;
  const [state, setState] = useState({
    topicItemList: {},
    topicName: location.state.subTopic,
    showModal: false,
  });
  let history = useHistory();
  useEffect(() => {
    getTopicList();
  }, []);

  const getTopicList = async () => {
    let data = {
      topic_name: subTopic,
    };
    startLoading();
    try {
      const response = await axios.post(url_91, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          topicItemList: response.data.data,
        }));
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const testModule = () => {
    history.push("/PyqCpsExamTest");
  };
  //...sendData
  const onHandleTakeTest = (testData) => {
    setPyqTestsData(testData);
    // if (!CommonBannerData.data.packageActive) {
    //   // history.push({
    //   //   pathname: "/store", state: { tab: "online_learning" }
    //   // })
    //   setState((prevState) => ({
    //     ...prevState,
    //     showModal: true,
    //   }));
    // } else {
      history.push({
        pathname: "/CpsTestComponent",
        state: { subTopic: subTopic },
      });
    // }
  };
  return (
    <>
      <div className="pyq_subjectSubTopicWrapper">
        <div className="container">
          <div className="pyq_subjectTopicInner">
            {state ? (
              <div className="pyqSbjTpcListBox">
                <div className="d-flex">
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/Cps_subjectTopic",
                      state: { tab: "pyq" },
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                  <h1 className="subject_name mx-auto">
                    {state.topicName.topic_name}
                  </h1>
                </div>
                <div className="subject_topic_list">
                  {!isEmpty(state.topicItemList)
                    ? state.topicItemList.length
                      ? state.topicItemList.map((elem, index) => {
                          return (
                            <div
                              className="subject_topics"
                              onClick={() => onHandleTakeTest(elem)}
                            >
                              {elem.is_attempt ? (
                                <div className="sbjct_tpc_name">
                                  {`${elem.sub_topic_name} `}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    (Attempted)
                                  </span>
                                </div>
                              ) : (
                                <div className="sbjct_tpc_name">
                                  {elem.sub_topic_name}
                                </div>
                              )}
                              <div className="arrow">
                                <img src={images.m2_rgtArow} alt="arrow" />
                              </div>
                            </div>
                          );
                        })
                      : null
                    : null}
                </div>
              </div>
            ) : (
              <div className="instructionScreenBox">
                <div className="backBtn">
                  <img src={images.m2_backBtn} alt="backBtn" />
                </div>
                <div className="instruction_title">Instruction Screen</div>
                <div className="instruction_detailBox">
                  <div className="examTime">
                    <div className="examTitle">Exam Topic</div>
                    <div className="examDurations">Duration : 3:00:00</div>
                  </div>
                  <div className="instructions">
                    <h3>Set of Instructions</h3>
                    <div className="instPoint">
                      <p>
                        This Section contains a set of questions for practice
                      </p>
                      <p>
                        Users can go to previous question by clicking on the
                        previous button
                      </p>
                      <p>
                        Users can go to next question by clicking on the next
                        button
                      </p>
                      <h6>Best of luck</h6>
                    </div>
                  </div>
                  <div className="startTestBtn">
                    <button onClick={testModule}>Start Test</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Modal
        id="cm"
          show={state.showModal}
          onHide={() =>
            setState((prevState) => ({
              ...prevState,
              showModal: false,
            }))
          }
          animation={false}
          centered
        >
          <Modal.Body style={{ padding: "35px 25px", position: "relative" }}>
            <p>Conceptual Problem Sheets</p>
            <p>All your free attempts have been completed!</p>
            <p>
              Please click on “
              <strong style={{ color: "#121212", fontWeight: "500" }}>
                Buy Now
              </strong>
              ” button to purchase Conceptual Problem Sheets package for
              attempting further tests.
            </p>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  history.push({
                    pathname: "/store",
                    state: { tab: "online_learning" },
                  });
                }}
              >
                Buy Now
              </button>
            </div>
            <div
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  showModal: false,
                }))
              }
            >
              <img src={images.close_btn} alt="close" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    pyqtopicData: state.userActivity.pyqtopicData,
    CommonBannerData: state.userActivity.commonBannerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPyqTopicData: (payload) => {
      dispatch(userActivityActions.setPyqTopicData(payload));
    },
    setPyqTestsData: (payload) => {
      dispatch(userPyqTestsAction.setPyqTestsData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpsSubjectSubTopicContent);