import { Fragment } from "react";
import "./test_instructions_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";

const TestInstructionsView = (props) => {
  let {
    testDetails,
    testSectionsDetails,
    startBtn,
    handleRedirectBtn,
    testInstructionData,
    total_attempts,
    is_paid,
    time_left,
    history,
  } = props;

  function testTime(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m + " hrs";
  }

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper_cps">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
            <div className="heading-div d-flex justify-content-between">
            <Link
                className="backBtn"
                to={{
                  pathname: "/Cps_subjectTopic",
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>

              <div className="instruction_title">Instruction Screen</div>
            </div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{testDetails?.paper_name}</div>
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <div className="img">
                      <ul>
                        <li>This Section contains a set of questions for practice</li>
                        <li>Users can go to previous question by clicking on the
                        previous button</li>
                        <li>Users can go to next question by clicking on the next
                        button</li>
                      </ul>
                    <h6>Best of luck</h6>
                      <img
                        src={`https://quizmaster.motion.ac.in${testInstructionData?.instructions_image}`}
                        alt="instructions_image"
                      />
                    </div>
                  </div>
                </div>
                {is_paid === 0 ? (
                  <div className="examTime">
                    <div className="examTitle">
                      {" "}
                      Attempts Left : {total_attempts}
                    </div>
                    <div className="examDurations">
                      Time Left to Attempt : {time_left}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="startTestBtn">
          <button onClick={() => startBtn()}>Start Test</button>
        </div>
      </div>
    </Fragment>
  );
};

export default TestInstructionsView;
