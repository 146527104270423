import React from "react";
import "./ProgressIndicator.scss";

const ProgressIndicator = ({
  currentQuestion,
  testQuestionsDetails,
  lang,
  setLang,
}) => {
  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;

  return (
    <div className="testProgressIndicator">
      <div className="qstnCount">
        <span>{currentQuestion + 1}</span>
        {` / `}
        <span>{testQuestionsDetails.length}</span>
      </div>

      <div className="languageSwitcherIcon">
        {testQuestionsDetails[currentQuestion] &&
        testQuestionsDetails[currentQuestion]?.q_text_hindi ? (
          <button onClick={() => setLang(!lang)}>{lang ? "अ" : "A"}</button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProgressIndicator;
