import React, { useEffect, useState, useRef } from "react";
import { Strophe, $pres, $msg } from "strophe.js";
import images from "../../../../../../../../utilities/images/images";
import Avatarr from "./Avatar";
import "./Chatting.scss";
import Emoji from "./Emoji";
import { url_218 } from "../../../../../../../../custom_utilities/api_services";
import Spinner from "../../../../../../../../custom_utilities/spinner/Spinner";


const BOSH_SERVICE = "https://chat.motion.ac.in:7443/http-bind";

function ChatComponent({ streamingDetails, isLive,live_class_id }) {
  const { username, password, name, chatroom } = streamingDetails;
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [selectedTab, setSelectedTab] = useState("chat");
  const [pollData, setPollData] = useState([]);
  const msgContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [showPoll, setShowPoll] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const conn = new Strophe.Connection(BOSH_SERVICE);
    setConnection(conn);

    conn.connect(username, password, (status) => {
      if (status === Strophe.Status.CONNECTED) {
        console.log("Connected");

        conn.send($pres().c("priority").t("5"));
        conn.send(
          $pres({
            to: chatroom + "/" + name,
          }).c("x", { xmlns: "http://jabber.org/protocol/muc" }),
        );

        conn.addHandler(onMessage, null, "message", "groupchat");
      }
    });

    return () => {
      if (conn) {
        conn.disconnect();
        console.log("Disconnected");
      }
    };



  }, []);

  useEffect(() => {
    if (msgContainerRef.current) {
      msgContainerRef.current.scrollTop = msgContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const onMessage = (msg) => {
    const isMyMessage = Strophe.getResourceFromJid(msg.getAttribute("from")) === name;
    const from = Strophe.getResourceFromJid(msg.getAttribute("from"));
    const body = Strophe.getText(msg.getElementsByTagName("body")[0]);
    const delayElement = msg.getElementsByTagName("delay")[0];
    const timestamp = delayElement ? delayElement.getAttribute("stamp") : null;
    let timeString = "";
    if (timestamp) {
      const date = new Date(timestamp);
      timeString = date.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { from, body, isMyMessage, timestamp: timeString },
    ]);

    return true;
  };

  const inputOnchangeHandler = (event) => {
    setInputMessage(event.target.value);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      sendGroupMessage();
    }
  };

  const sendGroupMessage = () => {
    if (inputMessage.length === 0) {
      alert("Please Enter Message");
      return;
    }

    const message = $msg({
      to: chatroom,
      type: "groupchat",
    }).c("body").t(inputMessage);

    connection.send(message);
    setInputMessage("");
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab === "polls") {
      fetchPollData();
    }
  };

  const fetchPollData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${url_218}?live_class_id=${live_class_id}`);
      const data = await response.json();
      setPollData([...data.data.live_url]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching poll data:", error);
      setLoading(false);
    }
  };

  console.log('pollData', pollData);

  return (
    <div className="chatbox-container">
      <div className="chatbox-wrapper">
        <header>
          <h3
            className={`tab ${selectedTab === "chat" ? "active" : ""}`}
            id='chat-tab'
            onClick={() => handleTabClick("chat")}
          >
            <img src={images.chatIcon} alt="chatIcon" />
            <span>Chat</span>
          </h3>
          <h3
            className={`tab ${selectedTab === "polls" ? "active" : ""}`}
            id='poll-tab'
            onClick={() => handleTabClick("polls")}
          >
            <img src={images.pollIcon} alt="pollIcon" />
            <span>Poll</span>
          </h3>
        </header>

        {selectedTab === "chat" && (
          <>
            <section className="message-container" ref={msgContainerRef}>
              {messages?.map((msg, index) => {
                const isMyMessage = msg.from === name;
                let splitAdmin;
                if (msg.body?.includes("##_SPLIT_##")) {
                  splitAdmin = msg.body.split("##_SPLIT_##");
                } else {
                  splitAdmin = [msg.from, msg.body];
                }

                if (msg.from === name) {
                  return (
                    <div className={"msg-list"} key={index}>
                      <div className={"avatar-and-name my"}>
                        <div className="user-name">{msg.timestamp}</div>
                        <div className="user-name">You</div>
                        <div className="avatar">
                          {msg.from ? <Avatarr name={msg.from} /> : ""}
                        </div>
                      </div>
                      <div className="msg-container2">
                        <div
                          className={"user-msg my-msg"}
                          dangerouslySetInnerHTML={{ __html: msg.body }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  if (splitAdmin[0] === password.toLowerCase() || splitAdmin[0] === "ALL") {
                    return (
                      <div className={"msg-list"} key={index}>
                        <div className={isMyMessage ? "name" : "avatar-and-name"}>
                          <div className="avatar">
                            {msg.from ? <Avatarr name={msg.from} /> : ""}
                          </div>
                          <div className="user-name">{msg.from}</div>
                          <div className="user-name">{msg.timestamp}</div>
                        </div>
                        <div className="msg-container1">
                          <div className={"user-msg"} dangerouslySetInnerHTML={{ __html: splitAdmin[1] }}></div>
                        </div>
                      </div>
                    );
                  }
                }
                return null;
              })}
            </section>

            {isLive && streamingDetails?.chat && (selectedTab === "chat") && (
              <section className="input-send-container">
                <div className="input-emoji-wrapper">
                  <div className="emoji-wrapper">
                    <div className="emoji" onClick={() => setOpenEmoji(!openEmoji)}>
                      <img src={images.emojiIcon} alt="emoji-icon" />
                    </div>
                    {openEmoji && (
                      <>
                        <Emoji
                          openEmoji={openEmoji}
                          selectedEmoji={inputMessage}
                          setSelectedEmoji={setInputMessage}
                          setOpenEmoji={setOpenEmoji}
                          inputRef={inputRef}
                          className="emoji-inner-wrapper"
                        />
                        <div className="close-btn" onClick={() => setOpenEmoji(!openEmoji)}>
                          &#x2715;
                        </div>
                      </>
                    )}
                  </div>
                  <div className="msg-input">
                    <input
                      value={inputMessage}
                      placeholder="Type here..."
                      onChange={inputOnchangeHandler}
                      onKeyDown={handleEnterKey}
                      ref={inputRef}
                    />
                  </div>
                </div>
                <div className="send-icon " onClick={sendGroupMessage}>
                  <img src={images.sendBtn} alt="send-btn" />
                </div>
              </section>
            )}
          </>
        )}

        {selectedTab === "polls" && (
          <div className="all-polls">
            

            {loading ? (
              <Spinner
                variant={"custom"}
                className={"live-chat"}
              />
            ) : (
            
              pollData?.map((poll, index) => {
                
                return (
                  <div className="single-poll" key={index}>
                    <iframe src={poll.poll_url} title={`poll-${index}`} />
                  </div>
                )
              
              })
            )
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatComponent;
