// import React, { useRef, useState } from "react";
// import { useLocation, useHistory } from "react-router-dom";
// import LoaderPopUp from "../../../../../../../components/loader/loader";

// const PaymentWebView = () => {
//   const history = useHistory();
//   const location = useLocation();

//   const [isLoading, setIsLoading] = useState(true);
//   const { handleBookDetails } = location.state || {};

//   console.log("handbook details", handleBookDetails);

//   // Construct the handbook URL based on provided details
//   //   const handbookUrl = `https://learning.motion.ac.in/motioneducation/api/v1/handbook_transaction?price=${handleBookDetails?.price}&discount=${handleBookDetails?.discount}&coupon_discount=${handleBookDetails?.coupon_discount}&shipping_charge=${handleBookDetails?.shipping_charge}&total_price=${handleBookDetails?.total_price}&coins_count=${handleBookDetails?.coins_count}&user_id=${handleBookDetails?.userId}&from_cart=${handleBookDetails?.from_cart}&product_id=${handleBookDetails?.product_id}&is_referal=${handleBookDetails?.is_referal}&coupon_code=${handleBookDetails?.coupon_code}&ccapplied_id=${handleBookDetails?.ccapplied_id}`;
//   const handbookUrl = `https://learning.motion.ac.in/motioneducation/api/v1/handbook_transaction?price=${handleBookDetails?.price}&discount=${handleBookDetails?.discount}&coupon_discount=${handleBookDetails?.coupon_discount}&shipping_charge=${handleBookDetails?.shipping_charge}&total_price=${handleBookDetails?.total_price}&coins_count=${handleBookDetails?.coins_count}&user_id=${handleBookDetails?.user_id}&from_cart=${handleBookDetails?.from_cart}`;

//   const webViewRef = useRef(null);

//   const handleWebViewNavigationStateChange = (event) => {
//     const newUrl = event.url;
//     if (newUrl.includes("response/handler")) {
//       const orderStatus = new URL(newUrl).searchParams.get("order_status");
//       history.push("/OrderStatus", { state: { order_status: orderStatus } });
//     }
//   };

//   const handleLoadStart = () => setIsLoading(true);
//   const handleLoad = () => setIsLoading(false);

//   return (
//     <div style={{ flex: 1 }}>
//       {isLoading && <LoaderPopUp />}
//       <iframe
//         ref={webViewRef}
//         src={handbookUrl}
//         title="Payment WebView"
//         onLoad={handleLoad}
//         onLoadStart={handleLoadStart}
//         onLoadEnd={() => setIsLoading(false)}
//         onNavigationStateChange={handleWebViewNavigationStateChange}
//         style={{ width: "100%", height: "100%", border: "none" }}
//       />
//     </div>
//   );
// };

// export default PaymentWebView;

import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import LoaderPopUp from "../../../../../../../components/loader/loader";

const PaymentWebView = () => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const { handleBookDetails } = location.state || {};

  const handbookUrl = `https://learning.motion.ac.in/motioneducation/api/v1/handbook_transaction?price=${handleBookDetails?.price}&discount=${handleBookDetails?.discount}&coupon_discount=${handleBookDetails?.coupon_discount}&shipping_charge=${handleBookDetails?.shipping_charge}&total_price=${handleBookDetails?.total_price}&coins_count=${handleBookDetails?.coins_count}&user_id=${handleBookDetails?.user_id}&from_cart=${handleBookDetails?.from_cart}`;

  const handlePayment = () => {
    setIsLoading(true);
    window.location.href = handbookUrl;
  };

  return (
    <div style={{ flex: 1 }}>
      {isLoading && <LoaderPopUp />}
      <button onClick={handlePayment}>Proceed to Payment</button>
    </div>
  );
};

export default PaymentWebView;
