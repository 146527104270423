import React, { Fragment } from "react";
import images from "../../../utilities/images/images";
import "./Banner.scss";

const Banner = (props) => {
  const { setIsLoginOpen, isLoginOpen } = props;

  const handleEnrollClick = () => {
    setIsLoginOpen(!isLoginOpen);
  };
  return (
    <Fragment>
      <div className="banner-inner-wrapper">
        <div className="banner-content">
          <div className="text1">
            Learn & prepare for competitive exams with Motion
          </div>
          <div className="text2">
            The largest online learning platform that helps you prepare for
            competitive exams like JEE, NEET & various Olympiads.
          </div>
          <div className="enroll-btn" onClick={handleEnrollClick}>
            Enroll Here
          </div>
        </div>

        <div className="banner-img">
          <img src={images.NvSir} alt="nv-sir-pic" />
        </div>
      </div>

      <div className="mobile-banner-inner-wrapper">
        <div className="banner-content">
          <div className="text1">
            Learn & prepare for competitive exams with Motion
          </div>
          <div className="text2">
            The largest online learning platform that helps you prepare for
            competitive exams like JEE, NEET & various Olympiads.
          </div>
          <div className="enroll-btn" onClick={handleEnrollClick}>
            Enroll Here
          </div>
        </div>
        <div className="banner-img">
          <img src={images.NvSir} alt="nv-sir-pic" />
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
