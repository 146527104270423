import { Fragment } from "react";
import "./test_component.scss";
import LoaderPopUp from "../../../.././../components/loader/loader";
import TestContentComponent from "./test_content_component/test_content_component";
//Redux
import { connect } from "react-redux";

const TestComponent = (props) => {
  let { history, loader, userData } = props;
  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          <Fragment>
            <TestContentComponent userData={userData} history={history} />
          </Fragment>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(TestComponent);
