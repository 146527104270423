import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { url_245 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useGetHandbookProducts = () => {
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const queryClient = useQueryClient();

  const { data: initialData } = useQuery({
    queryKey: ["handbook_products"],
    queryFn: async () => {
      startLoading();
      try {
        const response = await axios.get(url_245, headers);
        return response.data.data;
      } catch (err) {
        console.error(err.message);
      } finally {
        finishLoading();
      }
    },
  });

  const getHandbookProducts = async (productId) => {
    try {
      startLoading();
      if (productId) {
        const response = await axios.get(
          `${url_245}?product_id=${productId}`,
          headers
        );
        finishLoading();

        return response.data.data;
      } else {
        const response = await axios.get(url_245, headers);
        finishLoading();

        return response.data.data;
      }
    } catch (err) {
      console.log(err.message);
      finishLoading();
    }
  };

  const { data: handbookProducts, mutate } = useMutation({
    mutationKey: ["handbook_products"],
    mutationFn: ({ productId }) => getHandbookProducts(productId),
    onSuccess: () => {
      queryClient.invalidateQueries(["handbook_products"]);
    },
  });

  return { handbookProducts: handbookProducts || initialData, mutate };
};

export default useGetHandbookProducts;
