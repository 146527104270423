import React, { useEffect, useState } from "react";
import "./SupportCenterInfo.scss";
import images from "../../../../../utilities/images/images";
import axios from "axios";
import { url_198 } from "../../../../../custom_utilities/api_services";

const SupportCenterInfo = ({ handleStepTwo }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(url_198);
      if (response.data.status === 200) {
        setData(response.data.data.validdata[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="SupportCenterInfoWrpr">
      <div className="img-wrapper">
        {/* <img src={images.helpAndSupport} alt="" /> */}
        <div className="text">Motion support center</div>
      </div>

      <div className="form-section">
        <div className="rollnum">
          {/* <small>Roll number</small> */}
          <p>{data.roll_no}</p>
        </div>

        <div className="center-name">
          {/* <small>Center name</small> */}
          <p>{data.center}</p>
        </div>

        <button onClick={handleStepTwo}>Next</button>
      </div>
    </div>
  );
};

export default SupportCenterInfo;
