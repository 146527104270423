import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./terms_n_condition_content_component.scss";
import axios from "axios";
import images from "../../../utilities/images/images";

const TermsNConditionComponent = () => {
  const [state, setState] = useState({
    pageContent: {},
  });

  useEffect(() => {
    getPageContent();
  }, []);

  const url_34 =
    "https://learning.motion.ac.in/motioneducation/api/user/content-list";

  const getPageContent = async () => {
    let cms_type = 3;
    try {
      const response = await axios.get(`${url_34}?cms_type=${cms_type}`);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          pageContent: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderContent = () => {
    if (state.pageContent && state.pageContent.content) {
      return { __html: state.pageContent.content.cms };
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <div className="termsNConditionContentComponent_wrapper">
        <div className="container">
          <div className="termsNConditionContentComponent_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="heading_wrapper">
                  <p className="heading_text_content">Terms & Conditions:</p>
                </div>
              </div>
            </div>
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div
                  className="content_wrapper"
                  dangerouslySetInnerHTML={renderContent()}
                ></div>
              </div>
            </div>
            <div className="back_button_wrapper">
              <Link to="/#footer" className="back_button">
                <img src={images.login_2} alt="backarrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TermsNConditionComponent;
