import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import images from "../../../../../../../../utilities/images/images";
import "./HandbookCart.scss";
import useGetCartProducts from "../../hooks/cart_products/useGetCartProducts";
import saveTransactionAction from "../../../../../../../../redux/handbook_payment_gateway/action";

const HandbookCart = ({ token, tokenType, setTabAction }) => {
  const history = useHistory();
  const { cartProducts } = useGetCartProducts({ token, tokenType });
  const handleRedirectCartPage = () => {
    setTabAction("cart");
    history.push({
      pathname: "/handbook/cart-list",
    });
  };

  return (
    <div className="cart_count_container" onClick={handleRedirectCartPage}>
      <div className="cart_img">
        <img src={images.handbookCart} alt="cart-icon" />
        <div className="cart_count">{cartProducts?.length}</div>
      </div>

      <div className="my_cart_text">My Cart</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setTabAction: (payload) => {
      dispatch(saveTransactionAction.setTab(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(HandbookCart);
