import React, { useState, useEffect } from "react";
import "./TopperComparisonContentComponent.scss";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../utilities/images/images";
import LoaderPopUp from "../../../../../components/loader/loader";

const TopperComparisonContentComponent = (props) => {
  let { parentUserId, paperId, startLoading, finishLoading, loader } = props;

  const [state, setState] = useState({
    studentCompare: [],
    userScore: {},
    singleStudentCompare: {},
    step: 1,
    compareStudent: {},
    user: {},
  });

  useEffect(() => {
    if (state.step === 1) {
      getStudentCompare();
    } else if (state.step === 2 && state.singleStudentCompare.compare_user_id) {
      getCompareStudent();
    }
  }, [state.step]);

  const getStudentCompare = async () => {
    startLoading();

    let data = {
      user_id: parentUserId,
      paper_id: paperId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentCompare",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          userScore: response.data.user_score,
          studentCompare: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getCompareStudent = async () => {
    startLoading();
    let data = {
      user_id: parentUserId,
      paper_id: paperId,
      compare_user_id: state.singleStudentCompare.compare_user_id,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/Comparision_student",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          user: response.data.data.user,
          compareStudent: response.data.data.compareuser,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleStudentCompare = (student) => {
    setState((prevState) => ({
      ...prevState,
      singleStudentCompare: student,
      step: 1,
    }));
  };

  let {
    studentCompare,
    userScore,
    singleStudentCompare,
    step,
    user,
    compareStudent,
  } = state;

  const handleStep = () => {
    setState((prevState) => ({
      ...prevState,
      step: 2,
    }));
  };

  const renderTable = () => {
    if (step === 1) {
      return (
        <section>
          <div className="section-heading">Top 10 students</div>
          <table id="customers" className="topper-table">
            <thead>
              <tr>
                <th>S.no</th>
                <th className="th-name">Name</th>
                <th>Marks</th>
                <th>Rank</th>
                <th>Time Taken</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(studentCompare)
                ? studentCompare.length
                  ? studentCompare.map((element, index) => {
                      return (
                        <tr key={index}>
                          <td className="num td-text">{index + 1}.</td>
                          <td className="td-text num">
                            {element.topper_details?.name}
                          </td>
                          <td className="num">
                            {element.topper_details?.total_marks}
                          </td>
                          <td className="num">
                            {element.topper_details?.rank}
                          </td>
                          <td className="num">22</td>
                        </tr>
                      );
                    })
                  : null
                : null}
            </tbody>
          </table>
        </section>
      );
    } else if (step === 2) {
      return (
        <section>
          <div className="section-heading">Comparing your score</div>
          <table id="comparison-table" className="topper-table">
            <thead>
              <tr>
                <th>Summary</th>
                <th>Your Performance</th>
                <th>{singleStudentCompare?.topper_details?.name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-text">Marks</td>
                <td className="num">{user.marks}</td>
                <td className="num">{compareStudent.marks}</td>
              </tr>
              <tr>
                <td className="td-text">Rank</td>
                <td className="num">{user.rank}</td>
                <td className="num">{compareStudent.rank}</td>
              </tr>{" "}
              <tr>
                <td className="td-text">Time Taken</td>
                <td className="num">{user.time}</td>
                <td className="num">{compareStudent.time}</td>
              </tr>{" "}
              <tr>
                <td className="td-text">Questions Attempted</td>
                <td className="num">{user.qn_attempted}</td>
                <td className="num">{compareStudent.qn_attempted}</td>
              </tr>{" "}
              <tr>
                <td className="td-text">Negative Marks</td>
                <td className="num">{user.negative_marks}</td>
                <td className="num">{compareStudent.negative_marks}</td>
              </tr>{" "}
              <tr>
                <td className="td-text">No of Tough Q's Attempted</td>
                <td className="num">{user.tough_qns_attempted}</td>
                <td className="num">{compareStudent.tough_qns_attempted}</td>
              </tr>
            </tbody>
          </table>
        </section>
      );
    }
  };

  return (
    <div className="topperComparisonWrapper">
      <div className="topperComparisonContent">
        <section>
          <div className="text">
            <p>Compare with top 10 students</p>
          </div>

          <div className="banner">
            <div className="card-left cardd">
              <div className="bottom">
                <div className="text">Your Score</div>
                <div className="num">{userScore.total_marks}</div>
              </div>
            </div>

            {Object.keys(singleStudentCompare).length ? (
              <div className="card-right cardd">
                <div className="img compare">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <img src={images.user_activity_54_1} alt="img" />
                  </button>
                </div>
                <div className="text compare-text">
                  {singleStudentCompare?.topper_details?.name}
                </div>
              </div>
            ) : (
              <div className="card-right cardd">
                <div className="img plus-icon">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <img src={images.plusIcon} alt="img" />
                  </button>
                </div>
                <div className="text">Compare</div>
              </div>
            )}
          </div>

          {Object.keys(singleStudentCompare).length ? (
            <div className="activeCompareBtn" onClick={handleStep}>
              <button>View Comparision</button>
            </div>
          ) : (
            <div className="compareBtn">
              <button>View Comparision</button>
            </div>
          )}
        </section>

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-left">
                  Select and start comparing
                </div>
                <div className="modal-header-right">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div className="headings">
                <ul>
                  <li className="li-name">Name</li>
                  <li className="li-marks">Marks</li>
                  <li className="li-rank">Rank</li>
                </ul>
              </div>

              <div className="modal-body">
                {!isEmpty(state.studentCompare)
                  ? state.studentCompare.length
                    ? state.studentCompare.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="singleStudentDetails"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => handleStudentCompare(element)}
                          >
                            <ul>
                              <li className="li-name">
                                {element.topper_details?.name}
                              </li>
                              <li className="li-marks">
                                {element.topper_details?.total_marks}
                              </li>
                              <li className="li-rank">
                                {element.topper_details?.rank}
                              </li>
                            </ul>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div className="tables">{renderTable()}</div>
      </div>
      {loader && <LoaderPopUp />}
    </div>
  );
};

export default TopperComparisonContentComponent;
