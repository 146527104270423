import React, { useState, useEffect, useMemo, Fragment } from "react";
import HeaderComponent from "./../../../../structure_components/desktop_screens/header_component/header_component";
import "./CartCollection.scss";
import images from "../../../../../../utilities/images/images";
import AddedToCart from "./added_to_cart/AddedToCart";
import FillingAddress from "./filling_address/FillingAddress";
import axios from "axios";
import {
  url_162,
  url_164,
} from "../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import userStoreCartDetailsData from "../../../../../../redux/new_store/action";
import uiActions from "../../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../../components/loader/loader";
import { useHistory, Link, useLocation } from "react-router-dom";
import Circle from "../circle/Circle";
import Footer_component from "../../../../structure_components/desktop_screens/footer_component/Footer_component";
import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import Mobile_header_component from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import Mobile__right_sideBar_component from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Header_component from "./../../../../structure_components/desktop_screens/header_component/header_component";

const CartCollection = (props) => {
  let {
    setStoreCartDetailsData,
    startLoading,
    finishLoading,
    loader,
    tokenType,
    token,
    history,
    userPreference,
    userData,
  } = props;

  let location = useLocation();

  let step2 = location?.state?.step;

  let singleProduct = location?.state?.singleProduct;

  const [state, setState] = useState({
    step: step2 ? 2 : 1,
    cartDetails: {},
    wishList: [],
    flag: false,
  });

  const [circle] = useState(4);
  const [active, setActive] = useState(step2 ? 1 : 0);
  const [width, setWidth] = useState(0);

  const arr = [];
  for (let i = 0; i < circle; i++) {
    arr.push(
      <Circle className={i <= active ? "circle active" : " circle"} key={i}>
        {i}
      </Circle>
    );
  }

  const progressBarNames = [
    "Added to cart",
    " Filled Address",
    "Order Confirmed",
    " Payment",
  ];

  useEffect(() => {
    setWidth((100 / (circle - 1)) * active);
  }, [circle, active]);

  const isProgressdisabled = active >= circle - 1 ? true : false;
  let historys = useHistory();

  const getCartDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    startLoading();
    try {
      const response = await axios.post(url_162, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          cartDetails: response.data,
        }));
        setStoreCartDetailsData(response.data);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getWishList = async () => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_164, { headers });

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          wishList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  useEffect(() => {
    getCartDetails();
    getWishList();
  }, []);

  const handleCheckOut = () => {
    setState((prevState) => ({
      ...prevState,
      step: 2,
      flag: true,
    }));
    handleClickNext();
  };

  const handleWishListPage = () => {
    historys.push("/wishList_collection");
  };

  const handleRedirect = () => {
    historys.push({
      pathname: "/store",
      state: { tab: "hand_books" },
    });
  };

  const handleClickPrev = () => {
    if (active <= 0) {
      setActive(0);
    } else {
      setActive(active - 1);
    }
  };

  const handleClickPrev2 = () => {
    if (active <= 0) {
      setActive(0);
    } else {
      setActive(active - 2);
    }
  };

  const handleClickNext = () => {
    if (active >= circle) {
      setActive(circle);
    } else {
      setActive(active + 1);
    }
  };

  const handleStep1 = () => {
    setState((prevState) => ({
      ...prevState,
      step: 1,
    }));
    handleClickPrev();
  };

  const handleStep2 = () => {
    setState((prevState) => ({
      ...prevState,
      step: 1,
    }));
    handleClickPrev2();
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <AddedToCart
            handleCheckOut={handleCheckOut}
            cartDetails={state.cartDetails}
            getCartDetails={getCartDetails}
            getWishList={getWishList}
            isProgressdisabled={isProgressdisabled}
          />
        );
      case 2:
        return (
          <FillingAddress
            handleStep1={handleStep1}
            handleStep2={handleStep2}
            singleProduct={singleProduct}
            cartDetails={state.cartDetails}
            isProgressdisabled={isProgressdisabled}
            handleClickNext={handleClickNext}
            handleClickPrev={handleClickPrev}
            step={state.step}
            flag={state.flag}
            handleClickPrev2={handleClickPrev2}
          />
        );

      default:
        return null;
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidths = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidths);

    return () => {
      window.removeEventListener("resize", setWidths);
    };
  }, [windowWidth]);

  return (
    <div>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
        /////for Mobile Screens////////////////////
        <Fragment>
          <MobileLeftSideBarComponent />
          <Mobile_header_component
            userPreference={userPreference}
            userData={userData}
          />
          <Mobile__right_sideBar_component history={history} />
        </Fragment>
      ) : (
        /////for Screens greater than Mobile Screens////////////////////
        <Fragment>
          <Header_component history={history} />
        </Fragment>
      )}
      <div className="cart_collection">
        <div className="container">
          <div className="header">
            <div className="left">Cart</div>
            <div className="right">
              <div className="wishLst_addCart">
                <div className="wishList" onClick={handleWishListPage}>
                  <img src={images.user_activity_39} alt="wish-list" />
                  <div className="badge">{state.wishList.length}</div>
                  <div className="wishlist">Wishlist</div>
                </div>
                <div className="addCart">
                  <img src={images.user_activity_38} alt="add-cart" />
                  <div className="badge">{state.cartDetails.total}</div>
                  <div className="cart">Cart</div>
                </div>
              </div>
            </div>
          </div>
          {state.cartDetails.total > 0 ? (
            <section>
              <section>
                <div className="cart_details_wrapper">
                  <div className="content">
                    <div className="progressbar">
                      <div
                        className="progress"
                        style={{ width: width + "%" }}
                      ></div>
                      {arr}
                    </div>

                    <div className="progreesbarlables">
                      {progressBarNames.map((item, index) => {
                        return (
                          <div key={index} className="label">
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>

              <section>{renderView(state.step)}</section>
            </section>
          ) : (
            <section>
              <div className="backBtn_content">
                <Link
                  className="backBtn"
                  to={{
                    pathname: "/store",
                    state: { tab: "hand_books" },
                  }}
                >
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>

                <div className="backBtn_text" style={{ color: "#121212" }}>
                  Back to store
                </div>
              </div>
              <div className="center">
                <div className="text" style={{ color: "#121212" }}>
                  Your Cart is empty!
                </div>
                <button onClick={handleRedirect}>Add Now</button>
              </div>
            </section>
          )}
        </div>
      </div>
      {loader && <LoaderPopUp />}
      <Footer_component />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setStoreCartDetailsData: (payload) => {
      dispatch(userStoreCartDetailsData.setStoreCartDetailsData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartCollection);
