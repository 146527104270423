import React, { useState, useEffect } from "react";
import "./StudentTestReportPdf.scss";
import axios from "axios";
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import LoaderPopUp from "../../components/loader/loader";

const StudentReportPDF = (props) => {
  let {
    parentPaperId,
    parentUserId,
    startLoading,
    finishLoading,
    studentPaperId,
    studentUserId,
    loader,
  } = props;
  const [state, setState] = useState({
    studentPaperAnalysisReport: {},
  });

  useEffect(() => {
    getStudentPaperAnalysisReport();
  }, []);

  const getStudentPaperAnalysisReport = async () => {
    startLoading();
    let data = {
      user_id: parentUserId ? parentUserId : studentUserId,
      paper_id: parentPaperId ? parentPaperId : studentPaperId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/StudentPaperAnalysisReportWeb",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          studentPaperAnalysisReport: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <>
      <div className="studentReportContainer">
        <p
          dangerouslySetInnerHTML={{
            __html: state.studentPaperAnalysisReport.html,
          }}
        ></p>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    parentPaperId: state.parentrootReducer.parentTestReducer.paperId,
    studentPaperId: state.parentrootReducer.parentTestReducer.studentPaperId,
    studentUserId: state.parentrootReducer.parentTestReducer.studentUserId,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(mapStateToProps, mapDipatchToProps)(StudentReportPDF);
