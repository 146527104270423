import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "./VideoJsPlayer.styles.css"; // styles
import "video.js/dist/video-js.css"; // videoJs Default Styles
import "videojs-contrib-quality-levels"; // videoJs Quality levels **
import "videojs-hls-quality-selector"; // videojs Quality Selector **
import "videojs-contrib-eme";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setVideoLastPaused,
  setTotalWatchTime,
} from "../../../redux/watched_video_details/WatchedVideoDetailsSlice";
import axios from "axios";
import { url_226 } from "../../api_services";

const VideoJsPlayer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const remainingWatchTime = useSelector(
    (state) =>
      state.WatchedVideoDetailsSlice?.watchedVideoDetails?.remaining_user_time
  );

  const watchedVideoData = useSelector(
    (state) => state.WatchedVideoDetailsSlice.watchedVideoDetails
  );

  const videoLastPaused = useSelector(
    (state) => state.WatchedVideoDetailsSlice.lastPaused
  );

  const totalWatchTime = useSelector(
    (state) => state.WatchedVideoDetailsSlice.totalWatchTime
  );

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady, source, dType, lastPausedTime } = props;
  const { keySystem } = props;
  const [isLive, setIsLive] = useState(false);
  const watchTimeRef = useRef(0); // Total watch time in seconds
  const intervalRef = useRef(null); // To store the timer interval
  const [isAlertDisplayed, setIsAlertDisplayed] = useState(false);

  const base64DecodeUint8Array = (input) => {
    try {
      const raw = window.atob(input);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));
      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    } catch (error) {
      console.error("Invalid Base64 string", error);
      return new Uint8Array();
    }
  };

  const base64EncodeUint8Array = (input) => {
    const keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN;
      chr3 = i < input.length ? input[i++] : Number.NaN;

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output +=
        keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4);
    }
    return output;
  };

  const arrayToString = (array) => {
    const uint16array = new Uint16Array(array.buffer);
    return String.fromCharCode.apply(null, uint16array);
  };

  const startTimer = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        watchTimeRef.current += 1; // Increment watch time by 1 second
        // Check if remaining watch time is zero and handle accordingly
        if (remainingWatchTime <= watchTimeRef.current) {
          if (playerRef.current) {
            playerRef.current.pause(); // Pause the video
          }
          handleWatchStop();
          setIsAlertDisplayed(true);
          alert(
            "Your 10hrs free watch time is complete, please purchase the package."
          );
          postWatchedVideoDetails();
          history.push("/store");
        }
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleWatchStart = () => {
    startTimer();
  };

  const handleWatchStop = () => {
    stopTimer();
    dispatch(setVideoLastPaused(playerRef.current.currentTime()));
  };

  const calculateTotalWatchTime = () => {
    return watchTimeRef.current;
  };

  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const postWatchedVideoDetails = async () => {
    const { video_id, subject_id, video_duration } = watchedVideoData;

    // const payloadData = {
    //   video_id,
    //   subject_id,
    //   total_duration: timeStringToSeconds(video_duration),
    //   watched_time: Math.round(totalWatchTime),
    //   last_paused: Math.round(videoLastPaused),
    // };

    const payloadData = {
      video_id,
      subject_id,
      total_duration: timeStringToSeconds(video_duration),
      watched_time: calculateTotalWatchTime(),
      last_paused: Math.round(playerRef.current.currentTime()),
    };

    try {
      const response = await axios.post(url_226, payloadData);
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleEndVideo = () => {
    handleWatchStop();
  };

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      const fairplayCertUri =
        "https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=5VT4";
      const licenseUri = "https://license.videocrypt.com/validateLicense";

      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          plugins: {
            eme: {
              keySystems:
                keySystem === "com.apple.fps.1_0"
                  ? {
                      "com.apple.fps.1_0": {
                        getCertificate: (emeOptions, callback) => {
                          videojs.xhr(
                            {
                              url: fairplayCertUri,
                              method: "GET",
                            },
                            (err, response, responseBody) => {
                              if (err) {
                                callback(err);
                                return;
                              }
                              callback(
                                null,
                                base64DecodeUint8Array(responseBody)
                              );
                            }
                          );
                        },
                        getContentId: (emeOptions, initData) => {
                          const contentId = arrayToString(initData);
                          return contentId.substring(
                            contentId.indexOf("skd://") + 6
                          );
                        },
                        getLicense: (
                          emeOptions,
                          contentId,
                          keyMessage,
                          callback
                        ) => {
                          videojs.xhr(
                            {
                              url: licenseUri,
                              method: "POST",
                              responseType: "text",
                              body: "spc=" + base64EncodeUint8Array(keyMessage),
                              headers: {
                                "Content-type":
                                  "application/x-www-form-urlencoded",
                                "pallycon-customdata-v2": source.link.token,
                              },
                            },
                            (err, response, responseBody) => {
                              if (err) {
                                callback(err);
                                return;
                              }
                              callback(
                                null,
                                base64DecodeUint8Array(responseBody)
                              );
                            }
                          );
                        },
                      },
                    }
                  : {
                      [keySystem]: {
                        licenseUrl:
                          "https://license.videocrypt.com/validateLicense?pallyconCustomdataV2=" +
                          source?.link?.token,
                      },
                    },
            },
          },
        },
        () => {
          onReady && onReady(player);
          player.controlBar.progressControl.seekBar.on("mousedown", (event) => {
            event.preventDefault(); // Prevent the default behavior of seeking
          });

          // Set the current time to the last paused time
          if (lastPausedTime) {
            player.currentTime(lastPausedTime);
          }
        }
      ));

      player.on("play", handleWatchStart);
      player.on("pause", handleWatchStop);
      player.on("ended", handleEndVideo);

      player.on("timeupdate", () => {});

      player.qualityLevels();
      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      // var Button = videojs.getComponent("Button");
      // var rewind = videojs.extend(Button, {
      //   constructor: function () {
      //     Button.apply(this, arguments);
      //     this.addClass("rewindIcon");
      //   },
      //   handleClick: function () {
      //     player.currentTime(player.currentTime() - 10);
      //   },
      // });
      // videojs.registerComponent("rewind", rewind);

      // player.getChild("ControlBar").addChild("rewind", {}, 2);

      // var fastForward = videojs.extend(Button, {
      //   constructor: function () {
      //     Button.apply(this, arguments);
      //     this.addClass("fast-forward-icon");
      //   },
      //   handleClick: function () {
      //     player.currentTime(player.currentTime() + 10);
      //   },
      // });
      // videojs.registerComponent("fastForward", fastForward);

      // player.getChild("ControlBar").addChild("fastForward", {}, 3);

      // Custom Rewind Button
      class RewindButton extends videojs.getComponent("Button") {
        constructor(player, options) {
          super(player, options);
          this.addClass("rewindIcon");
          this.controlText("Rewind 10 seconds");
        }

        handleClick() {
          this.player().currentTime(this.player().currentTime() - 10);
        }
      }

      class FastForwardButton extends videojs.getComponent("Button") {
        constructor(player, options) {
          super(player, options);
          this.addClass("fast-forward-icon");
          this.controlText("Fast Forward 10 seconds");
        }

        handleClick() {
          this.player().currentTime(this.player().currentTime() + 10);
        }
      }

      videojs.registerComponent("rewind", RewindButton);
      videojs.registerComponent("fastForward", FastForwardButton);

      player.getChild("ControlBar").addChild("rewind", {}, 2);
      player.getChild("ControlBar").addChild("fastForward", {}, 3);

      player.on("keydown", (e) => {
        const playerVolume = player.volume();
        const playerCurrentTime = player.currentTime();
        switch (e.code) {
          case "Space":
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
            break;
          case "ArrowRight":
            player.currentTime(playerCurrentTime + 10);
            break;
          case "ArrowLeft":
            player.currentTime(playerCurrentTime - 10);
            break;
          case "ArrowUp":
            player.volume(playerVolume + 0.1);
            break;
          case "ArrowDown":
            player.volume(playerVolume - 0.1);
            break;
          case "KeyM":
            player.volume(0);
            break;
          default:
            return;
        }
      });

      if (dType === 1 || dType === 3) {
        if (props?.source?.link?.token) {
          player.src({
            src: props?.source?.link?.file_url,
            type: "application/dash+xml",
            keySystems: {
              [keySystem]:
                "https://license.videocrypt.com/validateLicense?pallyconCustomdataV2=" +
                props?.source?.link?.token,
            },
          });
        }
      }

      videoElement.addEventListener("loadedmetadata", handleMetadata);

      function handleMetadata() {
        setIsLive(player.liveTracker.isLive());
        const videoDuration = videoElement.duration;
      }

      return () => {
        videoElement.removeEventListener("loadedmetadata", handleMetadata);
      };
    }
  }, [options, onReady, keySystem, props.source?.link, lastPausedTime]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        handleWatchStop(); // Ensure the last interval is captured
        const totalWatchTime = calculateTotalWatchTime();
        dispatch(setTotalWatchTime(totalWatchTime));
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  const onBackButtonEvent = () => {
    if (isAlertDisplayed) return;
    const confirmed = window.confirm("Are you sure you want to go back");

    if (confirmed) {
      postWatchedVideoDetails();
      history.push("/topic_component");
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [isAlertDisplayed]);

  useEffect(() => {
    if (remainingWatchTime <= 0) {
      if (playerRef.current) {
        playerRef.current.pause(); // Pause the video
      }
      setIsAlertDisplayed(true);
      alert(
        "Your 10hrs free watch time is complete, please purchase the package."
      );
      postWatchedVideoDetails();

      history.push("/store");
    }
  }, [remainingWatchTime, history]);

  return (
    <>
      <div className={`player ${isLive ? "isLiveVideo" : "noLiveVideo"}`}>
        <div data-vjs-player>
          <video
            ref={videoRef}
            className="video-js vjs-matrix vjs-big-play-centered"
          >
            <source
              src={props?.source?.link?.file_url}
              type={"application/x-mpegURL"}
            />
          </video>
        </div>
      </div>
    </>
  );
};

export default VideoJsPlayer;
