import React, { useState, useEffect } from "react";
import axios from "axios";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import { connect } from "react-redux";
import uiActions from "../../../../../../../redux/ui/action";
import AdaptiveCpsViewSolutionContent from "./AdaptiveCpsViewSolutionContent/AdaptiveCpsViewSolutionContent";

const AdaptiveCpsViewSolution = (props) => {
  let { startLoading, finishLoading, loader, AdaptiveCpsId, userData } = props;
  const [state, setState] = useState({
    testQuestionsDetails: [],
    currentQuestion: 0,
    currentSolutionDetails: {},
    videoShow: false,
  });

  // const [videoShow, setVideoShow] = useState(false);

  const handleVideoShow = () => {
    setState((preState) => ({
      ...preState,
      videoShow: !state.videoShow,
    }));
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    startLoading();
    let data = {
      acps_id: AdaptiveCpsId,
    };
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSViewSolutions",
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((preState) => ({
          ...preState,
          testQuestionsDetails: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleNextQuestionBtn = () => {
    const { currentQuestion, testQuestionsDetails } = state;
    if (currentQuestion < testQuestionsDetails.length - 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion + 1,
        videoShow: false,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handlePreviousQuestionBtn = () => {
    const { currentQuestion } = state;
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        videoShow: false,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handleQuestionJump = (questionNumber) => {
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      videoShow: false,
    }));
  };

  return (
    <div>
      <AdaptiveCpsViewSolutionContent
        testQuestionsDetails={state.testQuestionsDetails}
        currentQuestion={state.currentQuestion}
        currentSolutionDetails={state.currentSolutionDetails}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        handleQuestionJump={handleQuestionJump}
        userData={userData}
        handleVideoShow={handleVideoShow}
        videoShow={state.videoShow}
        state={state}
      />
      {loader && <LoaderPopUp />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    AdaptiveCpsId: state.AdaptiveCpsReducer.AdaptiveCpsId,
    userData: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdaptiveCpsViewSolution);
