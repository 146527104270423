import { createSlice } from "@reduxjs/toolkit";

const LiveVideoStreamingSlice = createSlice({
  name: "liveVideoStreaming",
  initialState: {
      zoomId: "",
      isLive: "",
      live_class_id:""
  },

  reducers: {
    setLiveVideoStreamDetails: (state, action) => {
      state.zoomId = action.payload.zoomId;
      state.isLive = action.payload.isLive;
      state.live_class_id = action.payload.live_class_id
    },
  },
});

export const { setLiveVideoStreamDetails } = LiveVideoStreamingSlice.actions;
export default LiveVideoStreamingSlice.reducer;
