import React from "react";
import "./QuestionSolution.scss";

const QuestionSolution = ({
  testQuestionsDetails,
  currentQuestion,
  currentQuestionDetails,
  lang,
  getClicked,
}) => {
  return (
    <div className="solutionBoxWrapper">
      <div className="solHeading">Solution:</div>
      {getClicked() && testQuestionsDetails.length
        ? testQuestionsDetails.map((element, index) => {
            return (
              <>
                <p
                  className={index == currentQuestion && lang ? "show" : "hide"}
                  key={element.qid + "en"}
                  dangerouslySetInnerHTML={{
                    __html: element.solution,
                  }}
                ></p>
                <p
                  className={
                    index == currentQuestion && !lang ? "show" : "hide"
                  }
                  key={element.qid + "hi"}
                  dangerouslySetInnerHTML={{
                    __html: element.solution_hindi,
                  }}
                ></p>
              </>
            );
          })
        : null}
    </div>
  );
};

export default QuestionSolution;
