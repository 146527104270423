import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { url_252 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const usePostHandbookAddress = () => {
  const queryClient = useQueryClient();
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const postHandbookAddress = async (address) => {
    try {
      startLoading();
      const response = await axios.post(url_252, { ...address }, headers);

      finishLoading();

      return response.data.data;
    } catch (err) {
      finishLoading();
      console.log(err.message);
    }
  };

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["post_handbook_address"],
    mutationFn: ({ address, token, tokenType }) =>
      postHandbookAddress(address, token, tokenType),

    onSuccess: () => {
      queryClient.invalidateQueries(["handbook_address"]);
    },
  });

  return { mutate, isSuccess };
};

export default usePostHandbookAddress;
