import React from "react";
import "./OrderStatusContent.scss";

import images from "../../../../../../../utilities/images/images";
import StatusCard from "./StatusCard";
import CartHeader from "../handbookcart/cartheader/CartHeader";
import { useHistory } from "react-router-dom";

const OrderStatusContent = ({ orderStatusDetails }) => {
  const history = useHistory();

  const orderStatus = orderStatusDetails?.status;

  const goBack = () => {
    history.push({
      pathname: "/user_edit_profile",
      state: { tab: "packages" },
    });
  };
  return (
    <div className="orderStatusContainer">
      <div className="backBtnContainer">
        <div className="backBtnInrContainer">
          <div className="backBtn" onClick={goBack}>
            <img src={images.storeBck} alt="back" className="backBtnImg" />
            <div className="backBtnText">Back</div>
          </div>

          <div className="contactUsBtn">Contact Us</div>
        </div>
      </div>
      <div className="orderStatusinnerWpr">
        <div className="leftSideContainer">
          <div className="productsTotalId">
            <div className="productsTotal">
              Products ({orderStatusDetails?.product_list?.length})
            </div>
            <div className="productsId">
              <span className="productIdText">Order Id</span> -
              <span className="productIdNum">
                {orderStatusDetails?.order_id}
              </span>
            </div>
          </div>
          {orderStatusDetails?.product_list?.map((elem, index) => (
            <div className="handbookCard" key={index}>
              <img src={elem.image} alt="" className="handbookCardImg" />
              <div className="cardDetails">
                <div className="productName">{elem.product_name}</div>
                <div className="productCourse">{elem.target}</div>
                <div className="productPrice_Qty">
                  <div className="productPrice">{elem.price}</div>
                  <div className="productQnty">Qty {elem.qty}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="rightSideContainer">
          <StatusCard
            orderStatus={orderStatus}
            orderStatusDetails={orderStatusDetails}
          />

          {orderStatusDetails.tracking_id && orderStatusDetails.tracking_text && (
            <div className="trackingCard">
              <div className="trackingId">
                Tracking ID: {orderStatusDetails?.tracking_id}
              </div>
              <div className="courierText">
                {orderStatusDetails.tracking_text}
              </div>
            </div>
          )}

          <div className="priceDetailsCard">
            <div className="priceDetailsText">Price details</div>
            <div className="priceRow">
              <div className="priceRowText">Course Price</div>
              <div className="priceRowNum">₹{orderStatusDetails?.price}</div>
            </div>
            <div className="priceRow">
              <div className="priceRowText">Discount</div>
              <div className="priceRowNum priceRowNum2">
                -₹{orderStatusDetails?.discount}
              </div>
            </div>
            <div className="priceRow">
              <div className="priceRowText">Coupon Discount</div>
              <div className="priceRowNum priceRowNum2">
                -₹{orderStatusDetails?.coupon_discount}
              </div>
            </div>
            <div className="priceRow">
              <div className="priceRowText">Motion Coins</div>
              <div className="priceRowNum priceRowNum2">
                {orderStatusDetails?.motion_coin_used}
              </div>
            </div>

            <div className="priceRow">
              <div className="priceRowText">Delivery Charge</div>
              <div className="priceRowNum priceRowNum2">
                {orderStatusDetails?.shipping_charge}
              </div>
            </div>

            <div className="totalPriceContainer">
              <div className="priceRowText">Total Price</div>
              <div className="priceRowNum">
                ₹{orderStatusDetails?.total_price}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusContent;
