import React from "react";
import "./QuestionSolution.scss";

const QuestionSolution = ({
  currentSolutionDetails,
  lang,
  ConvertStringToHTML,
}) => {
  const renderSolution = (currentSolutionDetails, lang) => {
    let html = "";

    if (lang) {
      html = currentSolutionDetails?.sol_text;
    } else {
      const hindiText = currentSolutionDetails?.sol_text_hindi;
      const flag = ConvertStringToHTML(hindiText);
      html = flag ? hindiText : currentSolutionDetails?.sol_text;
    }

    return { __html: html };
  };

  return (
    <div className="QuestionSolution">
      <h5>Solution :</h5>
      <div>
        <p
          dangerouslySetInnerHTML={renderSolution(currentSolutionDetails, lang)}
        ></p>
      </div>
    </div>
  );
};

export default QuestionSolution;
