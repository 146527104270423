import { React, useState, useEffect, useCallback, Fragment } from "react";
import "./WalletContentComponent.scss";
// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
// Router
import { useHistory } from "react-router-dom";
// Images
import images from "../../../../../utilities/images/images";
import { Button, Modal } from "react-bootstrap";
import { url_175, url_176 } from "../../../../../custom_utilities/api_services";
import axios from "axios";
import { withRupeeSymbol } from "../../../../../custom_utilities/custom_useful_functions";
import InfiniteScroll from "react-infinite-scroll-component";

const WalletContentComponent = (props) => {
  let { startLoading, finishLoading, token, tokenType } = props;
  const historyState = useHistory();
  const [state, setState] = useState({
    expiryDate: "",
    about_us: [],
    consolidated_coins: {},
    faq: [],
    pageNo: 1,
    transaction_data: [],
    coinData: {},
    showAllTransactions: false,
    coinUsesPoints: [],
    coinEarnPoints: [],
    encUpi: "",
    encBank: {
      acc_name: "",
      ifsc_code: "",
      acc_no: "",
      cacc_no: "",
    },
    payMode: "",
    coin_balance_redeem: "",
  });

  const [showError, setShowError] = useState(false);
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [showRedeemConfirmModal, setShowRedeemConfirmModal] = useState(false);
  const [showRedemptionSuccessModal, setShowRedemptionSuccessModal] =
    useState(false);
  const [showFinalSuccessModal, setShowFinalSuccessModal] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [upiError, setUpiError] = useState("");

  // Handlers
  const handleShowErrorModal = () => setShowError(true);
  const handleCloseErrorModal = () => setShowError(false);

  const handleShowBankDetailsModal = () => setShowBankDetailsModal(true);
  const handleCloseBankDetailsModal = () => setShowBankDetailsModal(false);

  const handleShowRedeemConfirmModal = () => {
    if (state.encUpi.trim() === "" && state.encBank.acc_no.trim() === "") {
      handleShowErrorModal();
      return;
    }
    setShowError(false);
    setShowRedemptionSuccessModal(true);
  };

  const handleFinalRedeem = async () => {
    handleCloseRedemptionSuccessModal();
    setShowFinalSuccessModal(true);
    await getTransactionLists(1);
  };

  const handleShowRedemptionSuccessModal = async () => {
    handleCloseRedeemConfirmModal();
    setShowFinalSuccessModal(true);
    await getTransactionLists(2);
  };

  const handleCloseRedeemConfirmModal = () => {
    setShowRedeemConfirmModal(false);
  };

  const handleModifyRedemption = () => {
    handleCloseRedemptionSuccessModal();
    setShowBankDetailsModal(true);
  };

  const handleModifyUpiRedemption = () => {
    handleCloseRedemptionSuccessModal();
    setShowError(true);
  };

  const handleCloseRedemptionSuccessModal = () =>
    setShowRedemptionSuccessModal(false);

  const handleCloseFinalSuccessModal = async () => {
    setShowFinalSuccessModal(false);
    await getTransactionLists();
    window.location.reload();
  };

  const [refer, setRefer] = useState({
    updatedTransactionList: [],
    transaction_data: [],
    open: false,
  });

  const [range, setRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  useEffect(() => {
    // Scroll to the top of the page after a short delay
    const scrollToTop = () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };

    // Scroll to the top when the component mounts
    scrollToTop();

    // Restore original scroll behavior when the component unmounts
    return () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };
  }, []);

  const transactionApi = useCallback(
    async (pageNo = state.pageNo) => {
      startLoading();
      try {
        const response = await axios.get(`${url_176}?page=${pageNo}`);

        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            pageNo: response.data.transaction_data.current_page,
            coinData: response.data.consolidated_coins,
            transaction_data: [
              ...prevState.transaction_data,
              ...response.data.transaction_data.data,
            ],
            showAllTransactions:
              response.data.transaction_data.data.length < 5 ? false : true,
            faq: response.data.faq,
            about_us: response.data.about_us,
            expiryDate: response.data.expiry_date,
            encUpi: response?.data?.payment_data?.enc_upi?.upi_id,
            encBank: {
              acc_name: response?.data?.payment_data?.enc_bank?.acc_name,
              ifsc_code: response?.data?.payment_data?.enc_bank?.ifsc_code,
              acc_no: response?.data?.payment_data?.enc_bank?.acc_no,
              cacc_no: response?.data?.payment_data?.enc_bank?.cacc_no,
            },
            payMode: response?.data?.payment_data?.pay_mode,
            coin_balance_redeem: response?.data?.coin_balance_redeem,
          }));
        } else {
          finishLoading();
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
    },
    [state.pageNo, finishLoading, startLoading]
  );

  const coinUsesApi = useCallback(async () => {
    startLoading();
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          coinUsesPoints: response.data.Uses,
          coinEarnPoints: response.data.data,
          faq: response.data.faq,
          about_us: response.data.about_us,
          expiryDate: response.data.expiry_date,
          encUpi: response?.data?.payment_data?.enc_upi,
          encBank: {
            acc_name: response?.data?.payment_data?.enc_bank?.acc_name,
            ifsc_code: response?.data?.payment_data?.enc_bank?.ifsc_code,
            acc_no: response?.data?.payment_data?.enc_bank?.acc_no,
            cacc_no: response?.data?.payment_data?.enc_bank?.cacc_no,
          },
          payMode: response?.data?.payment_data?.pay_mode,
          coin_balance_redeem: response?.data?.coin_balance_redeem,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  }, [finishLoading, startLoading]);

  const fetchMoreData = async () => {
    setState((prevState) => ({ ...prevState, pageNo: prevState.pageNo + 1 }));
    try {
      const response = await axios.get(`${url_176}?page=${state.pageNo + 1}`);
      if (response.data.status === 200) {
        const newTransactionData = response.data.transaction_data.data;
        setState((prevState) => ({
          ...prevState,
          pageNo: response.data.transaction_data.current_page,
          transaction_data: [
            ...prevState.transaction_data,
            ...newTransactionData,
          ],
          showAllTransactions: newTransactionData.length < 5 ? false : true,
        }));
        // Update `hasMoreData` based on whether new data was returned
        setHasMoreData(newTransactionData.length >= 5);
      } else {
        setHasMoreData(false);
      }
    } catch (error) {
      console.log(error);
      setHasMoreData(false);
    }
  };

  const initialTransaction = useCallback(
    async (pageNo = state.pageNo) => {
      try {
        const response = await axios.get(`${url_176}?page=${pageNo}`);
        if (response.data.status === 200) {
          setRefer((prevState) => ({
            ...prevState,
            transaction_data: response.data.transaction_data.data,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [state.pageNo]
  );

  const handleCloseModal = () => {
    setRefer((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  useEffect(() => {
    coinUsesApi();
    transactionApi();
    initialTransaction();
  }, [coinUsesApi, initialTransaction, transactionApi]);

  const getTransactionLists = async (type) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      type,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/coin/redeem/reward",
        data,
        { headers }
      );
      if (response.data.status === 200) {
        setRefer((prevState) => ({
          ...prevState,
          updatedTransactionList: response?.data?.transaction_data?.data,
          transaction_data: [],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postBankDetails = async (type) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let data = {};

    if (type === 1) {
      data = {
        upi_id: state?.encUpi,
        type,
        acc_name: "",
        ifsc_code: "",
        acc_no: "",
        cacc_no: "",
      };
    } else if (type === 2) {
      data = {
        type,
        acc_name: state.encBank?.acc_name,
        ifsc_code: state.encBank.ifsc_code,
        acc_no: state.encBank.acc_no,
        cacc_no: state.encBank.cacc_no,
        upi_id: "",
      };
    }

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/referral/update/accdata",
        data,
        { headers }
      );

      if (response.data.status && type === 2) {
        setShowRedeemConfirmModal(true);
      } else {
        setShowRedemptionSuccessModal(true);
      }
    } catch (error) {
      console.log(error);
      handleShowErrorModal();
    }
  };

  const getPillClasses = (rewardStatus) => {
    switch (rewardStatus) {
      case "Requested":
        return "pill pill-requested";
      case "Redeemed":
        return "pill pill-redeem";
      default:
        return "pill";
    }
  };

  const getDotClasses = (rewardStatus) => {
    switch (rewardStatus) {
      case "Requested":
        return "pill-dot pill-dot-requested";
      case "Redeemed":
        return "pill-dot pill-dot-redeem";
      default:
        return "pill-dot";
    }
  };

  const totalRedeemCoins = state?.coin_balance_redeem / 100;

  const transactionConditionalList = (data) => {
    const rewardStatus = data?.reward_status;

    return (
      <div className="transaction">
        <div className="d-flex justify-content-between align-items-center mb-2 transaction-top">
          <div className="col-3">
            <p className="coin_desc mb-0">{`Motion Coins ${
              data.trans_type === "E" ? "Earned" : "Used"
            }`}</p>
          </div>
          <div className="col-3 text-center">
            <p className="mb-0">{data?.coin_date}</p>
          </div>
          <div className="col-3 text-center">
            <p className={getPillClasses(rewardStatus)}>
              <span className={getDotClasses(rewardStatus)}></span>
              {rewardStatus}
            </p>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center gap-2">
            <p
              className="coin_earn mb-0"
              style={{
                color:
                  (data.trans_type === "E" && rewardStatus === "Requested") ||
                  rewardStatus === "Redeemed"
                    ? "#B50503"
                    : "#3C9D00",
              }}
            >{`${
              (data.trans_type === "E" && rewardStatus === "Requested") ||
              rewardStatus === "Redeemed"
                ? "-"
                : "+"
            } ${data?.coins}`}</p>
            <img src={images.new_motionCoin} width={30} alt="motion_coins" />
          </div>
        </div>
        <div className="d-flex transaction_detail">
          <p className="mb-0">{`Activity : ${data?.description}`}</p>
        </div>
      </div>
    );
  };

  const handleAccountHolderNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setState((prevState) => ({
        ...prevState,
        encBank: {
          ...prevState.encBank,
          acc_name: value,
        },
      }));
    }
  };

  const handleIfscCodeChange = (e) => {
    const value = e.target.value.toUpperCase();
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        ifsc_code: value,
      },
    }));
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        acc_no: value,
      },
    }));
  };

  const handleConfirmAccountNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        cacc_no: value,
      },
    }));
  };
  const validateUpiId = (value) => {
    // Example regex pattern for UPI ID validation
    const upiIdPattern = /^[\w\.-]+@[a-zA-Z]{2,4}$/;
    return upiIdPattern.test(value);
  };

  const handleUpiChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      encUpi: value,
    }));

    if (value.trim() === "") {
      setUpiError(""); // Clear error if field is empty
    } else if (validateUpiId(value)) {
      setUpiError(""); // Clear error if valid
    } else {
      setUpiError("Invalid UPI ID format."); // Set error if invalid
    }
  };

  const isSaveButtonDisabled = () => {
    return upiError?.length > 0 || state?.encUpi?.trim() === "";
  };

  return (
    <>
      <div className="wallet_wrapper">
        <div className="wallet_InrWrapper">
          <div className="container">
            <div className="wallet_contentWpr">
              <div className="walletTabBox">
                <div className="walletTab">
                  <button
                    className="walletBackBtn"
                    onClick={historyState.goBack}
                  >
                    <img src={images.backBtn} width={30} alt="back" />
                  </button>
                  <h3 className="text-center mb-4">Motion Coins</h3>
                  <div className="coinsBanner">
                    <div className="banner-top d-flex justify-content-between align-items-center p-5">
                      <div>
                        <p className="text-center">Total Motion Coins Earned</p>
                        <div className="d-flex justify-content-center">
                          <img src={images.new_motionCoin} alt="coins" />
                          <p className="total-coins">
                            {state?.coin_balance_redeem}
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          className="redeem-coins"
                          onClick={handleShowErrorModal}
                          disabled={state?.coin_balance_redeem < 500}
                        >
                          Redeem Coins
                        </button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between banner-bottom">
                      <div>
                        <p>Expiring on {state?.expiryDate}</p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="m-0 d-flex align-items-center">
                          Coins Value{" "}
                          <span className="d-flex align-items-center ms-3">
                            <img
                              style={{ width: "125px" }}
                              src={images.new_motionCoin1}
                              width={30}
                              alt="coins"
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="walletTransaction_container">
                    <div className="d-flex justify-content-between wallet-heading">
                      <div className="d-flex gap-3">
                        <img
                          src={images.new_transactions}
                          alt="new transactions"
                          width={30}
                        />
                        <h3 className="mb-0"> Motion Wallet Transactions</h3>
                      </div>
                    </div>
                    <div
                      className="walletTransaction_wrapper"
                      id="parentScrollDiv"
                    >
                      <InfiniteScroll
                        dataLength={state.transaction_data.length}
                        next={fetchMoreData}
                        hasMore={hasMoreData}
                        endMessage={
                          <p className="text-center">No more transactions</p>
                        }
                        scrollableTarget="parentScrollDiv"
                      >
                        {state.transaction_data.length > 0 ? (
                          state.transaction_data.map((data) =>
                            transactionConditionalList(data)
                          )
                        ) : (
                          <p className="text-center">
                            No Transaction Available
                          </p>
                        )}
                      </InfiniteScroll>
                    </div>
                  </div>

                  <div className="coins-conditions">
                    <h3>How to use Motion Coins?</h3>
                    <ul>
                      {state?.about_us?.map((points, index) => (
                        <li key={index}>
                          <h6>{points?.name}</h6>
                          <p>
                            {points?.description
                              .split("\r\n")
                              .map((line, idx) => (
                                <Fragment key={idx}>
                                  {line?.trim() && (
                                    <>
                                      {line}
                                      <br />
                                    </>
                                  )}
                                </Fragment>
                              ))}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="coins-conditions">
                    <h3>How to earn Motion Coins?</h3>
                    <p>
                      You can earn Motion Coins by performing various activities
                      on Motion App.
                    </p>
                    <ul>
                      {state?.faq?.map((points) => (
                        <li key={points.id}>
                          <h6
                            dangerouslySetInnerHTML={{ __html: points.name }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: points.description,
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showError}
          onHide={handleCloseErrorModal}
          centered
          className="upi-details-modal"
        >
          <Modal.Header>
            <Modal.Title>Enter Bank Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                postBankDetails(1);
              }}
            >
              <div className="form-group">
                <label htmlFor="upiId">
                  UPI ID <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="upiId"
                  placeholder="Enter UPI ID"
                  value={state.encUpi}
                  onChange={handleUpiChange}
                  className={upiError ? "error-input" : ""}
                />
                {upiError?.length > 0 && (
                  <p className="error-text">{upiError}</p>
                )}
              </div>
              <p>
                Don't have UPI ID?{" "}
                <button
                  type="button"
                  className="add-bank-details"
                  onClick={() => {
                    handleCloseErrorModal();
                    handleShowBankDetailsModal();
                  }}
                >
                  Add Bank Details
                </button>
              </p>
              <div className="button-group">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    if (!isSaveButtonDisabled()) {
                      handleShowRedeemConfirmModal();
                      postBankDetails(1);
                    }
                  }}
                  disabled={isSaveButtonDisabled()}
                >
                  Save & Continue
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showBankDetailsModal}
          onHide={handleCloseBankDetailsModal}
          centered
          className="bankDetailsModal"
        >
          <Modal.Header>
            <Modal.Title>Enter Bank Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                postBankDetails(2);
              }}
            >
              <div className="form-group">
                <label htmlFor="accountHolderName">
                  Account Holder Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="accountHolderName"
                  placeholder="Full Name"
                  value={state.encBank.acc_name}
                  onChange={handleAccountHolderNameChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ifscCode">
                  IFSC Code <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="ifscCode"
                  placeholder="ABCD1211336"
                  value={state.encBank.ifsc_code}
                  onChange={handleIfscCodeChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="accountNumber">
                  Account Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="accountNumber"
                  placeholder="0000000000"
                  value={state.encBank.acc_no}
                  onChange={handleAccountNumberChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmAccountNumber">
                  Confirm Account Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="confirmAccountNumber"
                  placeholder="0000000000"
                  value={state.encBank.cacc_no}
                  onChange={handleConfirmAccountNumberChange}
                />
              </div>
              <div className="button-group">
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    handleCloseBankDetailsModal();
                    postBankDetails(2);
                  }}
                >
                  Save & Continue
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showRedeemConfirmModal}
          onHide={handleCloseRedeemConfirmModal}
          centered
          className="redeem-confirm-modal"
        >
          <Modal.Header>
            <Modal.Title>Verify your details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="confirm-text">
              Please confirm your cash reward redemption details.
            </p>
            <div className="detail-row p-2">
              <span>Coins Value</span>
              <span className="value">{withRupeeSymbol(totalRedeemCoins)}</span>
            </div>
            <div className="detail-row p-2">
              <span>Account Number</span>
              <span className="value">{state.encBank.acc_no}</span>
            </div>
            <div className="detail-row gray p-2">
              <span>IFSC Code</span>
              <span className="value">{state.encBank.ifsc_code}</span>
            </div>
            <div className="detail-row p-2">
              <span>Name</span>
              <span className="value">{state.encBank.acc_name}</span>
            </div>
            <div className="button-group">
              <Button
                variant="outline-primary"
                onClick={handleModifyRedemption}
              >
                Modify
              </Button>
              <Button
                variant="primary"
                onClick={handleShowRedemptionSuccessModal}
              >
                Redeem
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showRedemptionSuccessModal}
          onHide={handleCloseRedemptionSuccessModal}
          centered
          className="redemption-success-modal"
        >
          <Modal.Header>
            <Modal.Title>Your Redemption is Successful!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="confirm-text">
              Please confirm your cash reward redemption details.
            </p>
            <div className="detail-row p-2">
              <span>Coins Value</span>
              <span className="value">{withRupeeSymbol(totalRedeemCoins)}</span>
            </div>
            <div className="detail-row gray p-2">
              <span>UPI ID</span>
              <span className="value">{state.encUpi}</span>
            </div>
            <div className="button-group">
              <Button
                variant="outline-primary"
                onClick={handleModifyUpiRedemption}
              >
                Modify
              </Button>
              <Button variant="primary" onClick={handleFinalRedeem}>
                Redeem
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showFinalSuccessModal}
          onHide={handleCloseFinalSuccessModal}
          centered
          className="final-success-modal"
        >
          <Modal.Body className="text-center">
            <div className="success-icon">
              <img src={images.successfulIcon} alt="Success" />
            </div>
            <h2>Your Redemption is Successful!</h2>
            <p>
              Your amount will be credited to your bank
              <br />
              account within 48 working hours.
            </p>
            <Button onClick={handleCloseFinalSuccessModal}>Ok</Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletContentComponent);
