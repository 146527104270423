import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { url_251 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useGetHandbookAddress = () => {
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const getHandbookAdress = async () => {
    try {
      startLoading();

      const response = await axios.post(url_251, headers);
      finishLoading();

      console.log("response-address", response.data.address);
      return response.data.address;
    } catch (err) {
      finishLoading();
      console.log(err.message);
    }
  };

  const { data: address } = useQuery({
    queryKey: ["handbook_address"],
    queryFn: getHandbookAdress,
  });

  return { address };
};

export default useGetHandbookAddress;
