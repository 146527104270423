import React, { useState, useEffect } from "react";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import "./OtsTestComponent.scss";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import images from "../../../../../utilities/images/images";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import uiActions from "../../../../../redux/ui/action";
import { BASE_URL } from "../../../../../custom_utilities/baseURL";
import { url_118, url_119 } from "../../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../../components/loader/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import OtsAnalytics from "../ots_analytics/OtsAnalytics";
import otsActions from "../../../../../redux/online-test_series/action";
import { setStoreCurrentPackageDetails } from "../../../../../redux/packages_store/PackageSlice";

const OtsTestComponent = (props) => {
  let {
    class_id,
    target_id,
    token,
    tokenType,
    startLoading,
    finishLoading,
    loader,
    setOtsPlannerData,
    setOtsPremiumData,
    setOtsFreeData,
    setStoreCurrentPackageDetails,
  } = props;

  const history = useHistory();

  const [plannerModal, setPlannerModal] = useState(false);
  const [state, setState] = useState({
    otsOngoingTestsList: [],
  });

  let { otsOngoingTestsList } = state;
  const [otsPlanner, setOtsPlanner] = useState({});

  useEffect(() => {
    fetchTopicSubjectList();
  }, []);

  const getOtsPlannerData = async (planner) => {
    startLoading();

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      planner_id: planner.planner_id,
    };

    try {
      const response = await axios.post(url_119, data, { headers });
      if (response.data.status == 200) {
        finishLoading();
        setOtsPlanner(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirect = (test) => {
    setOtsPlannerData(test);
    setOtsPremiumData([]);
    setOtsFreeData([]);
    props.history.push({
      pathname: "/ots_tests",
    });
  };

  const handleShowPlanner = (planner) => {
    getOtsPlannerData(planner);
    setPlannerModal(true);
  };

  const handleClosePlanner = () => {
    setPlannerModal(false);
  };

  let page_limit = 5;

  const fetchTopicSubjectList = async () => {
    // let pageNo = Math.ceil(otsOngoingTestsList.length / page_limit) + 1;
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    startLoading();
    const data = {
      target: target_id,
      class: class_id,
      page: 1,
    };
    try {
      const response = await axios.post(url_118, data, { headers });
      // if (response.data.status === 200 || response.data.data.last_page) {
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          otsOngoingTestsList: [
            ...otsOngoingTestsList,
            ...response.data.data.planner.data,
          ],
        }));
      }
    } catch (error) {
      finishLoading();
    }
  };

  const fetchData = () => {
    fetchTopicSubjectList();
  };

  const handleClickPayNow = (packageDetails) => {
    //  history.push("/")
    setStoreCurrentPackageDetails(packageDetails);
    history.push(`/package/${packageDetails?.package_id}`);
  };

  return (
    <>
      <div className="ots_wrapper">
        <div className="otsTabBox">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="spsFree-tab"
                data-bs-toggle="tab"
                data-bs-target="#spsFree"
                type="button"
                role="tab"
                aria-controls="spsFree"
                aria-selected="true"
              >
                Planners
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="-tab"
                data-bs-toggle="tab"
                data-bs-target="#spsPremium"
                type="button"
                role="tab"
                aria-controls="spsPremium"
                aria-selected="false"
              >
                Analytics
              </button>
            </li>
          </ul>
        </div>
        <div className="otsTabContent">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="spsFree"
              role="tabpanel"
              aria-labelledby="spsFree-tab"
            >
              <div className="ots_test_list">
                {!isEmpty(otsOngoingTestsList) ? (
                  otsOngoingTestsList.length ? (
                    otsOngoingTestsList.map((elem) => {
                      return (
                        <div
                          className="ots_test_list_item ots_test_list_left ots_test_active"
                          key={elem.planner_id}
                        >
                          <div className="ots_test_list_content">
                            <div className="ots_test_list_img">
                              {/* {elem.planner_img ? (
                                <img
                                  src={`${BASE_URL}/${elem.planner_img}`}
                                  alt="img"
                                />
                              ) : ( */}
                              <img
                                src={images.new_user_activity_18}
                                alt="img"
                              />
                              {/* )} */}
                            </div>
                            <div className="ots_test_list_text d-flex justify-content-between align-items-center w-100">
                              <div
                                className="ots_test_list_left ots_test_list_left ots_test_active"
                                onClick={() => handleRedirect(elem)}
                              >
                                <div className="ots_test_list_title">
                                  {elem.planner_type}
                                </div>

                                <div className="ots_test_list_exam">
                                  {elem.planner_name}
                                </div>
                                <div className="ots_test_list_paragraph">
                                  <p>{elem.planner_desc}</p>
                                </div>
                              </div>

                              <div className="ots_test_list_right">
                                <div
                                  className="ots_test_list_planner_icon"
                                  onClick={() => handleShowPlanner(elem)}
                                >
                                  {/* <img src={images.user_activity_22} /> */}
                                </div>

                                <div className="ots_test_list_planner_price">
                                  {elem.is_paid_planner == 0 ? (
                                    <div className="free">
                                      {" "}
                                      <span>Free</span>
                                    </div>
                                  ) : elem.is_purchased == 0 ? (
                                    <>
                                      <div className="ots_test_list_price">
                                        <span className="ots_price_strike">
                                          {`₹${elem.planner_price}`}
                                        </span>

                                        <span className="ots_discount_price">
                                          {`  ₹${
                                            parseInt(elem.planner_price) -
                                            parseInt(elem.planner_discount)
                                          }`}
                                        </span>
                                      </div>

                                      <div
                                        className="ots_test_list_paynow"
                                        onClick={() => handleClickPayNow(elem)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>Pay Now</span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="paid">
                                        <span>Paid</span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="ots_no_data_found_wrapper">
                      <img src={images.m2_video_ntF} />
                      <p>There is no planners yet!</p>
                    </div>
                  )
                ) : null}
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="spsPremium"
              role="tabpanel"
              aria-labelledby="spsPremium-tab"
            >
              <OtsAnalytics />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={plannerModal}
        onHide={handleClosePlanner}
        dialogClassName="my-modal"
        // centered
      >
        <Modal.Header closeButton>
          {" "}
          <h3>Planner Overview</h3>
        </Modal.Header>

        <Modal.Body className="modal-body">
          {!isEmpty(otsPlanner) ? (
            otsPlanner.planner.length ? (
              otsPlanner.planner.map((elem) => {
                return (
                  <div className="ots_view_planner_wrapper">
                    <div className="ots_view_planner">
                      <div class="header" className="ots_view_planner_header">
                        <h2>{elem.planner_name}</h2>

                        <div className="test_free_paid">
                          {elem.planer_test_paid_free == 0 ? (
                            <span className="test_free"> Free!</span>
                          ) : (
                            <span className="test_paid"> Paid!</span>
                          )}
                        </div>
                      </div>
                      <hr />

                      <ul>
                        <div class="row">
                          <div class="col-6">
                            <li>Target:</li>
                            <li>
                              Test Duration :
                              <span>{elem.test_duration_min}</span>
                            </li>
                          </div>
                          <div class="col-6">
                            <li>
                              Total Questions :{" "}
                              <span>{elem.total_questions}</span>
                            </li>
                            <li>
                              Total Marks : <span>{elem.max_marks}</span>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Test name</th>
                          <th>Physics</th>
                          <th>Chemistry</th>
                          <th>Maths</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{elem.test_date}</td>
                          <td>{elem.test_name}</td>
                          <td>{elem.subjects.Physics}</td>
                          <td>{elem.subjects.Chemistry}</td>
                          <td>{elem.subjects.Maths}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })
            ) : (
              <div className="ots_no_data_text">
                <h4>Data Not Available</h4>
              </div>
            )
          ) : null}
        </Modal.Body>

        <div className="modal-footer-btn">
          <button onClick={handleClosePlanner}>Done</button>
        </div>
      </Modal>

      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    authType: state.auth.tokenType,
    class_id: state.auth.user.class_id,
    target_id: state.userPreference.targetDetails[0].target_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setOtsPlannerData: (payload) => {
      dispatch(otsActions.setOtsPlannerData(payload));
    },

    setOtsPremiumData: (payload) => {
      dispatch(otsActions.setOtsPremiumData(payload));
    },

    setOtsFreeData: (payload) => {
      dispatch(otsActions.setOtsFreeData(payload));
    },

    setStoreCurrentPackageDetails: (payload) => {
      dispatch(setStoreCurrentPackageDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtsTestComponent);
