import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import images from "../../../../../utilities/images/images";
import ControlPanel from "./ControlPanel";
import "./notes_feature_content_component.scss";

const NotesFeatureContentComponent = ({
  history,
  notesData,
  tokenType,
  token,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1.5);
  const [pageWidth, setPageWidth] = useState(null);

  useEffect(() => {
    const getPdfData = async () => {
      try {
        const response = await axios.get(
          `https://learning.motion.ac.in/motioneducation/api/notes?file=${
            notesData.notes.match(/\/([^/]+)$/)[1]
          }&id=${notesData.notes_id}`,
          {
            headers: {
              Authorization: `${tokenType} ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        if (response.status === 200) {
          setPdfData(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        setLoading(false);
      }
    };

    getPdfData();
  }, [notesData.notes, notesData.notes_id, token, tokenType]);

  useEffect(() => {
    const calculatePageWidth = () => {
      const maxWidth = Math.min(window.innerWidth - 40, 800);
      setPageWidth(maxWidth);
    };

    calculatePageWidth();
    window.addEventListener("resize", calculatePageWidth);

    return () => window.removeEventListener("resize", calculatePageWidth);
  }, []);

  useEffect(() => {
    const updateScale = () => {
      if (window.innerWidth >= 1024) {
        setScale(1.5);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setScale(1.2);
      } else {
        setScale(1);
      }
    };

    window.addEventListener("resize", updateScale);
    updateScale();

    return () => window.removeEventListener("resize", updateScale);
  }, []);

  const handleRedirect = () => {
    history.goBack();
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.2);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale / 1.2);
  };

  return (
    <Fragment>
      <div className="notesFeatureContentComponent_wrapper">
        <div className="pdf-container">
          <div className="notesFeatureContentComponent_inner_wrapper">
            {" "}
            <div className="pdf-header">
              <div className="btn_wrapper">
                <button onClick={handleRedirect}>
                  <img src={images.user_activity_1} alt="Back" />
                </button>
              </div>
              <div className="headingContentWrapper">
                <div className="titleText">{notesData.notes_title}</div>
              </div>
            </div>
            <div className="notes_wrapper">
              {loading ? (
                <p></p>
              ) : (
                <>
                  <ControlPanel
                    zoomIn={handleZoomIn}
                    zoomOut={handleZoomOut}
                    setScale={setScale}
                    scale={scale}
                    pdfData={pdfData}
                    notesData={notesData}
                  />
                  <div id="pdf-div" className="notes_inner_wrapper">
                    <div className="pdf-container">
                      <Document
                        file={{ data: pdfData }}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        onContextMenu={(e) => e.preventDefault()}
                        renderMode="canvas"
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={pageWidth}
                            scale={scale}
                          />
                        ))}
                      </Document>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotesFeatureContentComponent;
