import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import "./HandBookDetail.scss";
import { Accordion } from "react-bootstrap";
import { Render } from "../../../../../../homepage/Render";
import { url_177 } from "../../../../../../../custom_utilities/api_services";
import { useSelector, useDispatch } from "react-redux";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import "react-toastify/dist/ReactToastify.css";
import uiActions from "../../../../../../../redux/ui/action";
import HandbookAddress from "../handbookaddress/HandbookAddress";
import CartHeader from "../handbookcart/cartheader/CartHeader";
import useAddProductToCart from "../hooks/cart_products/useAddProductToCart";
import useGetHandbookProductDetail from "../hooks/products/useGetHandbookProductDetail";
import CcAvenueHandbook from "../payment/CcAvenueHandbook";
import { connect } from "react-redux";
import images from "../../../../../../../utilities/images/images";
import saveTransactionAction from "../../../../../../../redux/handbook_payment_gateway/action";

const HandBookDetail = ({ user_id, setTabAction }) => {
  const location = useLocation();
  const pathname = location.pathname;

  // Get query parameters

  const dispatch = useDispatch();
  const packageDetails = useSelector(
    (state) => state.HandbookSliceReducer.storeCurrentHandbookDetails
  );

  const { p_id: productId } = packageDetails;

  const { mutate, isSuccess } = useAddProductToCart();
  const { packageData } = useGetHandbookProductDetail(productId);

  console.log("packageData", packageData);

  const paymentDetails = useSelector(
    (state) => state.paymentReducer.ccAvenueDetails
  );

  const startLoading = () => {
    dispatch(uiActions.startLoading());
  };

  const finishLoading = () => {
    dispatch(uiActions.finishLoading());
  };

  const userId = useSelector((state) => state.auth.user.user_id);
  const loader = useSelector((state) => state.ui.loader);

  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [finalAmount, setFinalAmount] = useState();
  const [showCouponList, setShowCouponList] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showFacultyDetails, setShowFacultyDetails] = useState(false);

  const history = useHistory();

  const description = packageData?.PackageInstallmentDetails?.description || "";
  const truncatedDescription =
    description.length > 220
      ? description.substring(0, 220) + "..."
      : description;

  const applyCoupon = async () => {
    if (couponCode.length === 0) {
      alert("Please Enter the Coupon Code");
      return;
    }

    startLoading();
    try {
      const payloadData = {
        user_id: userId,
        package_id: productId,
        order_id: paymentDetails.Order_Id,
        amount:
          parseFloat(packageData?.price_details.course_price) -
            parseFloat(packageData?.price_details.discount) || 0,
        coupon_code: couponCode,
      };

      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/apply_couponweb",
        payloadData
      );

      if (response.data.status === 200) {
        finishLoading();
        toastifyMessage({
          status: response.data.data.status,
          message: response.data.data.message,
        });

        setFinalAmount(response.data.data.updated_amount);

        setCouponDiscount(finalAmount - response.data.data.updated_amount);
        handleCloseCoupon();
      }

      console.log("response of coupon", response);
    } catch (error) {
      console.error("Error applying coupon", error);
      handleCloseCoupon();
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      // Call the API if the checkbox is checked
      applyCoinsApi();
    }
  };

  const applyCoinsApi = async () => {
    try {
      startLoading();
      let payloadData = {
        cart_amount: finalAmount,

        coins_count: packageData?.redeem_motion_coins?.eligible_coins,
        type: "enquiry",
        order_id: paymentDetails.Order_Id,
        checked: true,
      };

      const response = await axios.post(url_177, payloadData);

      if (response.data.status === 200) {
        finishLoading();
        setFinalAmount(response.data.data?.updated_amount);
      }
    } catch (error) {
      finishLoading();
    }
  };

  const settings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openCouponList = () => {
    setShowCouponList(true);
  };

  const handleCloseCoupon = () => {
    setShowCouponList(false);
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };

  const closeFacultyDetails = () => {
    setShowFacultyDetails(false);
  };

  const checkApplyCouponBtnDisabled = () => {
    if (couponCode.length === 0) {
      return "disableapplybtn";
    }

    return "";
  };

  const handleOnChangeCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const expiryDate = packageData?.PackageInstallmentDetails?.expiry_date;

  // Check if the expiry date is not "0000-00-00"
  const shouldShowExpiryDate = expiryDate && expiryDate !== "0000-00-00";

  const goBack = () => {
    history.goBack();
  };

  const handleAddToCart = (productId) => {
    mutate({ productId });
  };

  const handleBuyNow = () => {
    setTabAction("single_product");
    history.push({
      pathname: "/handbook/cart-list",
      state: { cartProducts: [packageData] },
    });
  };

  return (
    <Fragment>
      <HeaderComponent />
      <div className="new_handbook_wrapper">
        <CartHeader />

        <div className="new_handbook_inner_wrapper container-fluid">
          <div className="new_handbook_container">
            <div className="store-banner-content-and-btn-wrapper">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <div>
                  <img src={packageData?.image} alt="banner" />
                </div>
                <div className="store-package">
                  {packageData?.images?.map((ele, index) => (
                    <div className="store-package-img" key={index}>
                      <img src={ele?.src} alt="images" />
                    </div>
                  ))}
                </div>
              </div>
              {packageData?.PackageInstallmentDetails?.thumbnail_tag && (
                <div
                  className="new-label"
                  style={{
                    backgroundColor:
                      packageData?.PackageInstallmentDetails?.tag_background,
                    color:
                      packageData?.PackageInstallmentDetails?.tag_textcolor,
                  }}
                >
                  {packageData?.PackageInstallmentDetails?.thumbnail_tag}
                </div>
              )}
              <div className="product_name">{packageData?.product_name}</div>
              <div className="product_type">{packageData?.type}</div>
              <div className="price_wrapper">
                <div className="discount_price">
                  {" "}
                  ₹{packageData?.discounted_price}
                  {!(
                    parseFloat(packageData?.price) -
                      parseFloat(packageData?.discounted_price) ===
                    0
                  ) && <span className="price">₹{packageData?.price}</span>}
                </div>

                {!(
                  parseFloat(packageData?.price) -
                    parseFloat(packageData?.discounted_price) ===
                  0
                ) && (
                  <div className="discount_percent">
                    <img src={images.store_offer} alt="offer" />
                    {100 -
                      Math.round(
                        (parseFloat(packageData?.discounted_price) /
                          parseFloat(packageData?.price)) *
                          100
                      )}
                    % Off
                  </div>
                )}
              </div>
            </div>

            <Render if={packageData?.product_benefits}>
              <div className="course-benefits-inner-wrapper">
                <h3>Course Benefits</h3>
                <div className="course-benefits-list">
                  <span className="course-benefit-name">
                    {packageData?.product_benefits}
                  </span>
                </div>
              </div>
            </Render>

            <Render if={packageData?.other_details?.length > 0}>
              <div className="other-details-inner-wrapper">
                <h3>Other details</h3>
                <ul>
                  {packageData?.other_details?.map((item, index) => (
                    <li key={index}>{item.name}</li>
                  ))}
                </ul>
              </div>
            </Render>

            <Render if={packageData?.faq?.length > 0}>
              <div className="faq-inner-wrapper">
                <h3>FAQ's (Frequently asked questions)</h3>
                <Accordion className="faq-accordion">
                  {packageData?.faq?.map((faq, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                      <Accordion.Header>{faq.name}</Accordion.Header>
                      <Accordion.Body>{faq.description}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Render>
          </div>

          <div className="cart_actions_wrapper">
            <HandbookAddress />

            <div className="cart_actions">
              <div
                className="handbook_add_cart"
                onClick={() => handleAddToCart(packageData.p_id)}
              >
                Add to cart
              </div>

              <div className="handbook_buynow" onClick={handleBuyNow}>
                Buy Now
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user.user_id,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setTabAction: (payload) => {
      dispatch(saveTransactionAction.setTab(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(HandBookDetail);
