import React from "react";
import "./AnswerdDoubtItem.scss";

const AnsweredDoubtItem = ({ element, index, handClick,convertDate,renderQuestion }) => {
  return (
    <div
      key={index}
      className="single_doubt_wrappr"
      onClick={() => handClick(element)}
    >
      <div className="single_doubt_innr-wrppr">
        <div className="doubt_date"> {`Posted at ${convertDate(element.post_date)}`}</div>
        <div className="doubt">  {renderQuestion(element.question)}</div>

        <div className="answered_by">
          {" "}
          Answered by
          <span>{element.Answer.name}</span>{" "}
        </div>
      </div>
    </div>
  );
};

export default AnsweredDoubtItem;
