import React, { useEffect, useState } from "react";
import axios from "axios";
import uiActions from "../../../../../../../redux/ui/action";
import authActions from "../../../../../../../redux/auth/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import images from "../../../../../../../utilities/images/images";
import "./CcAvenueHandbookResponse.scss";

const CcAvenueHandbookResponse = (props) => {
  const { tokenType, token, userData, orderId } = props;


  const [ccAvenueResponse, setccAvenueResponse] = useState({});
  const history = useHistory();

  useEffect(() => {
    getCcAvenueResponse();
  }, []);

  const getCcAvenueResponse = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(
      `${"https://learning.motion.ac.in/motioneducation/api/v1/get/cca/responsereact_handbook"}?user_id=${
        userData.user_id
      }&orderId=${orderId}&mode=web`,
      { headers }
    );

    if (response.data.status === 200) {
      setccAvenueResponse(response.data);
      if (response.data.payment_status.toLowerCase() === "completed") {
      }
    }
  };

  const goToStore = () => {
    history.push({
      pathname: "/store",
      state: { tab: "hand_books" },
    });
  };

  const goToMyPackages = () => {
    history.push({
      pathname: "/user_edit_profile",
      state: { tab: "packages" },
    });
  };

  const renderLottie = () => {
    if (ccAvenueResponse.payment_status.toLowerCase() == "completed") {
      return (
        <div className="cc_avenue_handbook_success_wrapper">
          <img src={images.handbookOrderSuccess} alt="order-success-img" />

          <div className="order_status">Order Successful!</div>
          <div className="order_id">
            Order Id : <span> {orderId} </span>
          </div>
          <div className="order_text">
            Order has been placed successfully. will be sharing the dispatch
            details soon.
          </div>

          <div className="go_to_store" onClick={goToStore}>
            Go to store
          </div>
          <div className="check_my_pkgs_btn" onClick={goToMyPackages}>
            {" "}
            <button>Check My packages</button>
          </div>
          <div className="check_my_pkgs_text">
            You can check your order details in the my package section
          </div>
        </div>
      );
    } else {
      return (
        <div className="cc_avenue_handbook_failed_wrapper">
          <img src={images.handbookOrderFailed} alt="order-failed-img" />

          <div className="on_no_text">Oh No!</div>
          <div className="order_failed_status">Payment Failed</div>

          <div className="order_failed_text">
            We can’t process your request now, please try again after sometime.
          </div>

          <div className="go_to_store" onClick={goToStore}>
            {" "}
            <button>Go to Store</button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "500px" }} className="cc_avenue_handbook_container">
        {Object.keys(ccAvenueResponse).length ? renderLottie() : ""}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.paymentReducer.orderId,
    userData: state.auth.user,
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    transactionData: state.handBookPaymentData.handBookTransactionDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CcAvenueHandbookResponse);
