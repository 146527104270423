import { Fragment, useState, useEffect, useRef } from "react";
import "./OtsTestQuestionsView.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../.././../../../../../utilities/images/images";

import axios from "axios";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import useMathjax from "../../../../../../../hooks/useMathjax";

const TestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestionDetails,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleLastQuestionSave,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleQuestionJump,
    handleSubmitTest,
    subjecSectiontWiseTestQuestions,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    userId,
    startLoading,
    finishLoading,
    disableInput,
    setDisableInput,
    testDetails,
    oldanswer,
    lang,
    handleChangeLanguage,
    setState,
  } = props;

  useEffect(() => {
    if (
      countDownTimer.hours == 0 &&
      countDownTimer.minutes == 0 &&
      countDownTimer.seconds == 0
    ) {
      alert("Time is up. Test will be submitted.");
      handleSubmitTest(currentQuestionDetails);
      handleLastQuestionSave();
    }
  }, [countDownTimer.seconds]);

  useMathjax(currentQuestionDetails, lang);

  const questionsCollectionRef = useRef(null);
  const [reportErrorData, setReportErrorData] = useState([]);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion]?.qid,
    option_id: "",
    description: "",
  });

  const shouldDisableInput = () => {
    alert(currentQuestionDetails.message1);
    const isSubmited = currentQuestionDetails?.oldanswer?.length ? true : false;
    setDisableInput(!isSubmited);
  };

  useEffect(() => {
    let formatData =
      testDetails.target_type == "JEE (M)"
        ? "MAIN"
        : testDetails?.target_type == "NEET"
        ? "NEET"
        : "OTHER";

    if (formatData === "MAIN") {
      currentQuestionDetails.attempt_count >= 5 && shouldDisableInput();
    } else if (formatData === "NEET") {
      currentQuestionDetails.attempt_count >= 10 && shouldDisableInput();
    }
  }, [currentQuestionDetails]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //  condition for Report Error modal submit button
  const isDisable =
    saveReportErrorData.option_id.length === 0 ||
    saveReportErrorData.description.length === 0
      ? true
      : false;

  //function for getting options Data from api

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggleMenu, setToggleMenu] = useState(false);

  // for report error modal
  const [showError, setShowError] = useState(false);
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const renderOptions = (currentQuestionDetails, lang) => {
    let step;
    let type = currentQuestionDetails?.answer_type?.toLowerCase();

    if (type === "single correct") {
      step = 1;
    } else if (type === "integer correct") {
      step = 2;
    } else {
      step = 3;
    }

    switch (step) {
      case 1:
        return renderSingleSelect(
          currentQuestionDetails,
          currentQuestion,
          lang
        );
      case 2:
        return renderInputField(currentQuestion, lang);
      case 3:
        return renderMultiSelect(currentQuestionDetails, currentQuestion, lang);
      default:
        return null;
    }
  };

  const switchLanguage = () => {
    setState((prevState) => ({
      ...prevState,
      lang: !prevState.lang,
    }));
  };

  // Handle backbutton
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    handleShow();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
  }, [show]);
  //

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "single_select_option_collection_wrapper show"
                : "single_select_option_collection_wrapper hide"
            }
          >
            {questionData.option?.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion2}`}
                        checked={
                          testQuestionsDetails[currentQuestion2]
                            .userAnswerCollection.length
                            ? testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection[0] == element.option_id
                            : oldanswer == element.option_id
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          handleSingleSelectAnswer(event, element.option_id)
                        }
                        disabled={disableInput}
                      />
                      <label
                        htmlFor={element.option_id}
                        className="single_option_wrapper"
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className={`${
                              element.option.includes("rightarrow")
                                ? "rightarrow"
                                : "text_content_3"
                            }`}
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      if (
        questionData.option_hindi !== undefined &&
        questionData.option_hindi !== null &&
        questionData?.option_hindi?.length > 0
      ) {
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 == currentQuestion
                  ? "single_select_option_collection_wrapper show"
                  : "single_select_option_collection_wrapper hide"
              }
            >
              {questionData.option_hindi?.length
                ? questionData.option_hindi.map((element, index) => {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[currentQuestion2]
                                  .userAnswerCollection[0] == element.option_id
                              : oldanswer == element.option_id
                              ? true
                              : false
                          }
                          onChange={(event) =>
                            handleSingleSelectAnswer(event, element.option_id)
                          }
                          disabled={disableInput}
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className={`${
                                element.option.includes("rightarrow")
                                  ? "rightarrow"
                                  : "text_content_3"
                              }`}
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </Fragment>
        );
      } else {
        switchLanguage();
        questionData.option.map((element, index) => {
          return (
            <div key={index} className="single_select">
              <input
                type="checkbox"
                id={element.option_id}
                name={`current_question_${currentQuestion2}`}
                checked={
                  testQuestionsDetails[currentQuestion2].userAnswerCollection
                    .length
                    ? testQuestionsDetails[currentQuestion2]
                        .userAnswerCollection[0] == element.option_id
                    : oldanswer == element.option_id
                    ? true
                    : false
                }
                onChange={(event) =>
                  handleSingleSelectAnswer(event, element.option_id)
                }
                disabled={disableInput}
              />
              <label
                htmlFor={element.option_id}
                className="single_option_wrapper"
              >
                <div className="option_initial">
                  <p className="text_content_2">{`${renderOptionInitial(
                    index
                  )}.`}</p>
                </div>
                <div className="option_final">
                  <p
                    className={`${
                      element.option.includes("rightarrow")
                        ? "rightarrow"
                        : "text_content_3"
                    }`}
                    dangerouslySetInnerHTML={renderOptionText(element.option)}
                  ></p>
                </div>
              </label>
            </div>
          );
        });
      }
    }
  };

  const renderMultiSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "multi_select_option_collection_wrapper show"
                : "multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion2}`}
                        checked={
                          testQuestionsDetails[currentQuestion2]
                            .userAnswerCollection.length
                            ? testQuestionsDetails[
                                currentQuestion2
                              ].userAnswerCollection.find((item) => {
                                return item === element.option_id;
                              })
                            : false
                        }
                        onChange={(event) =>
                          handleMultiSelectAnswer(
                            event,
                            element.option_id,
                            event.target.checked
                          )
                        }
                        disabled={disableInput}
                      />
                      <label
                        htmlFor={element.option_id}
                        className="single_option_wrapper"
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className={`${
                              element.option.includes("rightarrow")
                                ? "rightarrow"
                                : "text_content_3"
                            }`}
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      if (
        questionData.option_hindi !== undefined &&
        questionData.option_hindi !== null &&
        questionData?.option_hindi?.length > 0
      ) {
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 == currentQuestion
                  ? "multi_select_option_collection_wrapper show"
                  : "multi_select_option_collection_wrapper hide"
              }
            >
              {questionData.option_hindi.length
                ? questionData.option_hindi.map((element, index) => {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                          disabled={disableInput}
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className={`${
                                element.option.includes("rightarrow")
                                  ? "rightarrow"
                                  : "text_content_3"
                              }`}
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </Fragment>
        );
      } else {
        switchLanguage();
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 == currentQuestion
                  ? "multi_select_option_collection_wrapper show"
                  : "multi_select_option_collection_wrapper hide"
              }
            >
              {questionData.option.length
                ? questionData.option.map((element, index) => {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                          disabled={disableInput}
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className={`${
                                element.option.includes("rightarrow")
                                  ? "rightarrow"
                                  : "text_content_3"
                              }`}
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </Fragment>
        );
      }
    }
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              value={oldanswer || ""}
              onChange={(event) => handleInputFieldChange(event)}
              disabled={disableInput}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel = (question, index) => {
    const { q_text, q_text_hindi } = question;
    let textToDisplay = lang ? q_text : q_text_hindi;

    if (
      !lang &&
      (!q_text_hindi || !q_text_hindi.replace(/<[^>]+>/g, "").trim())
    ) {
      textToDisplay = q_text;
    }

    if (currentQuestion === index) {
      if (textToDisplay && textToDisplay.length > 0) {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              handleQuestionJump(index);
              setToggleMenu(false);
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      } else {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              handleQuestionJump(index);
              setToggleMenu(false);
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      }
    } else {
      let labelClass = "";
      let additionalClass = "";

      if (question.isAttempted) {
        labelClass = "answered_lbl";
      } else if (question.isAnswerGuessed) {
        labelClass = "isGussedAnswer";
      } else if (question.isReview) {
        labelClass = "review_lbl";
      } else if (question.is_option == 1) {
        additionalClass = "answered_lbl";
      } else if (question.is_option == 2) {
        additionalClass = "skip_lbl";
      } else if (question.isSkipped) {
        labelClass = "skip_lbl";
      } else {
        labelClass = "";
      }

      if (question.isReview) {
        additionalClass = "review_lbl";
      }
      let guessedAdditionalClass;
      if (question.isAnswerGuessed) {
        guessedAdditionalClass = "isGussedAnswer";
      }

      return (
        <label
          className={`unanswered_lbl ${labelClass} ${additionalClass} ${guessedAdditionalClass}`}
          onClick={() => {
            handleQuestionJump(index);
            setToggleMenu(false);
          }}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    }
  };

  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox questionBox1">
            <div>{`${index + 1}.`}</div>
            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox questionBox1">
              <div>{`${index + 1}.`}</div>
              <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };

  // method for converting string(coming from api) into hmtl
  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderQuestion = () => {
    const { q_text, q_text_hindi } = currentQuestionDetails || {}; // Destructure with default empty object
    const isImage = q_text && q_text.includes("<img");
    const isTable = q_text && q_text.includes("<table");
    const isMTable = q_text && q_text.includes("<mtable");

    if (lang) {
      return (
        <div>
          {q_text && (
            <p
              className={`${isImage ? "image-style" : ""} ${
                isTable ? "table-style" : ""
              } ${isMTable ? "mtable-style" : ""}`}
              dangerouslySetInnerHTML={{
                __html: q_text.replaceAll(
                  'src="ckfinder',
                  'src="http://quizmaster.motion.ac.in/ckfinder'
                ),
              }}
            ></p>
          )}
          {isTable && <table className="table-style"></table>}
          {isMTable && <mtable className="mtable-style"></mtable>}
        </div>
      );
    } else {
      if (q_text_hindi && q_text_hindi.trim().length > 0) {
        return (
          <div>
            {q_text_hindi && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text_hindi.replaceAll(
                    'src="ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  ),
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      } else {
        return (
          <div>
            {q_text && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text.replaceAll(
                    'src="ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  ),
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      }
    }
  };

  const renderParagraphQuestion = () => {
    const { p_text, p_text_hindi } = currentQuestionDetails || {}; // Destructure with default empty object
    const isImage = p_text && p_text.includes("<img");
    const isTable = p_text && p_text.includes("<table");
    const isMTable = p_text && p_text.includes("<mtable");

    if (lang) {
      return (
        <div>
          {p_text && (
            <p
              className={`${isImage ? "image-style" : ""} ${
                isTable ? "table-style" : ""
              } ${isMTable ? "mtable-style" : ""}`}
              dangerouslySetInnerHTML={{
                __html: p_text,
              }}
            ></p>
          )}

          {isTable && <table className="table-style"></table>}
          {isMTable && <mtable className="mtable-style"></mtable>}
        </div>
      );
    } else {
      if (p_text_hindi && p_text_hindi.trim().length > 0) {
        return (
          <div>
            {p_text_hindi && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: p_text_hindi,
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      } else {
        return (
          <div>
            {p_text && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: p_text,
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      }
    }
  };

  const renderColumMatchQuestion = () => {
    const { m_p_text, m_p_text_hindi } = currentQuestionDetails || {}; // Destructure with default empty object

    const contentToDisplay = lang
      ? m_p_text
      : m_p_text_hindi
      ? m_p_text_hindi
      : m_p_text;

    return (
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: contentToDisplay,
          }}
        ></p>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="Ots_ppr_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button className="cancel" onClick={handleClose}>
                              Cancel
                            </button>
                            <button
                              onClick={() => {
                                handleSubmitTest(currentQuestionDetails);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <div className="btm_container">
                      <div className="timer_container d-flex justify-content-between">
                        <div className="question_counter">
                          <span>{currentQuestion + 1}</span>
                          {` / `}
                          <span>{testQuestionsDetails.length}</span>
                        </div>
                        <div className="timer_div">
                          <span>
                            <label className="time_number">
                              {countDownTimer.hours}
                            </label>
                            <label className="time_partition">:</label>
                            <label className="time_number">
                              {countDownTimer.minutes}
                            </label>
                            <label className="time_partition">:</label>
                            <label className="time_number">
                              {countDownTimer.seconds > 9
                                ? countDownTimer.seconds
                                : `${"0" + countDownTimer.seconds}`}
                            </label>
                          </span>
                        </div>
                        <div className="right_button d-flex">
                          <div className="bookmark_image_wrapper">
                            {testQuestionsDetails[currentQuestion] &&
                            testQuestionsDetails[currentQuestion]
                              .isAnswerGuessed ? (
                              <img
                                src={images.BookmarkBtn1}
                                onClick={() => handleReview()}
                              />
                            ) : (
                              <img
                                src={images.BookmarkBtn}
                                onClick={() => handleReview()}
                              />
                            )}
                          </div>

                          {currentQuestionDetails?.answer_type?.toLowerCase() ===
                          "integer correct" ? (
                            <>
                              {ConvertStringToHTML(
                                currentQuestionDetails?.q_text_hindi
                              ) ? (
                                <button onClick={() => handleChangeLanguage()}>
                                  {lang ? `अ` : `En`}
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              {ConvertStringToHTML(
                                currentQuestionDetails?.q_text_hindi
                              ) &&
                              currentQuestionDetails?.option_hindi?.length >
                                0 ? (
                                <button onClick={() => handleChangeLanguage()}>
                                  {lang ? `अ` : `En`}
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="subject_select_container">
                        <div className="option_select">
                          <p>Select Subject:</p>
                          <div className="label_container">
                            {Object.keys(subjecSectiontWiseTestQuestions).map(
                              (subjectName, index) => {
                                return (
                                  <div key={index} class="single_select">
                                    <input
                                      type="radio"
                                      id={subjectName}
                                      name="subject"
                                      value={subjectName}
                                      checked={subjectName === selectedSubject}
                                      onChange={() =>
                                        handleSubjectChange(subjectName)
                                      }
                                    />
                                    <label for={subjectName}>
                                      {subjectName}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>

                        <div className="option_select">
                          <p>Change Section:</p>
                          <div className="label_container">
                            {selectedSubject &&
                              Object.keys(
                                subjecSectiontWiseTestQuestions[selectedSubject]
                              ).map((sectionName, index) => {
                                return (
                                  <div key={index} class="single_select">
                                    <input
                                      type="radio"
                                      id={sectionName}
                                      name="section"
                                      value={sectionName}
                                      checked={sectionName === selectedSection}
                                      onChange={() =>
                                        handleSectionChange(sectionName)
                                      }
                                    />
                                    <label for={sectionName}>
                                      {sectionName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="question_topic_container">
                        <p>
                          Question type:
                          <span>
                            {` `}
                            {testQuestionsDetails[currentQuestion]?.type}
                          </span>
                        </p>
                      </div>

                      <div className="question_details">
                        <p className="question_number">
                          Question {currentQuestion + 1}
                        </p>
                        <div className="question">
                          <div>
                            {renderColumMatchQuestion()}

                            {renderParagraphQuestion()}

                            <div id="your-dynamic-content">
                              {renderQuestion()}
                            </div>
                          </div>
                        </div>
                        <div className="questionWrapper">
                          <div className="sub_sub_section_wrapper_3">
                            {currentQuestionDetails !== null &&
                              renderOptions(currentQuestionDetails, lang)}
                          </div>
                        </div>
                      </div>

                      <ToastContainer />

                      <Modal
                        show={showError}
                        onHide={handleCloseReportError}
                        className="errorModal"
                      >
                        <Modal.Header closeButton>
                          {" "}
                          <h3>Report an Error</h3>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                          <div className="error_Popup">
                            <p className="text_content">
                              You can just write to us, If there is any
                              incorrect or error in question and given options.
                            </p>
                            <ul className="errorList">
                              {reportErrorData?.map((element, index) => {
                                return (
                                  <li className="errorListItem" key={index}>
                                    <input
                                      type="radio"
                                      id={element.id}
                                      name="error"
                                      value={element.option_name}
                                      onChange={(event) => {
                                        setSaveReportErrorData({
                                          ...saveReportErrorData,
                                          option_id: event.target.id,
                                        });
                                      }}
                                    />{" "}
                                    <label htmlFor={element.id}>
                                      {element.option_name}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                            <textarea
                              className="textarea_content"
                              type="text"
                              onChange={(event) =>
                                setSaveReportErrorData({
                                  ...saveReportErrorData,
                                  description: event.target.value,
                                })
                              }
                              placeholder="Write to Us..."
                            />{" "}
                            <br />
                            <Button
                              disabled={isDisable}
                              onClick={handlePostReportErrorData}
                            >
                              Submit
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <div className="report_error">
                        <span style={{ marginRight: "5px" }}>
                          <img src={images.user_activity_14_1}></img>
                        </span>{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                          onClick={handleGetReportErrorOptions}
                        >
                          Report an error
                        </span>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_5 row">
                      <div className="left_side_wrapper col-lg-4 col-md-6 col-sm-12 col-4">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handlePreviousQuestionBtn(currentQuestion - 1);
                                scrollUp2();
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="right_side_wrapper col-lg-4 col-md-6 col-sm-12 col-5">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <>
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handleLastQuestionSave();
                                  handleShow();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handleNextQuestionBtn(
                                  currentQuestion + 1,
                                  currentQuestionDetails
                                );
                                scrollUp();
                              }}
                            >
                              Save & Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>

                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button onClick={() => handleShow()}>Submit</button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_4"></label>
                            <label className="question_marking_text">
                              Skipped
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_5"></label>
                            <label className="question_marking_text">
                              Mark For Review
                            </label>
                          </div>
                        </div>
                        <div
                          className="questions_serial_number_collection"
                          ref={questionsCollectionRef}
                        >
                          {testQuestionsDetails.length
                            ? testQuestionsDetails.map((element, index) => (
                                <div
                                  key={index}
                                  className="single_question_number_wrapper single_question_number_wrapper1"
                                >
                                  {renderQuestionLabel(element, index)}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestQuestionsView;
