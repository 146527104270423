import React, { useEffect, useState } from "react";
import VideoJsPlayer from "./VideoPlayer/VideoJsPlayer.component";
import { useSelector } from "react-redux";
import axios from "axios";

export default function VIdeoPlayer({ id_video }) {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState();
  const [dType, setdType] = useState();
  const [key, setkey] = useState();

  const videoDetailsData = useSelector(
    (state) => state.WatchedVideoDetailsSlice
  );

  function getSupportedDRMSystems() {
    if (typeof navigator.requestMediaKeySystemAccess === "function") {
      const supportedConfig = [
        {
          initDataTypes: ["cenc"],
          videoCapabilities: [
            {
              contentType: 'video/mp4;codecs="avc1.42E01E"',
            },
          ],
        },
      ];
      const drmSystems = [
        "com.microsoft.playready",
        "com.apple.fps",
        "com.apple.fps.1_0",
      ];

      const supportedDRM = [];

      return Promise.all(
        drmSystems.map((drmSystem) => {
          return navigator
            .requestMediaKeySystemAccess(drmSystem, supportedConfig)
            .then(() => {
              supportedDRM.push(drmSystem);
            })
            .catch(() => {});
        })
      ).then(() => {
        return supportedDRM;
      });
    } else {
      return Promise.resolve([]);
    }
  }

  const DRM = id_video;
  const url = "https://api.videocrypt.com/getVideoDetailsDrm";
  const token = DRM;

  const formData = new FormData();
  formData.append("flag", 1);
  formData.append("name", token);

  function axioFun(D_id) {
    axios({
      url: url,
      method: "POST",
      headers: {
        accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
        secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
        "user-id": "TESTing_10000903_97",
        "device-type": D_id,
        "device-id": "71d3548555586126ed7071102e663619",
        version: "2",
        "device-name": "ChromeCDM",
        "account-id": "10000903",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        setSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setLoading(true);
    getSupportedDRMSystems().then(function (supportedDRM) {
      if (supportedDRM.length > 0) {
        console.log("Supported DRM systems:");
        supportedDRM.forEach((system) => {
          if (system === "com.apple.fps") {
            axioFun(2);
            setdType(2);
            setkey("com.apple.fps.1_0");
            return false;
          } else if (system === "com.microsoft.playready") {
            console.log("Edge supported DRM");
            axioFun(3);
            setdType(3);
            setkey("com.microsoft.playready");
            return false;
          }
          console.log(system);
        });
      } else {
        console.log("com.widevine.alpha");
        axioFun(1);
        setdType(1);
        setkey("com.widevine.alpha");

        console.log("No supported DRM systems found.");
        setLoading(false);
        return false;
      }
    });
  }, []);

  useEffect(() => {}, [videoDetailsData?.noteAddedVideoTime]);

  //video custom option
  const videoSrc = {
    autoplay: false,
    controls: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],
    aspectRatio: "21:9",
    controlBar: {
      remainingTimeDisplay: true,
    },
  };

  return (
    <>
      {loading
        ? "Loading..."
        : source && (
            <VideoJsPlayer
              source={source}
              keySystem={key}
              options={videoSrc}
              dType={dType}
              notesAddedTime={videoDetailsData?.noteAddedVideoTime ?? 0}
            />
          )}
    </>
  );
}
