import { Fragment } from "react";
import "./signUp_step_two.scss";
import images from "../../../utilities/images/images";

const SignUpStepTwo = (props) => {
  const {
    classes,
    userPreference,
    handleSelectClass,
    courses,
    handleSelectCourse,
    handleBack,
    innerStep,
  } = props;

  return (
    <Fragment>
      <div className="signUp_step_two_wrapper">
        <div className="signup_right_form">
          <div className="signup_form">
            <form>
              <div className="content_inner_wrapper">
                {innerStep === 1 && (
                  <div className="class_course_wrapper">
                    <div className="text_content_wrapper">
                      <img
                        src={images.leftArrow}
                        alt="left-arrow"
                        className="back_button"
                        onClick={handleBack}
                      />
                      <p className="text_content">Select Your Class</p>
                    </div>
                    <div className="radio_collection_wrapper">
                      {classes?.classes?.map((element) => (
                        <div key={element?.class_id} className="single_select">
                          <input
                            type="radio"
                            id={`class_${element?.class_id}`}
                            name="class"
                            value={element?.class_id}
                            onChange={() =>
                              handleSelectClass(element?.class_id)
                            }
                            checked={
                              element?.class_id === userPreference?.class_id
                            }
                          />
                          <label
                            htmlFor={`class_${element?.class_id}`}
                            className={
                              element?.class_id === userPreference?.class_id
                                ? "selected"
                                : ""
                            }
                          >
                            {element.class}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {innerStep === 2 && (
                  <div className="class_course_wrapper">
                    <div className="text_content_wrapper">
                      <img
                        src={images.leftArrow}
                        alt="left-arrow"
                        className="back_button"
                        onClick={handleBack}
                      />
                      <p className="text_content">Select Course</p>
                    </div>
                    <div className="radio_collection_wrapper">
                      {courses?.course?.map((element) => (
                        <div key={element.course_id} className="single_select">
                          <input
                            type="radio"
                            id={`course_${element.course_id}`}
                            name="course"
                            value={element.course_id}
                            onChange={() =>
                              handleSelectCourse(element.course_id)
                            }
                            checked={
                              element.course_id === userPreference.course_id
                            }
                          />
                          <label htmlFor={`course_${element.course_id}`}>
                            {element.course}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpStepTwo;
