import React from "react";
import { Modal } from "react-bootstrap";
import "./TermsAndCondition.scss";

const TermsAndCondition = ({ handleClose, terms }) => {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      dialogClassName="pkg-terms"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="hed">Terms and Conditions</div>
        <div className="pkg-terms-conditions">{terms}</div>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndCondition;
