import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./DoubtDetailModal.scss";
import AudioPlayer from "../../../../../../custom_utilities/AudioPlayer/AudioPlayer";
import FullScreenImageModal from "./FullScreenImageModal";

const DoubtDetailModal = ({
  showDbtDetail,
  handleDtlClose,

  selectedDoubt,
  renderQuestion,
  convertDate,
}) => {
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  const handleOpenFullScreenModal = (imgUrl) => {
    setShowFullScreenModal(true);
    setImgUrl(imgUrl);
  };

  const handleCloseFullScreenModal = () => {
    setShowFullScreenModal(false);
  };

  const imageWrapper = (image) => {
    return image ? (
      <div className="image_wrapper">
        <div className="image_inner_wrapper">
          <img src={image} alt="doubt_img" />

          <span
            className="view_image"
            onClick={() => handleOpenFullScreenModal(image)}
          >
            View Image
          </span>
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="douts_detailModal">
      <Modal
        show={showDbtDetail}
        onHide={handleDtlClose}
        dialogClassName="view_doubts_modal"
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <div className="posted_doubt_details">
            <div className="posted_date">
              Posted at {convertDate(selectedDoubt?.post_date)}
            </div>
            <div className="posted_doubt">
              {renderQuestion(selectedDoubt?.question)}
            </div>

            {imageWrapper(selectedDoubt?.Questionfiles[0]?.files)}
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="doubt_answer_details">
            <div className="doubt_answered_by">
              {selectedDoubt?.Answer?.name}
            </div>

            <div className="doubt_answer">{selectedDoubt?.Answer?.answer}</div>

            <div>{imageWrapper(selectedDoubt?.AnswerFile[0]?.files)}</div>

            <AudioPlayer audioUrl={selectedDoubt?.audio[0]?.audio} />
          </div>
        </Modal.Body>
      </Modal>
      <FullScreenImageModal
        image={imgUrl}
        show={showFullScreenModal}
        handleCloseFullScreenModal={handleCloseFullScreenModal}
      />
    </div>
  );
};

export default DoubtDetailModal;
