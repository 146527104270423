import { Bar } from "react-chartjs-2";

function SubjectWiseMarks(props) {
  let { dataPoints, target_id } = props;

  const labels = [""];

  // this is for adding margin to the label( header)
  const legendMargin = [
    {
      id: "legendMargin",
      beforeInit(chart, legend, options) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return (this.height += 20);
        };
      },
    },
  ];

  const datasets = dataPoints
    ?.slice(0, target_id === 3 ? dataPoints.length : 3)
    .map((dataPoint, index) => ({
      label: dataPoint.symbol,
      backgroundColor: getBackgroundColor(index),
      data: [Number(dataPoint.Total)],
      barThickness: 70,
    }));

  function getBackgroundColor(index) {
    const colors = ["#FFAFCA", "#819CFF", "#FFC557", "#00A9B3"];
    return colors[index] || "";
  }

  const data = {
    labels,
    datasets,
  };

  return (
    <div className="sec_1_inner_wrapper" style={{ height: "350px" }}>
      <Bar
        options={{ maintainAspectRatio: false }}
        plugins={legendMargin}
        data={data}
      />
    </div>
  );
}

export default SubjectWiseMarks;
