import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import images from "../../../../../../../../../../../utilities/images/images";
import { toastifyMessage } from "../../../../../../../../../../../custom_utilities/toastify_config";
import {
  url_101,
  url_102,
} from "../../../../../../../../../../../custom_utilities/api_services";

const ReportErrorModal = ({
  startLoading,
  finishLoading,
  userId,
  testQuestionsDetails,
  currentQuestion,
}) => {
  const [reportErrorData, setReportErrorData] = useState([]);

  const [showError, setShowError] = useState(false);
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);
  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion]?.qid,
    option_id: "",
    description: "",
  });
  const isDisable = saveReportErrorData.option_id.length === "" ? true : false;

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };
  // function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage("Your Report has Submitted Successfully");
          setSaveReportErrorData({ option_id: "" });
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <div
        style={{
          marginTop: "auto",
          color: "#B50503",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "14px",
          textDecoration: "none",
          marginBottom: "35px",
        }}
      >
        <span style={{ marginRight: "5px", cursor: "pointer" }}>
          <img src={images.user_activity_14_1} alt="img" />
        </span>{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={handleGetReportErrorOptions}
        >
          Report an error
        </span>
      </div>

      <Modal
        show={showError}
        onHide={handleCloseReportError}
        className="errorModal"
      >
        <Modal.Header closeButton>
          {" "}
          <h3>Report an Error</h3>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="error_Popup">
            <p className="text_content">
              You can just write to us, If there is any incorrect or error in
              question and given options.
            </p>
            <ul className="errorList">
              {reportErrorData?.map((element, index) => {
                return (
                  <li className="errorListItem" key={element.id}>
                    <input
                      type="radio"
                      id={element.id}
                      name="error"
                      value={element.option_name}
                      onChange={(event) => {
                        setSaveReportErrorData({
                          ...saveReportErrorData,
                          option_id: event.target.id,
                        });
                      }}
                    />{" "}
                    <label htmlFor={element.id}>{element.option_name}</label>
                  </li>
                );
              })}
            </ul>
            <textarea
              className="textarea_content"
              type="text"
              onChange={(event) =>
                setSaveReportErrorData({
                  ...saveReportErrorData,
                  description: event.target.value,
                })
              }
              placeholder="Write to Us..."
            />{" "}
            <br />
            <Button disabled={isDisable} onClick={handlePostReportErrorData}>
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportErrorModal;
