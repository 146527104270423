import { Fragment, useState } from "react";
import "./bookmark_view_questions_view.scss";
import images from "../../../../../../../utilities/images/images";
import "react-toastify/dist/ReactToastify.css";
import VideoDrmPlayer from "../../../../../features/video_feature/video_feature_content_component/VideoDrmPlayer";
import { useHistory } from "react-router-dom";

const BookmarkViewQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userId,
    handleBookmarkQuestion,
  } = props;
  const history = useHistory();
  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [videoShow, setVideoShow] = useState(false);
  const [isList, setisList] = useState(true);
  const [lang, setLang] = useState(true);
  const renderOptions = (currentSolutionDetails, lang) => {
    if (currentSolutionDetails) {
      let step,
        type = currentSolutionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentSolutionDetails, lang);
        case 2:
          return renderInputField(currentSolutionDetails, lang);
        case 3:
          return renderMultiSelect(currentSolutionDetails, lang);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderSingleSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderMultiSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion]?.integer_answer}
              //onChange={(event) => handleInputFieldChange(event)}
              disabled
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };
  const handleVideoShow = () => {
    setVideoShow(true);
  };

  const renderVideo = () => {
    const videoId = testQuestionsDetails[currentQuestion]?.video_cript_id;

    const hindiVideoId = testQuestionsDetails[currentQuestion]?.video_cript_id;

    const isHindi = !lang && hindiVideoId;

    const selectedVideoId = isHindi ? hindiVideoId : videoId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const isIframe = urlPattern.test(selectedVideoId);

    const buttonLabel =
      videoShow && !lang
        ? "वीडियो समाधान बंद करें"
        : videoShow && lang
        ? "Close Video Solution"
        : !videoShow && !lang
        ? "वीडियो समाधान देखें"
        : "View Video Solution";

    if (selectedVideoId && selectedVideoId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>

          {videoShow && isIframe ? (
            <iframe
              key={selectedVideoId}
              id="iframe"
              title="video"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}

          {videoShow && !isIframe ? (
            <div style={{ display: "block", width: "100%", height: "400px" }}>
              <VideoDrmPlayer key={videoId} id_video={videoId} />
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">
                <div className="questionBox">{`${index + 1}`}</div>
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                setVideoShow(false);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      }
    }
  };
  const renderQuestionLabel1 = (question, index) => {
    const { q_text, q_text_hindi } = question;
    const isTable = q_text.includes("<table");
    const isImage = q_text.includes("<img");
    let textToDisplay = "";
    if (lang) {
      textToDisplay = q_text;
    } else {
      textToDisplay = q_text_hindi || q_text; // Show Hindi if available, otherwise fallback to English
    }

    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => {
            handleQuestionJump(index);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <div className={`QBox ${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="arrow" />
        </label>
      );
    } else if (question.isAttempted) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 answered_lbl"
          onClick={() => {
            handleQuestionJump(index);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <div className={`QBox ${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="arrow" />
        </label>
      );
    } else {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1"
          onClick={() => {
            handleQuestionJump(index);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <div className={`QBox ${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="arrow" />
        </label>
      );
    }
  };
  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <div className="bookMark_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">
                        {testQuestionsDetails[currentQuestion]?.exam_name ??
                          "All Bookmark Questions"}
                      </p>
                      <button
                        onClick={() => {
                          history.push("/user_bookmarks");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="sub_section_2_wrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">All Questions</p>
                        <div className="d-flex gap-2">
                          <button onClick={() => setisList(true)}>
                            {isList ? (
                              <img
                                src={images.user_activity_list1}
                                alt="list1"
                              />
                            ) : (
                              <img src={images.user_activity_list} alt="list" />
                            )}
                          </button>
                          <button onClick={() => setisList(false)}>
                            {isList ? (
                              <img src={images.user_activity_grid} alt="grid" />
                            ) : (
                              <img
                                src={images.user_activity_grid1}
                                alt="grid1"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="question_marking_wrapper">
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_1"></label>
                          <label className="question_marking_text">
                            Completed
                          </label>
                        </div>
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_3"></label>
                          <label className="question_marking_text">
                            Pending
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className={`single_question_number_wrapper ${
                                  isList
                                    ? "single_question_number_wrapper1"
                                    : ""
                                }`}
                              >
                                {isList ? (
                                  <>{renderQuestionLabel1(element, index)}</>
                                ) : (
                                  <>{renderQuestionLabel(element, index)}</>
                                )}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="timer_container d-flex justify-content-between">
                      <div className="question_counter">
                        <span>{currentQuestion + 1}</span>
                        {` / `}
                        <span>{testQuestionsDetails.length}</span>
                      </div>

                      <div className="markReview right_button d-flex">
                        {testQuestionsDetails.map((element, index) => {
                          return (
                            <div key={index} style={{ cursor: "pointer" }}>
                              {index === currentQuestion && (
                                <>
                                  {element.is_saved ? (
                                    <img
                                      alt=""
                                      src={images.bookmark_img}
                                      onClick={() =>
                                        handleBookmarkQuestion(
                                          element,
                                          props.type === "pyq"
                                            ? 6
                                            : props.type === "dpp"
                                            ? 2
                                            : 1
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src={images.user_activity_3}
                                      onClick={() =>
                                        handleBookmarkQuestion(
                                          element,
                                          props.type === "pyq"
                                            ? 6
                                            : props.type === "dpp"
                                            ? 2
                                            : 1
                                        )
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        height: "63vh",
                        padding: "10px",
                      }}
                    >
                      <div className="text_content_wrapper">
                        <h4 className="text_content_wrapper_heading">
                          Saved Bookmark's
                        </h4>
                        <div className="typeLanguageWrapper">
                          <span>Question type: {handleQuestionType()}</span>
                        </div>

                        <div className="questionBox">
                          <span className="text_content">
                            {`${Number(currentQuestion) + 1}.`}
                          </span>
                          {testQuestionsDetails.length
                            ? testQuestionsDetails.map((element, index) => {
                                return (
                                  <>
                                    <p
                                      className={
                                        index == currentQuestion && lang
                                          ? "show"
                                          : "hide"
                                      }
                                      key={element.qid + "en"}
                                      dangerouslySetInnerHTML={{
                                        __html: element.q_text,
                                      }}
                                    ></p>
                                    <p
                                      className={
                                        index == currentQuestion && !lang
                                          ? "show"
                                          : "hide"
                                      }
                                      key={element.qid + "hi"}
                                      dangerouslySetInnerHTML={{
                                        __html: element.q_text_hindi,
                                      }}
                                    ></p>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>

                      <div className="sub_sub_section_wrapper_3">
                        {renderOptions(testQuestionsDetails[currentQuestion])}
                      </div>

                      <div className="solutionBoxWrapper">
                        <p className="text_content">Explanation:</p>
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <>
                                  <p
                                    className={
                                      index == currentQuestion && lang
                                        ? "show"
                                        : "hide"
                                    }
                                    key={element.qid + "en"}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        props.type === "pyq"
                                          ? element.sol_text
                                          : element.solution,
                                    }}
                                  ></p>

                                  <p
                                    className={
                                      index == currentQuestion && !lang
                                        ? "show"
                                        : "hide"
                                    }
                                    key={element.qid + "hi"}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        props.type === "pyq"
                                          ? element.sol_text_hindi
                                          : element.solution_hindi,
                                    }}
                                  ></p>
                                </>
                              );
                            })
                          : null}

                        {testQuestionsDetails.length && renderVideo()}
                      </div>
                    </div>
                  </div>

                  {/* new next button integration  */}

                  <div
                    style={{
                      margin: "auto 40px 25px",
                      cursor: "pointer",
                      display: "inline-block",
                      color: "#B50503",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <img src={images.user_activity_14_1} alt="" />
                    </span>{" "}
                    <span style={{ textDecoration: "none" }}>
                      Report an error
                    </span>
                  </div>

                  <div className="sub_sub_section_wrapper_5">
                    <div className="left_side_wrapper">
                      {currentQuestion > 0 && (
                        <div className="btn_wrapper">
                          <button
                            onClick={() => {
                              handlePreviousQuestionBtn();
                              setVideoShow(false);
                              scrollUp2();
                            }}
                          >
                            Previous
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="right_side_wrapper">
                      <div className="btn_wrapper">
                        <button
                          onClick={() => {
                            handleNextQuestionBtn();
                            setVideoShow(false);
                            scrollUp2();
                          }}
                        >
                          {currentQuestion === testQuestionsDetails.length - 1
                            ? "Submit"
                            : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BookmarkViewQuestionsView;
