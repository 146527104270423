import React from "react";
import ReactPlayer from "react-player";
import "./AudioPlayer.scss";

const AudioPlayer = ({ audioUrl }) => {
  return (
    <div className={`${audioUrl ? "custom-player-container" : ""}`}>
      <ReactPlayer
        url={audioUrl}
        width="100%"
        height="65px"
        className="custom-player"
        controls
      />
    </div>
  );
};

export default AudioPlayer;
