import React from "react";
import "./confirmationMessage.scss";
import images from "../../../../../utilities/images/images";

const ConfirmationMessage = ({ onClose }) => {
  return (
    <div className="confirmation-overlay">
      <div className="confirmation-message">
        <div className="check-icon">
          <img src={images.tick} alt="success" />
        </div>
        <h2>Thank you for reaching out!</h2>
        <p>
          Your message has been successfully submitted. Our team will get back
          to you within 24 hours.
        </p>
        <button onClick={onClose}>Done</button>
      </div>
    </div>
  );
};

export default ConfirmationMessage;
