import React from "react";
import "./QueInfo.scss";

// import { MathJax, MathJaxContext } from "better-react-mathjax";

const QuestionInfoSection = ({
  lang,
  testQuestionsDetails,
  currentQuestion,
}) => {
  const config = {
    //loader: { load: ["[tex]/html"] },
    loader: {
      load: [
        "input/asciimath",
        "output/chtml",
        "ui/menu",
        "[mml]/mml3",
        "[tex]/html",
      ],
    },
  };
  return (
    <div className="questionInfoText">
      {/* <h2>Adaptive CPS</h2> */}
      <div className="text_content_wrapper">
        <div className="topicWrapper">
          Topic:{" "}
          <span>{testQuestionsDetails[currentQuestion]?.topic_name}</span>
        </div>

        <div className="subTopicWrapper">
          Sub Topic:{" "}
          <span>{testQuestionsDetails[currentQuestion]?.sub_topic_name}</span>
        </div>

        <div className="typeLanguageWrapper">
          Question type:{" "}
          <span>{testQuestionsDetails[currentQuestion]?.answer_type}</span>
          <div className="language_chage_icon"></div>
        </div>

        <div className="questionBox">
          <div className="text_content">
            Question{` ${Number(currentQuestion) + 1}`}
          </div>

          {testQuestionsDetails.length
            ? testQuestionsDetails.map((element, index) => (
                <>
                  <p
                    className={
                      index === currentQuestion && lang ? "show" : "hide"
                    }
                    key={element.qid + "en"}
                    dangerouslySetInnerHTML={{ __html: element.q_text }}
                  ></p>
                  <p
                    className={
                      index === currentQuestion && !lang ? "show" : "hide"
                    }
                    key={element.qid + "hi"}
                    dangerouslySetInnerHTML={{ __html: element.q_text_hindi }}
                  ></p>
                </>
              ))
            : null}
          {/* {testQuestionsDetails.length
            ? testQuestionsDetails.map((element, index) => (
                <>
                  <p
                    className={
                      index === currentQuestion && lang ? "show" : "hide"
                    }
                    key={element.qid + "en"}
                    dangerouslySetInnerHTML={{ __html: element.q_text }}
                  ></p>
                  <p
                    className={
                      index === currentQuestion && !lang ? "show" : "hide"
                    }
                    key={element.qid + "hi"}
                    dangerouslySetInnerHTML={{ __html: element.q_text_hindi }}
                  ></p>
                </>
              ))
            : null} */}
        </div>
      </div>
    </div>
  );
};

export default QuestionInfoSection;
