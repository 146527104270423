import React from "react";
import { Modal } from "react-bootstrap";
import "./FacultyDetail.scss";
import VideoDrmPlayer from "../../../../../features/video_feature/video_feature_content_component/VideoDrmPlayer";

const FacultyDetail = ({ handleClose, FacultyDetails }) => {
  const {
    thumnail,
    name,
    video_crypt_id,
    subjects,
    description,
  } = FacultyDetails;
  return (
    <Modal show={true} onHide={handleClose} dialogClassName="faculty-details">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="faculty-wrapper">
          <div className="flty-header-wrapper">
            <img src={thumnail} alt="img" />
            <div>
              <div className="flty-name">By {name}</div>
              <div className="flty-sub">{subjects}</div>
            </div>
          </div>

          <div className="flty-video-wrapper">
            <div>
              <VideoDrmPlayer id_video={video_crypt_id} />
            </div>
          </div>

          <div className="flty-desc-wrapper">
            <h3>About Faculty</h3>
            {description}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FacultyDetail;
