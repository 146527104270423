import React, { useEffect, useState, useRef, useCallback } from "react";
import "./StreamingVideoDetails.scss";
import images from "../../../../../../../utilities/images/images";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  url_216,
  url_220,
  url_222,
  url_224,
  url_225,
  url_7,
} from "../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import uiActions from "../../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../../redux/user_activity/action";
import userBookmarkActions from "../../../../../../../redux/user_bookmark/action";
import { setNoteAddedVideoTime } from "../../../../../../../redux/watched_video_details/WatchedVideoDetailsSlice";
import { BASE_URL } from "../../../../../../../custom_utilities/baseURL";

const StreamingVideoDetails = ({
  streamingDetails,
  isLive,
  live_class_id,
  token,
  tokenType,
  startLoading,
  finishLoading,
  setRelatedVideos,
  videoData,
}) => {
  const history = useHistory();
  const { sub_name, topic_name, meeting_topic } = streamingDetails;
  const intervalRef = useRef(null);

  const [state, setState] = useState({
    videoId: null,
    videoDetails: {},
    relatedVideo: {},
    optionFeatureType: 2,
    dppCollections: {},
    videosDrmUrl: {},
    flag: false,
    activeTab: 0,
    studyMaterialList: {},
    rating: null,
    hover: null,
    comment: "",
    commentsList: {},
    timeline: [],
    note: {},
    notesList: {},
    notesInput: false,
    openOptionsIndex: null,
    enableNotesEdit: null,
    timeElapsed: "",
    deleteModal: false,
    deleteId: null,
    reportId: null,
    reportModal: false,
    report: "",
    reportCategory: [
      "Incorrect Question",
      "Incorrect Option",
      "Incorrect Answer Key",
      "Incorrect Solution",
      "Others",
    ],
    reportCategoryOption: "",
    jumpTo: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setVideoId();
    setVideoComponentInitialTime();
  }, []);

  useEffect(() => {}, [state.likesArray]);

  useEffect(() => {
    if (state.videoId) {
      getVideoDetails();
      getStudyMaterialList();
      initialiseLikes();
      getCommentsList();
      getTimeline();
      getNotesList();
      getAdditionalVideoDetails();
    }
  }, [state.videoId]);

  useEffect(() => {
    if (!isEmpty(state.videoDetails) && state.videoDetails.last_paused) {
      handleJumpToParticularTime();
    }
  }, [state.videoDetails]);

  const setVideoId = () => {
    let { video_id } = videoData;
    setState((prevState) => ({ ...prevState, videoId: video_id }));
  };

  const setVideoComponentInitialTime = () => {};

  const getVideoDetails = async () => {
    let { videoId } = state;
    try {
      const response = await axios.get(`${url_7}?video_id=${videoId}`);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          videoDetails: response.data.data.VideoDetail,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAdditionalVideoDetails = async () => {
    let { videoId } = state;
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/related_videos?video_id=${videoId}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          relatedVideo: response.data.data.data,
        }));

        setRelatedVideos(response.data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommentsList = async () => {
    let videoId = state?.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_222}?video_id=${videoId}`);
      if (response?.data?.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          commentsList: response?.data?.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const initialiseLikes = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_222}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        const likesArray = response.data.data.map((item) => item.like_status); // Extracting like_status values
        setState((prevState) => ({
          ...prevState,
          likesArray: likesArray, // Storing like_status values in likesArray
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTimeline = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_224}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          timeline: response.data.data.timeline,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getNotesList = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_225}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          notesList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleJumpToParticularTime = () => {
    let videoDetails = { ...state.videoDetails };
    if (!isEmpty(videoDetails) && videoDetails.last_paused) {
      let frame = document.querySelector("#iframe");
      //////jump to particular time///////////////////////
      frame.contentWindow.postMessage(
        JSON.stringify({ seekTo: videoDetails.last_paused }),
        "*"
      );
    }
  };

  const getStudyMaterialList = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_220}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          studyMaterialList: response.data.data.study_material,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getViewsCount = useCallback(async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(
        `${url_216}?live_class_id=${live_class_id}`,
        headers
      );
      setViewsCount(response.data.data.viewers_count);
    } catch (error) {
      console.log(error);
    }
  }, [live_class_id, token, tokenType]);

  const leaveClassApiCall = useCallback(async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(
      `https://learning.motion.ac.in/motioneducation/api/v1/live/viewers/update?live_class_id=${live_class_id}&status=left`,
      { headers }
    );

    if (response.data.status === 200) {
      history.push("/user_dashboard");
    } else {
      history.push("/user_dashboard");
    }
  }, [live_class_id, history, token, tokenType]);

  useEffect(() => {
    getViewsCount();

    if (isLive) {
      intervalRef.current = setInterval(() => {
        getViewsCount();
      }, 20000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [getViewsCount, isLive]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (isLive) {
        leaveClassApiCall();
      }
    },
    [isLive, leaveClassApiCall]
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  const [viewsCount, setViewsCount] = useState(0);

  /* eslint-disable no-restricted-globals */
  const leaveClass = () => {
    const userConfirmed = confirm(
      "Are you sure you want to leave the meeting?"
    );

    if (userConfirmed) {
      leaveClassApiCall();
    }
  };
  /* eslint-enable no-restricted-globals */

  const handleBackButton = () => {
    if (isLive) {
      alert("Are you sure you want to leave meeting?");

      history.push("/user_dashboard");

      leaveClassApiCall();
    } else {
      history.push("/topic_component");
    }
  };

  const classEditor = (isLive) => {
    return isLive ? "is-live" : "is-recorded-video";
  };

  return (
    <div className={`video-player-container ${classEditor(isLive)}`}>
      <div className="video-details-watch-count">
        <div className="video-details">
          <img
            src={images.backBtn1}
            alt="back-btn"
            style={{
              width: "2.25rem",
              height: "2.25rem",
              marginRight: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => {
              handleBackButton();
            }}
          />

          <div className="text_content_wrapper">
            <p className="text_content">
              {!isEmpty(state.videoDetails)
                ? state.videoDetails.video_title
                : null}
            </p>
          </div>
        </div>
        {isLive && (
          <div className="watch-count">
            <div className="img-and-count">
              <img src={images.eyeView} alt="eye-view" />
              {viewsCount}
            </div>
          </div>
        )}
      </div>
      <iframe
        src={streamingDetails?.playbackurl}
        height={isLive ? "100%" : "540px"}
        width="100%"
        title="Iframe Example"
        allow="fullscreen"
        className="video-player"
        scrolling="no"
        style={{ border: "none" }}
      ></iframe>
      <div className="more-details">
        {isLive && (
          <div className="live">
            <div className="live-circle"></div>
            <div className="live-text">Live</div>
          </div>
        )}

        {isLive && (
          <div className="topic-and-leave-class">
            <div className="topic">{meeting_topic}</div>
            <div className="leave-class-btn" onClick={leaveClass}>
              Leave Class
            </div>
          </div>
        )}
      </div>
      <div className="video_details_content_wrapper">
        <div className="text_content_wrapper">
          <p className="text_content">
            {!isEmpty(state.videoDetails)
              ? state.videoDetails.video_title
              : null}
          </p>
        </div>
        <div className="content_wrapper_1">
          <div className="left_side_content_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">
                {!isEmpty(state.videoDetails)
                  ? state.videoDetails.UploadBy.name
                  : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    videoComponentTime: state.userBookmarkData.videoComponentTime,
    videoCurrentTime: state.userBookmarkData.videoCurrentTime,
    videoDetailsForTime: state.userBookmarkData.videoDetailsForTime,
    topicData: state.userActivity.topicData,
    subjectData: state.userActivity.subjectData,
    watchedVideoData: state.WatchedVideoDetailsSlice.watchedVideoDetails,
    videoLastPaused: state.WatchedVideoDetailsSlice.lastPaused,
    totalWatchTime: state.WatchedVideoDetailsSlice.totalWatchTime,
    currentVideoWatchTime: state.WatchedVideoDetailsSlice.currentvideoTime,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setExerciseResultData: (payload) => {
      dispatch(userActivityActions.setExerciseResultData(payload));
    },
    setVideoComponentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoComponentTime(payload));
    },
    setRelatedVideos: (payload) => {
      dispatch(userBookmarkActions.setRelatedVideos(payload));
    },
    setVideoDetailsForTime: (payload) => {
      dispatch(userBookmarkActions.setVideoDetailsForTime(payload));
    },
    setVideoCurrentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoCurrentTime(payload));
    },

    setNoteAddedVideoTimeAction: (payload) => {
      dispatch(setNoteAddedVideoTime(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamingVideoDetails);
