import React from "react";
import images from "../../../utilities/images/images";
import { Modal } from "react-bootstrap";
import "./LoginSignupModal.scss";
import Login from "../../login/login";

const LoginSignupModal = ({ isLoginOpen, setIsLoginOpen }) => {
  return (
    <Modal show={isLoginOpen} onClose={() => setIsLoginOpen(false)}>
      <div className="modal-overlay">
        <div className="modal--">
          <div className="modal-left">
            <img src={images.loginPageImg} alt="" className="image" />
          </div>

          <div className="modal-right">
            <div
              className="modal-close-btn"
              onClick={() => setIsLoginOpen(false)}
            >
              <img src={images.loginCloseBtn} alt="" className="close-btn" />
            </div>

            <Login />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginSignupModal;
