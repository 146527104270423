import { Fragment, useEffect, useState } from "react";
import "./OfflineTransactions.scss";
import axios from "axios";
import images from "../../../../../utilities/images/images";

const OfflineTransactions = () => {
  const [state, setState] = useState({
    offlineTransactionsList: [],
    openTransactionIndex: null,
  });

  const toggleDropdown = (index) => {
    setState((prevState) => ({
      ...prevState,
      openTransactionIndex:
        prevState.openTransactionIndex === index ? null : index,
    }));
  };

  useEffect(() => {
    getOfflineTransactionsList();
  }, []);

  const getOfflineTransactionsList = async () => {
    try {
      const response = await axios.get(
        "https://learning.motion.ac.in/motioneducation/api/user/HandbookTransactionDetails"
      );
      console.log("getOfflineTransactionsList", response.data.data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          offlineTransactionsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="offlineTransactionWrapper">
      <div className="offline_transactions_history_collection_wrapper">
        {state.offlineTransactionsList.length ? (
          state.offlineTransactionsList.map((element, index) => {
            const isOpen = state.openTransactionIndex === index;

            return (
              <div key={index} className="single_transaction_wrapper">
                <div className="section_wrapper_1">
                  <div className="left_side_content_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">{`Order Id: ${element.transaction_id}`}</p>
                    </div>
                  </div>
                  <div className="right_side_content_wrappers">
                    <div className="text_content_wrapper">
                      <button
                        className="text_content dropDown"
                        onClick={() => toggleDropdown(index)}
                      >
                        View Breakup
                        {isOpen ? (
                          <span
                            style={{
                              transform: "rotate(-270deg)",
                              display: "inline-block",
                              fontSize: "18px",
                              marginLeft: "3px",
                            }}
                          >
                            {"<"}
                          </span>
                        ) : (
                          <span
                            style={{
                              transform: "rotate(90deg)",
                              display: "inline-block",
                              fontSize: "18px",
                              marginLeft: "3px",
                            }}
                          >
                            {">"}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                {isOpen && (
                  <div>
                    <div className="products">
                      {element.product_details.map((product) => {
                        return (
                          <div
                            className={
                              "product " +
                              (element.product_details.length == 1
                                ? "singleProduct"
                                : "items")
                            }
                          >
                            <div className="productName">
                              {product.product_name}
                            </div>

                            <div className="qnty">
                              <small>Quantity</small>
                              <small className="num">{product.qty} no</small>
                            </div>
                            <div className="actualPrice">
                              <small>Actual Price</small>
                              <small className="num">
                                {parseInt(product.price).toLocaleString(
                                  "en-US"
                                )}
                              </small>
                            </div>

                            <div className="discount">
                              <small>Discount</small>
                              <small className="num">
                                -{" "}
                                {parseInt(product.discount).toLocaleString(
                                  "en-US"
                                )}
                              </small>
                            </div>
                            <hr className="underLine" />
                            <div className="price">
                              <small>Price</small>
                              <small className="num">
                                {parseInt(
                                  product.discounted_price
                                ).toLocaleString("en-US")}
                              </small>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <ul>
                      <div className="heading">Overall Price Details</div>

                      <li>
                        <small className="text">Price</small>
                        <small className="num">
                          {parseInt(element.price).toLocaleString("en-US")}
                        </small>
                      </li>
                      <li>
                        <small className="text">Discount</small>

                        <small className="num">
                          - {parseInt(element.discount).toLocaleString("en-US")}
                        </small>
                      </li>
                      <li>
                        <small className="text">Coupon Discount</small>
                        <small className="num">
                          -{" "}
                          {parseInt(element.coupon_discount).toLocaleString(
                            "en-US"
                          )}
                        </small>
                      </li>
                      <li>
                        <small className="text">Shipping Charges</small>
                        <small className="num">
                          {parseInt(element.shipping_charge).toLocaleString(
                            "en-US"
                          )}
                        </small>
                      </li>
                      <li>
                        <small className="text">Net Price</small>
                        <small className="num">
                          {parseInt(element.total_price).toLocaleString(
                            "en-US"
                          )}
                        </small>
                      </li>
                    </ul>
                    <hr />
                  </div>
                )}

                <div className="section_wrapper_2">
                  <div className="text_content_wrapper">
                    <p className="text_content_1">Transaction Date & time</p>
                    <p className="text_content_2">{element.insert_on}</p>
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content_1 status-text">Status</p>
                    <p className="text_content_2">
                      <span className="img">
                        {" "}
                        <img src={images.user_activity_55} alt="img" />
                      </span>
                      <span>{element.status}</span>
                    </p>
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content_1">Net Price</p>
                    <p className="text_content_2 netPrice">
                      ₹ {parseInt(element.total_price).toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-transaction">
            <img src={images.new_m2_test_ntF} alt="no test found" />
            <p>No transactions yet!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfflineTransactions;
