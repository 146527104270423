import { Fragment, useState, useEffect, useRef } from "react";
import "./mockTestQuestionView.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import useMathjax from "../../../../../../../hooks/useMathjax";
import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as List1 } from "./list1.svg";
import { ReactComponent as Grid1 } from "./grid1.svg";

const MockTestQuestionView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    currentQuestionDetails,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleGuessedAnswer,
    handleQuestionJump,
    handleSubmitTest,
    subjecSectiontWiseTestQuestions,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
  } = props;

  useEffect(() => {
    if (
      countDownTimer.hours == 0 &&
      countDownTimer.minutes == 0 &&
      countDownTimer.seconds == 0
    ) {
      alert("Time is up. Your test will be auto submitted");
      handleSubmitTest(currentQuestionDetails);
    }
  }, [countDownTimer.seconds]);
  const [isList, setisList] = useState(true);
  const [reportErrorData, setReportErrorData] = useState([]);
  const [lang, setLang] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);

  useMathjax(currentQuestionDetails, lang);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });

  //  condition for Report Error modal submit button
  const isDisable =
    saveReportErrorData.option_id.length == 0 ||
    saveReportErrorData.description.length == 0
      ? true
      : false;

  //function for getting options Data from api

  const handleGetReportErrorOptions = () => {
    startLoading();
    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  // const scrollUp = () => {
  //   var element = document.getElementsByClassName(
  //     "questions_serial_number_collection"
  //   )[0];
  //   element.scrollTo({
  //     top: `${(currentQuestion + 1) * 80}`,
  //     behavior: "smooth",
  //   });
  // };
  // const scrollUp2 = () => {
  //   var element = document.getElementsByClassName(
  //     "questions_serial_number_collection"
  //   )[0];
  //   element.scrollTo({
  //     top: `${(currentQuestion + 1) * 80 - 160}`,
  //     behavior: "smooth",
  //   });
  // };
  const questionsCollectionRef = useRef(null);

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // for report error modal
  const [showError, setShowError] = useState(false);
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const renderOptions = (currentQuestionDetails, lang) => {
    let step;
    let type = currentQuestionDetails?.answer_type.toLowerCase();

    if (type === "single correct") {
      step = 1;
    } else if (type.toLowerCase() === "integer correct") {
      step = 2;
    } else {
      step = 3;
    }

    switch (step) {
      case 1:
        return renderSingleSelect(
          currentQuestionDetails,
          currentQuestion,
          lang
        );
      case 2:
        return renderInputField(currentQuestion, lang);
      case 3:
        return renderMultiSelect(currentQuestionDetails, currentQuestion, lang);
      default:
        return null;
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "single_select_option_collection_wrapper show"
                : "single_select_option_collection_wrapper hide"
            }
          >
            {questionData.option?.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 1) {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[currentQuestion2]
                                  .userAnswerCollection[0] === element.option_id
                              : element.is_user_correct_ans == 1
                              ? true
                              : false
                          }
                          // checked={element.is_user_correct_ans == 1}
                          onChange={(event) =>
                            handleSingleSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      if (questionData.option !== undefined && questionData.option !== null) {
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 == currentQuestion
                  ? "single_select_option_collection_wrapper show"
                  : "single_select_option_collection_wrapper hide"
              }
            >
              {questionData.option?.length
                ? questionData.option.map((element, index) => {
                    if (element.lang_id == 2) {
                      return (
                        <div key={index} className="single_select">
                          <input
                            type="checkbox"
                            id={element.option_id}
                            name={`current_question_${currentQuestion2}`}
                            checked={
                              testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection.length
                                ? testQuestionsDetails[currentQuestion2]
                                    .userAnswerCollection[0] ===
                                  element.option_id
                                : element.is_user_correct_ans == 1
                                ? true
                                : false
                            }
                            // checked={element.is_user_correct_ans == 1}
                            onChange={(event) =>
                              handleSingleSelectAnswer(event, element.option_id)
                            }
                          />
                          <label
                            htmlFor={element.option_id}
                            className="single_option_wrapper"
                          >
                            <div className="option_initial">
                              <p className="text_content_2">{`${renderOptionInitial(
                                index
                              )}.`}</p>
                            </div>
                            <div className="option_final">
                              <p
                                className="text_content_3"
                                dangerouslySetInnerHTML={renderOptionText(
                                  element.option
                                )}
                              ></p>
                            </div>
                          </label>
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="single_select">
                          <input
                            type="checkbox"
                            id={element.option_id}
                            name={`current_question_${currentQuestion2}`}
                            checked={
                              testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection.length
                                ? testQuestionsDetails[currentQuestion2]
                                    .userAnswerCollection[0] ===
                                  element.option_id
                                : element.is_user_correct_ans == 1
                                ? true
                                : false
                            }
                            // checked={element.is_user_correct_ans == 1}
                            onChange={(event) =>
                              handleSingleSelectAnswer(event, element.option_id)
                            }
                          />
                          <label
                            htmlFor={element.option_id}
                            className="single_option_wrapper"
                          >
                            <div className="option_initial">
                              <p className="text_content_2">{`${renderOptionInitial(
                                index
                              )}.`}</p>
                            </div>
                            <div className="option_final">
                              <p
                                className="text_content_3"
                                dangerouslySetInnerHTML={renderOptionText(
                                  element.option
                                )}
                              ></p>
                            </div>
                          </label>
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          </Fragment>
        );
      }
    }
  };

  const renderMultiSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "multi_select_option_collection_wrapper show"
                : "multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 1) {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (
      lang == false &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "multi_select_option_collection_wrapper show"
                : "multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 2) {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion].integer_answer || ""}
              // onChange={(event) =>
              //   handleInputFieldChange(
              //     event.target.value.replace(/[^0-9]/g, "")

              //   )

              // }

              onChange={(event) => handleInputFieldChange(event)}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return {
        __html: lang
          ? questionsArr[currentQuestion].q_text
          : questionsArr[currentQuestion].q_text_hindi !== null
          ? questionsArr[currentQuestion].q_text_hindi
          : questionsArr[currentQuestion].q_text,
      };
    } else {
      return null;
    }
  };

  const renderQuestion = () => {
    if (lang) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: currentQuestionDetails.q_text,
          }}
        ></p>
      );
    } else {
      if (ConvertStringToHTML(currentQuestionDetails?.q_text_hindi)) {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text_hindi,
            }}
          ></p>
        );
      } else {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text,
            }}
          ></p>
        );
      }
    }
  };

  // method for converting string(coming from api) into hmtl
  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox">
            {`${index + 1}`}
            {/* <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div> */}
          </div>
          {/* <img className="arrow" src={images.right_Ar} /> */}
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}`}
                  {/* <div className="QBox">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.q_text,
                      }}
                    ></p>
                  </div> */}
                </div>
                {/* <img className="arrow" src={images.right_Ar} /> */}
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox">
              {`${index + 1}`}
              {/* <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div> */}
            </div>
            {/* <img className="arrow" src={images.right_Ar} /> */}
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      }
    }
  };
  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox questionBox1">
            {`${index + 1}.`}
            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox questionBox1">
                  {`${index + 1}.`}
                  <div className="QBox">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.q_text,
                      }}
                    ></p>
                  </div>
                </div>
                <img className="arrow" src={images.right_Ar} />
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl  unanswered_lbl1 isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox questionBox1">
              {`${index + 1}.`}
              <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl  unanswered_lbl1"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;
  // const config = {
  //   //loader: { load: ["[tex]/html"] },
  //   loader: {
  //     load: [
  //       "input/asciimath",
  //       "output/chtml",
  //       "ui/menu",
  //       "[mml]/mml3",
  //       "[tex]/html",
  //     ],
  //   },
  // };

  const config = {
    "fast-preview": {
      disabled: true,
    },
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
    messageStyle: "none",
  };

  return (
    <Fragment>
      <div className="mock_test_ppr_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Questions
                    </button>
                    {/* <div className="countdown_timer_container">
                      <span>
                        <label className="time_number">
                          {countDownTimer.hours}
                        </label>
                        <label className="time_partition">:</label>
                        <label className="time_number">
                          {countDownTimer.minutes}
                        </label>
                        <label className="time_partition">:</label>
                        <label className="time_number">
                          {countDownTimer.seconds > 9
                            ? countDownTimer.seconds
                            : `${"0" + countDownTimer.seconds}`}
                        </label>
                      </span>
                    </div> */}
                    <button
                      className="togglebtn submit"
                      onClick={() => {
                        //handleSaveQuestion();
                        handleShow();
                      }}
                    >
                      Submit Test
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div>
                      <div className="sub_sub_section_wrapper_1">
                        <div className="sub_sub_section_wrapper_1_card">
                          <p>
                            {currentQuestion + 1}/{testQuestionsDetails.length}
                          </p>
                          <div className="countdown_timer_container">
                            <span>
                              <label className="time_number">
                                {countDownTimer.hours}
                              </label>
                              <label className="time_partition">:</label>
                              <label className="time_number">
                                {countDownTimer.minutes}
                              </label>
                              <label className="time_partition">:</label>
                              <label className="time_number">
                                {countDownTimer.seconds > 9
                                  ? countDownTimer.seconds
                                  : `${"0" + countDownTimer.seconds}`}
                              </label>
                            </span>
                          </div>
                          <div className="markReview">
                            <div className="bookmark_image_wrapper">
                              {testQuestionsDetails[currentQuestion] &&
                              testQuestionsDetails[currentQuestion]
                                .isAnswerGuessed ? (
                                <img
                                  src={images.BookmarkBtn1}
                                  className="BookmarkBtn"
                                  onClick={() => handleReview()}
                                />
                              ) : (
                                <img
                                  src={images.BookmarkBtn}
                                  className="BookmarkBtn"
                                  onClick={() => handleReview()}
                                />
                              )}
                            </div>
                            {/* <div className="bookmark_image_wrapper">
                                {testQuestionsDetails[currentQuestion] &&
                                testQuestionsDetails[currentQuestion]
                                  .isAnswerGuessed ? (
                                  <img
                                    src={images.user_activity_4}
                                    onClick={() => handleReview()}
                                  />
                                ) : (
                                  <img
                                    src={images.user_activity_3}
                                    onClick={() => handleReview()}
                                  />
                                )}
                              </div> */}

                            {currentQuestionDetails.q_text_hindi ? (
                              <button
                                className={"m2_language"}
                                onClick={() => setLang(!lang)}
                              >
                                {/* <img src={images.m2_language} /> */}
                                {lang ? "अ" : "A"}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="onSubmitModal"
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <div className="submit_Popup">
                            <div className="exit_img">
                              <img src={images.m2_exit1} alt="" />
                            </div>
                            <h5>Are you Sure?</h5>
                            <p>
                              Do you want to continue the exam or you want to
                              Submit.
                            </p>
                            <div className="submitCancelBtn">
                              <button className="cancel" onClick={handleClose}>
                                Cancel
                              </button>
                              <button
                                onClick={() =>
                                  handleSubmitTest(currentQuestionDetails)
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {/* <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                    </div> */}
                      <div className="sectionWiseTestWrapper">
                        <div className="d-flex gap-3 align-items-center">
                          <p className="subjectWiseTest_heading">
                            Select Subject:
                          </p>
                          <div className="subjectWiseTest">
                            {Object.keys(subjecSectiontWiseTestQuestions).map(
                              (subjectName, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="single_select_subject"
                                  >
                                    <input
                                      type="radio"
                                      id={subjectName}
                                      name="subject"
                                      value={subjectName}
                                      checked={subjectName === selectedSubject}
                                      onChange={() =>
                                        handleSubjectChange(subjectName)
                                      }
                                    />
                                    <label for={subjectName}>
                                      {subjectName}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center">
                          <p className="subjectWiseTest_heading">
                            Change Section:
                          </p>
                          <div className="subjectWiseTest sectionWiseTest">
                            {selectedSubject &&
                              Object.keys(
                                subjecSectiontWiseTestQuestions[selectedSubject]
                              ).map((sectionName, index) => {
                                return (
                                  <div
                                    key={index}
                                    class="single_select_subject"
                                  >
                                    <input
                                      type="radio"
                                      id={sectionName}
                                      name="section"
                                      value={sectionName}
                                      checked={sectionName === selectedSection}
                                      onChange={() =>
                                        handleSectionChange(sectionName)
                                      }
                                    />
                                    <label for={sectionName}>
                                      {sectionName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="questionWrapper">
                      <div className="sub_sub_section_wrapper_2">
                        {/* <h2>Mock Test</h2> */}
                        <div className="text_content_wrapper">
                          {/* <div className="typeLanguageWrapper">
                          <span>
                            Question type:{" "}
                            {testQuestionsDetails[currentQuestion].answer_type}
                          </span>
                          <div className="markReview">
                            <div className="bookmark_image_wrapper">
                              <span>Mark For Review</span>
                              {testQuestionsDetails[currentQuestion] &&
                              testQuestionsDetails[currentQuestion]
                                .isAnswerGuessed ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() => handleReview()}
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() => handleReview()}
                                />
                              )}
                            </div>

                            {currentQuestionDetails.q_text_hindi ? (
                              <button onClick={() => setLang(!lang)}>
                                <img src={images.m2_language} />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}
                          <div className="questionBox">
                            <span className="text_content">
                              {`Question ${Number(currentQuestion) + 1}.`}
                            </span>
                            {/* <p
                            className="text_content"
                            dangerouslySetInnerHTML={renderQuestionText()}
                          ></p> */}

                            <div className="qst">{renderQuestion()}</div>
                          </div>
                        </div>
                      </div>
                      <div className="sub_sub_section_wrapper_3">
                        {currentQuestionDetails !== null &&
                        currentQuestionDetails.option !== undefined
                          ? renderOptions(currentQuestionDetails, lang)
                          : ""}
                      </div>
                    </div>

                    {/* toaster for  successfull submission of Report error  */}

                    <ToastContainer />

                    <Modal
                      show={showError}
                      onHide={handleCloseReportError}
                      className="errorModal"
                    >
                      <Modal.Header closeButton>
                        {" "}
                        <h3>Report an Error</h3>
                      </Modal.Header>
                      <Modal.Body className="modal-body">
                        <div className="error_Popup">
                          <p className="text_content">
                            You can just write to us, If there is any incorrect
                            or error in question and given options.
                          </p>
                          <ul className="errorList">
                            {reportErrorData?.map((element, index) => {
                              return (
                                <li className="errorListItem" key={index}>
                                  <input
                                    type="radio"
                                    id={element.id}
                                    name="error"
                                    value={element.option_name}
                                    onChange={(event) => {
                                      setSaveReportErrorData({
                                        ...saveReportErrorData,
                                        option_id: event.target.id,
                                      });
                                    }}
                                  />{" "}
                                  <label htmlFor={element.id}>
                                    {element.option_name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="textarea_content"
                            type="text"
                            onChange={(event) =>
                              setSaveReportErrorData({
                                ...saveReportErrorData,
                                description: event.target.value,
                              })
                            }
                            placeholder="Write to Us..."
                          />{" "}
                          <br />
                          <Button
                            disabled={isDisable}
                            onClick={handlePostReportErrorData}
                          >
                            Submit
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>

                    {/* <div className="sub_sub_section_wrapper_4">
                    <div className="checkbox_wrapper">
                      <input
                        type="checkbox"
                        checked={
                          testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].isAnswerGuessed
                        }
                        onChange={(event) => handleGuessedAnswer(event)}
                      />
                      <label>Have you guessed the answer?</label>
                    </div>
                  </div> */}

                    <div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          color: "#B50503",
                          fontSize: " 0.75rem",
                        }}
                      >
                        <span style={{ marginRight: "5px", cursor: "pointer" }}>
                          <img src={images.user_activity_14_1}></img>
                        </span>{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleGetReportErrorOptions}
                        >
                          Report an error
                        </span>
                      </div>
                      <div className="sub_sub_section_wrapper_5">
                        <div className="left_side_wrapper">
                          {currentQuestion > 0 && (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handlePreviousQuestionBtn(
                                    currentQuestion - 1
                                  );
                                  scrollUp2();
                                }}
                              >
                                Previous
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="right_side_wrapper">
                          {testQuestionsDetails.length ===
                          currentQuestion + 1 ? (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handleSaveQuestion();
                                  handleShow();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handleNextQuestionBtn(currentQuestion + 1);
                                  scrollUp();
                                }}
                              >
                                Save & Next
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div
                      style={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        color: "#FF8724",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={handleGetReportErrorOptions}
                    >
                      <span style={{ marginRight: "5px" }}>
                        <img src={images.user_activity_14}></img>
                      </span>{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Report an error
                      </span>
                    </div> */}
                    {/* <div className="sec_1_wrapper">
                      <div className="sec_1_inner_wrapper">
                        <div className="section_content_wrapper">
                          <div className="btn_wrapper">
                            <button onClick={() => handleSubmitTest()}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>
                      {/* <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                    </div> */}
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button onClick={() => handleShow()}>
                          Submit Test
                        </button>
                        {/* <div className="countdown_timer_container">
                          <span>
                            <label className="time_number">
                              {countDownTimer.hours}
                            </label>
                            <label className="time_partition">:</label>
                            <label className="time_number">
                              {countDownTimer.minutes}
                            </label>
                            <label className="time_partition">:</label>
                            <label className="time_number">
                              {countDownTimer.seconds > 9
                                ? countDownTimer.seconds
                                : `${"0" + countDownTimer.seconds}`}
                            </label>
                          </span>
                        </div> */}
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <List1 /> : <List />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <Grid /> : <Grid1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_4"></label>
                            <label className="question_marking_text">
                              Skipped
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_5"></label>
                            <label className="question_marking_text">
                              Mark For Review
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="questions_serial_number_collection"
                        ref={questionsCollectionRef}
                      >
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                >
                                  {isList ? (
                                    <>{renderQuestionLabel1(element, index)}</>
                                  ) : (
                                    <>{renderQuestionLabel(element, index)}</>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                      {/* <div className="questions_serial_number_collection">
                      {subjecSectiontWiseTestQuestions[selectedSubject][
                        selectedSection
                      ]
                        ? subjecSectiontWiseTestQuestions[selectedSubject][
                            selectedSection
                          ].map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div> */}
                    </div>
                    {/* <button className="submitBtn" onClick={() => handleShow()}>
                      Submit Test
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MockTestQuestionView;
