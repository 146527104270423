import { Fragment } from "react";
import "./mockTestInstructionView.scss";
import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";

const MockTestInstructionView = (props) => {
  let { testDetails, testSectionsDetails, startBtn } = props;
  function testTime(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m + " hrs";
  }
  return (
    <Fragment>
      <div className="mock_test_ppr_test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <Link
                className="backBtn"
                to={{
                  pathname: "/mock_test",
                  state: { tab: "mock_test" },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="instruction_title">{testDetails.paper_name}</div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{testDetails.format}</div>
                  <div className="examDurations">
                    Duration :
                    <span>
                    {!isEmpty(testDetails)
                      ? `${testTime(testDetails.duration_mins)}`
                      : null}
                      </span>
                  </div>
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <ul>
                  <li>This Section contains a set of questions for practice</li>
                    <li>
                      Users can go to previous question by clicking on the
                      previous button
                    </li>
                    <li>
                      Users can go to next question by clicking on the next
                      button
                    </li>
                  </ul>
                  <div className="instPoint">
                    <div className="img">
                      <img
                        src={`https://quizmaster.motion.ac.in${testDetails.instructions_image}`}
                        alt="instructions_image"
                      />
                    </div>
                    
                    <h6>Best of luck</h6>
                  </div>
                </div>
                <div onClick={() => startBtn()} className="startTestBtn">
                  <button>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MockTestInstructionView;
