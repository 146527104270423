import React from "react";
import "./QueLabel.scss";
import images from "../../../../../../../../../../../utilities/images/images";

const QuestionStatusLabel = ({
  question,
  index,
  currentQuestion,
  handleQuestionJump,
  lang,
  handleSol,
  handleSolution,
  testQuestionsDetails,
  setVideoShow,
  isList,
}) => {
  const isCurrent = currentQuestion === index;
  const isAttempted = question.isAttempted;

  const handlePurticularQuestion = (questionNumber) => {
    if (testQuestionsDetails[questionNumber].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handleQuestionClick = () => {
    handleQuestionJump(index, handlePurticularQuestion);
    // handlePurticularQuestion();

    setVideoShow(false);
  };

  const getLabelClassName = () => {
    if (isCurrent) {
      return "current_lbl lblBox";
    } else if (isAttempted) {
      return "answered_lbl lblBox";
    } else {
      return "unanswered_lbl lblBox";
    }
  };

  return (
    <>
    {isList ? 
    <div className="single_question_number_wrapper single_question_number_wrapper1">
      <label className={getLabelClassName()} onClick={handleQuestionClick}>
        <div className="questionBox">
          {`${index + 1}.`}
          <div className="QBox">
            <p
              dangerouslySetInnerHTML={{
                __html: lang
                  ? question.q_text
                  : question.q_text_hindi || question.q_text,
              }}
            />
          </div>
        </div>
        <img className="arrow" src={images.right_Ar} alt="" />
      </label>
    </div>
     : 
     <div className="single_question_number_wrapper">
      <label className={getLabelClassName()} onClick={handleQuestionClick}>
        <div className="questionBox">
          {`${index + 1}`}
        </div>
      </label>
    </div>}
     </>
  );
};

export default QuestionStatusLabel;
