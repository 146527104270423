import { Fragment, useEffect, useState } from "react";
import "./user_analytics_content_component.scss";

import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import {
  url_63,
  url_9,
  url_41,
  url_42,
} from "../../../../custom_utilities/api_services";
import moment from "moment";

/////

import SubjectWiseDoughnut from "../../../../components/canvas_charts/canvas_doughnut_chart/SubjectWiseDoughnut";

import OverallDifficultyWiseProgress from "../../../../components/canvas_charts/canvas_doughnut_chart/OverallDifficultyWiseProgress";

import CorrectIncorrectDoughnutChart from "../../../../components/canvas_charts/canvas_doughnut_chart/CorrectIncorrectDoughnutChart";
import AttemptUnattemptDoughnutChart from "../../../../components/canvas_charts/canvas_doughnut_chart/AttemptUnattemptDoughnutChart";
import OverallStrongAndImproveTopics from "../overall_strong_and_improve_topics/OverallStrongAndImproveTopics";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import userTestsExercisesAction from "../../../../redux/user_tests_exercises/action";
import { setPaperId } from "../../../../parent/redux/testDataSlice/TestDataSlice";
import {
  setUserId,
  setLoginFromParentFlag,
  setParentTarget,
} from "../../../../parent/redux/authSlice/AuthSlice";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UserAnalyticsContentComponent = (props) => {
  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    setUserTestsResultData,
    options,
    options2,
    token,
    tokenType,
    userId,
    setUserId,
    setPaperId,
    setLoginFromParentFlag,
    setParentTarget,
    target,
  } = props;

  // const [overAllTestsData,setOverAllTestsData] = useState([])
  // const [flag, setFlag] = useState(true);

  const [state, setState] = useState({
    tabType: 1,

    ////overall//////
    overAllTestsData: {},
    /////inidividual//////
    individualTestSubjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    individualTestSubjectList: {},
    individualTestList: {},
    individualTestInitialSubjectCheck: true,
    individualTestListPageNumber: 1,
    /////learn Analysis
    learnAnalysisSubjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    learnAnalysisSubjectList: {},
    learnAnalysisData: {},
    learnAnalysisInitialSubjectCheck: true,
  });

  useEffect(() => {
    if (state.tabType === 1) {
      getOverAllTestsData();
    } else if (state.tabType === 2) {
      getIndividualTestSubjectList();
    } else if (state.tabType === 3) {
      getLearnAnalysisSubjectList();
    }
  }, [state.tabType]);

  useEffect(() => {
    if (
      state.tabType === 2 &&
      !isEmpty(state.individualTestSubjectList) &&
      state.individualTestSubjectList.subject.length &&
      state.individualTestInitialSubjectCheck
    ) {
      getIndividualTestInitialData();
    }
  }, [state.tabType, state.individualTestSubjectList]);

  useEffect(() => {
    if (
      state.tabType === 3 &&
      !isEmpty(state.learnAnalysisSubjectList) &&
      state.learnAnalysisSubjectList.subject.length &&
      state.learnAnalysisInitialSubjectCheck
    ) {
      getLearnAnalysisInitialData();
    }
  }, [state.tabType, state.learnAnalysisSubjectList]);

  const getIndividualTestInitialData = () => {
    let { subject } = state.individualTestSubjectList;

    setState((prevState) => ({
      ...prevState,
      individualTestSubjectDataDetails: {
        ...prevState.individualTestSubjectDataDetails,
        subjectId: subject[0].sub_id,
        subjectName: subject[0].sub_name,
        subjectIcon: subject[0].icon,
      },
      individualTestInitialSubjectCheck: false,
    }));

    getIndividualTestList(subject[0].sub_id);
  };

  const getLearnAnalysisInitialData = () => {
    let { subject } = state.learnAnalysisSubjectList;

    setState((prevState) => ({
      ...prevState,
      learnAnalysisSubjectDataDetails: {
        ...prevState.learnAnalysisSubjectDataDetails,
        subjectId: subject[0].sub_id,
        subjectName: subject[0].sub_name,
        subjectIcon: subject[0].icon,
      },
      learnAnalysisInitialSubjectCheck: false,
    }));

    getLearnAnalysisData(subject[0].sub_id);
  };

  const getOverAllTestsData = async () => {
    startLoading();
    try {
      const response = await axios.get(url_63);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          overAllTestsData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getIndividualTestSubjectList = async () => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      class_id: userPreference.classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, requestPayload);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestSubjectList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleTabChange = (tabType) => {
    switch (tabType) {
      case "overall_tests":
        setState((prevState) => ({
          ...prevState,
          tabType: 1,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      case "individual_test":
        setState((prevState) => ({
          ...prevState,
          tabType: 2,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      case "learn_analysis":
        setState((prevState) => ({
          ...prevState,
          tabType: 3,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      default:
        break;
    }
  };

  const handleChangeSubject1 = (event, sub_id, sub_name, icon) => {
    setState((prevState) => ({
      ...prevState,
      individualTestSubjectDataDetails: {
        ...prevState.individualTestSubjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getIndividualTestList(sub_id);
  };

  const getIndividualTestList = async (subject_id) => {
    startLoading();
    try {
      const response = await axios.get(url_41);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleRedirectToParent = async (testData) => {
    await setParentTarget(target);
    setPaperId(testData.paper_id);
    setUserId(userId);
    setLoginFromParentFlag(false);

    history.push({
      pathname: "/student-tests",
      state: {
        step: 2,
      },
    });
  };

  const getLearnAnalysisSubjectList = async () => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      class_id: userPreference.classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, requestPayload);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          learnAnalysisSubjectList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeSubject2 = (event, sub_id, sub_name, icon) => {
    setState((prevState) => ({
      ...prevState,
      learnAnalysisSubjectDataDetails: {
        ...prevState.learnAnalysisSubjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getLearnAnalysisData(sub_id);
  };

  const getLearnAnalysisData = async (subject_id) => {
    startLoading();
    try {
      const response = await axios.get(`${url_42}?subject_id=${subject_id}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          learnAnalysisData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleIndividualTestListPrevBtn = async () => {
    let subjectId = state.individualTestSubjectDataDetails.subjectId;
    let individualTestListPageNumber = state.individualTestListPageNumber;
    if (individualTestListPageNumber > 1) {
      startLoading();
      try {
        const response = await axios.get(
          `${url_41}?subject_id=${subjectId}&page=${
            individualTestListPageNumber - 1
          }`
        );
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            individualTestList: response.data.data,
            individualTestListPageNumber: individualTestListPageNumber - 1,
          }));
        } else {
          finishLoading();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const handleIndividualTestListNextBtn = async () => {
    let subjectId = state.individualTestSubjectDataDetails.subjectId;
    let individualTestListPageNumber = state.individualTestListPageNumber;
    startLoading();
    try {
      const response = await axios.get(
        `${url_41}?subject_id=${subjectId}&page=${
          individualTestListPageNumber + 1
        }`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestList: response.data.data,
          individualTestListPageNumber: individualTestListPageNumber + 1,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="userAnalyticsContentComponent_wrapper">
        <div className="container-fluid container-lg">
          <div className="userAnalyticsContentComponent_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <h2>Test Analytics</h2>
                <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-overall-test-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-overall-test"
                        type="button"
                        role="tab"
                        aria-controls="pills-overall-test"
                        aria-selected="true"
                        onClick={() => handleTabChange("overall_tests")}
                      >
                        Overall Test
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-individual-test-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-individual-test"
                        type="button"
                        role="tab"
                        aria-controls="pills-individual-test"
                        aria-selected="false"
                        onClick={() => handleTabChange("individual_test")}
                      >
                        Individual Test
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////overall test tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-overall-test"
                      role="tabpanel"
                      aria-labelledby="pills-overall-test-tab"
                    >
                      <div className="overall_test_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="Chartcontent_wrapper">
                            <div className="sec_1_wrapper">
                              {!isEmpty(state.overAllTestsData) ? (
                                state.overAllTestsData.OverallSubjectReport
                                  .length ? (
                                  <SubjectWiseDoughnut
                                    // options2
                                    // title={"All Tests"}
                                    dataPoints={
                                      state.overAllTestsData
                                        .OverallSubjectReport
                                    }
                                  />
                                ) : null
                              ) : null}

                              {!isEmpty(state.overAllTestsData) ? (
                                state.overAllTestsData.OverallDifficultyReport
                                  .length ? (
                                  <OverallDifficultyWiseProgress
                                    dataPoints={
                                      state.overAllTestsData
                                        .OverallDifficultyReport
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>

                            <div className="sec_1_wrapper">
                              {!isEmpty(state.overAllTestsData) ? (
                                state.overAllTestsData.TestChartQuestionsData
                                  ?.length ? (
                                  <CorrectIncorrectDoughnutChart
                                    // title={"All Tests"}
                                    dataPoints={
                                      state.overAllTestsData
                                        .TestChartQuestionsData
                                    }
                                  />
                                ) : null
                              ) : null}

                              {!isEmpty(state.overAllTestsData) ? (
                                state.overAllTestsData.TestChartData?.length ? (
                                  <AttemptUnattemptDoughnutChart
                                    title={"All Tests"}
                                    dataDetails={
                                      state.overAllTestsData.TestChartData
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>

                            <div className="sec_1_wrapper">
                              {!isEmpty(state.overAllTestsData) ? (
                                <OverallStrongAndImproveTopics
                                  subjects={
                                    state.overAllTestsData.OverallSubject
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //////////////individual test tab//////// */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-individual-test"
                      role="tabpanel"
                      aria-labelledby="pills-individual-test-tab"
                    >
                      <div className="individual_test_tab_wrapper">
                        <div className="outer_content_wrapper">
                          {/* <div className="left_side_content_wrapper">
                            <div className="subject_collection_wrapper">
                              {!isEmpty(state.individualTestSubjectList)
                                ? state.individualTestSubjectList.subject.length
                                  ? state.individualTestSubjectList.subject.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="single_select_subject"
                                          >
                                            <input
                                              type="radio"
                                              id={`individual_test_subject_${element.sub_id}`}
                                              name="individual_test_subject"
                                              value={element.sub_id}
                                              checked={
                                                element.sub_id ===
                                                parseInt(
                                                  state
                                                    .individualTestSubjectDataDetails
                                                    .subjectId
                                                )
                                              }
                                              onChange={(event) =>
                                                handleChangeSubject1(
                                                  event,
                                                  element.sub_id,
                                                  element.sub_name,
                                                  element.icon
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`individual_test_subject_${element.sub_id}`}
                                            >
                                              {element.sub_name}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </div>
                          </div> */}
                          <div className="right_side_content_wrapper">
                            <div className="tests_collection_wrapper">
                              {!isEmpty(state.individualTestList)
                                ? state.individualTestList.TestList.data.length
                                  ? state.individualTestList.TestList.data.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="single_test_wrapper"
                                          >
                                            <div className="sub_section_wrapper_1">
                                              <label>{`Start at ${moment(
                                                element.start_date
                                              ).format(
                                                "MMMM Do YYYY"
                                              )}`}</label>
                                              <div className="paper_idd">
                                                <span className="paper_id_text">
                                                  Paper ID:{" "}
                                                </span>
                                                {element.paper_id}
                                              </div>
                                            </div>
                                            <div className="sub_section_wrapper_2">
                                              <div className="text_content_wrapper">
                                                <p className="text_content">
                                                  {element.paper_name}
                                                </p>
                                              </div>
                                            </div>
                                            <div>
                                              {" "}
                                              <p>
                                                Attempted on-{" "}
                                                {element.attempted_on}
                                              </p>
                                            </div>
                                            <div className="sub_section_wrapper_3">
                                              <div className="sub_content_wrapper">
                                                <div className="left_content_wrapper">
                                                  <label>{`${element.total_questions} ques | ${element.duration_mins}min | ${element.total_marks} marks`}</label>
                                                </div>
                                                <div className="right_content_wrapper">
                                                  <label
                                                    className="lbl_1"
                                                    // onClick={() =>
                                                    //   handleStorageTestData(
                                                    //     element
                                                    //   )
                                                    // }
                                                    onClick={() =>
                                                      handleRedirectToParent(
                                                        element
                                                      )
                                                    }
                                                  >
                                                    View solution
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </div>
                            {/* {!isEmpty(state.individualTestList) ? (
                              state.individualTestList.TestList.data.length ? (
                                <div className="navigation_btn_wrapper">
                                  <div className="navigation_btn_inner_wrapper">
                                    <div className="btn_wrapper">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleIndividualTestListPrevBtn()
                                        }
                                      >
                                        Prev
                                      </button>
                                    </div>
                                    <div className="btn_wrapper">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleIndividualTestListNextBtn()
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userId: state.auth.user.user_id,
    target: state?.auth?.user?.UsertargetDetail[0]?.target,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },

    setPaperId: (payload) => {
      dispatch(setPaperId(payload));
    },

    setUserId: (payload) => {
      dispatch(setUserId(payload));
    },

    setLoginFromParentFlag: (payload) => {
      dispatch(setLoginFromParentFlag(payload));
    },
    setParentTarget: (payload) => {
      dispatch(setParentTarget(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAnalyticsContentComponent);
