import React from "react";
import images from "../../../../../../utilities/images/images";
import "./CreateNewDoubtBtn.scss";

const CreateNewDoubtBtn = ({ handleShow }) => {
  return (
    <div className="create_doubt_btn_wrapperr" onClick={handleShow}>
      <img className="plus_symbol" src={images.plus_icon} alt="plus icon"></img>{" "}
      Create New Doubt
    </div>
  );
};

export default CreateNewDoubtBtn;
