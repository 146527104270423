import React from "react";
import { Link } from "react-router-dom";
import images from "../../../../utilities/images/images";
import "./QuestionBankCreatePaper.scss";

const QuestionBankCreatePaper = () => {
  return (
    <>
      <div className="d-flex align-items-center q_bank_banner">
        <div><img src={images.acps_banner} alt="banner" /></div>
        <div>
          <p>Unlimited Question Bank</p>
          <p>Create your <strong>Own Customized Test & Practice</strong> unlimited times with <span>FREE</span> access</p>
        </div>
        <div>
          
        <Link to={{ pathname: "/question_bank_form" }}><img src={images.acps_banner_plus} alt="plus" />Create Paper</Link>
          </div>
      </div>
    </>
    // <div className="QuestionBankCreatePaperWrppr">
    //   <div className="QuestionBankCreatePaperInrWrppr">
    //     <div className="QuestionBankCreatePaperContent">
    //       <div className="heading">Question Bank</div>
    //       <div className="imgWrppr">
    //         <img className="img" src={images.user_activity_32} />
    //       </div>
    //       <p className="QuestionBankCreatePaperContentPara">
    //         Create your{" "}
    //         <span className="span-1">Own Customized Test & Practice</span>{" "}
    //         unlimited times with
    //         <span className="span-2"> FREE</span> access
    //       </p>
    //       <Link
    //         to={{ pathname: "/question_bank_form" }}
    //         className="create_new_button"
    //       >
    //         <div className="text_content_wrapper">
    //           <p className="text_content">
    //             <img className="add_btn" src={images.user_activity_33} />
    //             Create New Practice Paper
    //           </p>
    //         </div>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
  );
};

export default QuestionBankCreatePaper;
