import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeCurrentHandbookDetails: {},
  userHandbookAddress: {},
  cartProducts: [],
  //   refferalCode: "",
};

const HandbookSlice = createSlice({
  name: "handbooks",
  initialState,
  reducers: {
    setStoreCurrentHandbookDetails: (state, action) => {
      state.storeCurrentHandbookDetails = action.payload;
    },

    setUserHandbookAddress: (state, action) => {
      state.userAddress = action.payload;
    },

    addProductsIntoCart: (state, action) => {
      addToCart(state, action);
    },

    deleteProductsFromCart: (state, action) => {
      deleteFromCart(state, action);
    },

    // setRefferalCode: (state, action) => {
    //   state.refferalCode = action.payload;
    // },
  },
});

const addToCart = (state, action) => {
  const existingProduct = state.cartProducts.find(
    (product) => product.p_id === action.payload.p_id
  );

  if (existingProduct) {
    existingProduct.quantity += 1;
  } else {
    state.cartProducts.push({ ...action.payload, quantity: 1 });
  }
};

const deleteFromCart = (state, action) => {
  const filteredProduct = state.cartProducts.filter(
    (product) => product.p_id !== action.payload.p_id
  );

  state.cartProducts = filteredProduct;
};

export const {
  setStoreCurrentHandbookDetails,
  setUserHandbookAddress,
  addProductsIntoCart,
  //   setRefferalCode,
} = HandbookSlice.actions;

export default HandbookSlice.reducer;
