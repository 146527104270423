import { Fragment, useState, useEffect } from "react";
import "./faqs_content_component.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { url_35 } from "../../../../../custom_utilities/api_services";

const FaqsContentComponent = () => {
  const [state, setState] = useState({
    faqsList: [],
  });

  useEffect(() => {
    getFaqsList();
  }, []);

  const getFaqsList = async () => {
    try {
      const response = await axios.get(url_35);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          faqsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="faqsContentComponent_wrapper">
        {/* <div className="container"> */}
        <div className="faqsContentComponent_inner_wrapper">
          <div className="heading_wrapper">
            <p className="heading_text_content">Frequently Asked Questions?</p>
            {/* <div className="contact_btn">
              <span>Still didn't find your answer?</span>
              <Link className="cnt" to="/contact_us">
                Contact Us
              </Link>
            </div> */}
          </div>
          {state.faqsList.length ? (
            <section
              id="tab_accordion_section"
              className="tab_accordion_section"
            >
              <div className="tab_accordion_wrapper">
                <div className="accordion customAccordion" id="customAccordion">
                  {state.faqsList.map((element, index) => {
                    return (
                      <div class="accordion-item custom_accordion_item">
                        <h2
                          class="accordion-header custom_accordion_header"
                          id={`heading${index}`}
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            {element.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          class="accordion-collapse collapse custom_accordion_collapse"
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#customAccordion"
                        >
                          <div class="accordion-body custom_accordion_body">
                            {element.answer}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : null}
        </div>
        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default FaqsContentComponent;
