import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { url_247 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useAddProductToCart = () => {
  const queryClient = useQueryClient();
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const addProductToCart = async (productId) => {
    try {
      startLoading();

      const response = await axios.post(
        url_247,
        { product_id: productId },
        headers
      );

      finishLoading();

      console.log("response.data", response.data.data);
      return response.data.data;
    } catch (err) {
      finishLoading();
      console.log(err.message);
    }
  };

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["add_product_to_cart"],
    mutationFn: ({ productId, token, tokenType }) =>
      addProductToCart(productId, token, tokenType),

    onSuccess: () => {
      queryClient.invalidateQueries(["cart_products"]);
    },
  });

  return { mutate, isSuccess };
};

export default useAddProductToCart;
