import { Fragment, useState, useRef } from "react";
import './bitSatQuestionPaperSolutionContent.scss'
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import ShakaSolutionViewVideoPlayer from "../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import { ReactComponent as List } from './list.svg'
import { ReactComponent as Grid } from './grid.svg'
import { ReactComponent as List1 } from './list1.svg'
import { ReactComponent as Grid1 } from './grid1.svg'

const BitSatQuestionPaperSolutionContent = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    currentSolutionDetails,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userId,
  } = props;
  const questionsCollectionRef = useRef(null);

  const [lang, setLang] = useState(true);
  const [videoShow, setVideoShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isList, setisList] = useState(false);

  const renderOptions = (currentSolutionDetails, lang) => {
    if (currentSolutionDetails) {
      let step,
        type = currentSolutionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentSolutionDetails, lang);
        case 2:
          return renderInputField(currentSolutionDetails, lang);
        case 3:
          return renderMultiSelect(currentSolutionDetails, lang);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderSingleSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderMultiSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion]?.integer_answer}
              //onChange={(event) => handleInputFieldChange(event)}
              disabled
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = (currentSolutionDetails, lang) => {
    if (lang == true) {
      return {
        __html: currentSolutionDetails?.q_text,
      };
    } else if (lang == false) {
      const flag = ConvertStringToHTML(currentSolutionDetails.q_text_hindi);

      if (flag) {
        return {
          __html: currentSolutionDetails?.q_text_hindi,
        };
      } else {
        return {
          __html: currentSolutionDetails?.q_text,
        };
      }
    } else {
      return {
        __html: currentSolutionDetails?.q_text,
      };
    }
  };

  const renderSolution = (currentSolutionDetails, lang) => {
    if (lang == true) {
      return {
        __html: currentSolutionDetails?.sol_text,
      };
    } else if (lang == false) {
      const flag = ConvertStringToHTML(currentSolutionDetails.sol_text_hindi);

      if (flag) {
        return {
          __html: currentSolutionDetails?.sol_text_hindi,
        };
      } else {
        return {
          __html: currentSolutionDetails?.sol_text,
        };
      }
    } else {
      return {
        __html: currentSolutionDetails?.q_text,
      };
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true, "userId": "${userId}"}`,
      "*"
    );

    // Jump to a particular time
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    // Handle message event for receiving video timing details
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimingDetails",
          JSON.stringify(videoTimingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
  };

  const renderVideo = (currentQuestionDetails) => {
    const videoId = currentQuestionDetails.video_id_english;
    const hindiVideoId = currentQuestionDetails.video_id_hindi;
    const englishVideoCriptId = currentQuestionDetails.english_video_cript_id;
    const hindiVideoCriptId = currentQuestionDetails.hindi_video_cript_id;

    const isHindi = !lang && hindiVideoId && hindiVideoCriptId;
    const selectedVideoId = isHindi ? hindiVideoId : videoId;
    const selectedVideoCriptId = isHindi
      ? hindiVideoCriptId
      : englishVideoCriptId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    if (!urlPattern.test(selectedVideoId)) {
      return null;
    }

    const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";

    if (selectedVideoCriptId && selectedVideoCriptId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>
          {videoShow ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoCriptId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="videoWrapper">
          {selectedVideoId && (
            <button
              className="view_videoSolution_btn"
              onClick={handleVideoShow}
            >
              {buttonLabel}
            </button>
          )}

          {videoShow ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
        </div>
      );
    }
  };

  // method for converting string(coming from api) into hmtl
  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  // const renderQuestionLabel = (question, index) => {
  //   if (currentQuestion === index) {
  //     return (
  //       <label
  //         className="unanswered_lbl current_lbl"
  //         onClick={() => handleQuestionJump(index)}
  //       >
  //         <div className="questionBox">
  //           {`${index + 1}.`}
  //           <p
  //             dangerouslySetInnerHTML={{
  //               __html: question.q_text,
  //             }}
  //           ></p>
  //         </div>
  //         <img className="arrow" src={images.right_Ar} />
  //       </label>
  //     );
  //   } else {
  //     if (question.isAttempted) {
  //       if (question.isReview) {
  //         return (
  //           <label
  //             className="unanswered_lbl review_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               <div className="questionBox">
  //                 {`${index + 1}.`}
  //                 <p
  //                   dangerouslySetInnerHTML={{
  //                     __html: question.q_text,
  //                   }}
  //                 ></p>
  //               </div>
  //               <img className="arrow" src={images.right_Ar} />
  //             </div>
  //           </label>
  //         );
  //       } else {
  //         return (
  //           <label
  //             className="unanswered_lbl answered_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               {`${index + 1}.`}
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: question.q_text,
  //                 }}
  //               ></p>
  //             </div>
  //             <img className="arrow" src={images.right_Ar} />
  //           </label>
  //         );
  //       }
  //     } else {
  //       if (question.isReview) {
  //         return (
  //           <label
  //             className="unanswered_lbl review_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               {`${index + 1}.`}
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: question.q_text,
  //                 }}
  //               ></p>
  //             </div>
  //             <img className="arrow" src={images.right_Ar} />
  //           </label>
  //         );
  //       } else if (question.isSkipped) {
  //         return (
  //           <label
  //             className="unanswered_lbl skip_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               {`${index + 1}.`}
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: question.q_text,
  //                 }}
  //               ></p>
  //             </div>
  //             <img className="arrow" src={images.right_Ar} />
  //           </label>
  //         );
  //       } else if (
  //         question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
  //         question.integer_answer?.length
  //       ) {
  //         return (
  //           <label
  //             className="unanswered_lbl answered_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               {`${index + 1}.`}
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: question.q_text,
  //                 }}
  //               ></p>
  //             </div>
  //             <img className="arrow" src={images.right_Ar} />
  //           </label>
  //         );
  //       } else {
  //         return (
  //           <label
  //             className="unanswered_lbl"
  //             onClick={() => handleQuestionJump(index)}
  //           >
  //             <div className="questionBox">
  //               {`${index + 1}.`}
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: question.q_text,
  //                 }}
  //               ></p>
  //             </div>
  //             <img className="arrow" src={images.right_Ar} />
  //           </label>
  //         );
  //       }
  //     }
  //   }
  // };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unAttempted current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}`}
            {/* <p
              dangerouslySetInnerHTML={{
                __html: question.q_text,
              }}
            ></p> */}
          </div>
          {/* <img className="arrow" src={images.right_Ar} /> */}
        </label>
      );
    } else {
      if (question.is_attempted == 1) {
        return (
          <label
            className="answereds_lbl unanswered_lbl"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">
              {`${index + 1}`}
              {/* <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p> */}
            </div>
            {/* <img className="arrow" src={images.right_Ar} /> */}
          </label>
        );
      } else if (question.is_attempted == 0) {
        return (
          <label
            className="unAttempted"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">
              {`${index + 1}`}
              {/* <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p> */}
            </div>
            {/* <img className="arrow" src={images.right_Ar} /> */}
          </label>
        );
      }
    }
  };
  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unAttempted unanswered_lbl1 current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            setVideoShow(false);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
                  <div className="QBox">
            <p
              dangerouslySetInnerHTML={{
                __html: question.q_text,
              }}
            ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.is_attempted == 1) {
        return (
          <label
            className="answereds_lbl unanswered_lbl unanswered_lbl1"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox questionBox1">
              <p>{`${index + 1}.`}</p>
                  <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else if (question.is_attempted == 0) {
        return (
          <label
            className="unAttempted unanswered_lbl1"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox questionBox1">
              <p>{`${index + 1}.`}</p>
                  <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      }
    }
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  let progressCount =
    (100 / testQuestionsDetails.length) * (currentQuestion + 1);


  return (
    <Fragment>
      <div className="bitsat_questions_solution_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Questions
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="sub_sub_section_wrapper_1_card">
                        <p>
                          {currentQuestion + 1}/{testQuestionsDetails.length}
                        </p>
                        <div className="markReview">
                          {ConvertStringToHTML(
                            currentSolutionDetails?.q_text_hindi
                          ) ? (
                            <button
                              className={"m2_language"}
                              onClick={() => setLang(!lang)}
                            >
                              {/* <img src={images.m2_language} /> */}
                              {lang ? "अ" : "A"}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails?.length}</span>
                      </div>
                    </div> */}
                    <div className="sub_sub_section_wrapper_2">
                      {/* <h2>Exam Test</h2> */}
                      <div className="sectionWiseTestWrapper">
                        <p className="subjectWiseTest_heading">
                          Question type:{" "}
                          <span>{currentSolutionDetails?.answer_type}</span>
                        </p>
                      </div>
                      <div className="text_content_wrapper">
                        {/* <div className="typeLanguageWrapper">
                          <span>
                            Question type: {currentSolutionDetails?.answer_type}
                          </span>
                          <div className="language_chage_icon">
                            {ConvertStringToHTML(
                              currentSolutionDetails?.q_text_hindi
                            ) ? (
                              <button onClick={() => setLang(!lang)}>
                                <img src={images.m2_language} />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}

                        <div className="questionBox">
                          <span className="text_content">
                            {`${Number(currentQuestion) + 1}.`}
                          </span>

                          {currentSolutionDetails !== null &&
                          currentSolutionDetails !== undefined ? (
                            <p
                              className="text_content"
                              dangerouslySetInnerHTML={renderQuestionText(
                                currentSolutionDetails,
                                lang
                              )}
                            ></p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="sub_sub_section_wrapper_3">
                      {currentSolutionDetails !== null &&
                      currentSolutionDetails.option !== undefined
                        ? renderOptions(currentSolutionDetails, lang)
                        : ""}
                    </div>

                    {/* <div className="sub_sub_section_wrapper_4">
                    <div className="checkbox_wrapper">
                      <input
                        type="checkbox"
                        checked={
                          testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].isAnswerGuessed
                        }
                        onChange={(event) => handleGuessedAnswer(event)}
                      />
                      <label>Have you guessed the answer?</label>
                    </div>
                  </div> */}
                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handlePreviousQuestionBtn(currentQuestion - 1);
                                scrollUp2();
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="right_side_wrapper">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <div className="btn_wrapper">
                            <Link
                              className="backBtn"
                              to={{
                                pathname: "/bit_sat_summary",
                                // state: { tab: "exam" },
                              }}
                            >
                              Exit
                            </Link>
                          </div>
                        ) : (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handleNextQuestionBtn(currentQuestion + 1);
                                scrollUp();
                              }}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="QuestionSolution">
                      <h5>Solution :</h5>
                      <div>
                        <p
                          dangerouslySetInnerHTML={renderSolution(
                            currentSolutionDetails,
                            lang
                          )}
                        ></p>
                        {renderVideo(currentSolutionDetails)}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>
                      <div className="text_content_wrapper">
                        <p className="text_content">All Questions</p>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <List1 /> : <List />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <Grid /> : <Grid1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="questions_serial_number_collection"
                        ref={questionsCollectionRef}
                      >
                        {testQuestionsDetails?.length
                          ? testQuestionsDetails?.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                >
                                  {isList
                                    ? renderQuestionLabel1(element, index)
                                    : renderQuestionLabel(element, index)}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BitSatQuestionPaperSolutionContent;
