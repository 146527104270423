import React from "react";
import "./All_page_content.scss";

import PrivacyPolicyContentComponent from "../privacy_policy/privacy_policy";
import Terms_n_condition from "../terms_n_condition/terms_n_condition";
import Faqs from "../faqs/faqs";
import AboutUs from "../about_us/about_us";
import ContactUs from "../contact_us/contact_us";
import images from "../../../../utilities/images/images";
import { Link } from "react-router-dom";
// import HelpAndSupport from "../helpAndSupport/HelpAndSupport";
import HelpAndSupport from "../helpAndSupport/HelpAndSupport";

const All_page_content = (props) => {
  const {
    state: { tab },
  } = props.location;
  return (
    <>
      <div className="all_page_content_wrapper">
        <div className="all_page_button">
          {/* <div className="container"> */}
          <div className="back_btn">
            <Link to="/user_dashboard">
              <img src={images.login_2} alt="backarrow" />
            </Link>
            <h2>Welcome, We are here to help you</h2>
          </div>
          <div className="container_inner">
            {/* <h2>Welcome, We are here to help you</h2> */}
            <ul class="nav nav-tabs all_page_tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class={
                    tab == "privacy_policy" ? "nav-link active" : "nav-link"
                  }
                  id="privacy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#privacy"
                  type="button"
                  role="tab"
                  aria-controls="privacy"
                  aria-selected="true"
                >
                  Privacy Policy
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "terms" ? "nav-link active" : "nav-link"}
                  id="terms-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#terms"
                  type="button"
                  role="tab"
                  aria-controls="terms"
                  aria-selected="false"
                >
                  Terms & Conditions
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "faqs" ? "nav-link active" : "nav-link"}
                  id="faqs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#faqs"
                  type="button"
                  role="tab"
                  aria-controls="faqs"
                  aria-selected="false"
                >
                  FAQS
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "AboutUs" ? "nav-link active" : "nav-link"}
                  id="aboutUs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#aboutUs"
                  type="button"
                  role="tab"
                  aria-controls="aboutUs"
                  aria-selected="false"
                >
                  About Us
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "contactUs" ? "nav-link active" : "nav-link"}
                  id="contactUs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contactUs"
                  type="button"
                  role="tab"
                  aria-controls="contactUs"
                  aria-selected="false"
                >
                  Contact Us
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class={
                    tab === "help-support" ? "nav-link active" : "nav-link"
                  }
                  id="help-support-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#help-support"
                  type="button"
                  role="tab"
                  aria-controls="help-support"
                  aria-selected="false"
                >
                  Help & support
                </button>
              </li>
            </ul>
          </div>
          {/* </div> */}
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class={
              tab == "privacy_policy"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="privacy"
            role="tabpanel"
            aria-labelledby="privacy-tab"
          >
            <PrivacyPolicyContentComponent />
          </div>
          <div
            class={
              tab == "terms" ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="terms"
            role="tabpanel"
            aria-labelledby="terms-tab"
          >
            <Terms_n_condition />
          </div>
          <div
            class={
              tab == "faqs" ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="faqs"
            role="tabpanel"
            aria-labelledby="faqs-tab"
          >
            <Faqs />
          </div>
          <div
            class={
              tab == "AboutUs" ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="aboutUs"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <AboutUs />
          </div>
          <div
            class={
              tab === "contactUs"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="contactUs"
            role="tabpanel"
            aria-labelledby="contactUs-tab"
          >
            <ContactUs />
          </div>

          <div
            class={
              tab === "help-support"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="help-support"
            role="tabpanel"
            aria-labelledby="help-support-tab"
          >
            <HelpAndSupport />
          </div>
        </div>
      </div>
    </>
  );
};

export default All_page_content;
