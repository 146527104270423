// SubjectSelection.js
import React from "react";
import "./SubjectSelection.scss";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";

const SubjectSelection = ({
  topicSubjectList,
  currentSubject,
  handleChangeSubject,
}) => {
  const backGroundColorFromBackend = (color_code) => {
    const colorCode = color_code ? color_code.split(",") : null;

    if (colorCode !== null) {
      return {
        background: `linear-gradient(277deg,${colorCode[0]}, ${colorCode[1]} 101.46%  )`,
      };
    } else {
      return {
        background: "#ffffff",
      };
    }
  };

  return (
    <div className="AdaptiveCps_subject_collection_wrapper">
      {!isEmpty(topicSubjectList) &&
        topicSubjectList.map((element, index) => {
          const isChecked = topicSubjectList.length === 1;

          return (
            <div key={index} className="AdaptiveCps_single_select_subject">
              <input
                type="radio"
                id={`subject_${element.subject}`}
                name="subject"
                value={element.subject} // Use the subject as the value
                checked={currentSubject === element.subject}
                onChange={handleChangeSubject}
              />
              <label
                htmlFor={`subject_${element.subject}`}
                style={
                  currentSubject === element.subject
                    ? backGroundColorFromBackend(element.color_code)
                    : {}
                }
              >
                <div
                  className={`subject_icon ${element.subject.toLowerCase()}`}
                >
                  <img src={element.icon1} alt="icon" />
                </div>
                {element.subject}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default SubjectSelection;
