import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { isTablet, isMobile, osName } from "react-device-detect";
import { BASE_URL } from "../../../../../../../custom_utilities/baseURL";
import "./StreamingVideo.scss";
import ChatComponent from "./chatting_box/Chatting";
import StreamingVideoDetails from "./StreamingVideoDetails";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";

const StreamingVideo = ({
  token,
  tokenType,
  LiveVideoStreamingDetails,
  videoData,
}) => {
  const { zoomId, isLive, live_class_id } = LiveVideoStreamingDetails;

  const [streamingDetails, setStreamingDetails] = useState({});

  useEffect(() => {
    getStreamingDetails();
  }, []);

  const isAndroidTablet = isTablet && osName === "Android";
  const isIPad = isTablet && osName === "iOS";
  const isIPhone = isMobile && osName === "iOS";

  const checkDevice = () => {
    if (isTablet) {
      if (isAndroidTablet) {
        return "tablet";
      } else if (isIPad) {
        return "ios";
      } else {
        return "web";
      }
    } else if (isIPhone) {
      return "ios";
    } else {
      return "web";
    }
  };

  const checkIsIphone = () => {
    if (isIPhone) {
      return 1;
    } else {
      return 0;
    }
  };

  const getStreamingDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(
      `${BASE_URL}/api/streamos?id=${zoomId}&isMobile=false&type=${
        isLive ? "Live" : "Recorded Live"
      }&device=${checkDevice()}&is_ios=${checkIsIphone()}`,
      { headers }
    );

    if (response.status === 200) {
      setStreamingDetails(response.data.data);
    }
  };

  return (
    <>
      <HeaderComponent />
      {Object.keys(streamingDetails).length ? (
        <div className="live-streaming-container">
          <StreamingVideoDetails
            streamingDetails={streamingDetails}
            isLive={isLive}
            live_class_id={live_class_id}
            videoData={videoData}
          />
          {isLive && (
            <ChatComponent
              streamingDetails={streamingDetails}
              live_class_id={live_class_id}
              isLive={isLive}
              videoData={videoData}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    LiveVideoStreamingDetails: state.LiveVideoStreamingSlice,
    videoData: state.userActivity.videoData,
  };
};

export default connect(mapStateToProps, null)(StreamingVideo);
