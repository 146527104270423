import axios from "axios";
import {
  url_237,
  url_238,
  url_239,
  url_240,
} from "../../../../custom_utilities/api_services";
import { REFER_AND_EARN_MODALS } from "../utils";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../custom_utilities/toastify_config";
import {
  setPaymentDetails,
  setReferallList,
  setCheckedCards,
} from "../../../../redux/refer_earn/ReferAndEarnSlice";

export const saveUpiBankDetails = async (data, token, tokenType) => {
  const headers = {
    Authorization: `${tokenType} ${token}`,
  };

  try {
    const response = await axios.post(url_238, data, { headers });
    if (response.data.status === 200) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const cancelRequest = async (data, token, tokenType, setModalType) => {
  const headers = {
    Authorization: `${tokenType} ${token}`,
  };

  try {
    const response = await axios.post(url_240, data, { headers });
    if (response.data.status === 200) {
      setModalType(REFER_AND_EARN_MODALS.INIT);
      toastifyMessage({
        status: response.data.status,
        message: "Your Request has Submitted Successfully",
      });

      return response.data.data;
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const reeedemCash = async (data, token, tokenType, setModalType) => {
  const headers = {
    Authorization: `${tokenType} ${token}`,
  };

  const payloadData = data.map((elem) => {
    return elem.id;
  });

  const finalPayloadData = {
    id: payloadData,
  };

  try {
    const response = await axios.post(url_239, finalPayloadData, { headers });
    if (response.data.status === 200) {
      setModalType(REFER_AND_EARN_MODALS.SUCCESSFULL);

      return response.data.data;
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const getReferalLists = async (
  token,
  tokenType,
  dispatch,
  startLoading,
  finishLoading
) => {
  const headers = {
    Authorization: `${tokenType} ${token}`,
  };

  try {
    startLoading();
    const response = await axios.get(url_237, { headers });

    if (response.data.status === 200) {
      finishLoading();
      dispatch(setPaymentDetails(response.data.payment_data));
      dispatch(setReferallList([...response.data.referral_list]));
      dispatch(setCheckedCards([]));
    }
  } catch (error) {
    console.log(error);
  }
};
