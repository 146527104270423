import { Fragment, useState, useEffect, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import useMathjax from "../../../../../../../hooks/useMathjax";
import images from "../../../../../../../utilities/images/images";
import "./mockTestPracticeQuestionView.scss";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import ShakaSolutionViewVideoPlayer from "../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as List1 } from "./list1.svg";
import { ReactComponent as Grid1 } from "./grid1.svg";

const MockTestPracticeQuestionView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestionDetails,
    page,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleSaved,
    handleGuessedAnswer,
    handleQuestionJump,
    handleSubmitTest,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
    handleCurrentOption,
  } = props;

  const [reportErrorData, setReportErrorData] = useState([]);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });
  const [isList, setisList] = useState(true);
  const [videoShow, setVideoShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  //  condition for Report Error modal submit button

  const isDisable =
    saveReportErrorData.option_id.length == 0 ||
    saveReportErrorData.description.length == 0
      ? true
      : false;

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  // function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80}`,
      behavior: "smooth",
    });
  };
  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showError, setShowError] = useState(false);
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  // for report error modal
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [disabled1, setDisabled] = useState(false);

  useMathjax(currentQuestionDetails, lang, sol);
  useMathjax(currentQuestionDetails, isList, sol);

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };
  const handleSolution = () => {
    setSol(false);
    setVideoShow(false);
    //classEditor();
  };
  const disableSol = () => {
    setDisabled(true);
  };

  const renderSolutionss = () => {
    if (!sol) {
      return (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.sol_text,
            }}
          ></p>
          {renderVideo()}
        </>
      );
    }
  };

  const renderOptions = (currentQuestionDetails, lang) => {
    if (currentQuestionDetails) {
      let step,
        type = currentQuestionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentQuestionDetails, currentQuestion);
        case 2:
          return renderInputField(currentQuestionDetails, currentQuestion);
        case 3:
          return renderMultiSelect(currentQuestionDetails, currentQuestion);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const classEditor = (s, wc, optionId) => {
    if (!sol && Number(s)) {
      return "single_option_wrapper classCorrect";
    } else if (!sol && wc && wc.indexOf(Number(optionId)) > -1) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const handlePurticularQuestion = (currentQuestion) => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion4 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSol();
    } else {
      handleSolution();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans == 1
                          ? true
                          : false
                      }
                      // checked={element.is_user_correct_ans == 1}
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        handleCurrentOption(element.option_id);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "multi_select_option_collection_wrapper show"
              : "multi_select_option_collection_wrapper hide"
          }
        >
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (currentQuestionDetails, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              // value={testQuestionsDetails[currentQuestion2].integer_answer}
              value={currentQuestionDetails.integer_answer}
              onChange={(event) => {
                handleInputFieldChange(event);
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return {
        __html: lang
          ? questionsArr[currentQuestion].q_text
          : questionsArr[currentQuestion].q_text_hindi !== null
          ? questionsArr[currentQuestion].q_text_hindi
          : questionsArr[currentQuestion].q_text,
      };
    } else {
      return null;
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion3(index);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <p
              className="QBox"
              dangerouslySetInnerHTML={{
                __html: lang
                  ? question.q_text
                  : question.q_text_hindi !== null
                  ? question.q_text_hindi
                  : question.q_text,
              }}
            ></p>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox questionBox1">
              <p>{`${index + 1}.`}</p>
              <p
                className="QBox"
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox  questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };
  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion3(index);
          }}
        >
          <div className="questionBox">
            {`${index + 1}`}
            {/* <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div> */}
          </div>
          {/* <img className="arrow" src={images.right_Ar} /> */}
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}`}
                  {/* <div className="QBox">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.q_text,
                      }}
                    ></p>
                  </div> */}
                </div>
                {/* <img className="arrow" src={images.right_Ar} /> */}
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox">
              {`${index + 1}`}
              {/* <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div> */}
            </div>
            {/* <img className="arrow" src={images.right_Ar} /> */}
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                  rr
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      }
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
    setTimeout(() => {
      targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const renderVideo = () => {
    const videoId = currentQuestionDetails?.video_id_english;
    const hindiVideoId = currentQuestionDetails?.video_id_hindi;

    const isHindi = !lang && hindiVideoId;

    const selectedVideoId = isHindi ? hindiVideoId : videoId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const isIframe = urlPattern.test(selectedVideoId);

    // const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";
    const buttonLabel =
      videoShow && !lang
        ? "वीडियो समाधान बंद करें" // Close Video Solution
        : videoShow && lang
        ? "Close Video Solution"
        : !videoShow && !lang
        ? "वीडियो समाधान देखें" // View Video Solution
        : "View Video Solution";

    if (selectedVideoId && selectedVideoId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>

          {videoShow && isIframe ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
          {videoShow && !isIframe ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderSingleQuestion = () => {
    if (lang) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: currentQuestionDetails.q_text,
          }}
        ></p>
      );
    } else {
      if (ConvertStringToHTML(currentQuestionDetails?.q_text_hindi)) {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text_hindi,
            }}
          ></p>
        );
      } else {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text,
            }}
          ></p>
        );
      }
    }
  };

  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;
  // const config = {
  //   //loader: { load: ["[tex]/html"] },
  //   loader: {
  //     load: [
  //       "input/asciimath",
  //       "output/chtml",
  //       "ui/menu",
  //       "[mml]/mml3",
  //       "[tex]/html",
  //     ],
  //   },
  // };

  const config = {
    "fast-preview": {
      disabled: true,
    },
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
    messageStyle: "none",
  };
  return (
    <Fragment>
      <div className="mock_practice_test_ppr_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Questions
                    </button>
                    <button
                      className="togglebtn submit"
                      onClick={() => {
                        //handleSaveQuestion();
                        handleShow();
                      }}
                    >
                      Submit Test
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="sub_sub_section_wrapper_1_card">
                        <p>
                          {currentQuestion + 1}/{testQuestionsDetails.length}
                        </p>
                      </div>
                    </div>

                    <div style={{ overflowY: "auto", padding: "18px" }}>
                      <div className="sectionWiseTestWrapper">
                        <div className="d-flex gap-3 align-items-center justify-content-between">
                          <p className="subjectWiseTest_heading">
                            Question type:{" "}
                            <span>
                              {
                                testQuestionsDetails[currentQuestion]
                                  .answer_type
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="questionWrapper">
                        <div className="sub_sub_section_wrapper_2">
                          <div className="text_content_wrapper">
                            <div className="questionBox">
                              <span className="text_content">
                                {`${Number(currentQuestion) + 1}.`}
                              </span>
                              {renderSingleQuestion()}
                            </div>
                          </div>
                        </div>
                        <div className="sub_sub_section_wrapper_3">
                          {currentQuestionDetails !== null &&
                          currentQuestionDetails.option !== undefined
                            ? renderOptions(currentQuestionDetails, lang)
                            : ""}
                        </div>
                      </div>
                      {renderSolutionss()}
                    </div>

                    {/* toaster for  successfull submission of Report error  */}

                    <ToastContainer />
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit1} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button className="cancel" onClick={handleClose}>
                              Cancel
                            </button>
                            <button
                              onClick={() =>
                                handleSubmitTest(currentQuestionDetails)
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={showError}
                      onHide={handleCloseReportError}
                      className="errorModal"
                    >
                      <Modal.Header closeButton>
                        {" "}
                        <h3>Report an Error</h3>
                      </Modal.Header>
                      <Modal.Body className="modal-body">
                        <div className="error_Popup">
                          <p className="text_content">
                            You can just write to us, If there is any incorrect
                            or error in question and given options.
                          </p>
                          <ul className="errorList">
                            {reportErrorData?.map((element, index) => {
                              return (
                                <li className="errorListItem" key={element.id}>
                                  <input
                                    type="radio"
                                    id={element.id}
                                    name="error"
                                    value={element.option_name}
                                    onChange={(event) => {
                                      setSaveReportErrorData({
                                        ...saveReportErrorData,
                                        option_id: event.target.id,
                                      });
                                    }}
                                  />{" "}
                                  <label htmlFor={element.id}>
                                    {element.option_name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="textarea_content"
                            type="text"
                            onChange={(event) =>
                              setSaveReportErrorData({
                                ...saveReportErrorData,
                                description: event.target.value,
                              })
                            }
                            placeholder="Write to Us..."
                          />{" "}
                          <br />
                          <Button
                            disabled={isDisable}
                            onClick={handlePostReportErrorData}
                          >
                            Submit
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>

                    {/* <div className="sub_sub_section_wrapper_4">
                    <div className="checkbox_wrapper">
                      <input
                        type="checkbox"
                        checked={
                          testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].isAnswerGuessed
                        }
                        onChange={(event) => handleGuessedAnswer(event)}
                      />
                      <label>Have you guessed the answer?</label>
                    </div>
                  </div> */}
                    <div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          color: "#B50503",
                          fontSize: " 0.75rem",
                        }}
                      >
                        <span style={{ marginRight: "5px", cursor: "pointer" }}>
                          <img src={images.user_activity_14_1}></img>
                        </span>{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleGetReportErrorOptions}
                        >
                          Report an error
                        </span>
                      </div>
                      <div className="sub_sub_section_wrapper_5">
                        <div className="left_side_wrapper">
                          {currentQuestion > 0 && (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handlePreviousQuestionBtn(
                                    currentQuestion - 1
                                  );
                                  scrollUp2();
                                  handlePurticularQuestion(currentQuestion);
                                }}
                              >
                                Previous
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="right_side_wrapper">
                          {testQuestionsDetails.length ===
                          currentQuestion + 1 ? (
                            <div className="btn_wrapper">
                              {sol ? (
                                <button
                                  disabled={disabled1 ? false : true}
                                  className={!disabled1 ? "opacity" : "true"}
                                  onClick={() => handleSolution()}
                                >
                                  Solution
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleSaveQuestion();
                                    handleShow();
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          ) : (
                            <div className="btn_wrapper">
                              {sol ? (
                                <button
                                  disabled={disabled1 ? false : true}
                                  className={!disabled1 ? "opacity" : "true"}
                                  onClick={() => handleSolution()}
                                >
                                  Solution
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleNextQuestionBtn(currentQuestion + 1);
                                    handleSol();
                                    scrollUp();
                                    handlePurticularQuestion4();
                                  }}
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div
                      style={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        color: "#FF8724",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={handleGetReportErrorOptions}
                    >
                      <span style={{ marginRight: "5px" }}>
                        <img src={images.user_activity_14}></img>
                      </span>{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Report an error
                      </span>
                    </div> */}
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button
                          onClick={() => {
                            handleSaveQuestion();
                            handleShow();
                          }}
                        >
                          Submit Test
                        </button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <List1 /> : <List />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <Grid /> : <Grid1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>

                          {/* <div className="single_marking">
                          <label className="box_pic box_pick_background_5"></label>
                          <label className="question_marking_text">
                            Mark For Review....
                          </label>
                        </div> */}
                        </div>
                      </div>
                      <div className="questions_serial_number_collection">
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                  id="myDiv"
                                >
                                  {isList ? (
                                    <>{renderQuestionLabel1(element, index)}</>
                                  ) : (
                                    <>{renderQuestionLabel(element, index)}</>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MockTestPracticeQuestionView;
