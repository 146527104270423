import moment from "moment";

const StatusCard = ({ orderStatus, orderStatusDetails }) => {
  const formatDate = (dateString) => {
    const date = moment(dateString, "YYYY-MM-DD HH:mm:ss");
    return date.format("DD/MM/YYYY hh:mm A");
  };
  return (
    <div className="statusCard">
      {orderStatusDetails?.handbook_order_status?.map((statusDetail, index) => (
        <div
          key={index}
          className={`statusItem ${
            orderStatus === statusDetail.label ? "active" : ""
          }`}
        >
          <span className="statusCircle"></span>
          <div className="statusTextContainer">
            <span
              className={`statusTitle ${
                orderStatus === statusDetail?.label ? "active" : ""
              }`}
            >
              {statusDetail?.label}
            </span>
            <div className="statusDate">
              {statusDetail?.created_at
                ? formatDate(statusDetail?.created_at)
                : ""}
            </div>
            <div className="statusDescription">{statusDetail.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatusCard;

