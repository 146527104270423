import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./QuestionSwitcher.scss";

const QuestionSwitcher = ({
  currentSolutionDetails,
  currentQuestion,
  scrollUp2,
  scrollUp,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  testQuestionsDetails,
}) => {
  return (
    <div className="parent_sub_sub_section_wrapper_5">
      <div className="left_side_wrapper">
        {currentQuestion > 0 && (
          <div className="btn_wrapper">
            <button
              onClick={() => {
                handlePreviousQuestionBtn(currentQuestion - 1);
                // setVideoShow(false);
                scrollUp2();
              }}
            >
              Previous
            </button>
          </div>
        )}
      </div>
      <div className="right_side_wrapper">
        {testQuestionsDetails?.data?.length === currentQuestion + 1 ? (
          <div className="btn_wrapper">
            <Link
              className="btn"
              to={{
                pathname: "/student-tests",
                state: { step: 2 },
              }}
            >
              Exit
            </Link>
          </div>
        ) : (
          <div className="btn_wrapper">
            <button
              onClick={() => {
                handleNextQuestionBtn(currentQuestion + 1);
                // setVideoShow(false);
                scrollUp();
              }}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionSwitcher;
