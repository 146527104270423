import React from "react";
import "./CartProductsPage.scss";
import images from "../../../../../../../../utilities/images/images";
import useDeleteProductFromCart from "../../hooks/cart_products/useDeleteProductFromCart";
import useHandleProductQuantity from "../../hooks/cart_products/useHandleProductQuantity";

const CartProductsPage = ({ token, tokenType, cartProducts, tab }) => {
  const { mutate: updateProductQntyMutation } = useHandleProductQuantity();
  const { mutate: deleteProductFromCartMutation } = useDeleteProductFromCart();

  const handleDeleteFromCart = (productId) => {
    deleteProductFromCartMutation({ productId, token, tokenType });
  };

  const handleProductQuantity = (newQty, productId) => {
    updateProductQntyMutation({ newQty, productId, token, tokenType });
  };

  return (
    <div className="cart_products_container">
      <div className="cart_product_list_container">
        <ul className="products_list">
          {cartProducts?.map((product) => {
            return (
              <li key={product.p_id} className="product_item_wrapper">
                <div className="product_item_inner_wrapper">
                  <div className="product_img">
                    <img src={product.image} />
                  </div>

                  <div className="product_content">
                    <div className="product_top">
                      <div className="p_title">{product?.product_name}</div>
                      <div className="p_type">{product?.type}</div>
                    </div>

                    <div className="price_and_actions">
                      <div className="p_price">
                        ₹{" "}
                        {tab === "cart"
                          ? product?.price
                          : product?.discounted_price}
                      </div>

                      {tab == "cart" && (
                        <div className="p_actions">
                          <div className="p_increase_decrease">
                            <div className="p_decrease">
                              <img
                                onClick={() =>
                                  handleProductQuantity(
                                    product?.qty - 1,
                                    product?.p_id
                                  )
                                }
                                src={images.handbookDecrease}
                                alt="remove_cart_icon"
                              />
                            </div>

                            <div className="p_quantity">{product?.qty}</div>
                            <div className="p_increase">
                              <img
                                onClick={() =>
                                  handleProductQuantity(
                                    product?.qty + 1,
                                    product?.p_id
                                  )
                                }
                                src={images.handbookIncrease}
                                alt="add_cart_icon"
                              />
                            </div>
                          </div>

                          <div
                            className="delete"
                            onClick={() => handleDeleteFromCart(product?.p_id)}
                          >
                            <img
                              src={images.handbookDelete}
                              alt="delete_icon"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CartProductsPage;
