import { Fragment } from "react";
import "./examCrackerTestInstruction.scss";

import images from "../../../../../../../utilities/images/images";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

const ExamCrackerTestInstructionView = (props) => {
  let { startBtn, testData } = props;

  const history = useHistory();
  const location = useLocation();
  let package_name = location.state.package_name;

  const handleBackBtn = () => {
    console.log("handleBackBtn");
    history.push({
      pathname: "/exam_cracker_subject_topic",
      state: {
        package_name: package_name,
        courseId: location.state,
      },
    });
  };

  return (
    <>
      <div className="examcrackerInstructionScreenWrppr">
        <div className="examcrackerInstructionScreenInnrWrppr">
          <div className="bckBtnAndNameWrppr">
            <button className="backBtn" onClick={handleBackBtn}>
              <img src={images.backBtn1} alt="backBtn" />
            </button>
            <h1>Test Instructions</h1>
          </div>

          <div className="contentWrppr">
            <div className="header-section">
              <div className="testName name">Exam Cracker</div>
              <div className="topicName name">{testData.topic_name}</div>
            </div>

            <div className="content-section">
              <div className="content-inner-section">
                <h3>Set of Instructions</h3>

                <ul>
                  <li>This Section contains a set of questions for practice</li>

                  <li>
                    {" "}
                    Users can go to previous question by clicking on the
                    previous button
                  </li>

                  <li>
                    {" "}
                    Users can go to next question by clicking on the next button
                  </li>
                </ul>

                <div className="bestOfLuckText">Best of Luck</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="startTestBtn" onClick={() => startBtn()}>
        Start Test
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.examCrackerTestsData.examCrackerTestData,
  };
};

export default connect(mapStateToProps)(ExamCrackerTestInstructionView);
