import { Fragment, useState } from "react";
import "./signUp_step_one.scss";
import images from "../../../utilities/images/images";

const SignUpStepOne = (props) => {
  const {
    userDetails,
    targets,
    handleSelectTarget,
    handleUserDetailsInputField,
    handleUserDetailsFormSubmit,
    userPreference,
    handleBack,
  } = props;
  const [innerStep, setInnerStep] = useState(1);

  const handleInnerStep = () => {
    if (userDetails.fullName === "") {
      alert("Empty not accepted");
      return;
    }
    handleUserDetailsFormSubmit(userPreference.target_id);
    setInnerStep(2);
  };

  const handleBackStep = () => {
    if (innerStep === 1) {
      handleBack();
    } else {
      setInnerStep(1);
    }
  };

  return (
    <Fragment>
      <div className="signUp_step_one_wrapper">
        {innerStep === 1 ? (
          <div className="input_wrapperr">
            <div className="input-heading-text">
              Hi! What shall we call you.
            </div>
            <input
              type="text"
              name="fullName"
              placeholder="Name"
              value={userDetails.fullName}
              onChange={handleUserDetailsInputField}
              required
            />
            <div className="nextBtn" onClick={handleInnerStep}>
              Next
            </div>
          </div>
        ) : (
          <div className="signup_right_form">
            <div className="signup_form">
              <form>
                <div className="content_inner_wrapper">
                  <div className="targets_wrapper">
                    <div className="text_content_wrapper">
                      <img
                        src={images.leftArrow}
                        alt="left-arrow"
                        className="back_button"
                        onClick={handleBackStep}
                      />
                      <p className="text_content-target">Select Your Target</p>
                    </div>
                    <div className="radio_collection_wrapper">
                      {targets?.target?.map((element) => (
                        <div key={element?.target_id} className="single_select">
                          <input
                            type="radio"
                            id={`target_${element?.target_id}`}
                            name="target"
                            value={element?.target_id}
                            onChange={() =>
                              handleSelectTarget(element?.target_id)
                            }
                            checked={
                              element?.target_id === userPreference?.target_id
                            }
                          />
                          <label
                            htmlFor={`target_${element?.target_id}`}
                            className={
                              element?.target_id === userPreference?.target_id
                                ? "selected"
                                : ""
                            }
                          >
                            {element.target}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SignUpStepOne;
