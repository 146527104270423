import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import usePostHandbookAddress from "../../hooks/address/usePostHandbookAddress";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";
import "./HandbookForm.scss";
import axios from "axios";
import useGetHandbookAddress from "../../hooks/address/useGetHandbookAddress";

const HandbookForm = () => {
  const history = useHistory();
  const { mutate: postHandbookAddress, isSuccess } = usePostHandbookAddress();
  const { address } = useGetHandbookAddress();
  const { headers } = useAuthUtils();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [addressForm, setAddressForm] = useState({
    name: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    alter_mobile_number: "",
    email_id: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const BASE_URL = "https://learning.motion.ac.in/motioneducation/api/v1";

  const fetchStates = async (headers) => {
    const response = await axios.get(`${BASE_URL}/states`, { headers });
    console.log("response", response.data.data.states);
    return response.data.data.states;
  };

  const fetchCities = async (state, headers) => {
    const response = await axios.get(`${BASE_URL}/cities?state=${state}`, {
      headers,
    });
    return response.data.data.districts;
  };

  // Fetch states
  useEffect(() => {
    const loadStates = async () => {
      try {
        const statesData = await fetchStates(headers);
        console.log("states", statesData);
        setStates([...statesData]);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    loadStates();
  }, []);

  useEffect(() => {
    if (address) {
      setAddressForm({
        name: address.fullname || "",
        address: address.address || "",
        state: address.state || "",
        city: address.city || "",
        pincode: address.pincode || "",
        mobile: address.mobile || "",
        alter_mobile_number: address.alternate_mobile || "",
        email_id: address.email_id || "",
      });
      setSelectedState(address.state || "");
    }
  }, [address]);

  // Fetch cities when a state is selected
  useEffect(() => {
    const loadCities = async () => {
      if (selectedState) {
        try {
          const citiesData = await fetchCities(selectedState, headers);
          setCities(citiesData);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        setCities([]); // Clear cities if no state is selected
      }
    };
    loadCities();
  }, [selectedState]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setAddressForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // Update selectedState when the state dropdown is changed
    if (name === "state") {
      console.log("calling handleChnnageInput");
      setSelectedState(value);
      setAddressForm((prevForm) => ({
        ...prevForm,
        city: "", // Reset city when state changes
      }));
    }
  };

  const validateForm = () => {
    console.log("validating form function is calling");
    const {
      name,
      address,
      state,
      city,
      pincode,
      mobile,
      email_id,
    } = addressForm;
    if (
      name?.trim() &&
      address?.trim() &&
      state &&
      city &&
      /^[1-9][0-9]{5}$/.test(pincode) &&
      /^[6-9]\d{9}$/.test(mobile) &&
      /^\S+@\S+\.\S+$/.test(email_id)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [addressForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      postHandbookAddress({ address: { ...addressForm, id: address.id } });
    }
  };

  if (isSuccess) {
    history.goBack();
  }

  return (
    <form onSubmit={handleSubmit} className="address_form_wrapper">
      <div className="form_inner_wrapper">
        <div className="input_container">
          <label htmlFor="name" className="form_label">
            Full Name*
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={addressForm.name}
            onChange={handleChangeInput}
            required
            maxLength="30"
          />
        </div>
        <div className="input_container">
          <label htmlFor="address" className="form_label">
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={addressForm.address}
            onChange={handleChangeInput}
            required
          />
        </div>
        <div className="form-state-city input_container">
          <div className="form-state">
            <label htmlFor="state" className="form_label">
              State
            </label>
            <select
              id="state"
              name="state"
              value={addressForm.state}
              onChange={handleChangeInput}
              required
            >
              <option value="">Select a state</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          <div className="form-city">
            <label htmlFor="city" className="form_label">
              City
            </label>
            <select
              id="city"
              name="city"
              value={addressForm.city}
              onChange={handleChangeInput}
              required
              disabled={!selectedState}
            >
              <option value="">Select a city</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="pincode" className="form_label">
            Pincode
          </label>
          <input
            type="number"
            id="pincode"
            name="pincode"
            value={addressForm.pincode}
            // onChange={handleChangeInput}
            onChange={(e) => {
              if (/^\d{0,6}$/.test(e.target.value)) {
                handleChangeInput(e);
              }
            }}
            required
            maxlength="6"
          />
        </div>
        <div className="form-mobile-num-alternate-num">
          <div className="input_container form-num">
            <label htmlFor="mobile" className="form_label">
              Mobile Number
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              value={addressForm.mobile}
              onChange={(e) => {
                if (/^\d{0,10}$/.test(e.target.value)) {
                  handleChangeInput(e);
                }
              }}
              required
              maxlength="10"
            />
          </div>
          <div className="input_container form-alt-num">
            <label htmlFor="alter_mobile_number" className="form_label">
              Alternate Mobile Number
            </label>
            <input
              type="number"
              id="alter_mobile_number"
              name="alter_mobile_number"
              value={addressForm.alter_mobile_number}
              // onChange={handleChangeInput}
              onChange={(e) => {
                if (/^\d{0,10}$/.test(e.target.value)) {
                  handleChangeInput(e);
                }
              }}
              maxlength="10"
            />
          </div>
        </div>
        <div className="input_container">
          <label htmlFor="email_id" className="form_label">
            Email
          </label>
          <input
            type="email"
            id="email_id"
            name="email_id"
            value={addressForm.email_id}
            onChange={handleChangeInput}
            required
          />
        </div>
        <div className="form_submit_wrapper" style={{}}>
          {/* <button type="submit" disabled={!isFormValid} style={!isFormValid}>
            Update Changes
          </button> */}

          <button
            type="submit"
            disabled={!isFormValid}
            style={{
              opacity: !isFormValid ? 0.5 : 1,
              cursor: !isFormValid ? "not-allowed" : "pointer",
            }}
          >
            Update Changes
          </button>
        </div>
      </div>
    </form>
  );
};

export default HandbookForm;
