import React from "react";
import FormHeader from "./handbookformheader/FormHeader";
import HandbookForm from "./handbookform/HandbookForm";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import "./Address.scss";

const Address = () => {
  return (
    <div>
      <HeaderComponent />

      <div className="address_wrapper">
        <FormHeader />
        <HandbookForm />
      </div>
    </div>
  );
};


export default Address;
