import { Fragment, useState, useEffect, useRef } from "react";
import "./test_questions_view.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../.././../../../../../utilities/images/images";
import axios from "axios";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import useMathjax from "../../../../../../../hooks/useMathjax";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as List1 } from "./list1.svg";
import { ReactComponent as Grid1 } from "./grid1.svg";

const TestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    currentQuestionDetails,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleQuestionJump,
    handleSubmitTest,
    subjecSectiontWiseTestQuestions,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    userId,
    startLoading,
    finishLoading,
    handleShow,
    handleClose,
    show,
    setState,
  } = props;

  useEffect(() => {
    if (
      countDownTimer.hours === 0 &&
      countDownTimer.minutes === 0 &&
      countDownTimer.seconds === 0
    ) {
      alert("Time is up. Your test will be auto submitted");
      handleSubmitTest(currentQuestionDetails);
    }
  }, [countDownTimer.seconds]);

  const [isList, setisList] = useState(true);
  const [reportErrorData, setReportErrorData] = useState([]);
  const [lang, setLang] = useState(true);
  const questionsCollectionRef = useRef(null);
  // for report error modal
  const [showError, setShowError] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useMathjax(currentQuestionDetails, lang);
  useMathjax(currentQuestionDetails, isList);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails?.[currentQuestion]?.qid,
    option_id: "",
    description: "",
  });

  //  condition for Report Error modal submit button
  const isDisable =
    saveReportErrorData.option_id.length === 0 ||
    saveReportErrorData.description.length === 0
      ? true
      : false;

  //function for getting options Data from api
  const handleGetReportErrorOptions = () => {
    startLoading();
    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //function for posting user error data to api
  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const switchLanguage = () => {
    setState((prevState) => ({
      ...prevState,
      lang: !prevState.lang,
    }));
  };

  const renderOptions = (currentQuestionDetails, lang) => {
    let step;
    let type = currentQuestionDetails?.answer_type.toLowerCase();
    if (type === "single correct") {
      step = 1;
    } else if (type.toLowerCase() === "integer correct") {
      step = 2;
    } else {
      step = 3;
    }

    switch (step) {
      case 1:
        return renderSingleSelect(
          currentQuestionDetails,
          currentQuestion,
          lang
        );
      case 2:
        return renderInputField(currentQuestion, lang);
      case 3:
        return renderMultiSelect(currentQuestionDetails, currentQuestion, lang);
      default:
        return null;
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang === true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 === currentQuestion
                ? "single_select_option_collection_wrapper show"
                : "single_select_option_collection_wrapper hide"
            }
          >
            {questionData.option?.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion2}`}
                        checked={
                          testQuestionsDetails[currentQuestion2]
                            .userAnswerCollection.length
                            ? testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection[0] === element.option_id
                            : element.is_user_correct_ans === 1
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          handleSingleSelectAnswer(event, element.option_id)
                        }
                      />
                      <label
                        htmlFor={element.option_id}
                        className="single_option_wrapper"
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className={`${
                              element.option.includes("rightarrow")
                                ? "rightarrow"
                                : "text_content_3"
                            }`}
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang === false) {
      if (
        questionData.option_hindi !== undefined &&
        questionData.option_hindi !== null &&
        questionData?.option_hindi?.length > 0
      ) {
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 === currentQuestion
                  ? "single_select_option_collection_wrapper show"
                  : "single_select_option_collection_wrapper hide"
              }
            >
              {questionData.option_hindi?.length
                ? questionData.option_hindi.map((element, index) => {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[currentQuestion2]
                                  .userAnswerCollection[0] === element.option_id
                              : element.is_user_correct_ans === 1
                              ? true
                              : false
                          }
                          onChange={(event) =>
                            handleSingleSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className={`${
                                element.option.includes("rightarrow")
                                  ? "rightarrow"
                                  : "text_content_3"
                              }`}
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </Fragment>
        );
      } else {
        switchLanguage();
        questionData.option.map((element, index) => {
          return (
            <div key={index} className="single_select">
              <input
                type="checkbox"
                id={element.option_id}
                name={`current_question_${currentQuestion2}`}
                checked={
                  testQuestionsDetails[currentQuestion2].userAnswerCollection
                    .length
                    ? testQuestionsDetails[currentQuestion2]
                        .userAnswerCollection[0] === element.option_id
                    : element.is_user_correct_ans === 1
                    ? true
                    : false
                }
                onChange={(event) =>
                  handleSingleSelectAnswer(event, element.option_id)
                }
              />
              <label
                htmlFor={element.option_id}
                className="single_option_wrapper"
              >
                <div className="option_initial">
                  <p className="text_content_2">{`${renderOptionInitial(
                    index
                  )}.`}</p>
                </div>
                <div className="option_final">
                  <p
                    className={`${
                      element.option.includes("rightarrow")
                        ? "rightarrow"
                        : "text_content_3"
                    }`}
                    dangerouslySetInnerHTML={renderOptionText(element.option)}
                  ></p>
                </div>
              </label>
            </div>
          );
        });
      }
    }
  };

  const renderMultiSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang === true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 === currentQuestion
                ? "multi_select_option_collection_wrapper show"
                : "multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id === 1) {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                  return null;
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (
      lang === false &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 === currentQuestion
                ? "multi_select_option_collection_wrapper show"
                : "multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id === 2) {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) =>
                            handleMultiSelectAnswer(event, element.option_id)
                          }
                        />
                        <label
                          htmlFor={element.option_id}
                          className="single_option_wrapper"
                        >
                          <div className="option_initial">
                            <p className="text_content_2">
                              {`${renderOptionInitial(index)}.`}
                            </p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion].integer_answer || ""}
              onChange={(event) => handleInputFieldChange(event)}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestion = () => {
    if (lang) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: currentQuestionDetails.q_text,
          }}
        ></p>
      );
    } else {
      if (ConvertStringToHTML(currentQuestionDetails?.q_text_hindi)) {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text_hindi,
            }}
          ></p>
        );
      } else {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text,
            }}
          ></p>
        );
      }
    }
  };

  // method for converting string(coming from api) into hmtl
  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">
                <div className="questionBox">{`${index + 1}`}</div>
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      }
    }
  };
  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => handleQuestionJump(index)}
          // style={{ display: "block", margin: " 0.875rem 5px" }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <div className="QBox">
              <div
                className="tableContainer"
                dangerouslySetInnerHTML={{ __html: question.q_text }}
              />
            </div>
            <img className="arrow" src={images.right_Ar} alt="arrow" />
          </div>
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox questionBox1">
              <p>{`${index + 1}.`}</p>
              <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="arrow" />
            </div>
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="arrow" />
              </div>
            </label>
          );
        }
      }
    }
  };

  return (
    <Fragment>
      <div className="Pyq_ppr_test_questions_view_wrapper_PYQ_Test">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}

                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Questions
                    </button>

                    <button
                      className="togglebtn submit"
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Submit Test
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit1} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button
                              className="cancel"
                              onClick={() =>
                                handleSubmitTest(currentQuestionDetails)
                              }
                            >
                              Submit
                            </button>
                            <button onClick={handleClose}>Continue</button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <div>
                      <div className="sub_sub_section_wrapper_1">
                        <div className="sub_sub_section_wrapper_1_card">
                          <p>
                            {currentQuestion + 1}/{testQuestionsDetails.length}
                          </p>
                          <div className="countdown_timer_container">
                            <span>
                              <label className="time_number">
                                {countDownTimer.hours}
                              </label>
                              <label className="time_partition">:</label>
                              <label className="time_number">
                                {countDownTimer.minutes}
                              </label>
                              <label className="time_partition">:</label>
                              <label className="time_number">
                                {countDownTimer.seconds > 9
                                  ? countDownTimer.seconds
                                  : `${"0" + countDownTimer.seconds}`}
                              </label>
                            </span>
                          </div>
                          <div className="markReview">
                            <div className="bookmark_image_wrapper">
                              {testQuestionsDetails[currentQuestion] &&
                              testQuestionsDetails[currentQuestion]
                                .isAnswerGuessed ? (
                                <img
                                  src={images.BookmarkBtn1}
                                  className="BookmarkBtn"
                                  onClick={() => handleReview()}
                                  alt="bookmarkbtn"
                                />
                              ) : (
                                <img
                                  src={images.BookmarkBtn}
                                  className="BookmarkBtn"
                                  onClick={() => handleReview()}
                                  alt="bookmarkbtn"
                                />
                              )}
                            </div>

                            {currentQuestionDetails.q_text_hindi ? (
                              <button
                                className={"m2_language"}
                                onClick={() => setLang(!lang)}
                              >
                                {lang ? "अ" : "A"}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="sectionWiseTestWrapper">
                        <div className="d-flex gap-3 align-items-center">
                          <p className="subjectWiseTest_heading">
                            Select Subject:
                          </p>
                          <div className="subjectWiseTest">
                            {Object.keys(subjecSectiontWiseTestQuestions).map(
                              (subjectName, index) => {
                                return (
                                  <div
                                    key={index}
                                    class="single_select_subject"
                                  >
                                    <input
                                      type="radio"
                                      id={subjectName}
                                      name="subject"
                                      value={subjectName}
                                      checked={subjectName === selectedSubject}
                                      onChange={() =>
                                        handleSubjectChange(subjectName)
                                      }
                                    />
                                    <label for={subjectName}>
                                      {subjectName}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <p className="subjectWiseTest_heading">
                            Change Section:
                          </p>
                          <div className="subjectWiseTest sectionWiseTest">
                            {selectedSubject &&
                              Object.keys(
                                subjecSectiontWiseTestQuestions[selectedSubject]
                              ).map((sectionName, index) => {
                                return (
                                  <div
                                    key={index}
                                    class="single_select_subject"
                                  >
                                    <input
                                      type="radio"
                                      id={sectionName}
                                      name="section"
                                      value={sectionName}
                                      checked={sectionName === selectedSection}
                                      onChange={() =>
                                        handleSectionChange(sectionName)
                                      }
                                    />
                                    <label for={sectionName}>
                                      {sectionName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <p className="subjectWiseTest_heading">
                          Question type:{" "}
                          <span>
                            {testQuestionsDetails[currentQuestion].answer_type}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="questionWrapper">
                      <div className="sub_sub_section_wrapper_2">
                        <div className="text_content_wrapper">
                          <div className="questionBox">
                            <span className="text_content">
                              {`Question ${Number(currentQuestion) + 1}.`}
                            </span>

                            <div className="qst">{renderQuestion()}</div>
                          </div>
                        </div>
                      </div>
                      <div className="sub_sub_section_wrapper_3">
                        {currentQuestionDetails !== null &&
                        currentQuestionDetails.option !== undefined
                          ? renderOptions(currentQuestionDetails, lang)
                          : ""}
                      </div>
                    </div>

                    <ToastContainer />

                    <Modal
                      show={showError}
                      onHide={handleCloseReportError}
                      className="errorModal"
                    >
                      <Modal.Header closeButton>
                        {" "}
                        <h3>Report an Error</h3>
                      </Modal.Header>
                      <Modal.Body className="modal-body">
                        <div className="error_Popup">
                          <p className="text_content">
                            You can just write to us, If there is any incorrect
                            or error in question and given options.
                          </p>
                          <ul className="errorList">
                            {reportErrorData?.map((element, index) => {
                              return (
                                <li className="errorListItem" key={index}>
                                  <input
                                    type="radio"
                                    id={element.id}
                                    name="error"
                                    value={element.option_name}
                                    onChange={(event) => {
                                      setSaveReportErrorData({
                                        ...saveReportErrorData,
                                        option_id: event.target.id,
                                      });
                                    }}
                                  />{" "}
                                  <label htmlFor={element.id}>
                                    {element.option_name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="textarea_content"
                            type="text"
                            onChange={(event) =>
                              setSaveReportErrorData({
                                ...saveReportErrorData,
                                description: event.target.value,
                              })
                            }
                            placeholder="Write to Us..."
                          />{" "}
                          <br />
                          <Button
                            disabled={isDisable}
                            onClick={handlePostReportErrorData}
                          >
                            Submit
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          color: "#B50503",
                          fontSize: " 0.75rem",
                        }}
                      >
                        <span style={{ marginRight: "5px", cursor: "pointer" }}>
                          <img
                            src={images.user_activity_14_1}
                            alt="user-activity"
                          />
                        </span>{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleGetReportErrorOptions}
                        >
                          Report an error
                        </span>
                      </div>
                      <div className="sub_sub_section_wrapper_5">
                        <div className="left_side_wrapper">
                          {currentQuestion > 0 && (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handlePreviousQuestionBtn(
                                    currentQuestion - 1
                                  );
                                  scrollUp2();
                                }}
                              >
                                Previous
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="right_side_wrapper">
                          {testQuestionsDetails.length ===
                          currentQuestion + 1 ? (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handleSaveQuestion();
                                  handleShow();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            <div className="btn_wrapper d-flex">
                              <button
                                className={
                                  testQuestionsDetails[currentQuestion] &&
                                  testQuestionsDetails[currentQuestion]
                                    .isAnswerGuessed
                                    ? "markBtn"
                                    : ""
                                }
                                onClick={() => handleReview()}
                              >
                                Mark for review
                              </button>
                              <button
                                onClick={() => {
                                  handleNextQuestionBtn(currentQuestion + 1);
                                  scrollUp();
                                }}
                              >
                                Save & Next
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>

                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button onClick={() => handleShow()}>
                          Submit Test
                        </button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <List1 /> : <List />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <Grid /> : <Grid1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_4"></label>
                            <label className="question_marking_text">
                              Skipped
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_5"></label>
                            <label className="question_marking_text">
                              Mark For Review
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="questions_serial_number_collection"
                        ref={questionsCollectionRef}
                      >
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                >
                                  {isList ? (
                                    <>{renderQuestionLabel1(element, index)}</>
                                  ) : (
                                    <>{renderQuestionLabel(element, index)}</>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestQuestionsView;
