import { useRef } from "react";
import "./Pdf.scss";
import StudentReportPDF from "./StudentTestReportPdf";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";

function Pdf({ loginFromParent }) {
  let history = useHistory();
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = async () => {
    const canvas = await html2canvas(reportTemplateRef.current);
    const imgData = canvas.toDataURL("image/png");
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([canvas.width, canvas.height]);

    const image = await pdfDoc.embedPng(imgData);
    const { width, height } = image.scale(1);

    page.drawImage(image, { x: 0, y: 0, width, height });

    const pdfBytes = await pdfDoc.save();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "document.pdf";
    link.click();

    // Redirect after a delay
    setTimeout(() => {
      if (loginFromParent) {
        history.push("/parent-dashboard");
      } else {
        history.push({
          pathname: "/student-tests",
          state: { step: 2 },
        });
      }
    }, 2000);
  };

  return (
    <div className="student_pdf">
      <div className="pdf_btn">
        <button className="button" onClick={handleGeneratePdf}>
          Download (PDF)
        </button>
      </div>
      <div ref={reportTemplateRef}>
        <StudentReportPDF />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginFromParent: state.parentrootReducer.parentAuthReducer.loginFromParent,
  };
};

export default connect(mapStateToProps, null)(Pdf);
