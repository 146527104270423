import { React, useState, useEffect } from "react";
import "./SubjectTopicWiseMarks.scss";
import { useLocation } from "react-router-dom";

import {
  url_70,
  url_71,
  url_72,
} from "../../../../../../../../custom_utilities/api_services";
import ProgressBar from "react-bootstrap/ProgressBar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import SubjectWiseMarkChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/SubjectWiseMarks";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
import moment from "moment";
//mobileDevice
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";

import RankDoughnutChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/RankDoughnutChart";
import SubjectWiseMarks from "./SubjectWiseMarks";
import TopicWiseMarks from "./TopicWiseMarks";

const SubjectTopicWiseMarks = (props) => {
  let { startLoading, finishLoading, loader, userData, userPreference } = props;
  let history = useHistory();
  const backBtn = () => {
    history.push("/test_result");
  };
  const location = useLocation();
  const [currentSubject, setCurrentSubject] = useState(null);
  const [tabType, setTabType] = useState(1);

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="subjectTopicWiseMarksWrapper">
        {isMob() ? (
          <>
            <MobileLeftSideBarComponent />
            <MobileHeaderComponent
              userPreference={userPreference}
              userData={userData}
            />
            <MobileRightSideBarComponent history={history} />
          </>
        ) : (
          <HeaderComponent />
        )}

        <div className="subjectTopicWiseMarksInrWrapper">
          <div className="sbjtTopicWiseMarksInr">
            <div>
              <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div>
              <h3>Subject Wise & Topic Wise Marks</h3>
            </div>

            <div className="subjectTopicDetailWrapper">
              <div className="subjectTopicTabBox">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="subjectwise-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#subjectwise"
                      type="button"
                      role="tab"
                      aria-controls="subjectwise"
                      aria-selected="true"
                    >
                      Subject wise
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="topicwise-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#topicwise"
                      type="button"
                      role="tab"
                      aria-controls="topicwise"
                      aria-selected="false"
                    >
                      Topic Wise
                    </button>
                  </li>
                </ul>
              </div>
              <div className="subjectTopicTabContent">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="subjectwise"
                    role="tabpanel"
                    aria-labelledby="subjectwise-tab"
                  >
                    <SubjectWiseMarks location={location} />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="topicwise"
                    role="tabpanel"
                    aria-labelledby="topicwise-tab"
                  >
                    <TopicWiseMarks location={location} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectTopicWiseMarks);
