import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import "./PackagesOverview.scss";
import images from "../../../../utilities/images/images";
import { Accordion } from "react-bootstrap";
import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../Navbar/Navbar";
import LoginSignupModal from "../../LoginSignupModal/LoginSignupModal";
import { Render } from "../../Render";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import FacultyDetail from "../FacultyDetail/FacultyDetail";

const VideoDrmPlayer = lazy(() =>
  import(
    "../../../logged_user_components/features/video_feature/video_feature_content_component/VideoDrmPlayer"
  )
);

const PackagesOverview = () => {
  const { package_id } = useParams();
  const [packageData, setPackageData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [couponResponse, setCouponResponse] = useState(null);
  const [finalAmount, setFinalAmount] = useState();
  const [showTerms, setShowTerms] = useState(false);
  const [showFacultyDetails, setShowFacultyDetails] = useState(false);
  const [FacultyDetails, setFacultyDetails] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const [active, setActive] = useState("desc");

  const history = useHistory();

  const description = packageData?.PackageInstallmentDetails?.description || "";
  const truncatedDescription =
    description.length > 220
      ? description.substring(0, 220) + "..."
      : description;

  //redeem_motion_coins.eligible_coins
  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        const response = await axios.get(
          `https://learning.motion.ac.in/motioneducation/api/user/package_detail_web?package_id=${package_id}`
        );
        setPackageData(response.data.data);
        setFinalAmount(
          parseFloat(response.data.data.price_details?.course_price) -
            parseFloat(response.data.data?.price_details?.discount)
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchPackageData();
  }, [package_id]);

  const settings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings1 = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };

  const openFacultyDetails = (data) => {
    setFacultyDetails(data);
    setShowFacultyDetails(true);
  };
  const closeFacultyDetails = () => {
    setShowFacultyDetails(false);
  };

  const changeTab = (tab) => {
    setActive(tab);
  };
  const handlePurchaseInitiation = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const whatsAppMessageSender = () => {
    const phoneNumber = parseInt(packageData?.motion_whatsapp_no);

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const expiryDate = packageData?.PackageInstallmentDetails?.expiry_date;

  // Check if the expiry date is not "0000-00-00"
  const shouldShowExpiryDate = expiryDate && expiryDate !== "0000-00-00";

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <LoginSignupModal
        isLoginOpen={isLoginOpen}
        setIsLoginOpen={setIsLoginOpen}
      />
      <Navbar setIsLoginOpen={setIsLoginOpen} isLoginOpen={isLoginOpen} />
      <div
        style={{
          background: " #F9F9F9",
          display: "flex",
          justifyContent: "center",
          gap: "50px",
        }}
      >
        <div>
          <div className="storeBack" onClick={goBack}>
            <img src={images.storeBck} alt="back" />
            Back
          </div>
          <div className="store-banner-inner-wrapper">
            <div className="landingpage-store-banner-content-and-btn-wrapper">
              <div className="text">
                {packageData?.PackageInstallmentDetails?.course}
              </div>
              <img src={images.storeBanner} alt="banner" />
              {packageData?.PackageInstallmentDetails?.thumbnail_tag && (
                <div
                  className="new-label"
                  style={{
                    backgroundColor:
                      packageData?.PackageInstallmentDetails?.tag_background,
                    color:
                      packageData?.PackageInstallmentDetails?.tag_textcolor,
                  }}
                >
                  {packageData?.PackageInstallmentDetails?.thumbnail_tag}
                </div>
              )}
            </div>
            <div className="tabs-container">
              <div className="tabs">
                <div
                  className={`tab ${active === "desc" ? "active" : ""}`}
                  onClick={() => changeTab("desc")}
                >
                  <i className="icon description-icon"></i>
                  Description
                </div>
                <div
                  className={`tab ${active === "subjects" ? "active" : ""}`}
                  onClick={() => changeTab("subjects")}
                >
                  <i className="icon subjects-icon"></i>
                  Subjects
                </div>
              </div>
              <div className="contact-icons">
                <div
                  className="icon-button whatsapp"
                  onClick={whatsAppMessageSender}
                >
                  <img src={images.whatsaap} alt="WhatsApp" />
                </div>

                <div className="icon-button call">
                  <a href={`tel:${packageData?.motion_mobile_no}`}>
                    <img src={images.phoneCall} alt="Call" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {active === "desc" && (
            <>
              <Render if={!packageData?.description}>
                <div className="description-inner-wrapper">
                  <div className="description-content-and-btn-wrapper">
                    <h3>Description</h3>

                    <div className="description-content-wrapper">
                      <div className="description-content">
                        <div className="description-heading">
                          {isExpanded ? description : truncatedDescription}
                          {description.length > 220 && (
                            <button
                              className="view-more-btn"
                              onClick={() => setIsExpanded(!isExpanded)}
                            >
                              {isExpanded ? "View Less" : "View More"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Render>
              <Render if={!!packageData?.orientation_video}>
                <div className="video-inner-wrapper">
                  <div className="video-content-and-btn-wrapper">
                    <h3>Orientation Video</h3>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      position: "relative",
                    }}
                  >
                    <Suspense
                      fallback={
                        <img
                          src={images.videoPlaceholder}
                          alt="Loading..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      }
                    >
                      <VideoDrmPlayer
                        id_video={packageData?.orientation_video}
                      />
                    </Suspense>
                  </div>
                </div>
              </Render>

              <Render
                if={!!packageData?.PackageInstallmentDetails?.course_benefits}
              >
                <div className="course-benefits-inner-wrapper">
                  <h3>Course Benefits</h3>
                  <div className="course-benefits-list">
                    {packageData?.PackageInstallmentDetails?.course_benefits?.map(
                      (benefit, index) => (
                        <div key={index} className="course-benefit-item">
                          <img
                            src={benefit.icon}
                            alt={benefit.name}
                            className="benefit-icon"
                          />
                          <span className="course-benefit-name">
                            {benefit.name}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Render>

              <div className="performance-inner-wrapper">
                <div className="performance-benefits-list">
                  <div className="performance-benefit-item">
                    <img
                      src={images?.learningPerformance}
                      alt={"learningPerformance"}
                      className="benefit-icon"
                    />
                    <span className="performance-benefit-name">
                      Imporove Your Overall Learning Performance
                    </span>
                  </div>
                </div>
              </div>

              <Render if={!!packageData?.faculty_list}>
                <div className="faculty-details-inner-wrapper">
                  <h3>Faculty details</h3>
                  <Slider {...settings}>
                    {packageData?.faculty_list?.map((faculty) => (
                      <div key={faculty?.user_id} className="faculty-slide">
                        <div className="faculty-slider">
                          <div className="faculty-image">
                            <img src={faculty?.thumnail} alt={faculty?.name} />
                          </div>
                          <div className="faculty-name">By {faculty?.name}</div>
                          <div className="view-more">
                            <button onClick={() => openFacultyDetails(faculty)}>
                              View more
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Render>
              <Render if={packageData?.other_details?.length > 0}>
                <div className="other-details-inner-wrapper">
                  <h3>Other details</h3>
                  <ul>
                    {packageData?.other_details?.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </Render>
              <Render if={packageData?.faq?.length > 0}>
                <div className="faq-inner-wrapper">
                  <h3>FAQ's (Frequently asked questions)</h3>
                  <Accordion className="faq-accordion">
                    {packageData?.faq?.map((faq, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{faq.name}</Accordion.Header>
                        <Accordion.Body>{faq.description}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </Render>
            </>
          )}

          {active === "subjects" && (
            <div className="subject-tab-container">
              {packageData?.subject?.map((sub) => {
                return (
                  <div
                    key={sub.sub_id}
                    className="single-sub"
                    style={{ background: sub.color }}
                  >
                    <div className="sub-content">
                      <img className="sub-image" src={sub.icon} alt="img" />
                      <div>
                        <div className="subname"> {sub.subject_name}</div>
                        <div className="subtopics">
                          {sub.total_topics} topics
                        </div>
                      </div>
                    </div>

                    <img
                      className="arrw-image"
                      src={images.storeRightArrow}
                      alt="arrow"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="coupon-referral-wrapperr" style={{ marginTop: "45px" }}>
          <div className="header">
            <div className="title">
              <img src={images.coupon} alt="Coupon icon" />
              <span>Coupon/Referral</span>
              <button
                onClick={() => setIsLoginOpen(!isLoginOpen)}
                className="view-coupons"
              >
                View Coupons
              </button>
            </div>
          </div>

          <div className="coupon-input">
            <input type="text" placeholder=" Enter Coupon/Referral Code" />
            <button onClick={() => setIsLoginOpen(!isLoginOpen)}>Apply</button>
          </div>

          <div className="price-details">
            <h3>Price details</h3>
            <div className="price-row">
              <span>Course Price</span>
              <span>
                ₹
                {parseFloat(packageData?.price_details?.course_price).toFixed(
                  2
                )}
              </span>
            </div>
            <div className="price-row">
              <span>Discount</span>
              <span className="discount">
                - ₹{parseFloat(packageData?.price_details?.discount).toFixed(2)}
              </span>
            </div>
            <div className="price-row">
              <span>Coupon Discount</span>
              <span className="discount">
                - ₹
                {(
                  couponResponse?.discount_amount ||
                  packageData?.price_details?.coupon_discount
                )?.toFixed(2)}
              </span>
            </div>
            <div className="price-row total">
              <span>Total Price</span>
              <span>₹{finalAmount}</span>
            </div>
          </div>

          <button onClick={handlePurchaseInitiation} className="proceed-button">
            Proceed to Pay
          </button>

          <div className="terms" onClick={openTerms}>
            <img src={images?.phInfo} alt="ph-no" />
            Terms & Conditions
          </div>

          {shouldShowExpiryDate && (
            <div className="validity">
              Valid up to <span>{expiryDate}</span>
            </div>
          )}
        </div>
      </div>

      {/* mobile-view */}
      <div style={{ backgroundColor: "#F6F6F7" }}>
        <div className="mobile-storeBack" onClick={goBack}>
          <img src={images.storeBck} alt="back" />
          Overview
        </div>
        <div className="mobile-store-banner-inner-wrapper">
          <div className="mobile-landingpage-store-banner-content-and-btn-wrapper">
            <div className="text">
              {packageData?.PackageInstallmentDetails?.course}
            </div>
            <img src={images.storeBanner} alt="banner" />
            {packageData?.PackageInstallmentDetails?.thumbnail_tag && (
              <div
                className="new-label"
                style={{
                  backgroundColor:
                    packageData?.PackageInstallmentDetails?.tag_background,
                  color: packageData?.PackageInstallmentDetails?.tag_textcolor,
                }}
              >
                {packageData?.PackageInstallmentDetails?.thumbnail_tag}
              </div>
            )}
          </div>
          <div className="mobile-tabs-container">
            <div className="tabs">
              <div
                className={`tab ${active === "desc" ? "active" : ""}`}
                onClick={() => changeTab("desc")}
              >
                <i className="icon description-icon"></i>
                Description
              </div>
              <div
                className={`tab ${active === "subjects" ? "active" : ""}`}
                onClick={() => changeTab("subjects")}
              >
                <i className="icon subjects-icon"></i>
                Subjects
              </div>
            </div>
          </div>
        </div>
        {active === "desc" && (
          <>
            <Render if={!packageData?.description}>
              <div className="mobile-description-inner-wrapper">
                <div className="mobile-description-content-and-btn-wrapper">
                  <h3>Description</h3>

                  <div className="mobile-description-content-wrapper">
                    <div className="mobile-description-content">
                      <div className="mobile-description-heading">
                        {isExpanded ? description : truncatedDescription}
                        {description.length > 220 && (
                          <button
                            className="view-more-btn"
                            onClick={() => setIsExpanded(!isExpanded)}
                          >
                            {isExpanded ? "View Less" : "View More"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Render>
            <Render if={!!packageData?.orientation_video}>
              <div className="mobile-video-inner-wrapper">
                <div className="mobile-video-content-and-btn-wrapper">
                  <h3>Orientation Video</h3>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    position: "relative",
                  }}
                >
                  <Suspense
                    fallback={
                      <img
                        src={images.videoPlaceholder}
                        alt="Loading..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    }
                  >
                    <VideoDrmPlayer id_video={packageData?.orientation_video} />
                  </Suspense>
                </div>
              </div>
            </Render>

            <Render
              if={!!packageData?.PackageInstallmentDetails?.course_benefits}
            >
              <div className="mobile-course-benefits-inner-wrapper">
                <h3 className="course-benefits">Course Benefits</h3>
                <div className="mobile-course-benefits-list">
                  {packageData?.PackageInstallmentDetails?.course_benefits?.map(
                    (benefit, index) => (
                      <div key={index} className="mobile-course-benefit-item">
                        <img
                          src={benefit.icon}
                          alt={benefit.name}
                          className="mobile-benefit-icon"
                        />
                        <span className="mobile-course-benefit-name">
                          {benefit.name}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Render>

            <div className="mobile-performance-inner-wrapper">
              <div className="mobile-performance-benefits-list">
                <div className="mobile-performance-benefit-item">
                  <img
                    src={images?.learningPerformance}
                    alt={"learningPerformance"}
                    className="benefit-icon"
                  />
                  <span className="mobile-performance-benefit-name">
                    Imporove Your Overall Learning Performance
                  </span>
                </div>
              </div>
            </div>

            <Render if={!!packageData?.faculty_list}>
              <div className="mobile-faculty-details-inner-wrapper">
                <h3 className="mobile-faculty-details">Faculty details</h3>
                <div className="mobile-other-details-inner-list">
                  <Slider {...settings1}>
                    {packageData?.faculty_list?.map((faculty) => (
                      <div
                        key={faculty?.user_id}
                        className="mobile-faculty-slide"
                      >
                        <div className="mobile-faculty-slider">
                          <div className="mobile-faculty-image">
                            <img src={faculty?.thumnail} alt={faculty?.name} />
                          </div>
                          <div className="mobile-faculty-name">
                            By {faculty?.name}
                          </div>
                        </div>
                        <div className="mobile-view-more">
                          <button onClick={() => openFacultyDetails(faculty)}>
                            View more
                          </button>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Render>
            <Render if={packageData?.other_details?.length > 0}>
              <div className="mobile-other-details-inner-wrapper">
                <h3 className="mobile-other-details">Other details</h3>
                <div className="mobile-other-details-inner-list">
                  <ul>
                    {packageData?.other_details?.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Render>
            <Render if={packageData?.faq?.length > 0}>
              <div className="mobile-faq-inner-wrapper">
                <h3 className="mobile-faq">
                  FAQ's (Frequently asked questions)
                </h3>
                <div className="mobile-faq-inner-list">
                  <Accordion className="mobile-faq-accordion">
                    {packageData?.faq?.map((faq, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{faq.name}</Accordion.Header>
                        <Accordion.Body>{faq.description}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </Render>
          </>
        )}

        {active === "subjects" && (
          <div className="mobile-subject-tab-container">
            {packageData?.subject?.map((sub) => {
              return (
                <div
                  key={sub.sub_id}
                  className="mobile-single-sub"
                  style={{ background: sub.color }}
                >
                  <div className="mobile-sub-content">
                    <img
                      className="mobile-sub-image"
                      src={sub.icon}
                      alt="img"
                    />
                    <div>
                      <div className="mobile-subname"> {sub.subject_name}</div>
                      <div className="mobile-subtopics">
                        {sub.total_topics} topics
                      </div>
                    </div>
                  </div>

                  <img
                    className="mobile-arrw-image"
                    src={images.storeRightArrow}
                    alt="arrow"
                  />
                </div>
              );
            })}
          </div>
        )}

        {showTerms && (
          <TermsAndCondition
            handleClose={closeTerms}
            terms={packageData?.terms_conditions}
          />
        )}

        {showFacultyDetails && (
          <FacultyDetail
            handleClose={closeFacultyDetails}
            FacultyDetails={FacultyDetails}
          />
        )}

        <div className="mobile-buy-now" style={{ display: "flex" }}>
          <div className="mobile-buy-now-wrapper">
            <span>₹{finalAmount}</span>
            <div>
              <p>View Details</p>
            </div>
          </div>
          <div className="mobile-buy-now-button">
            <button
              onClick={handlePurchaseInitiation}
              className="proceed-button"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PackagesOverview;
