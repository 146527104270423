import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import "./InstructionModal.scss";
import { url_121 } from "../../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";

const InstructionModal = (props) => {
  const { test, token, tokenType, startLoading, finishLoading } = props;

  const [lang, setLang] = useState("English");

  useEffect(() => {
    getInstructionsData();
  }, []);

  const [modalInstructionData, setModalInstructionData] = useState({
    showInstructionModal: false,
    instructionPara: "",
  });

  const handleCloseInstructionModal = () => {
    setModalInstructionData((prevState) => ({
      ...prevState,
      showInstructionModal: false,
    }));

    props.setInstructionModalFlag(false);
  };

  const handleShowInstructionModal = () =>
    setModalInstructionData((prevState) => ({
      ...prevState,
      showInstructionModal: true,
    }));

  //for changing instruction language

  const handleChangeLanguage = (event) => {
    setLang(event.target.value);
  };

  const getInstructionsData = () => {
    startLoading();

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      test_id: test.test_id,
      planner_id: test.planner_id,
    };
    try {
      axios.post(url_121, data).then((response) => {
        setModalInstructionData((prevState) => ({
          ...prevState,
          instructionPara: response.data.data.instructions,
        }));

        if (response.status === 200) {
          finishLoading();
          handleShowInstructionModal();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //  function for removing html tags from api paragraph data
  const StripHtml = (htmlString) => {
    let tmp = document.createElement("p");
    tmp.innerHTML = htmlString;

    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <Modal
      show={modalInstructionData.showInstructionModal}
      onHide={handleCloseInstructionModal}
      dialogClassName="my-modal-1"
      // centered
    >
      <Modal.Header closeButton>
        {" "}
        <h3>Test Instructions</h3>
      </Modal.Header>

      <Modal.Body className="modal-body">
        <div className="instruction_language">
          {/* <span>view in</span> */}
          <div className="lanugage_dropdown">
            <select onChange={handleChangeLanguage}>
              <option>English</option>
              <option>Hindi</option>
            </select>
          </div>
        </div>

        <div className="instruction_wrapper">
          <div className="instruction_inner_wrapper">
            <div className="instruction_inner_header">
              <h3>Please read the instructions carefully</h3>
              <h4>General Instructions:</h4>
            </div>
            {lang == "English"
              ? !isEmpty(modalInstructionData)
                ? !isEmpty(modalInstructionData.instructionPara)
                  ? StripHtml(
                      modalInstructionData.instructionPara.test_instruction_eng
                    )
                  : null
                : null
              : StripHtml(
                  modalInstructionData.instructionPara.test_instruction_hindi
                )}
          </div>
        </div>
      </Modal.Body>

      <div className="modal-footer-btn">
        <button onClick={handleCloseInstructionModal}>Done</button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    authType: state.auth.authType,
  };
};

export default connect(mapStateToProps, null)(InstructionModal);
