import { React, useEffect, useState } from "react";
import "./CpsSubjectTopicContent.scss";
import images from "../../../../../../utilities/images/images";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_84,
} from "../../../../../../custom_utilities/api_services";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userPyqTestsAction from "../../../../../../redux/pyq_test_exercises/action";

const CpsSubjectTopicContent = (props) => {
  let { userPreference, pyqtopicData, startLoading,finishLoading,setPyqTestsData
  } = props;
  const [state, setState] = useState({
topicItemList:{}
  });
  let history = useHistory();
  useEffect(() => {
    getTopicList();
  }, []);

  const getTopicList = async() => {
    let data = {
      subject: pyqtopicData.subId,
    }
    startLoading();
    try { 
      const response = await axios.post(url_84, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState, topicItemList: response.data.data,
        }))
        finishLoading();
      }
      finishLoading();
    }
    catch (error) {
      console.log(error);
    }
}
  
  const testModule = () => {
    history.push("/PyqCpsExamTest");
  };
  //...sendData
  const onHandleTakeTest = (testData) => {
   //setPyqTestsData(testData);
    //history.push("/PYQ_TopicWise_Test");
    history.push({
             pathname: '/CpsSubjectSubTopic',
             state: { subTopic: testData },
       });
    // else {
    //   setUserTestsResultData(testData);
    //   history.push("/test_result");
    // }
    // setUserTestsResultData(testData);
    // history.push("/test_result");
  };
  return (
    <>
      <div className="pyq_subjectTopicWrapper">
        <div className="container">
          <div className="pyq_subjectTopicInner">
            {state ? (
              <div className="pyqSbjTpcListBox">
                <div className="d-flex">
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/cps",
                      state: { tab: "cps" },
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                  <h1 className="subject_name d-inline-block mx-auto">{pyqtopicData.subId}</h1>
                </div>
                <div className="subject_topic_list">
                  {!isEmpty(state.topicItemList)
                    ? state.topicItemList.length
                      ? state.topicItemList.map((elem, index) => {
                          return (
                            <div
                              className="subject_topics"
                              onClick={() => onHandleTakeTest(elem)}
                            >
                              <div className="sbjct_tpc_name">
                                {elem.topic_name}
                              </div>
                              <div className="arrow">
                                <img src={images.m2_rgtArow} alt="arrow" />
                              </div>
                            </div>
                          );
                        })
                      : null
                    : null}
                </div>
              </div>
            ) : (
              <div className="instructionScreenBox">
                <div className="backBtn">
                  <img src={images.m2_backBtn} alt="backBtn" />
                </div>
                <div className="instruction_title">Instruction Screen</div>
                <div className="instruction_detailBox">
                  <div className="examTime">
                    <div className="examTitle">Exam Topic</div>
                    <div className="examDurations">Duration : 3:00:00</div>
                  </div>
                  <div className="instructions">
                    <h3>Set of Instructions</h3>
                    <div className="instPoint">
                      <p>
                        This Section contains a set of questions for practice
                      </p>
                      <p>
                        Users can go to previous question by clicking on the
                        previous button
                      </p>
                      <p>
                        Users can go to next question by clicking on the next
                        button
                      </p>
                      <h6>Best of luck</h6>
                    </div>
                  </div>
                  <div className="startTestBtn">
                    <button onClick={testModule}>Start Test</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    pyqtopicData: state.userActivity.pyqtopicData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPyqTopicData: (payload) => {
      dispatch(userActivityActions.setPyqTopicData(payload));
    },
    setPyqTestsData: (payload) => {
      dispatch(userPyqTestsAction.setPyqTestsData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpsSubjectTopicContent);