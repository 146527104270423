import React from "react";
import "./All_page_content.scss";
import images from "../../../utilities/images/images";
import { Link } from "react-router-dom";
import AboutUsComponent from "../Footer/About_us_component";
import PrivacyPolicyComponent from "../Footer/PrivacyPolicyComponent";
import TermsNConditionComponent from "../Footer/Terms_n_condition_component";

const All_page_content = ({ tab }) => {
  return (
    <div className="all_page_content_wrapper">
      <div className="all_page_button">
        <div className="back_btn">
          <Link to="/#footer">
            <img src={images.login_2} alt="backarrow" />
          </Link>
          <h2>Welcome, We are here to help you</h2>
        </div>
        <div className="container_inner">
          <ul className="nav nav-tabs all_page_tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={tab === "AboutUs" ? "nav-link active" : "nav-link"}
                id="aboutUs-tab"
                data-bs-toggle="tab"
                data-bs-target="#aboutUs"
                type="button"
                role="tab"
                aria-controls="aboutUs"
                aria-selected="false"
              >
                About Us
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={
                  tab === "privacyPolicy" ? "nav-link active" : "nav-link"
                }
                id="privacy-tab"
                data-bs-toggle="tab"
                data-bs-target="#privacy"
                type="button"
                role="tab"
                aria-controls="privacy"
                aria-selected="true"
              >
                Privacy Policy
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={tab === "terms" ? "nav-link active" : "nav-link"}
                id="terms-tab"
                data-bs-toggle="tab"
                data-bs-target="#terms"
                type="button"
                role="tab"
                aria-controls="terms"
                aria-selected="false"
              >
                Terms & Conditions
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className={
            tab === "AboutUs" ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="aboutUs"
          role="tabpanel"
          aria-labelledby="about-tab"
        >
          <AboutUsComponent />
        </div>
        <div
          className={
            tab === "privacyPolicy"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="privacy_policy"
          role="tabpanel"
          aria-labelledby="privacy-tab"
        >
          <PrivacyPolicyComponent />
        </div>
        <div
          className={
            tab === "terms" ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="terms"
          role="tabpanel"
          aria-labelledby="terms-tab"
        >
          <TermsNConditionComponent />
        </div>
      </div>
    </div>
  );
};

export default All_page_content;
