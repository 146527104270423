import React, { useState, useEffect } from "react";
import "./TestReportContentComponent.scss";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../utilities/images/images";
import LoaderPopUp from "../../../../../components/loader/loader";
import QuestionClassWiseTable from "./questionClassWiseTabel/QuestionClassWiseTable";
import TestSummaryTable from "./testSummaryTabel/TestSummaryTable";
import ImprovementsSuggestions from "./improvementsSuggestions/ImprovementsSuggestions";
import OtherTabs from "./otherTabs/OtherTabs";

const TestReportContentComponent = (props) => {
  let {
    loader,
    startLoading,
    finishLoading,
    papers,
    redirectToPredictCollege,
    setExpectedRank,
    setAllIndiaRank,
    setPlannerId,
    paperId,
    parentUserId,
    handleTabClick,
  } = props;

  const [state, setState] = useState({
    testReport: {},
    improvements_suggestions: [],
    paper_type: 1,
    current_paper: "",
    questionClassWise: [],
    classFrom: "",
  });

  useEffect(() => {
    getQuestionsClassWise();
    getImprovementsSuggestions();
  }, []);

  useEffect(() => {
    getTestReport();
  }, [state.current_paper]);

  let {
    testReport,
    questionClassWise,
    current_paper,
    classFrom,
    improvements_suggestions,
  } = state;

  const getTestReport = async () => {
    startLoading();
    const curt_paper = state.current_paper.replace(/ /g, "");

    let data = {
      user_id: parentUserId,
      paper_id: paperId,
      paper: curt_paper,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/StudentTestReport",
        data
      );

      console.log("getTestReport", response.data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testReport: response.data.data,
        }));

        setPlannerId(response.data.data.planner_test_id);
        setExpectedRank(response.data.data.expected_rank);
        setAllIndiaRank(response.data.data.rank);
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    } finally {
      finishLoading();
    }
  };

  const getQuestionsClassWise = async () => {
    startLoading();

    let data = {
      user_id: parentUserId,
      paper_id: paperId,
      paper: current_paper,
    };

    try {
      const response = await axios.post(
        " https://learning.motion.ac.in/motioneducation/api/StudentQnclass_wise",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          questionClassWise: response.data.data.marks,
          classFrom: response.data.questions_from,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getImprovementsSuggestions = async () => {
    startLoading();

    let data = {
      user_id: parentUserId,
      paper_id: paperId,
      paper: current_paper,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/Improve_suggest",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          improvements_suggestions: response.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handlePaperChange = (event, name) => {
    setState((prevState) => ({
      ...prevState,
      current_paper: name,
    }));
  };

  return (
    <div className="TestReportContentComponentWrapper">
      <div className="papers_tab_wrapper">
        <div className="outer_content_wrapper">
          <div className="content_wrapper">
            {!isEmpty(papers)
              ? papers?.length
                ? papers?.map((element, index) => {
                    return (
                      <div className="left_side_content_wrapper">
                        <div className="subject_type_collection_wrapper">
                          <div
                            key={index}
                            className="single_select_subject_type"
                          >
                            <input
                              type="radio"
                              id={`subject_${element?.name}`}
                              name="subject"
                              value={element?.name}
                              checked={
                                element.name === parseInt(state.current_paper)
                              }
                              onChange={(event) =>
                                handlePaperChange(event, element.name)
                              }
                            />
                            <label htmlFor={`subject_${element?.name}`}>
                              {element?.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null
              : null}

            <div className="right_side_content_wrapper">
              <div className="paper">
                <section>
                  <div className="testcards">
                    <div className="testCard">
                      <div className="testCardImg">
                        <img src={images.expectedIcon} alt="img" />
                      </div>

                      <div className="testCardRight">
                        <div className="testCardRightTop">Expected Rank</div>
                        <div className="testCardRightBottom expected">
                          {" "}
                          {testReport.expected_rank}
                        </div>
                      </div>
                    </div>
                    <div className="testCard">
                      <div className="testCardImg">
                        <img src={images.allIndiaIcon} alt="img" />
                      </div>

                      <div className="testCardRight">
                        <div className="testCardRightTop">All India Rank</div>
                        <div className="testCardRightBottom allindia">
                          {" "}
                          {testReport.rank}
                        </div>
                      </div>
                    </div>
                    <div className="testCard">
                      <div className="testCardImg">
                        <img src={images.yourMarksIcon} alt="img" />
                      </div>

                      <div className="testCardRight">
                        <div className="testCardRightTop">Your Marks</div>
                        <div className="testCardRightBottom yourmarks">
                          {" "}
                          {testReport.self_score}
                        </div>
                      </div>
                    </div>
                    <div className="testCard">
                      <div className="testCardImg">
                        <img src={images.topperMarksIcon} alt="img" />
                      </div>

                      <div className="testCardRight">
                        <div className="testCardRightTop">Topper Marks</div>
                        <div className="testCardRightBottom toppermarks">
                          {" "}
                          {testReport.topper_score}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="redirectPredictClg">
                  <div className="redirectPredictClgPara">
                    Unlock Your Academic Destiny:
                    <div className="btm-textt">
                      Predict Your Ideal College/University.
                    </div>
                  </div>

                  <button className="btn" onClick={redirectToPredictCollege}>
                    <span>Predict College</span>{" "}
                    <span>
                      <img src={images.user_activity_47} alt="img" />
                    </span>
                  </button>
                </section>

                <QuestionClassWiseTable
                  questionClassWise={questionClassWise}
                  classFrom={classFrom}
                />

                <TestSummaryTable testReport={testReport} />

                <ImprovementsSuggestions
                  improvements_suggestions={improvements_suggestions}
                />

                <OtherTabs handleTabClick={handleTabClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
       {loader && <LoaderPopUp />}
    </div>
  );
};

export default TestReportContentComponent;
