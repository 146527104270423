import React from "react";
import "./QuestionListPanel.scss";
import images from "../../../../../../../utilities/images/images";

const QuestionListPanel = ({
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  questionsCollectionRef,
}) => {
  const renderQuestionLabel = (question, index) => {
    const isCurrentQuestion = currentQuestion === index;

    const labelClassName = isCurrentQuestion
      ? "unAttempted current_lbl"
      : question.is_attempted
      ? "answereds_lbl"
      : "unAttempted";

    return (
      <label
        className="unanswered_lbl unanswered_lbl1 current_lbl"
        onClick={() => handleQuestionJump(index)}
      >
        <div className="questionBox questionBox1">
          <div>{`${index + 1}.`}</div>
          <div className="QBox">
            <p
              dangerouslySetInnerHTML={{
                __html: question.q_text,
              }}
            ></p>
          </div>
        </div>
        <img className="arrow" src={images.right_Ar} alt="right-arrow" />
      </label>
    );
  };

  return (
    <div className="QuestionListPanelContent">
      <div className="subsection_wrapper">
        <div className="text_content_wrapper">
          <p className="text_content">
            Questions - {testQuestionsDetails?.data?.length}
          </p>
        </div>

        <div className="question_marking_wrapper">
          <div className="question_marking_Innrwrapper">
            <div className="single_marking">
              <label className="box_pic box_pick_background_1"></label>
              <label className="question_marking_text">Completed</label>
            </div>
            <div className="single_marking">
              <label className="box_pic box_pick_background_3"></label>
              <label className="question_marking_text">Pending</label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="questions_serial_number_collection"
        // ref={questionsCollectionRef}
      >
        {testQuestionsDetails?.data?.length
          ? testQuestionsDetails?.data.map((element, index) => {
              return (
                <div
                  key={index}
                  className="single_question_number_wrapper"
                  id="myDiv"
                >
                  {renderQuestionLabel(element, index)}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default QuestionListPanel;
