// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const AdaptiveCpsTestResults = () => {
//   const [adaptiveCpsResults, setAdaptiveCpsResults] = useState({});

//   useEffect(() => {
//     getAdaptiveCpsResults();
//   }, []);

//   const getAdaptiveCpsResults = async () => {
//     console.log("getAdaptiveCpsResults is calling");
//     const data = {
//       cps_id: 2322,
//     };

//     try {
//       const response = await axios.post(
//         "https://learning.motion.ac.in/motioneducation/api/user/UserACPSAnalysis",
//         data
//       );

//       console.log("response of adaptive", response);

//       if (response.data.status === 200) {
//         setAdaptiveCpsResults(response.data.data);
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   return (
//     <div>
//       {Object.keys(adaptiveCpsResults) && adaptiveCpsResults.unattempted}
//       sjingsongosgnosgn
//     </div>
//   );
// };

// export default AdaptiveCpsTestResults;

import { React, useState } from "react";
import "./AdaptiveCpsTestResults.scss";

import images from "../../../../../../../utilities/images/images";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import { useHistory, Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../redux/ui/action";

import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";

const AdaptiveCpsTestResults = (props) => {
  let { startLoading, finishLoading, loader, AdaptiveCpsId } = props;
  const [adaptiveCpsResults, setAdaptiveCpsResults] = useState({});

  useEffect(() => {
    getAdaptiveCpsResults();
  }, []);

  const getAdaptiveCpsResults = async () => {
    console.log("getAdaptiveCpsResults is calling");
    const data = {
      cps_id: AdaptiveCpsId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSAnalysis",
        data
      );

      console.log("response of adaptive", response);

      if (response.data.status === 200) {
        setAdaptiveCpsResults(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  let history = useHistory();

  const backBtn = () => {
    history.push("/user_dashboard");
  };

  const handleRedirectToCps = () => {
    history.push({
      pathname: "/create-adaptive-cps",
      state: { tab: "adaptive_cps" },
    });
  };

  const handleRedirectToStore = () => {
    history.push("/store");
  };

  return (
    <>
      <div className="adaptiveCpsWrapper">
        <HeaderComponent />
        <div className="summary_outer">
          <div className="summary_wrapper">
            <div className="d-flex heading_div">
              <Link className="backBtn" to={{
                      pathname: "/create-adaptive-cps",
                      state: { tab: "adaptive_cps" },
                  }}>
                      <img src={images.m2_backBtn} alt="backBtn" />
                    </Link>
              <h3>Test Summary</h3>
            </div>
            <div className="cards_wrapper">
              <div className="summary_card">
                <p>Total Questions</p>
                <p>{adaptiveCpsResults?.total_questions}</p>
              </div>
              <div className="summary_card">
                <p>Total Marks</p>
                <p>{adaptiveCpsResults?.total_marks}</p>
              </div>
              <div className="summary_card">
                <p>Correct Answers</p>
                <p>{adaptiveCpsResults?.correct_answer}</p>
              </div>
              <div className="summary_card">
                <p>Incorrect Answer</p>
                <p>{adaptiveCpsResults?.incorrect_answer}</p>
              </div>
              <div className="summary_card">
                <p>Score Percentage</p>
                <p>{`${adaptiveCpsResults?.score_percentage}%`}</p>
              </div>
            </div>
          </div>
          <div className="button_div">
            <button onClick={() => {handleRedirectToCps()}}>Back to Tests</button>
            {/* <button onClick>View Solutions</button> */}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    testResultData: state.cpsTestsReducer.cpsResultData,
    AdaptiveCpsId: state.AdaptiveCpsReducer.AdaptiveCpsId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdaptiveCpsTestResults);
