import { React, useState, useEffect } from "react";
import "./PyqCpsExamContentComponent.scss";
//

//
import Previous_question_paper from "./previous_question_paper/Previous_question_paper";
import Customized_practise_sheet from "./customized_practice_sheet/Customized_practise_sheet";
import User_Exercise_Test from "../../../user_exercise_test/user_exercise_test";

import User_ots_component from "../../../user_exercise_test/user_ots_test_series/ots_tests/OtsTestComponent";
import images from "../../../../../utilities/images/images";

import ExamCracker from "../../../user_exercise_test/user_exam_cracker/exam_cracker";
import Mock_test from "../../../user_exercise_test/user_mock_test/mock_test";
import BitSat from "../../../user_exercise_test/user_bit_sat/bit_sat";
// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import AdaptiveCps from "../../../user_exercise_test/user_adaptive_cps/AdaptiveCps";
import CommonBannerComponent from "./CommonBannerComponent";
import AdaptiveCpsCreatePaper from "../../../user_exercise_test/user_adaptive_cps/components/home-components/adaptive-cps-create/AdaptiveCpsCreatePaper";
const PyqCpsExamComponent = (props) => {
  let { history } = props;

  const {
    state: { tab },
  } = props.location;

  const [state, setState] = useState({ step: 1 });

  const handleStep = (newStep) => {
    setState((prevState) => ({
      ...prevState,
      step: newStep,
    }));
  };

  return (
    <>
      <div className="pyqCpsExam_wrapper">
        <div className="pyqCpsExam_InrWrapper">
          {tab === "create-adaptive-cps" && state.step === 1 && (
            <AdaptiveCpsCreatePaper handleStep={handleStep} />
          )}
          <div className="container">
            <div className="pyqCPsExam_contentWpr">
              <div className="pyqCpsExamTabBox">
                <div className="heading">
                  {tab == "pyq" && "Previous Year Questions Paper"}
                  {tab == "cps" && "Conceptual Problem Sheet"}
                  {tab == "exam" && "Exams"}
                  {tab == "ots" && "Test Series"}
                  {tab == "exam_cracker" && "Exam Cracker"}
                  {tab == "bit_sat" && "BITSAT"}
                  {tab == "mock_test" && "Mock tests"}
                  {tab == "create-adaptive-cps" && "Your Adaptive CPS"}
                </div>

                <div className="pyqCpsExamTabContent">
                  <div class="tab-content" id="myTabContent">
                    {tab === "pyq" && (
                      <div
                        class={
                          tab == "pyq"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="previous"
                        role="tabpanel"
                        aria-labelledby="previous-tab"
                      >
                        <Previous_question_paper />
                      </div>
                    )}

                    {tab === "cps" && (
                      <div
                        class={
                          tab == "cps"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="cstmPractise"
                        role="tabpanel"
                        aria-labelledby="cstmPractise-tab"
                      >
                        <CommonBannerComponent tab={tab} />
                        <Customized_practise_sheet />
                      </div>
                    )}

                    {tab === "exam" && (
                      <div
                        class={
                          tab == "exam"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="exam"
                        role="tabpanel"
                        aria-labelledby="exam-tab"
                      >
                        <User_Exercise_Test history={history} />
                      </div>
                    )}

                    {tab === "ots" && (
                      <div
                        class={
                          tab == "ots"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="ots"
                        role="tabpanel"
                        aria-labelledby="ots-tab"
                      >
                        <User_ots_component history={history} />
                      </div>
                    )}

                    {tab === "exam_cracker" && (
                      <div
                        class={
                          tab == "exam_cracker"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="exam_cracker"
                        role="tabpanel"
                        aria-labelledby="exam_cracker-tab"
                      >
                        <ExamCracker />
                      </div>
                    )}

                    {tab === "bit_sat" && (
                      <div
                        class={
                          tab == "bit_sat"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="bit_sat"
                        role="tabpanel"
                        aria-labelledby="bit_sat-tab"
                      >
                        <BitSat />
                      </div>
                    )}

                    {tab === "mock_test" && (
                      <div
                        class={
                          tab == "mock_test"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="mock_test"
                        role="tabpanel"
                        aria-labelledby="mock_test-tab"
                      >
                        <Mock_test />
                      </div>
                    )}

                    {tab === "create-adaptive-cps" && (
                      <div
                        class={
                          tab == "create-adaptive-cps"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="adaptive_cps"
                        role="tabpanel"
                        aria-labelledby="adaptive_cps-tab"
                      >
                        <AdaptiveCps
                          handleStep={handleStep}
                          step={state.step}
                          handleButtonClick={2}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(null, mapDispatchToProps)(PyqCpsExamComponent);
