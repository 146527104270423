import React from "react";
import images from "../../../../../utilities/images/images";

const InitialScreen = ({ parents, handleOptionSelect }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "62vh",
  };

  return (
    <div className="options" style={containerStyle}>
      <div className="message">
        <div className="content">
          <small>Motion Assistant</small>
          <p className="para1">
            Hi! Thank you for contacting Motion, I am Motion assistant how may i
            help you?
          </p>

          <p className="para2">
            Please choose the options below related to your issue
          </p>
        </div>
        <img src={images.moitonchatLogo} alt="" />
      </div>

      <div className="buttons">
        {parents.map((parent) => (
          <button
            key={parent.parentid}
            onClick={() =>
              handleOptionSelect(parent.name, parent.parentid, null, null)
            }
          >
            {parent.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InitialScreen;
