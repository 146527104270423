import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { url_250 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useHandleProductQuantity = () => {
  const queryClient = useQueryClient();
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const handleQuantityChange = async (newQty, productId) => {
    if (newQty < 1) return;

    try {
      startLoading();

      const response = await axios.post(
        url_250,
        {
          product_id: productId,
          qty: newQty,
        },
        headers
      );
      finishLoading();
    } catch (error) {
      finishLoading();

      console.error("Error updating quantity:", error);
    }
  };

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["update_product_quantity_in_cart"],
    mutationFn: ({ newQty, productId, token, tokenType }) =>
      handleQuantityChange(newQty, productId, token, tokenType),

    onSuccess: () => {
      queryClient.invalidateQueries(["cart_products"]);
    },
  });

  return { mutate, isSuccess };
};

export default useHandleProductQuantity;
