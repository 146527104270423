import React, { useState, useEffect, Fragment } from "react";
import "./WishListCollection.scss";
import images from "../../../../../../../utilities/images/images";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import {
  url_164,
  url_161,
  url_160,
  url_162,
} from "../../../../../../../custom_utilities/api_services";
import { Link } from "react-router-dom";
import wishListData from "../../../../../../../redux/new_store/action";
import uiActions from "../../../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../../../components/loader/loader";

import { connect } from "react-redux";
import Footer_component from "../../../../../structure_components/desktop_screens/footer_component/Footer_component";
import MobileLeftSideBarComponent from "../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import Mobile_header_component from "../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import Mobile__right_sideBar_component from "../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Header_component from "../../../../../structure_components/desktop_screens/header_component/header_component";

const WishListCollection = (props) => {
  let {
    setWishList,
    startLoading,
    finishLoading,
    loader,
    history,
    userPreference,
    userData,
  } = props;

  const [state, setState] = useState({
    wishList: [],
    cartDetails: {},
  });
  let historys = useHistory();

  useEffect(() => {
    getWishList();
    getCartDetails();
  }, []);

  const handleRedirect = () => {
    historys.push({
      pathname: "/cart_collection",
    });
  };

  const getWishList = async () => {
    startLoading();
    try {
      const response = await axios.post(url_164);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          wishList: response.data.data,
        }));
        setWishList(response.data.data);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    } finally {
      finishLoading();
    }
  };

  const handleDeleteItem = async (p_id) => {
    startLoading();
    let data = {
      product_id: p_id,
      type: 0,
    };
    try {
      const response = await axios.post(url_161, data);
      if (response.data.status === 200) {
        finishLoading();
      }

      getWishList();
    } catch (error) {
      finishLoading();
      console.log(error);
    } finally {
      finishLoading();
    }
  };

  const moveToCart = async (p_id) => {
    startLoading();
    let data = {
      product_id: p_id,
    };
    try {
      const response = await axios.post(url_160, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          addToCart: response.data,
        }));
      }
      handleDeleteItem(p_id);
      getCartDetails();
    } catch (error) {
      finishLoading();
      console.log(error);
    } finally {
      finishLoading();
    }
  };

  const getCartDetails = async () => {
    // startLoading();
    try {
      const response = await axios.post(url_162);
      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          cartDetails: response.data,
        }));
      }
    } catch (error) {
      //   finishLoading();
      console.log(error);
    }
  };

  const handleRedirect2 = () => {
    historys.push({
      pathname: "/store",
      state: { tab: "hand_books" },
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);

  return (
    <div>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
        /////for Mobile Screens////////////////////
        <Fragment>
          <MobileLeftSideBarComponent />
          <Mobile_header_component
            userPreference={userPreference}
            userData={userData}
          />
          <Mobile__right_sideBar_component history={history} />
        </Fragment>
      ) : (
        /////for Screens greater than Mobile Screens////////////////////
        <Fragment>
          <Header_component history={history} />
        </Fragment>
      )}
      <div className="wishList_collection">
        <div className="container">
          <div className="header">
            <div className="left">WishList</div>
            <div className="right">
              <div className="addCart" onClick={handleRedirect}>
                <img src={images.user_activity_38} alt="add-cart" />
                <div className="badge">{state.cartDetails.total}</div>
                <div className="cart">Cart</div>
              </div>
            </div>
          </div>
          <div className="backBtn_content">
            <Link
              className="backBtn"
              to={{
                pathname: "/store",
                state: { tab: "hand_books" },
              }}
            >
              <img src={images.m2_backBtn} alt="backBtn" />
            </Link>
            <div className="backBtn_text" style={{ color: "#121212" }}>
              Back to store
            </div>
          </div>

          {state.wishList.length > 0 ? (
            <section className="cart_container">
              {!isEmpty(state.wishList)
                ? state.wishList.length
                  ? state.wishList.map((element, index) => {
                      return (
                        <div key={index} className="card mb-3">
                          <div className="row g-0">
                            <div className="col-md-3 card_left">
                              <img src={element.image} alt="item_img" />
                            </div>
                            <div className="col-md-9 col-right">
                              <div className="card-body">
                                <h5 className="card-title">
                                  {element.product_name}
                                </h5>
                                <p className="card-text">
                                  The pack with Solution book and practice
                                  papers.
                                </p>
                                <div className="card_text_actions">
                                  <div className="actions">
                                    <button
                                      style={{
                                        color: "#b50503",
                                        fontWeight: "500",
                                      }}
                                      className="delete action"
                                      onClick={() =>
                                        handleDeleteItem(element.p_id)
                                      }
                                    >
                                      Delete
                                    </button>
                                    |
                                    <div
                                      style={{
                                        color: "#b50503",
                                        fontWeight: "500",
                                      }}
                                      className="moveToCart action"
                                      onClick={() => moveToCart(element.p_id)}
                                    >
                                      Move to cart
                                    </div>
                                  </div>
                                </div>
                                <div className="price">
                                  <span className="price_text">Price</span>
                                  <span className="cost">
                                    {parseInt(element.price).toLocaleString(
                                      "en-US"
                                    )}
                                    /-
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </section>
          ) : (
            <section>
              <div className="center">
                <div className="text" style={{ color: "#121212" }}>
                  Your WishList is empty!
                </div>
                <button onClick={handleRedirect2}>Add Now</button>
              </div>
            </section>
          )}
        </div>
      </div>
      <Footer_component />
      {loader && <LoaderPopUp />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setWishList: (payload) => {
      dispatch(wishListData.setWishList(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WishListCollection);
