import { useState } from "react";
import LoginSignupModal from "../LoginSignupModal/LoginSignupModal";
import { StoreBanner } from "./StoreBanner/StoreBanner";

export const Store = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  return (
    <div>
      <LoginSignupModal
        isLoginOpen={isLoginOpen}
        setIsLoginOpen={setIsLoginOpen}
      />
      <StoreBanner isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
    </div>
  );
};
