import * as actionTypes from "./action_types";

const initialState = {
  storeCartDetailsData: {},
  productList: [],
  wishList: [],
  loginType: "",
};

const storeReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_STORE_CART_DETAILS_DATA:
      return setStoreCartDetailsData(state, payload);
    case actionTypes.SET_PRODUCT_LIST:
      return setProductList(state, payload);
    case actionTypes.SET_WISH_LIST:
      return setWishList(state, payload);
      case actionTypes.SET_LOGIN_TYPE:
        return setLoginType(state, payload);  
    default:
      return state;
  }
};

const setStoreCartDetailsData = (state, payload) => {
  state = { ...state, storeCartDetailsData: payload };
  return state;
};

const setProductList = (state, payload) => {
  state = { ...state, productList: payload };
  return state;
};

const setWishList = (state, payload) => {
  state = { ...state, wishList: payload };
  return state;
};

const setLoginType = (state, payload) => {
  state = { ...state, loginType: payload };
  return state;
};


export default storeReducer;
