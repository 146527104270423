import * as actionTypes from "./action_type";

const initialState = {
  handBookOrderId: "",
  handBookTransactionDetails: {},
  tab: "cart",
};

const HandBookReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.HANDBOOK_SET_ORDER_ID:
      return setOrderId(state, payload);

    case actionTypes.HANDBOOKS_SAVE_TRANSACTION_DETAILS:
      return setHandBookTransactionDetails(state, payload);

    case actionTypes.HANDBOOKS_TAB:
      return setTab(state, payload);

    default:
      return state;
  }
};

const setOrderId = (state, payload) => {
  return (state = { ...state, handBookOrderId: payload });
};

const setHandBookTransactionDetails = (state, payload) => {
  state = { ...state, handBookTransactionDetails: payload };
  return state;
};

const setTab = (state, payload) => {
  return (state = { ...state, tab: payload });
};

export default HandBookReducer;
