import React, { useState } from "react";
import LoginSignupModal from "./LoginSignupModal/LoginSignupModal";
import Navbar from "./Navbar/Navbar";
import Banner from "./Banner/Banner";
import MoreDetails from "./MoreDetails/MoreDetails";
import Courses from "./Courses/Courses";
import Benifits from "./Benifits/Benifits";
import AppDetails from "./AppDetails/AppDetails";
import Packages from "./Packages/Packages";
import WhyMotion from "./WhyMotion/WhyMotion";
import MotionNews from "./MotionNews/MotionNews";
import ContactUs from "./ContactUs/ContactUs";
import Footer from "./Footer/Footer";
import "./NewHomePage.scss";
import ContactBanner from "./ContactBanner/ContactBanner";

const NewHomePage = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  return (
    <div className="homepage-container">
      <LoginSignupModal
        isLoginOpen={isLoginOpen}
        setIsLoginOpen={setIsLoginOpen}
      />

      <section id="navbar">
        <Navbar setIsLoginOpen={setIsLoginOpen} isLoginOpen={isLoginOpen} />
      </section>
      <section className="contact-banner-wrapper">
        <ContactBanner />
      </section>

      <section className="banner-wrapper">
        <Banner isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
      </section>

      <section className="more-details-wrapper">
        <MoreDetails />
      </section>

      <section className="courses-wrapper" id="courses-id">
        <Courses />
      </section>

      <section className="benifits-wrapper" id="benefits-id">
        <Benifits isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
      </section>

      <section className="app-details-wrapper">
        <AppDetails />
      </section>

      <section className="packages-wrapper">
        <Packages />
      </section>

      <section className="why-motion-wrapper">
        <WhyMotion />
      </section>

      <section className="motion-news-wrapper">
        <MotionNews />
      </section>

      <section className="contact-us-wrapper">
        <ContactUs />
      </section>

      <section className="footer-wrapper">
        <Footer />
      </section>
    </div>
  );
};

export default NewHomePage;
