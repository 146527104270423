import React from "react";

// Desktop
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";
import PyqDailyChallengeContent from "../Pyq_daily_challenge/PyqDailyChallengeContent";

// Mobile
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import LoaderPopUp from "../../../components/loader/loader";
// Redux
import { connect } from "react-redux";
import FooterComponent from "../structure_components/desktop_screens/footer_component/Footer_component";

const PyqDailyChallenge = (props) => {
  let { loader, history, userData, userPreference } = props;

  const isMob = () => {
    return window.innerWidth <= 480;
  };

  return (
    <>
      <div className="PyqSubjectTopicWrapper">
        <div className="PyqSubjectTopicInrWrpr">
          {isMob() ? (
            <>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
            </>
          ) : (
            <HeaderComponent history={history} />
          )}
          <PyqDailyChallengeContent />
        </div>
      </div>
      <FooterComponent />
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(PyqDailyChallenge);
