import React from "react";
import { useHistory } from "react-router-dom";
import images from "../../../../../../../../utilities/images/images";
import "./FormHeader.scss";

const FormHeader = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="address_form_header_wrapper">
      <div className="address_form_header">
        <img src={images.storeBck} alt="back" onClick={goBack} />
        <div className="add_address_text">Add Address</div>
      </div>
    </div>
  );
};

export default FormHeader;
