import { Fragment, useState, useEffect } from "react";
import "./examCrackerSubjectTopicLists.scss";
import images from "../../../../../utilities/images/images";
import HeaderComponent from "../../../structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import {
  url_107,
  url_108,
} from "./../../../../../custom_utilities/api_services";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  url_113,
  url_114,
} from "./../../../../../custom_utilities/api_services";
import { useSelector } from "react-redux";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import userExamCrackerTestsAction from "../../../../../redux/exam_cracker_test_exercises/action";
import LoaderPopUp from "../../../../../components/loader/loader";
import Footer_component from "../../../structure_components/desktop_screens/footer_component/Footer_component";
import Mobile__right_sideBar_component from "../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import CommonBannerComponent from "../../../PYQ_CPS_Exam/pyqCpsExamComponent/PyqCpsExamContentComponent/CommonBannerComponent";
import { Modal } from "react-bootstrap";

const ExamCrackerSubjectTopicLists = (props) => {
  let {
    userPreference,
    subjectData,
    setSubjectData,
    setTopicData,
    startLoading,
    finishLoading,
    loader,
    setExamCrackerTestsData,
    userData,
    commonBannerData,
  } = props;
  let { courseDetails, classDetails } = userPreference;
  let history = useHistory();
  const location = useLocation();
  let package_name = location.state.package_name;
  let freeCourseId = location.courseId;

  const packageDetails = useSelector(
    (state) => state?.PackageSliceReducer?.storeCurrentPackageDetails
  );

  const [state, setState] = useState({
    subjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
      showTopic: false,
      showModal: false,
    },
    subjectList: {},
    topicList: {},
    initialSubjectCheck: true,
  });

  useEffect(() => {
    getSubjectData();
    getSubjectList();
    console.log(commonBannerData.data.package_active);
  }, []);

  useEffect(() => {
    if (
      !isEmpty(state.subjectList) &&
      state.subjectList.subject.length &&
      state.initialSubjectCheck
    ) {
      getInitialData();
    }
  }, [state.subjectList]);

  const getInitialData = () => {
    let { subjectId } = subjectData;
    let { subjectList } = state;
    setState((prevState) => ({ ...prevState, initialSubjectCheck: false }));

    if (subjectId) {
      getSubjectData();
      getTopics(courseDetails[0].course_id, subjectId);
    } else {
      setSubjectData({
        subjectId: subjectList.subject[0].sub_id,
        subjectName: subjectList.subject[0].sub_name,
        subjectIcon: subjectList.subject[0].icon,
      });
      setState((prevState) => ({
        ...prevState,
        subjectDataDetails: {
          ...prevState.subjectDataDetails,
          subjectId: subjectList.subject[0].sub_id,
          subjectName: subjectList.subject[0].sub_name,
          subjectIcon: subjectList.subject[0].icon,
        },
      }));
      getTopics(courseDetails[0].course_id, subjectList.subject[0].sub_id);
    }
  };

  const getSubjectData = () => {
    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        ...prevState.subjectDataDetails,
        subjectId: subjectData?.subjectId,
        subjectName: subjectData.subjectName,
        subjectIcon: subjectData.subjectIcon,
      },
    }));
  };

  const getSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;
    let data = {};

    if (package_name === "Exam Cracker - JEE Mains") {
      data = {
        // course_id: courseDetails[0].course_id,
        course_id: location.courseId
          ? freeCourseId
          : courseDetails[0].course_id,
        class_id: classDetails.class_id,
        target_id: 1,
      };
    } else if (package_name === "Exam Cracker - JEE Advanced") {
      data = {
        course_id: courseDetails[0].course_id,
        class_id: classDetails.class_id,
        target_id: 2,
      };
    } else if (package_name === "Exam Cracker - NEET") {
      data = {
        course_id: courseDetails[0].course_id,
        class_id: classDetails.class_id,
        target_id: 3,
      };
    }

    startLoading();

    try {
      const response = await axios.post(url_113, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
        if (location.courseId) {
          const { sub_id, sub_name, icon } = response.data.data.subject[0];
          handleChangeSubject("", sub_id, sub_name, icon);
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTopics = async (course_id, sub_id) => {
    let data = {};
    if (package_name === "Exam Cracker - JEE Mains") {
      data = {
        course_id: location.courseId ? freeCourseId : course_id,
        subject_id: sub_id,
        target_id: 1,
      };
    } else if (package_name === "Exam Cracker - JEE Advanced") {
      data = {
        subject_id: sub_id,
        target_id: 2,
      };
    } else if (package_name === "Exam Cracker - NEET") {
      data = {
        subject_id: sub_id,
        target_id: 3,
      };
    }

    startLoading();
    try {
      const response = await axios.post(url_114, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const backGroundColorFromBackend = (color_code) => {
    const colorCode = color_code ? color_code.split(",") : null;

    if (colorCode !== null) {
      return {
        background: `linear-gradient(277deg,${colorCode[0]}, ${colorCode[1]} 101.46%  )`,
      };
    } else {
      return {
        background: "#ffffff",
      };
    }
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    setSubjectData({
      subjectId: sub_id,
      subjectName: sub_name,
      subjectIcon: icon,
    });

    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        ...prevState.subjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));
    getTopics(courseDetails[0].course_id, sub_id);
  };

  const handleShowTopic = (sub_name) => {
    setState((prevState) => ({
      ...prevState,
      showTopic: true,
      subject: sub_name,
    }));
  };

  const onHandleTakeTest = (testData) => {
    const subject = state.subjectDataDetails;
    const data = { ...testData, subject };
    console.log("tp", data);
    setExamCrackerTestsData(data);
    if (!commonBannerData.data.packageActive) {
      // history.push({
      //   pathname: "/store", state: { tab: "online_learning" }
      // })
      setState((prevState) => ({
        ...prevState,
        showModal: true,
      }));
    } else {
      history.push({
        pathname: "/exam_cracker_test_component",
        state: {
          package_name: package_name,
          state: location.courseId,
        },
      });
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);

  return (
    <Fragment>
      <Modal
        id="ecm"
        show={state.showModal}
        onHide={() =>
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }))
        }
        animation={false}
        centered
      >
        <Modal.Body>
          <p>Exam Cracker</p>
          <p>
            Your path to Exam success begins with{" "}
            <strong style={{ color: "#121212", fontWeight: "500" }}>
              “Exam Cracker”
            </strong>
            . Subscribe now!
          </p>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                history.push(`/package/${packageDetails?.package_id}`);
              }}
            >
              Buy Now
            </button>
          </div>
          <div
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showModal: false,
              }))
            }
          >
            <img src={images.close_btn} alt="close" />
          </div>
        </Modal.Body>
      </Modal>

      <div className="examCrackerPageWrapper">
        <div className="examCrackerPageInrWrpr">
          {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_dashboard_left_wrapper">
                {/* <LeftSideBarComponent /> */}
              </div>
              <div className="user_dashboard_right_wrapper">
                <HeaderComponent history={history} />
              </div>
            </Fragment>
          )}
          <div className="subjectContentComponent_wrapper">
            <div className="container">
              <div className="subjectContentComponent_inner_wrapper">
                <div className="examCrackerHeading">
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/exam_cracker",
                      state: { tab: "exam_cracker" },
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                  <h2>{state.showTopic ? state.subject : package_name}</h2>
                </div>
                {!state.showTopic && (
                  <CommonBannerComponent tab={package_name} />
                )}
                <div className="sec_2_wrapper">
                  <div className="sec_2_inner_wrapper">
                    <div className="content_wrapper">
                      {!state.showTopic && (
                        <div className="left_side_content_wrapper">
                          <div className="subject_collection_wrapper">
                            {!isEmpty(commonBannerData.data)
                              ? commonBannerData.data.subject.length
                                ? commonBannerData.data.subject.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="single_select_subject"
                                        >
                                          <input
                                            type="radio"
                                            id={`subject_${element.sub_id}`}
                                            name="subject"
                                            value={element.sub_id}
                                            checked={
                                              element.sub_id ===
                                              parseInt(
                                                state.subjectDataDetails
                                                  .subjectId
                                              )
                                            }
                                            // onChange={(event) =>
                                            //   handleChangeSubject(
                                            //     event,
                                            //     element.sub_id,
                                            //     element.sub_name,
                                            //     element.icon
                                            //   )
                                            // }
                                          />
                                          <label
                                            htmlFor={`subject_${element.sub_id}`}
                                            style={backGroundColorFromBackend(
                                              element.color_code
                                            )}
                                            onClick={() => {
                                              handleChangeSubject(
                                                null,
                                                element.sub_id,
                                                element.sub_name,
                                                element.icon
                                              );
                                              setTimeout(() => {
                                                handleShowTopic(
                                                  element.sub_name
                                                );
                                              }, 100);
                                            }}
                                          >
                                            <div className="subject_icon_examcracker">
                                              <img
                                                src={element.icon}
                                                alt="icon"
                                              />
                                              {element.sub_name}
                                            </div>

                                            <img
                                              src={images.new_m2_rgtArr_blk}
                                              alt="arrow"
                                            />
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                : null
                              : null}
                            {loader && <LoaderPopUp />}
                          </div>
                        </div>
                      )}

                      {state.showTopic && (
                        <div className="right_side_content_wrappers">
                          <div className="topic_collection_wrapper">
                            {!isEmpty(state.topicList) ? (
                              state.topicList.topic.length ? (
                                state.topicList.topic.map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="single_topic_wrapper"
                                      onClick={() => onHandleTakeTest(element)}
                                    >
                                      <div className="topic_description_wrapper">
                                        <div className="topic_details_wrapper">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {element.topic_name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="caret_wraper">
                                          <img
                                            src={images.right_Ar}
                                            alt="arrow"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no_data_found_wrapper">
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : // <div className="no_data_found_wrapper">
                            //   <img src={images.no_data} />
                            // </div>
                            null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer_component />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    subjectData: state.userActivity.subjectData,
    userPreference: state.userPreference,
    userData: state.auth.user,
    commonBannerData: state.userActivity.commonBannerData,
  };
};
const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
    setTopicData: (payload) => {
      dispatch(userActivityActions.setTopicData(payload));
    },
    setExamCrackerTestsData: (payload) => {
      dispatch(userExamCrackerTestsAction.setExamCrackerTestsData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(ExamCrackerSubjectTopicLists);
