import React, { useState, useEffect } from "react";
import "./ExamTestSummary.scss";
import images from "../../../../../../utilities/images/images";
import { url_195 } from "../../../../../../custom_utilities/api_services";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import uiActions from "../../../../../../redux/ui/action";
import { connect } from "react-redux";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import Header_component from "../../../../structure_components/desktop_screens/header_component/header_component";
import Mobile__right_sideBar_component from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const ExamTestSummary = (props) => {
  const { startLoading, finishLoading, testResultData, testData, token, tokenType, history, userData, userPreference } =
    props;

  const [examSummary, setExamSummary] = useState({});

  useEffect(() => {
    getExamSummary();
  }, []);

  const getExamSummary = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testData.paper_id,
    };

    startLoading();
    try {
      const response = await axios.post(url_195, data, { headers });
      if (response.data.status === 200) {
        setExamSummary(response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])

  return (
    <>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <>
                <Header_component history={history} />
            </>
          )}
      <div className="examSummaryComponentWrapper">
        <div className="summary_outer">
          <div className="summary_wrapper">
            <div className="d-flex heading_div">
              <Link className="backBtn" to={{
                      pathname: "/exam",
                      state: { tab: "exam" }
                  }}>
                      <img src={images.m2_backBtn} alt="backBtn" />
                    </Link>
              <h3>Test Summary</h3>
            </div>
            <div className="cards_wrapper">
              <div className="summary_card">
                <p>Total Questions</p>
                <p>{examSummary?.total_questions}</p>
              </div>
              <div className="summary_card">
                <p>Total Marks</p>
                <p>{examSummary?.total_marks}</p>
              </div>
              <div className="summary_card">
                <p>Correct Answers</p>
                <p>{examSummary?.correct_answer}</p>
              </div>
              <div className="summary_card">
                <p>Incorrect Answer</p>
                <p>{examSummary?.incorrect_answer}</p>
              </div>
              <div className="summary_card">
                <p>Score Percentage</p>
                <p>{`${examSummary?.score_percentage}%`}</p>
              </div>
            </div>
          </div>
          <div className="button_div">
            <button onClick={() => {
              history.push({pathname: "/exam", state:  { tab: "exam" }})
            }}>Back to Tests</button>
            {/* <button onClick>View Solutions</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    testResultData: state.userTestsExercisesData.testResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    testData: state.userTestsExercisesData.testData,
    userData: state.auth.user,
    userPreference: state.userPreference,
    testData: state.userTestsExercisesData.testData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamTestSummary);
