import React from "react";
import images from "../../../../../../utilities/images/images";
import CreateNewDoubtBtn from "../buttons/CreateNewDoubtBtn";
import "./NoDoubt.scss";

const NoDoubt = ({ handleShow,message }) => {
  return (
    <>
      <div className="no_data_found_wrapper">
        <img src={images.doubts_no_data} alt="No Doubts" />
        <p>{message}</p>
      </div>

      <CreateNewDoubtBtn handleShow={handleShow} />
    </>
  );
};

export default NoDoubt;
