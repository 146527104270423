import { Modal } from "react-bootstrap";
import "./FullScreenImageModal.scss";

const FullScreenImageModal = ({ show, image, handleCloseFullScreenModal }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseFullScreenModal}
        dialogClassName="modal-full-screen"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={image} alt="doubt-imag" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FullScreenImageModal;
