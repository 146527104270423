import { Fragment, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./login_step_one.scss";
import { Link } from "react-router-dom";
import images from "../../../utilities/images/images";

const LoginStepOne = (props) => {
  let {
    phoneDetails,
    loginCandidate,
    handleRedirectToStudentLogin,
    handleRedirectToParentLogin,
    submitParentMobileNumber,
  } = props;

  const [clickCount, setClickCount] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const siteKey = "6Lcx-yAoAAAAAIqP73W-JK46kaKq-JGkJ533JvBA";

  const handleSendOTPClick = (event) => {
    if (clickCount >= 4) {
      setShowCaptcha(true);
    } else {
      if (loginCandidate === "student") {
        props.submitMobileNumber(event);
      } else {
        submitParentMobileNumber(event);
      }
    }
    setClickCount(clickCount + 1);
  };

  const handleCaptchaSubmit = (event) => {
    event.preventDefault();
    if (captchaVerified) {
      setClickCount(0);
      setShowCaptcha(false);
    }
  };

  const handleCaptchaVerify = (response) => {
    if (response) {
      setCaptchaVerified(true); // Set to true when captcha is verified
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleMoveToNext = (current, nextFieldId) => {
    // console.log(current, nextFieldId);
    if (
      current.target.value.length ===
      parseInt(current.target.attributes["maxlength"].value)
    ) {
      document.getElementById(nextFieldId).focus();
    }
  };

  return (
    <Fragment>
      <div className="login_step_one_wrapper">
        <div className="login_step_one_inner_wrapper">
          <div className="content_wrapper">
            <div className="content_inner_wrapper">
              {/* {loginCandidate == "student" ? (
                <div
                  className="parent-login"
                  onClick={() => handleRedirectToParentLogin()}
                >
                  Parent Login
                </div>
              ) : (
                <div
                  className="parent-login"
                  onClick={() => handleRedirectToStudentLogin()}
                >
                  Student Login
                </div>
              )} */}

              <div className="login-heading-wrapper">
                <div className="login-heading">Log In/Sign Up as</div>

                <div className="login-subheading">
                  Please share your number for verification.
                </div>
              </div>

              <div className="logintypeslist">
                <div
                  className={`logintype ${
                    loginCandidate === "student" ? "active" : " "
                  }`}
                  onClick={() => handleRedirectToStudentLogin()}
                >
                  Student
                </div>
                <div
                  className={`logintype ${
                    loginCandidate === "parent" ? "active" : " "
                  }`}
                  onClick={() => handleRedirectToParentLogin()}
                >
                  Parent
                </div>
              </div>
              {loginCandidate === "student" ? (
                <form
                  onSubmit={
                    showCaptcha ? handleCaptchaSubmit : handleSendOTPClick
                  }
                >
                  {/* <div className="image_container">
                    <img src={images.m2_loginIcon} />
                  </div> */}
                  {/* <h3> {loginCandidate === "student" ? "S" : "P"}</h3> */}
                  <p>Enter your mobile number</p>
                  <div className="input_wrapper">
                    <input
                      type="number"
                      name="phone_no"
                      maxLength="10"
                      pattern="^\d{10}$"
                      title="Only Ten Digit mobile number, no alphabet"
                      placeholder="Enter Mobile No"
                      value={phoneDetails.mobile_number}
                      onKeyUp={(event) =>
                        handleMoveToNext(event, "send_otp_btn")
                      }
                      onChange={(event) =>
                        props.handleChangeMobileNumber(event)
                      }
                      required
                    />
                  </div>
                  <div className="btn_wrapper">
                    <button id="send_otp_btn" type="submit">
                      Send OTP
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={(event) => submitParentMobileNumber(event)}>
                  {/* <div className="image_container">
                    <img src={images.m2_loginIcon} />
                  </div> */}
                  <h3>
                    {" "}
                    {/* {loginCandidate === "student"
                      ? "S"
                      : "P"} */}
                  </h3>
                  {/* <p>Please share your number for verification.</p> */}
                  <p>Enter your mobile number</p>

                  <div className="input_wrapper">
                    <input
                      type="number"
                      name="phone_no"
                      maxLength="10"
                      pattern="^\d{10}$"
                      title="Only Ten Digit mobile number, no alphabet"
                      placeholder="Enter Mobile No"
                      value={phoneDetails.mobile_number}
                      onKeyUp={(event) =>
                        handleMoveToNext(event, "send_otp_btn")
                      }
                      onChange={(event) =>
                        props.handleChangeMobileNumber(event)
                      }
                      required
                    />
                  </div>
                  <div className="btn_wrapper">
                    {showCaptcha && (
                      <div className="captcha-container mb-4">
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={handleCaptchaVerify}
                        />
                      </div>
                    )}

                    <button
                      id="send_otp_btn"
                      type="button"
                      onClick={handleSendOTPClick}
                      disabled={
                        showCaptcha || (showCaptcha && !captchaVerified)
                      }
                    >
                      Send OTP
                    </button>
                  </div>
                </form>
              )}
              {/* {loginCandidate == "student" && (
                <div className="not_member">
                  <span>Not a Member? </span>
                  <Link to="/signUp2" className="singup">
                    Signup
                  </Link>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginStepOne;
