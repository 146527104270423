import { React, useState } from "react";

import QuestionBankSolutionContent from "./QuestionBankSolutionContent";

import { url_157 } from "../../../../custom_utilities/api_services";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const QuestionBankSolution = (props) => {
  let { startLoading, finishLoading, loader, questionBankTestId, userData } =
    props;
  const [state, setState] = useState({
    testQuestionsDetails: [],
    currentQuestion: 0,
    currentSolutionDetails: {},
  });
  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    startLoading();
    let data = {
      question_bank_id: questionBankTestId,
    };
    try {
      const response = await axios.post(url_157, data);
      if (response.data.status === 200) {
        finishLoading();

        setState((preState) => ({
          ...preState,
          testQuestionsDetails: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  //nextBtn
  const handleNextQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
    }));
    window.scrollTo(0, 0);
  };
  //previous btn
  const handlePreviousQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));

      window.scrollTo(0, 0);
    }
  };
  //handleJump
  const handleQuestionJump = (questionNumber) => {
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };
  return (
    <div>
      <QuestionBankSolutionContent
        testQuestionsDetails={state.testQuestionsDetails}
        currentQuestion={state.currentQuestion}
        currentSolutionDetails={state.currentSolutionDetails}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        handleQuestionJump={handleQuestionJump}
        userData={userData}
      />
      {loader && <LoaderPopUp />}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    questionBankTestId: state.QuestionBank.questionBankId,
    userData: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBankSolution);
