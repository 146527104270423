import React, { useState, useEffect } from "react";
import "./exam_cracker.scss";
import LoaderPopUp from "../../../../components/loader/loader";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { url_112 } from "../../../../custom_utilities/api_services";
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import images from "./../../../../utilities/images/images";
import { setStoreCurrentPackageDetails } from "../../../../redux/packages_store/PackageSlice";

const ExamCracker = (props) => {
  let {
    loader,
    userData,
    userPreference,
    targetDetails,
    userId,
    setStoreCurrentPackageDetails,
  } = props;
  let history = useHistory();

  const [state, setState] = useState({
    courseList: [],
  });

  useEffect(() => {
    getCourseList();
  }, []);

  const getCourseList = async () => {
    let { targetDetails } = userPreference;
    let data = {
      target_id: targetDetails[0].target_id,
      user_id: userId,
    };
    try {
      const response = await axios.post(url_112, data);
      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          courseList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const examCrackerSubjectTopics = (elem) => {
    // if (elem.is_purchased == 0) {
    //   history.push({
    //     pathname: "/exam_cracker_payment_page",
    //     state: {
    //       packageData: elem,
    //     },
    //   });
    // } else if (elem.is_purchased > 0) {
    //   history.push({
    //     pathname: "/exam_cracker_subject_topic",
    //     state: {
    //       package_name: elem.package_name,
    //     },
    //   });
    // }

    setStoreCurrentPackageDetails(elem);

    history.push({
      pathname: "/exam_cracker_subject_topic",
      state: {
        package_name: elem.package_name,
      },
    });
  };

  const imgData = (elem) => {
    if (elem.package_name === "Exam Cracker - JEE Advanced") {
      return (
        <div className="img">
          <img src={images.user_activity_25} alt="jee-Advanced" />
        </div>
      );
    } else if (elem.package_name === "Exam Cracker - JEE Mains") {
      return (
        <div className="img">
          <img src={images.user_activity_24} alt="jee-main" />
        </div>
      );
    } else if (elem.package_name === "Exam Cracker - NEET") {
      return (
        <div className="img img_neet">
          <img src={images.user_activity_26} alt="neet" />
        </div>
      );
    }
  };

  return (
    <>
      <div className="examCracker_courseWrapper">
        <div className="examCracker_courseInrWrapper">
          <div className="course_WiseWrapper">
            {!isEmpty(state.courseList)
              ? state.courseList.length
                ? state.courseList.map((elem, index) => {
                    return (
                      <div
                        key={index}
                        className="courseBox"
                        onClick={() => examCrackerSubjectTopics(elem)}
                      >
                        <div className="course_content">
                          {imgData(elem)}
                          <div className="content">
                            <h5>{elem.package_name}</h5>
                            <p className="contentDesc">{elem.description}</p>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                    );
                  })
                : null
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    userId: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreCurrentPackageDetails: (payload) => {
      dispatch(setStoreCurrentPackageDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamCracker);
