import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import "./new_offerings_slider.scss";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";

//slick-carousel
import Slider from "react-slick";

const NewOfferingsSlider = ({ state }) => {
  const history = useHistory();

  const handleRedirect = (elem) => {
    switch (elem.name) {
      case "EXAM CRACKER":
        history.push({
          pathname: "/exam_cracker",
          state: { tab: "exam_cracker" },
        });
        break;
      case "OTS - Online Test Series":
        history.push({
          pathname: "/ots",
          state: { tab: "ots" },
        });
        break;
      case "PYQ":
        history.push({
          pathname: "/pyq",
          state: { tab: "pyq" },
        });
        break;
      case "CPS":
        history.push({
          pathname: "/cps",
          state: { tab: "cps" },
        });
        break;
      case "EXAMS":
        history.push({
          pathname: "/exam",
          state: { tab: "exam" },
        });
        break;
      case "ACPS":
        history.push({
          pathname: "/create-adaptive-cps",
          state: { tab: "create-adaptive-cps" },
        });
        break;
      default:
        break;
    }
  };

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="dashboard_offerings_slider_wrapper">
        <div className="dashboard_offerings_slider_inner_wrapper">
          <div className="new_offerings_header_text">
            <h4 className="text-center text-md-start text-lg-start">
              New offerings
            </h4>
          </div>
          <Slider {...settings}>
            {!isEmpty(state)
              ? state.map((element, index) => {
                  return (
                    <div key={index} className="new_offerings_wrapper">
                      <div className="new_offerings_inner_wrapper">
                        <div className="new_offerings_image_wrapper">
                          {element.image ? (
                            <img
                              src={`${BASE_URL}${element.image}`}
                              alt="new_offerings_image"
                              loading="lazy"
                              className="new_offerings_img"
                            />
                          ) : (
                            <img
                              src={images.user_dashboard_11}
                              alt="new_offerings_image"
                              loading="lazy"
                            />
                          )}
                        </div>

                        <div className="new_offerings_content">
                          <div className="new_offerings_title_descript">
                            <h2 className="new_offerings_title">
                              {element.name}
                            </h2>
                            <p className="new_offerings_description">
                              {element.description}
                            </p>
                          </div>
                          <div className="new_offerings_start_btn">
                            <button
                              className="n_o_btn"
                              onClick={() => handleRedirect(element)}
                            >
                              Start Practice
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default NewOfferingsSlider;
