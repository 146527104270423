const GridIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 178524">
        <rect
          x="0.3"
          y="0.3"
          width="35.4"
          height="35.4"
          rx="3.7"
          fill="#F6F6F7"
        />
        <rect
          x="0.3"
          y="0.3"
          width="35.4"
          height="35.4"
          rx="3.7"
          stroke="#CECECE"
          stroke-width="0.6"
        />
        <path
          id="Vector"
          d="M15.75 9.75H11.25C10.8522 9.75 10.4706 9.90804 10.1893 10.1893C9.90804 10.4706 9.75 10.8522 9.75 11.25V15.75C9.75 16.1478 9.90804 16.5294 10.1893 16.8107C10.4706 17.092 10.8522 17.25 11.25 17.25H15.75C16.1478 17.25 16.5294 17.092 16.8107 16.8107C17.092 16.5294 17.25 16.1478 17.25 15.75V11.25C17.25 10.8522 17.092 10.4706 16.8107 10.1893C16.5294 9.90804 16.1478 9.75 15.75 9.75ZM15.75 15.75H11.25V11.25H15.75V15.75ZM24.75 9.75H20.25C19.8522 9.75 19.4706 9.90804 19.1893 10.1893C18.908 10.4706 18.75 10.8522 18.75 11.25V15.75C18.75 16.1478 18.908 16.5294 19.1893 16.8107C19.4706 17.092 19.8522 17.25 20.25 17.25H24.75C25.1478 17.25 25.5294 17.092 25.8107 16.8107C26.092 16.5294 26.25 16.1478 26.25 15.75V11.25C26.25 10.8522 26.092 10.4706 25.8107 10.1893C25.5294 9.90804 25.1478 9.75 24.75 9.75ZM24.75 15.75H20.25V11.25H24.75V15.75ZM15.75 18.75H11.25C10.8522 18.75 10.4706 18.908 10.1893 19.1893C9.90804 19.4706 9.75 19.8522 9.75 20.25V24.75C9.75 25.1478 9.90804 25.5294 10.1893 25.8107C10.4706 26.092 10.8522 26.25 11.25 26.25H15.75C16.1478 26.25 16.5294 26.092 16.8107 25.8107C17.092 25.5294 17.25 25.1478 17.25 24.75V20.25C17.25 19.8522 17.092 19.4706 16.8107 19.1893C16.5294 18.908 16.1478 18.75 15.75 18.75ZM15.75 24.75H11.25V20.25H15.75V24.75ZM24.75 18.75H20.25C19.8522 18.75 19.4706 18.908 19.1893 19.1893C18.908 19.4706 18.75 19.8522 18.75 20.25V24.75C18.75 25.1478 18.908 25.5294 19.1893 25.8107C19.4706 26.092 19.8522 26.25 20.25 26.25H24.75C25.1478 26.25 25.5294 26.092 25.8107 25.8107C26.092 25.5294 26.25 25.1478 26.25 24.75V20.25C26.25 19.8522 26.092 19.4706 25.8107 19.1893C25.5294 18.908 25.1478 18.75 24.75 18.75ZM24.75 24.75H20.25V20.25H24.75V24.75Z"
          fill="#646666"
        />
      </g>
    </svg>
  );
};

export default GridIcon;
