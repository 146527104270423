import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";
import { connect } from "react-redux";

const DashboardSlider = (props) => {
  let { token, tokenType } = props;
  const [state, setState] = useState([]);
  const [pathMappings, setPathMappings] = useState({});
  const history = useHistory();

  useEffect(() => {
    getSliderImg();
  }, []);

  const getSliderImg = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/WebSliderImagestest?version=1`,
        headers
      );
      if (response.data.status === 200) {
        setState(response.data.data);
        setPathMappings(createPathMappings(response.data.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createPathMappings = (data) => {
    const pathMappings = {};
    data.forEach((item) => {
      if (item.value) {
        pathMappings[item.value] = `/${item.redirect_path.toLowerCase()}`;
      }
    });
    return pathMappings;
  };

  const navigateToPath = (identifier) => {
    const path = pathMappings[identifier];
    if (path) {
      history.push(path);
    } else {
      console.error("Path not found for identifier:", identifier);
    }
  };

  const handleImageClick = (identifier) => {
    navigateToPath(identifier);
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      <div className="dashboard_slider_wrapper">
        <div className="dashboard_slider_inner_wrapper">
          <Slider {...settings}>
            {state.length > 0 ? (
              state.map((element, index) => (
                <div className="single_image_wrapper" key={index}>
                  <div
                    className="single_image_inner_wrapper"
                    style={{
                      width: "100%",
                      maxHeight: "441px",
                      borderRadius: "1rem",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={element.img}
                      alt="slider"
                      onClick={() => handleImageClick(element.value)}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="single_image_wrapper">
                <div className="single_image_inner_wrapper">
                  <img src="default-image.jpg" alt="default" />
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};
export default connect(mapStateToProps)(DashboardSlider);
