import { Fragment, useEffect, useState } from "react";
import "./privacy_policy_content_component.scss";

import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { url_34 } from "../../../../../custom_utilities/api_services";

const PrivacyPolicyContentComponent = () => {
  const [state, setState] = useState({
    pageContent: {},
  });

  useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = async () => {
    let cms_type = 2;
    try {
      const response = await axios.get(`${url_34}?cms_type=${cms_type}`);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          pageContent: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderContent = () => {
    if (!isEmpty(state.pageContent) && !isEmpty(state.pageContent.content)) {
      return { __html: state.pageContent.content.cms };
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <div className="privacyPolicyContentComponent_wrapper">
        <div className="container">
          <div className="privacyPolicyContentComponent_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="heading_wrapper">
                  <h2 className="heading_text_content">Privacy Policy:</h2>
                </div>
              </div>
            </div>
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div
                  className="content_wrapper"
                  dangerouslySetInnerHTML={renderContent()}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicyContentComponent;
