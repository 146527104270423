import { Fragment, useState, useEffect } from "react";
import "./PyqDailyTestQuestionsView.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../../../utilities/images/images";
import ShakaSolutionViewVideoPlayer from "../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import useStopwatch from "../../../hooks/useStopwatch";
import uiActions from "../../../redux/ui/action";
import { connect } from "react-redux";
import axios from "axios";
import { url_101, url_102 } from "../../../custom_utilities/api_services";
import { toastifyMessage } from "../../../custom_utilities/toastify_config";
import { ToastContainer } from "react-toastify";
import useMathjax from "../../../hooks/useMathjax";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as List1 } from "./list1.svg";
import { ReactComponent as Grid1 } from "./grid1.svg";

const PyqDailyTestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleQuestionJump,
    handleSubmitTest,
    checkQuestion,
    userData,
    viewVideo,
    handleViewVideo,
    startLoading,
    finishLoading,
    user_id,
  } = props;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isList, setisList] = useState(true);
  const [reportErrorData, setReportErrorData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: user_id,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });

  const isDisable = saveReportErrorData.option_id.length == "" ? true : false;

  const {
    stopwatchText,
    updateTime,
    updateSolution,
    getClicked,
    solDisabled,
    showSolution,
    textInputSolution,
  } = useStopwatch(
    currentQuestion,
    testQuestionsDetails[currentQuestion].integer_answer
  );

  const scrollUp = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80}`,
      behavior: "smooth",
    });
  };
  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    handleShow();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
  }, [show]);

  useMathjax(currentQuestion, lang, getClicked());
  useMathjax(currentQuestion, isList, getClicked());

  const handleSol = () => {
    setSol(true);
  };
  const handleSolution = () => {
    setSol(false);
  };
  const disableSol = () => {};
  const renderOptions = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      return questionsArr.map((val, i) => {
        let step, type;
        type = val.answer_type;
        if (type.toLowerCase() === "single correct") {
          step = 1;
        } else if (type.toLowerCase() === "integer correct") {
          step = 2;
        } else {
          step = 3;
        }
        switch (step) {
          case 1:
            return renderSingleSelect(questionsArr[i], i);
          case 2:
            return renderInputField(i);
          case 3:
            return renderMultiSelect(questionsArr[i], i);
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };
  const classEditor = (s, wc, optionId) => {
    if (!sol && s && getClicked()) {
      return "single_option_wrapper classCorrect";
    } else if (!sol && wc && wc.indexOf(optionId) > -1 && getClicked()) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const handlePurticularQuestion = () => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1]?.userAnswerCollection?.length ||
      testQuestionsDetails[currentQuestion + 1]?.isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage("Your Report has Submitted Successfully");
          setSaveReportErrorData({ option_id: "" });
        }
      });
    } catch (error) {}
  };

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  const handleInput = (event) => {
    let sanitizedValue = event.target.value.replace(/[^\d.-]/g, "");
    // Ensure there is at most one '-' at the beginning
    sanitizedValue = sanitizedValue
      .replace(/^-/, "$#$")
      .replace(/-/g, "")
      .replace("$#$", "-");
    // Ensure there is at most one '.' and only allow up to 2 decimal places
    const dotIndex = sanitizedValue.indexOf(".");
    if (dotIndex !== -1) {
      const integerPart = sanitizedValue.substring(0, dotIndex);
      const decimalPart = sanitizedValue.substring(dotIndex + 1, dotIndex + 3);
      sanitizedValue = integerPart + "." + decimalPart;
    }
    return sanitizedValue;
  };

  const renderSingleSelect = (questionData, currentQuestion2) => {
    const options = lang ? questionData?.option : questionData.option_hindi;
    // console.log("renderSingleSelect options", options);

    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {options?.length
            ? options?.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans === 1
                          ? true
                          : false
                      }
                      // checked={element.is_user_correct_ans == 1}
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    const options = lang ? questionData?.option : questionData.option_hindi;
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "multi_select_option_collection_wrapper show"
              : "multi_select_option_collection_wrapper hide"
          }
        >
          {options.length
            ? options.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              //type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion2].integer_answer}
              onChange={(event) => {
                textInputSolution(event.target.value);
                handleInputFieldChange(handleInput(event));
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox questionBox1">
            <p>{`${index + 1}.`}</p>
            <p
              className="QBox"
              dangerouslySetInnerHTML={{
                __html: lang
                  ? question.q_text
                  : question.q_text_hindi !== null
                  ? question.q_text_hindi
                  : question.q_text,
              }}
            ></p>
          </div>
          <img className="arrow" src={images.right_Ar} alt="" />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox questionBox1">
                <div className="questionBox questionBox1">
                  <p>{`${index + 1}.`}</p>
                  <p
                    className="QBox"
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} alt="" />
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
            onClick={() => {
              updateTime();
              handleQuestionJump(index);
              handleSol();
            }}
          >
            <div className="questionBox questionBox1">
              <p>{`${index + 1}.`}</p>
              <p
                className="QBox"
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
            <img className="arrow" src={images.right_Ar} alt="" />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 skip_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox questionBox1">
                <p>{`${index + 1}.`}</p>
                <p
                  className="QBox"
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} alt="" />
            </label>
          );
        }
      }
    }
  };
  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox">{`${index + 1}`}</div>
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">
                <div className="questionBox">{`${index + 1}`}</div>
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => {
              updateTime();
              handleQuestionJump(index);
              handleSol();
            }}
          >
            <div className="questionBox">{`${index + 1}`}</div>
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans === 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">{`${index + 1}`}</div>
            </label>
          );
        }
      }
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userData.user_id}"}`,
      "*"
    );
  };

  const renderFrame = (videoIdEnglish) => {
    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const buttonLabel =
      viewVideo && !lang
        ? "वीडियो समाधान बंद करें" // Close Video Solution
        : viewVideo && lang
        ? "Close Video Solution"
        : !viewVideo && !lang
        ? "वीडियो समाधान देखें" // View Video Solution
        : "View Video Solution";

    if (videoIdEnglish && urlPattern.test(videoIdEnglish)) {
      // Render iframe with selectedVideoId
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleViewVideo}>
            {buttonLabel}
          </button>
          {viewVideo && (
            <iframe
              title="videoidenglish"
              id="iframe"
              src={videoIdEnglish}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}{" "}
        </div>
      );
    } else {
      // Render ShakaSolutionViewVideoPlayer
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleViewVideo}>
            {buttonLabel}
          </button>
          {viewVideo && (
            <ShakaSolutionViewVideoPlayer
              id_video={videoIdEnglish}
              className="shakaPlayer"
            />
          )}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="Exercise_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Questions
                    </button>

                    <button
                      className="togglebtn submit"
                      onClick={() => {
                        //handleSaveQuestion();
                        handleShow();
                      }}
                    >
                      Submit Test
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="sub_sub_section_wrapper_1_card">
                        <p>
                          {currentQuestion + 1}/{testQuestionsDetails.length}
                        </p>

                        <span className="countdown_timer_container">
                          {stopwatchText}
                        </span>
                        <div className="markReview">
                          <div className="bookmark_image_wrapper">
                            {testQuestionsDetails[currentQuestion] &&
                            testQuestionsDetails[currentQuestion]
                              .isAnswerGuessed ? (
                              <img
                                alt=""
                                src={images.BookmarkBtn1}
                                className="BookmarkBtn"
                                onClick={() => handleReview()}
                              />
                            ) : (
                              <img
                                alt=""
                                src={images.BookmarkBtn}
                                className="BookmarkBtn"
                                onClick={() => handleReview()}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <ToastContainer />
                    <Modal
                      show={showError}
                      onHide={handleCloseReportError}
                      className="errorModal"
                    >
                      <Modal.Header closeButton>
                        {" "}
                        <h3>Report an Error</h3>
                      </Modal.Header>
                      <Modal.Body className="modal-body">
                        <div className="error_Popup">
                          <p className="text_content">
                            You can just write to us, If there is any incorrect
                            or error in question and given options.
                          </p>
                          <ul className="errorList">
                            {reportErrorData?.map((element, index) => {
                              return (
                                <li className="errorListItem" key={element.id}>
                                  <input
                                    type="radio"
                                    id={element.id}
                                    name="error"
                                    value={element.option_name}
                                    onChange={(event) => {
                                      setSaveReportErrorData({
                                        ...saveReportErrorData,
                                        option_id: event.target.id,
                                      });
                                    }}
                                  />{" "}
                                  <label htmlFor={element.id}>
                                    {element.option_name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="textarea_content"
                            type="text"
                            onChange={(event) =>
                              setSaveReportErrorData({
                                ...saveReportErrorData,
                                description: event.target.value,
                              })
                            }
                            placeholder="Write to Us..."
                          />{" "}
                          <br />
                          <Button
                            disabled={isDisable}
                            onClick={handlePostReportErrorData}
                          >
                            Submit
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit1} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button className="cancel" onClick={handleClose}>
                              Cancel
                            </button>
                            <button onClick={() => handleSubmitTest()}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <div className="questionWrapper">
                      <div className="sub_sub_section_wrapper_2">
                        <div className="text_content_wrapper">
                          <div className="questionBox">
                            <span className="text_content">
                              {`Question ${Number(currentQuestion) + 1}.`}
                            </span>
                            {testQuestionsDetails.length
                              ? testQuestionsDetails.map((element, index) => {
                                  return (
                                    <>
                                      <p
                                        className={
                                          index === currentQuestion && lang
                                            ? "show"
                                            : "hide"
                                        }
                                        key={element.qid + "en"}
                                        dangerouslySetInnerHTML={{
                                          __html: element.q_text,
                                        }}
                                      ></p>
                                      <p
                                        className={
                                          index === currentQuestion && !lang
                                            ? "show"
                                            : "hide"
                                        }
                                        key={element.qid + "hi"}
                                        dangerouslySetInnerHTML={{
                                          __html: element.q_text_hindi,
                                        }}
                                      ></p>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="sub_sub_section_wrapper_3">
                        {renderOptions()}
                      </div>
                      {getClicked() && (
                        <div className="solutionBoxWrapper">
                          {testQuestionsDetails[currentQuestion]
                            ?.answer_type === "Subjective" ? (
                            <>
                              <div className="QuestionSolution">
                                <h5>Solution:</h5>
                                {testQuestionsDetails.length
                                  ? testQuestionsDetails.map(
                                      (element, index) => {
                                        if (
                                          element.answer_type === "Subjective"
                                        ) {
                                          return (
                                            <>
                                              <p
                                                className={
                                                  index === currentQuestion &&
                                                  lang
                                                    ? "show"
                                                    : "hide"
                                                }
                                                key={element.qid + "en"}
                                                dangerouslySetInnerHTML={{
                                                  __html: element.solution,
                                                }}
                                              ></p>
                                              <p
                                                className={
                                                  index === currentQuestion &&
                                                  !lang
                                                    ? "show"
                                                    : "hide"
                                                }
                                                key={element.qid + "hi"}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    element.solution_hindi,
                                                }}
                                              ></p>
                                            </>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }
                                    )
                                  : null}
                              </div>

                              {testQuestionsDetails[currentQuestion]
                                ?.video_id_english?.length
                                ? renderFrame(
                                    testQuestionsDetails[currentQuestion]
                                      ?.video_id_english
                                  )
                                : ""}
                            </>
                          ) : (
                            <>
                              {
                                <>
                                  <div className="QuestionSolution">
                                    <h5>Solution:</h5>

                                    {testQuestionsDetails.length
                                      ? testQuestionsDetails.map(
                                          (element, index) => {
                                            // console.log("elementelement", element);
                                            return (
                                              <div key={element.qid}>
                                                <p
                                                  className={
                                                    index === currentQuestion &&
                                                    lang
                                                      ? "show"
                                                      : "hide"
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html: element.solution,
                                                  }}
                                                ></p>

                                                <p
                                                  className={
                                                    index === currentQuestion &&
                                                    (!lang ||
                                                      !element.solution_hindi) // Check if lang is false or Hindi solution is empty
                                                      ? "show"
                                                      : "hide"
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      !lang ||
                                                      !element.solution_hindi
                                                        ? element.solution
                                                        : element.solution_hindi,
                                                  }}
                                                ></p>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>

                                  {testQuestionsDetails[currentQuestion]
                                    ?.video_id_english?.length
                                    ? renderFrame(
                                        testQuestionsDetails[currentQuestion]
                                          ?.video_id_english
                                      )
                                    : ""}
                                </>
                              }
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          color: "#B50503",
                          fontSize: " 0.75rem",
                        }}
                      >
                        <span style={{ marginRight: "5px", cursor: "pointer" }}>
                          <img src={images.user_activity_14_1} alt=""></img>
                        </span>{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleGetReportErrorOptions}
                        >
                          Report an error
                        </span>
                      </div>
                      <div className="sub_sub_section_wrapper_5">
                        <div className="left_side_wrapper">
                          {currentQuestion > 0 && (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  updateTime();
                                  scrollUp2();
                                  handlePreviousQuestionBtn();
                                  handlePurticularQuestion();
                                  showSolution(currentQuestion - 1);
                                }}
                              >
                                Previous
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="right_side_wrapper">
                          {testQuestionsDetails.length ===
                          currentQuestion + 1 ? (
                            <div className="btn_wrapper d-flex">
                              <button
                                disabled={solDisabled}
                                className={
                                  solDisabled
                                    ? "opacity solution"
                                    : "true solution"
                                }
                                onClick={() => {
                                  updateSolution(true);
                                  handleSolution();
                                }}
                              >
                                Solution
                              </button>

                              <button
                                onClick={() => {
                                  // handleSaveQuestion();
                                  handleShow();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            <div className="btn_wrapper d-flex">
                              {testQuestionsDetails[
                                currentQuestion
                              ]?.answer_type.toLowerCase() === "subjective" ? (
                                <>
                                  <button
                                    onClick={() => {
                                      handleNextQuestionBtn();

                                      scrollUp();
                                      handlePurticularQuestion3();
                                      showSolution(currentQuestion + 1);
                                    }}
                                  >
                                    Next
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    disabled={solDisabled}
                                    className={
                                      solDisabled
                                        ? "opacity solution"
                                        : "true solution"
                                    }
                                    onClick={() => {
                                      updateSolution(true);
                                      handleSolution();
                                    }}
                                  >
                                    Solution
                                  </button>

                                  <button
                                    onClick={() => {
                                      updateTime();
                                      handleNextQuestionBtn();

                                      scrollUp();
                                      handlePurticularQuestion3();
                                      showSolution(currentQuestion + 1);
                                    }}
                                  >
                                    Next
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button
                          onClick={() => {
                            //handleSaveQuestion();
                            handleShow();
                          }}
                        >
                          Submit Test
                        </button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <List1 /> : <List />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <Grid /> : <Grid1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div class="single_marking">
                            <label class="box_pic box_pick_background_3"></label>
                            <label class="question_marking_text">Pending</label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_5"></label>
                            <label className="question_marking_text">
                              Mark For Review
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="questions_serial_number_collection">
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                >
                                  {isList ? (
                                    <>{renderQuestionLabel1(element, index)}</>
                                  ) : (
                                    <>{renderQuestionLabel(element, index)}</>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PyqDailyTestQuestionsView);
