import { React, useState } from "react";
import OtsQuestionPaperSolutionContent from "./OtsQuestionPaperSolutionContent";

import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import {
  url_135,
  url_136,
} from "../../../../../../../../custom_utilities/api_services";
const OtsQuestionPaperSolution = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    testPaperResultData,
    token,
    tokenType,
    userData,
  } = props;
  const [state, setState] = useState({
    testQuestionsDetails: [],
    testQuestionsDetails1: {},
    currentQuestion: 0,
    currentSolutionDetails: {},
  });
  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    startLoading();
    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
      test_id: testPaperResultData?.PaperData?.testid,
    };
    try {
      const response = await axios.post(url_135, data, { headers });
      console.log("getQuestionListots", response.data);
      if (response.data.status === 200) {
        getCurrentSolutionDetails(
          testPaperResultData?.PaperData?.paper_id,
          response.data.data[state.currentQuestion]?.qid,
          testPaperResultData?.PaperData?.testid
        );

        setState((preState) => ({
          ...preState,
          testQuestionsDetails: response.data.data,
          testQuestionsDetails1: response.data,
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const getCurrentSolutionDetails = async (paper_id, qid, test_id) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      paper_id,
      qid,
      test_id,
    };

    startLoading();

    try {
      const response = await axios.post(url_136, data, { headers });

      if (response.data.status === 200) {
        finishLoading();

        setState((prevState) => ({
          ...prevState,
          currentSolutionDetails: response.data.data[0],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //nextBtn
  const handleNextQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    getCurrentSolutionDetails(
      testPaperResultData?.PaperData?.paper_id,
      qid,
      testPaperResultData?.PaperData?.testid
    );
    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
    }));
    window.scrollTo(0, 0);
  };
  //previous btn
  const handlePreviousQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    getCurrentSolutionDetails(
      testPaperResultData?.PaperData?.paper_id,
      qid,
      testPaperResultData?.PaperData?.testid
    );
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));

      window.scrollTo(0, 0);
    }
  };
  //handleJump
  const handleQuestionJump = (questionNumber) => {
    const { paper_id, qid } = state.testQuestionsDetails[questionNumber];

    getCurrentSolutionDetails(
      testPaperResultData?.PaperData?.paper_id,
      qid,
      testPaperResultData?.PaperData?.testid
    );

    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };
  return (
    <div>
      <OtsQuestionPaperSolutionContent
        testQuestionsDetails={state.testQuestionsDetails}
        testQuestionsDetails1={state.testQuestionsDetails1}
        currentQuestion={state.currentQuestion}
        currentSolutionDetails={state.currentSolutionDetails}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        handleQuestionJump={handleQuestionJump}
        userData={userData}
        testPaperResultData={testPaperResultData}
      />
      {loader && <LoaderPopUp />}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    testPaperResultData: state?.otsTestsData?.otsPaperResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsQuestionPaperSolution);
