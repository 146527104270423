import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import { setOrderId } from "../../../../../../../redux/payment_gateway/action";
import "./CcAvenueHandbook.scss";
import { url_147 } from "../../../../../../../custom_utilities/api_services";
import EmailUpdateModal from "../../../../../user_edit_profile/user_edit_profile_content_component/update_email/EmailUpdateModal";

const CcAvenueHandbook = (props) => {
  const {
    packageDetails,
    handleBookDetails,
    amount,
    token,
    tokenType,
    paymentAction,
    saveTransactionAction,
    setCcAvenueDataInStore,
    userData,
    buttonText,
    buttonClass,
    is_amrit,
    finalAmount
  } = props;

  const [emailStatus, setEmailStatus] = useState({
    showEmailModal: false,
    emailUpdated: false,
  });

  const [ccAvenueData, setCcAvenueData] = useState({});

  useEffect(() => {
    getCcAvenueData();
  }, []);

  const getCcAvenueData = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(
        `${"https://learning.motion.ac.in/motioneducation/api/v1/handbook_transaction"}?mode=web&price=${
          handleBookDetails?.price
        }&
    
      discount=${handleBookDetails?.discount || 0}&
      coupon_discount=${handleBookDetails?.coupon_discount || 0}&
      shipping_charge=${handleBookDetails?.shipping_charge || 0}&
      total_price=${
        handleBookDetails?.total_price || handleBookDetails?.discounted_price
      }&
      coins_count=${
        handleBookDetails?.coins_count || 0
      }&user_id=${user_id}&from_cart=true`,
        { headers }
      );

      console.log('response.data.state-ccHandbookPage', response.data);

      if (response.data.status === 200) {
        setCcAvenueData(response.data);
        paymentAction(response.data.Order_Id);
        // setCcAvenueDataInStore(response.data);
        // saveTransactionAction({
        //   course_id: userData.targets[0].course_id,
        //   batch_id,
        //   package_id: packageDetails?.package_id
        //     ? packageDetails?.package_id
        //     : "",
        //   txn_amount: amount,
        //   txn_discount_amount: packageDetails?.discount
        //     ? packageDetails.discount.amount
        //     : "",
        //   txn_date: dateTime,
        //   package_duration: packageDetails?.duration
        //     ? packageDetails.duration
        //     : "",
        //   txnid: response.data.Order_Id,
        // });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setEmailStatus((prevState) => ({
      ...prevState,
      showEmailModal: true,
    }));
  };

  const handleClose = () => {
    setEmailStatus((prevState) => ({
      ...prevState,
      showEmailModal: false,
    }));
  };

  const {
    name,
    mobile,
    email,
    address,
    batch_id,
    user_id,
    UsertargetDetail,
  } = userData;

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;

  const handleProfileData = (e) => {
    if (is_amrit || packageDetails.is_subscribe) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      const fieldsToCheck = [
        {
          field: name,
          label: "name",
        },
        {
          field: mobile,
          label: "mobile number",
        },
        {
          field: email,
          label: "email",
        },
        {
          field: address.state,
          label: "state",
        },
        {
          field: address.city,
          label: "city",
        },
      ];

      const updatedfields = fieldsToCheck.filter(
        (element) => element.field === "" || element.field === null
      );

      if (updatedfields.length > 0) {
        let missingFields = updatedfields.map((item) => item.label).join(", ");

        if (missingFields.includes("email")) {
          handleOpen();
        }
      }
      window.onbeforeunload = null;
    }
  };

  const ccAvenueFunction = () => {
    return (
      <div id="ccav-payment-form">
        <form
          name="ccavenuePaymentForm"
          method="post"
          action="https://learning.motion.ac.in/motioneducation/api/v1/get/get_cca_request_handlerweb"
        >
          <input type="hidden" name="language" value="EN" />
          <input type="hidden" name="currency" value="INR" />

          <input
            type="hidden"
            name="merchant_id"
            value={ccAvenueData.Merchant_ID}
          />
          <input
            type="hidden"
            name="redirect_url"
            value={`${
              ccAvenueData.Redirect_Url
            }?page=${"https://webtesting.motion.ac.in/handbook_payment_gateway"}`}

            // value={"http://localhost:3000/handbook_payment_gateway"}
            // value="https://webtesting.motion.ac.in/handbook_payment_gateway"
          />
          <input
            type="hidden"
            name="cancel_url"
            value={`${
              ccAvenueData?.Redirect_Url
            }?page=${"https://webtesting.motion.ac.in/handbook_payment_gateway"}`}

            // value={`${
            //   ccAvenueData.Redirect_Url
            // }?page=${"https://webtesting.motion.ac.in/payment_gateway"}`}f

            // value={`${

            // }?page=${"https://learning.motion.ac.in/handbook_payment_gateway"}`}

            // value={"https://learning.motion.ac.in/handbook_payment_gateway"}
            // value={"http://localhost:3000/handbook_payment_gateway"}
            // value="https://webtesting.motion.ac.in/handbook_payment_gateway"

            // value={`${
            //   ccAvenueData.Redirect_Url
            // }?page=${"http://localhost:3001/payment_gateway"}`}
          />

          <input type="hidden" name="order_id" value={ccAvenueData.Order_Id} />
          <input
            type="hidden"
            name="access_code"
            value={ccAvenueData.Access_code}
          />
          <input
            type="hidden"
            name="rsa_key_url"
            value={ccAvenueData.RSA_Url}
          />

          <input type="hidden" name="txnId" value={ccAvenueData.Order_Id} />

          <input type="hidden" name="amount" value={finalAmount} />

          <input
            type="hidden"
            name="discountAmount"
            value={handleBookDetails?.discount || 0}
          />
         
        
          <input type="hidden" name="userId" value={user_id} />

          <input type="hidden" name="billing_name" value={name} />
          <input type="hidden" name="billing_tel" value={mobile} />
          <input type="hidden" name="billing_email" value={email} />
          <input
            type="hidden"
            name="billing_address"
            value={address.address_type}
          />

          <input type="hidden" name="duration" value={""} />
          <input
            type="hidden"
            name="billing_city"
            value={address.city ? address.city : ""}
          />
          <input
            type="hidden"
            name="billing_state"
            value={address.state ? address.state : ""}
          />

          <input type="hidden" name="batchId" value={batch_id} />
          <input type="hidden" name="userId" value={user_id} />
          <input
            type="hidden"
            name="merchant_param2"
            value={'MLA'}
          />
            <input
              type="hidden"
              name="merchant_param1"
              value={userData?.class}
          />

<input
              type="hidden"
              name="merchant_param4"
              value={""}
          />

<input
              type="hidden"
              name="merchant_param3"
              value={""}
          />
          
          <input
              type="hidden"
              name="packageAmt"
              value={handleBookDetails?.price}
          />
          
          <input
              type="hidden"
              name="courseId"
              value={""}
          />
          
          <input type="hidden" name="finalAmount" value={finalAmount} />


          <button type="submit" className="store_paynow">
            {buttonText}
          </button>
        </form>.
      </div>
    );
  };

  const ccAvenueFunction1 = () => {
    return (
      <button
        type="submit"
        className="store_paynow"
        onClick={handleProfileData}
      >
        Proceed to Pay
      </button>
    );
  };

  return (
    <>

      {Object.keys(ccAvenueData).length &&
        (email.length ? ccAvenueFunction() : ccAvenueFunction1())}

      {emailStatus?.showEmailModal && (
        <EmailUpdateModal
          isEmailModalOpen={emailStatus.showEmailModal}
          closeEmailModal={handleClose}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    userData: state.auth.user,
    user_id: state.auth.user.user_id,
    is_amrit: state.auth.user.is_amrit,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    paymentAction: (payload) => {
      dispatch(setOrderId(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(CcAvenueHandbook);
