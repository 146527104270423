import React from "react";
import { useHistory } from "react-router-dom";
import images from "../../../../../../../utilities/images/images";
import "./HandbookAddress.scss";
import useGetHandbookAddress from "../hooks/address/useGetHandbookAddress";

const HandbookAddress = () => {
  const history = useHistory();

  const { address } = useGetHandbookAddress();

  const handleRedirectAddressForm = () => {
    history.push("/store/address-form");
  };

  return (
    <div className="user_address">
      <div className="address_left">
        <img src={images.locationIcon} alt="location" />

        <div className="address_wrapper">
          <div className="student_name_pincode">
            <div className="student_name">{address?.fullname}</div>

            <div className="pincode">{address?.pincode}</div>
          </div>
          <div className="address_detail">
            {address?.address}, {address?.city}
          </div>
        </div>
      </div>

      <div className="address_right" onClick={handleRedirectAddressForm}>
        Change
      </div>
    </div>
  );
};

export default HandbookAddress;
