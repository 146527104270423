// import React, { Fragment,useEffect,useState } from "react";
// import "./homepage.scss";
// import Login from "../login/login";
// import images from "../../utilities/images/images";
// import { Link, useHistory } from "react-router-dom";

// //slick-carousel
// import Slider from "react-slick";
// import { ToggleButton } from "react-bootstrap";
// import { connect } from "react-redux";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block" }}
//       onClick={onClick}
//     />
//   );
// }

// const Homepage = ({ isAuthenticated }) => {
//   const settings1 = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     nextArrow: <SampleNextArrow className="slick-next" />,
//     prevArrow: <SamplePrevArrow className="slick-prev" />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           // infinite: true,
//           // dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           // initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//     ],
//   };
//   const [state, setState] = useState(true);
//   const ToggleButton =()=>{}
//   const history = useHistory()

//   useEffect(()=> {
//     if(isAuthenticated){
//       history.push('/user_dashboard')
//     }
//   }, [])

//   return (
//     <Fragment>
//       <div className="homepage_wrapper">
//         <div className="homepage_inner_wrapper">
//           <section className="sec_1_wrapper" id="home_sec">
//             <div className="container sec1_inr_wrap">
//               <header className="header">
//                 <div className="logo_dv">
//                   <img src={images.m2_logo} alt="logo" />
//                 </div>
//                 <div className={state ? "target_link" : "target_link show"}>
//                   <button className="toggle2" onClick={() => setState(!state)}>X</button>
//                   <a href="#our_course">Our Courses</a>
//                   <a href="#howitwork">How It works</a>
//                   {/* <a href="#meetOurExprt">Meet our experts</a> */}
//                   <a href="#ourBenefits">Our Benefits</a>
//                   <a href="#footer">Contact Us</a>
//                   {/* <Link to="/contact_us">Contact Us</Link> */}
//                 </div>
//                 <button className="toggle" onClick={()=>setState(!state)}>=</button>
//               </header>
//               <div className="login_register_wrapr">
//                 <div className="register_part">
//                   <h1>Learn & prepare for competitive exams with motion</h1>
//                   <p>
//                     The largest online learning platform that helps you prepare
//                     for competitive exams like JEE, NEET & various Olympiads.
//                   </p>
//                   <div className="reg_plstr_btn">
//                     <Link to="/signUp2">
//                       <button className="register_btn">Register Now</button>
//                     </Link>
//                     <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.elearning.motion"}} target="_blank">
//                     <button className="playStore_btn">
//                       <img src={images.m2_googlePlay} alt="" /> Play Store
//                       </button>
//                       </Link>
//                   </div>
//                 </div>
//                 <div className="login_part">
//                   <Login />
//                 </div>
//               </div>
//             </div>
//           </section>
//           <section className="sec_2_wrapper" id="our_course">
//             <div className="container sec_2_inr_wrap">
//               <div className="section_ttl">
//                 <h2 className="h2-heading-ttl">Our Courses</h2>
//                 <p className="p-desc">
//                   Motion offers you an unmatched experience by combining the
//                   best teaching methodologies with powerful visualization.
//                 </p>
//               </div>
//               <div className="our_course_list">
//                 <Slider {...settings1}>
//                   <div className="course_box">
//                     <div className="crs_img">
//                       <img src={images.m2_jee} alt="jee" />
//                     </div>
//                     <h4>JEE</h4>
//                     <p>
//                       We provide the right preparation strategy to ace one of
//                       the toughest exam JEE.
//                     </p>
//                   </div>
//                   <div className="course_box">
//                     <div className="crs_img">
//                       <img src={images.m2_neet} alt="neet" />
//                     </div>
//                     <h4>NEET</h4>
//                     <p>
//                       Level up your NEET preparation and crack a seat at the
//                       best medical college.
//                     </p>
//                   </div>
//                   <div className="course_box">
//                     <div className="crs_img">
//                       <img src={images.m2_foundation} alt="foundation" />
//                     </div>
//                     <h4>Foundation</h4>
//                     <p>
//                       Foster creativity, scientific-thinking & competitive
//                       temperament to make student’s foundation strong
//                     </p>
//                   </div>
//                   {/* <div className="course_box">
//                     <div className="crs_img">
//                       <img src={images.m2_jee} alt="jee" />
//                     </div>
//                     <h4>JEE</h4>
//                     <p>
//                       We provide the right preparation strategy to ace one of
//                       the toughest exam JEE.
//                     </p>
//                   </div> */}
//                 </Slider>
//               </div>
//             </div>
//           </section>
//           <section className="sec_3_wrapper" id="howitwork">
//             <div className="container sec_3_inr_wrap">
//               <div className="section_ttl">
//                 <h2 className="h2-heading-ttl">How It Works</h2>
//                 <p className="p-desc">
//                   Learning made easy with features that will help students to
//                   analysis their concepts and excel in competitive exams
//                 </p>
//               </div>
//               <div className="work_type_list">
//                 <Slider {...settings1}>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box">
//                       <div className="img_wrp">
//                         <img src={images.m2_lvCls} alt="work" />
//                       </div>
//                       <h4>Live classes</h4>
//                       <p>
//                         Live classes and recorded video lectures Interactive
//                         live classes and doubt solving sessions by Kota's top
//                         faculties
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box">
//                       <div className="img_wrp">
//                         <img src={images.m2_tstAnls} alt="work2" />
//                       </div>
//                       <h4>Test and Analysis</h4>
//                       <p>
//                         Highly curated tests to simulate the real testing
//                         environment and deep analytics to evaluate the
//                         performance
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box">
//                       <div className="img_wrp">
//                         <img src={images.m2_ulmtDts} alt="work3" />
//                       </div>
//                       <h4>Unlimited Doubts</h4>
//                       <p>
//                         Ask unlimited doubts anytime and get detailed solutions
//                         from our experienced team
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box">
//                       <div className="img_wrp">
//                         <img src={images.m2_lvCls} alt="work" />
//                       </div>
//                       <h4>Live classes</h4>
//                       <p>
//                         Live classes and recorded video lectures Interactive
//                         live classes and doubt solving sessions by Kota's top
//                         faculties
//                       </p>
//                     </div>
//                   </div>
//                 </Slider>
//               </div>
//             </div>
//           </section>
//           <section className="sec_4_wrapper">
//             <div className="container sec_4_inr_wrap">
//               <div className="sec4_left"></div>
//               <div className="sec4_right">
//                 <h2>Download Motion Learning App</h2>
//                 <p>
//                   Prepare for India’s most prestigious exams from anytime
//                   anywhere!
//                 </p>
//                 <p>
//                   Ample amount of Subject wise & topic wise practice questions
//                   to master the subjects.
//                 </p>
//                 <p>Doubt Support to strengthen your topics for the exam.</p>
//                 <p>Test Series by Top faculty.</p>
//                 <p>
//                   Best Study Material to improve understanding and retention of
//                   concepts.
//                 </p>
//                 <div className="play_store_btn">
//                   <a
//                     href="https://play.google.com/store/apps/details?id=com.elearning.motion&ah=DL-YDHRYctvb_-s_Cjcw7O4FWnA"
//                     target="_blank"
//                   >
//                     <img src={images.homepage_9} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </section>
//           {/* <section className="sec_3_wrapper sec_5_wrapper" id="meetOurExprt">
//             <div className="container sec_3_inr_wrap">
//               <div className="section_ttl">
//                 <h2 className="h2-heading-ttl">Meet our experts</h2>
//                 <p className="p-desc">
//                   MOTION is equipped with an experienced faculty pool with
//                   proven results in the past. Their in-depth subject knowledge
//                   and superb teaching techniques have helped several IIT and
//                   NEET aspirants achieve their goals.
//                 </p>
//               </div>
//               <div className="work_type_list">
//                 <Slider {...settings1}>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box expert_prf_bx">
//                       <div className="img_wrp">
//                         <img src={images.m2_lvCls} alt="work" />
//                       </div>
//                       <h4>Harshit Thakuria</h4>
//                       <p>
//                         <span>MPSI, Madhya Pradesh</span> 14+ years of
//                         experience in teaching
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box expert_prf_bx">
//                       <div className="img_wrp">
//                         <img src={images.m2_tstAnls} alt="work2" />
//                       </div>
//                       <h4>Nitin Vijay</h4>
//                       <p>
//                         <span>MPSI, Madhya Pradesh</span> 19+ years of
//                         experience in teaching
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box expert_prf_bx">
//                       <div className="img_wrp">
//                         <img src={images.m2_ulmtDts} alt="work3" />
//                       </div>
//                       <h4>Seema Singh</h4>
//                       <p>
//                         <span>MPSI, Madhya Pradesh</span> 6+ years of experience
//                         in teaching
//                       </p>
//                     </div>
//                   </div>
//                   <div className="work_box_wrp">
//                     <div className="work_type_box expert_prf_bx">
//                       <div className="img_wrp">
//                         <img src={images.m2_lvCls} alt="work" />
//                       </div>
//                       <h4>Kabir Kapoor</h4>
//                       <p>
//                         <span>MPSI, Madhya Pradesh</span> 4+ years of experience
//                         in teaching
//                       </p>
//                     </div>
//                   </div>
//                 </Slider>
//               </div>
//             </div>
//           </section> */}
//           <section className="sec_6_wrapper" id="ourBenefits" style={{paddingTop:"50px"}}>
//             <div className="container sec_6_inner_wrapper">
//               <div className="content_wrapper">
//                 <div className="text_content_wrapper_1">
//                   <h2 className="h2-heading">Additional Benefits</h2>
//                 </div>
//                 <div className="sub_content_wrapper_1">
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_live} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Live Classes</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           Our interactive classes are designed with proficiency
//                           so that no extra effort is needed to achieve your
//                           desired success in JEE/NEET/Foundation.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_dpp} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Exercise and DPP</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           Practice content (exercise) is designed to provide
//                           sufficient practice on a particular topic along with
//                           previous year questions. Daily Practice Problems (DPP)
//                           are provided lecture wise to ensure proper practice
//                           and to provide concept recall.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_video} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Videos</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           Premium quality video lectures recorded by the most
//                           experienced faculties of MOTION and provides the
//                           required edge over others to elevate your chances of
//                           clearing the competitive exams.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_tests} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Tests</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           Focuses on boosting confidence of students for facing
//                           national level competitive exams by providing subject
//                           wise and topic wise questions with solutions.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_notes} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Notes</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           Study Material designed by expert faculty to improve
//                           understanding and enhance students concepts knowledge.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="single_card_wrapper">
//                     <div className="single_card_inner_wrapper">
//                       <div className="image_container">
//                         <img src={images.m2_testSeries} />
//                       </div>
//                       <div className="text_content_wrapper_1">
//                         <p className="text_content_1">Test Series</p>
//                       </div>
//                       <div className="text_content_wrapper_2">
//                         <p className="text_content_2">
//                           OTS is designed according to the exam pattern that
//                           stimulate the actual examination environment and
//                           provides an opportunity to revise every concept
//                           thoroughly.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <footer id="footer">
//             <div className="container sec7_wrapper">
//               <div className="logo">
//                 <img src={images.m2_logo} alt="logo" />
//               </div>
//               <div className="about_contact width20">
//                 <h5>Motion</h5>
//                 <Link to="" className="a_link">
//                   <span>About us</span>
//                 </Link>
//                 <Link to="" className="a_link">
//                   <span> Privacy policy</span>
//                 </Link>
//                 <Link to="" className="a_link">
//                   <span>Term and conditions</span>
//                 </Link>
//               </div>
//               <div className="about_contact">
//                 <h5>Get in touch</h5>
//                 <Link to="" className="a_link">
//                   <i class="fas fa-phone-volume phn"></i>
//                   <span>+91 9116126261</span>
//                 </Link>
//                 <Link to="" className="a_link">
//                   <i class="fas fa-envelope"></i>
//                   <span>info@motionittjee.com</span>
//                 </Link>
//                 <Link to="" className="a_link">
//                   <i class="fas fa-map-marker-alt"></i>
//                   <span> 394, Rajeev Gandhi Nagar, Kota-324005</span>
//                 </Link>
//               </div>
//               <div className="contact_form about_contact">
//                 <form>
//                   <h5>Contact Us</h5>
//                   <div className="input_wrapper">
//                     <input type="text" placeholder="Your name" />
//                   </div>
//                   <div className="input_wrapper">
//                     <input type="email" placeholder="Share your Email id" />
//                   </div>
//                   <div className="input_wrapper">
//                     <input type="text" placeholder="Mobile Number" />
//                   </div>
//                   <div className="btn_wrapper">
//                     <button type="submit">Send</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div className="copyright">
//               Copyright © 2019-2020 motion. All rights reserved.
//             </div>
//             <a className="upArrow" href="#home_sec">
//               <img src={images.m2_upArrow} alt="arrow" />
//             </a>
//           </footer>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.auth.isAuthenticated,
//   };
// };

// export default connect(mapStateToProps)(Homepage);
// // export default Homepage;

import React, { Fragment, useState } from "react";
import "./homepage.scss";
import Login from "../login/login";
import images from "../../utilities/images/images";
import { Link } from "react-router-dom";
import { checkXSSAttackThroughInput } from "../../custom_utilities/custom_useful_functions";

//slick-carousel
import Slider from "react-slick";
import { ToggleButton } from "react-bootstrap";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Homepage = () => {
  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="slick-next" />,
    prevArrow: <SamplePrevArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const [state, setState] = useState(true);
  const [inputValues, setInputValues] = useState({
    name: "",
    mail: "",
    mobile_number: "",
  });

  
  const ToggleButton = () => {};

  const handleInputChange = async (event) => {
    const { name, value } = event.target;

    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      // setInputValues({ ...inputValues, [name]: value });

      setInputValues((prevState) => ({
        ...prevState.inputValues,
        [name]: value,
      }));
    }

    // if (value.includes("script")) {
    //   alert("Script-related content not allowed!");
    //   return;
    // }
  };

  return (
    <Fragment>
      <div className="homepage_wrapper">
        <div className="homepage_inner_wrapper">
        <header className="header">
                <div className="logo_dv">
                  <img src={images.m2_logo} alt="logo" />
                </div>
                <div className={state ? "target_link" : "target_link show"}>
                  <button className="toggle2" onClick={() => setState(!state)}>
                    X
                  </button>
                  <a href="#our_course">Our Courses</a>
                  <a href="#howitwork">How It works</a>
                  {/* <a href="#meetOurExprt">Meet our experts</a> */}
                  <a href="#ourBenefits">Our Benefits</a>
                  <a href="#footer">Contact Us</a>
                  {/* <Link to="/contact_us">Contact Us</Link> */}
                </div>
                <button className="toggle" onClick={() => setState(!state)}>
                  =
                </button>
              </header>
          <section className="sec_1_wrapper" id="home_sec">
            <div className="container sec1_inr_wrap">
             
              {/* <div className="login_register_wrapr">
                <div className="register_part">
                  <h1>Learn & prepare for competitive exams with motion</h1>
                  <p>
                    The largest online learning platform that helps you prepare
                    for competitive exams like JEE, NEET & various Olympiads.
                  </p>
                  <div className="reg_plstr_btn">
                    <Link to="/signUp2">
                      <button className="register_btn">Register Now</button>
                    </Link>
                    <Link
                      to={{
                        pathname:
                          "https://play.google.com/store/apps/details?id=com.elearning.motion",
                      }}
                      target="_blank"
                    >
                      <button className="playStore_btn">
                        <img src={images.m2_googlePlay} alt="" /> Play Store
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="login_part">
                  <Login />
                </div>
              </div> */}
            </div>
          </section>
          <section className="sec_2_wrapper" id="our_course">
            <div className="container sec_2_inr_wrap">
              <div className="section_ttl">
                <h2 className="h2-heading-ttl">Our Courses</h2>
                <p className="p-desc">
                  Motion offers you an unmatched experience by combining the
                  best teaching methodologies with powerful visualization.
                </p>
              </div>
              <div className="our_course_list">
                <Slider {...settings1}>
                  <div className="course_box">
                    <div className="crs_img">
                      <img src={images.m2_jee} alt="jee" />
                    </div>
                    <h4>JEE</h4>
                    <p>
                      We provide the right preparation strategy to ace one of
                      the toughest exam JEE.
                    </p>
                  </div>
                  <div className="course_box">
                    <div className="crs_img">
                      <img src={images.m2_neet} alt="neet" />
                    </div>
                    <h4>NEET</h4>
                    <p>
                      Level up your NEET preparation and crack a seat at the
                      best medical college.
                    </p>
                  </div>
                  <div className="course_box">
                    <div className="crs_img">
                      <img src={images.m2_foundation} alt="foundation" />
                    </div>
                    <h4>Foundation</h4>
                    <p>
                      Foster creativity, scientific-thinking & competitive
                      temperament to make student’s foundation strong
                    </p>
                  </div>
                  {/* <div className="course_box">
                    <div className="crs_img">
                      <img src={images.m2_jee} alt="jee" />
                    </div>
                    <h4>JEE</h4>
                    <p>
                      We provide the right preparation strategy to ace one of
                      the toughest exam JEE.
                    </p>
                  </div> */}
                </Slider>
              </div>
            </div>
          </section>
          <section className="sec_3_wrapper" id="howitwork">
            <div className="container sec_3_inr_wrap">
              <div className="section_ttl">
                <h2 className="h2-heading-ttl">How It Works</h2>
                <p className="p-desc">
                  Learning made easy with features that will help students to
                  analysis their concepts and excel in competitive exams
                </p>
              </div>
              <div className="work_type_list">
                <Slider {...settings1}>
                  <div className="work_box_wrp">
                    <div className="work_type_box">
                      <div className="img_wrp">
                        <img src={images.m2_lvCls} alt="work" />
                      </div>
                      <h4>Live classes</h4>
                      <p>
                        Live classes and recorded video lectures Interactive
                        live classes and doubt solving sessions by Kota's top
                        faculties
                      </p>
                    </div>
                  </div>
                  <div className="work_box_wrp">
                    <div className="work_type_box">
                      <div className="img_wrp">
                        <img src={images.m2_tstAnls} alt="work2" />
                      </div>
                      <h4>Test and Analysis</h4>
                      <p>
                        Highly curated tests to simulate the real testing
                        environment and deep analytics to evaluate the
                        performance
                      </p>
                    </div>
                  </div>
                  <div className="work_box_wrp">
                    <div className="work_type_box">
                      <div className="img_wrp">
                        <img src={images.m2_ulmtDts} alt="work3" />
                      </div>
                      <h4>Unlimited Doubts</h4>
                      <p>
                        Ask unlimited doubts anytime and get detailed solutions
                        from our experienced team
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          <section className="sec_4_wrapper">
            <div className="container sec_4_inr_wrap">
              <div className="sec4_left"></div>
              <div className="sec4_right">
                <h2>Download Motion Learning App</h2>
                <p>
                  Prepare for India’s most prestigious exams from anytime
                  anywhere!
                </p>
                <p>
                  Ample amount of Subject wise & topic wise practice questions
                  to master the subjects.
                </p>
                <p>Doubt Support to strengthen your topics for the exam.</p>
                <p>Test Series by Top faculty.</p>
                <p>
                  Best Study Material to improve understanding and retention of
                  concepts.
                </p>
                <div className="play_store_btn">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.elearning.motion&ah=DL-YDHRYctvb_-s_Cjcw7O4FWnA"
                    target="_blank" rel="noreferrer"
                  >
                    <img src={images.homepage_9} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="sec_3_wrapper sec_5_wrapper" id="meetOurExprt">
            <div className="container sec_3_inr_wrap">
              <div className="section_ttl">
                <h2 className="h2-heading-ttl">Meet our experts</h2>
                <p className="p-desc">
                  MOTION is equipped with an experienced faculty pool with
                  proven results in the past. Their in-depth subject knowledge
                  and superb teaching techniques have helped several IIT and
                  NEET aspirants achieve their goals.
                </p>
              </div>
              <div className="work_type_list">
                <Slider {...settings1}>
                  <div className="work_box_wrp">
                    <div className="work_type_box expert_prf_bx">
                      <div className="img_wrp">
                        <img src={images.m2_lvCls} alt="work" />
                      </div>
                      <h4>Harshit Thakuria</h4>
                      <p>
                        <span>MPSI, Madhya Pradesh</span> 14+ years of
                        experience in teaching
                      </p>
                    </div>
                  </div>
                  <div className="work_box_wrp">
                    <div className="work_type_box expert_prf_bx">
                      <div className="img_wrp">
                        <img src={images.m2_tstAnls} alt="work2" />
                      </div>
                      <h4>Nitin Vijay</h4>
                      <p>
                        <span>MPSI, Madhya Pradesh</span> 19+ years of
                        experience in teaching
                      </p>
                    </div>
                  </div>
                  <div className="work_box_wrp">
                    <div className="work_type_box expert_prf_bx">
                      <div className="img_wrp">
                        <img src={images.m2_ulmtDts} alt="work3" />
                      </div>
                      <h4>Seema Singh</h4>
                      <p>
                        <span>MPSI, Madhya Pradesh</span> 6+ years of experience
                        in teaching
                      </p>
                    </div>
                  </div>
                  <div className="work_box_wrp">
                    <div className="work_type_box expert_prf_bx">
                      <div className="img_wrp">
                        <img src={images.m2_lvCls} alt="work" />
                      </div>
                      <h4>Kabir Kapoor</h4>
                      <p>
                        <span>MPSI, Madhya Pradesh</span> 4+ years of experience
                        in teaching
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section> */}
          <section
            className="sec_6_wrapper"
            id="ourBenefits"
            style={{ paddingTop: "50px" }}
          >
            <div className="container sec_6_inner_wrapper">
              <div className="content_wrapper">
                <div className="text_content_wrapper_1">
                  <h2 className="h2-heading">Additional Benefits</h2>
                </div>
                <div className="sub_content_wrapper_1">
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_live} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Live Classes</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Our interactive classes are designed with proficiency
                          so that no extra effort is needed to achieve your
                          desired success in JEE/NEET/Foundation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_dpp} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Exercise and DPP</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Practice content (exercise) is designed to provide
                          sufficient practice on a particular topic along with
                          previous year questions. Daily Practice Problems (DPP)
                          are provided lecture wise to ensure proper practice
                          and to provide concept recall.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_video} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Videos</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Premium quality video lectures recorded by the most
                          experienced faculties of MOTION and provides the
                          required edge over others to elevate your chances of
                          clearing the competitive exams.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_tests} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Tests</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Focuses on boosting confidence of students for facing
                          national level competitive exams by providing subject
                          wise and topic wise questions with solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_notes} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Notes</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Study Material designed by expert faculty to improve
                          understanding and enhance students concepts knowledge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.m2_testSeries} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Test Series</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          OTS is designed according to the exam pattern that
                          stimulate the actual examination environment and
                          provides an opportunity to revise every concept
                          thoroughly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer id="footer">
            <div className="container sec7_wrapper">
              <div className="logo">
                <img src={images.m2_logo} alt="logo" />
              </div>
              <div className="about_contact width20">
                <h5>Motion</h5>
                <Link to="" className="a_link">
                  <span>About us</span>
                </Link>
                <Link to="" className="a_link">
                  <span> Privacy policy</span>
                </Link>
                <Link to="" className="a_link">
                  <span>Term and conditions</span>
                </Link>
              </div>
              <div className="about_contact">
                <h5>Get in touch</h5>
                <Link to="" className="a_link">
                  <i class="fas fa-phone-volume phn"></i>
                  <span>+91 8003899585</span>
                </Link>
                <Link to="" className="a_link">
                  <i class="fas fa-envelope"></i>
                  <span>info@motioniitjee.com</span>
                </Link>
                <Link to="" className="a_link">
                  <i class="fas fa-map-marker-alt"></i>
                  <span> 394, Rajeev Gandhi Nagar, Kota-324005</span>
                </Link>
              </div>
              <div className="contact_form about_contact">
                <form>
                  <h5>Contact Us</h5>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      placeholder="Your name"
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="email"
                      placeholder="Share your Email id"
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      placeholder="Mobile Number"
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </div>
                  <div className="btn_wrapper">
                    <button type="submit">Send</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="copyright">
              Copyright © 2019-2020 motion. All rights reserved.
            </div>
            <a className="upArrow" href="#home_sec">
              <img src={images.m2_upArrow} alt="arrow" />
            </a>
          </footer>
        </div>
      </div>
    </Fragment>
  );
};

export default Homepage;
