import { useState } from "react";
import { Modal } from "react-bootstrap";

const useReportAndError = () => {
  const [showModal, setShowModal] = useState(false);

  console.log("showModal", showModal);

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const ReactAndErrorText = () => {
    return <div onClick={handleToggleModal}>Report And Error</div>;
  };

  const ReportAndErrorModal = () => {
    return (
      <Modal show={true} onHide={handleToggleModal}>
        <Modal.Header closeButton>Report and Error</Modal.Header>
        <Modal.Body>I am the Report and Error Modal</Modal.Body>
      </Modal>
    );
  };

  return { ReactAndErrorText, ReportAndErrorModal, showModal };
};

export default useReportAndError;
