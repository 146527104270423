import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { url_248 } from "../../../../../../../../custom_utilities/api_services";
import useAuthUtils from "../../../../../../../../hooks/auth/useAuthUtils";

const useGetCartProducts = () => {
  const { headers, startLoading, finishLoading } = useAuthUtils();

  const getProducts = async () => {
    try {
      startLoading();

      const response = await axios.get(url_248, headers);

      console.log("response.data", response.data.data);
      finishLoading();

      return response.data;
    } catch (err) {
      finishLoading();

      console.log(err.message);
    }
  };

  const { data } = useQuery({
    queryKey: ["cart_products"],
    queryFn: getProducts,
  });

  return {
    cartProducts: data?.data,
    shippingCharge: data?.shipping_charge,
    productsPrice: data?.product_price,
    productsDiscountPrice: data?.discount_price,
    eligibleMotionCoins: data?.eligible_coins,
    motionCoins: data?.motion_coins,
    finalPrice: data?.product_price - data?.discount_price,
  };
};

export default useGetCartProducts;
