import React from "react";
import { Modal } from "react-bootstrap";
import "./CreateDoubtModal.scss";

const CreateDoubtModal = ({
  show,
  handleClose,
  Usercreatedoubt,
  getQuestionList,
  subjectDetails,
}) => {
  return (
    <div className="create_douts_modalWrap">
      <Modal
        show={show}
        onHide={handleClose}
        className="create_douts_modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Usercreatedoubt
            getQuestionList={getQuestionList}
            handleClose={handleClose}
            slctSubjectId={subjectDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateDoubtModal;
