import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);

function CorrectIncorrectDoughnutChart(props) {
  let {dataPoints } = props;

  const labels = [""];

  // this is for adding margin to the label( header)
  const legendMargin = [
    {
      id: "legendMargin",
      beforeInit(chart, legend, options) {
        chart.legend.options.position = "bottom";
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return (this.height += 20);
        };
      },
    },
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Correct",
        backgroundColor: "#1BCB4C",
        data: [Number(dataPoints[0].Total)],
        barThickness: 70,
        borderRadius:5
      },
      {
        label: "Incorrect",
        backgroundColor: "#FF3434",
        data: [Number(dataPoints[1].Total)],
        barThickness: 70,
        borderRadius:5
      },
    ],
  };

  return (
    <div className="sec_1_inner_wrapper" style={{ height: "350px" }}>
      <Bar
        options={{ maintainAspectRatio: false, legend: {
            position: 'bottom',
        },plugins: {
    datalabels: {
      display: false, // Hide the values inside the bars
    },
  }, }}
        plugins={legendMargin}
        data={data}
      />
    </div>
  );
}

export default CorrectIncorrectDoughnutChart;
