import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "./MotionNews.scss";
import { url_235 } from "../../../custom_utilities/api_services";

const MotionNews = () => {
  const [motionNews, setMotionNews] = useState([]);

  useEffect(() => {
    getMotionNews();
  }, []);

  const getMotionNews = async () => {
    try {
      const response = await axios.get(url_235);

      if (response.data.status === 200) {
        setMotionNews([...response.data.data.news]);
      }
    } catch (error) {
      console.error("Error fetching motion news:", error);
    }
  };

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="news-inner-wrapper">
      <header className="news-heading-wrapper">
        <div className="news-heading">
          Motion in the <span> News </span>
        </div>
        <div className="news-subheading">
          See what prominent publishing firms have to say about us.
        </div>
      </header>

      <div className="news-content-wrapper">
        <div className="news-content-inner-wrapper">
          <Slider {...settings}>
            {motionNews.map((news) => (
              <div className="news-slide" key={news.id}>
                <div className="news">
                  <div className="news-img">
                    <img src={news.news_banner} alt="news-img" />
                  </div>
                  <div className="news-title">{news.title}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MotionNews;
