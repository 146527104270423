import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import images from "../../../../../../../utilities/images/images";
import "./CouponList.scss";

const CouponList = ({
  package_id,
  packageData,
  couponCode,
  setCouponCode,
  applyCoupon,
  handleCloseCoupon,
  checkApplyCouponBtnDisabled,
  handleOnChangeCouponCode,
}) => {
  const [couponList, setCouponList] = useState(null);

  useEffect(() => {
    getCouponList();
  }, []);

  const getCouponList = async () => {
    try {
      const response = await axios.get(
        `https://learning.motion.ac.in/motioneducation/api/v1/coupon/list?package_id=${package_id}`
      );

      if (response.data.status === 200) {
        setCouponList([...response.data.data.coupon_list]);
      }
    } catch (error) {}
  };

  return (
    <Modal
      show={true}
      onHide={handleCloseCoupon}
      dialogClassName="couponlist_modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="coupon-wrapper">
          <h3>Coupons</h3>
          <div className="header">
            <div className="title">
              <img src={images.coupon} alt="Coupon icon" />
              <span>Coupon/Referral</span>
            </div>
          </div>
          <div className="coupon-input">
            <input
              type="text"
              placeholder=" Enter Coupon/Referral Code"
              value={couponCode}
              onChange={(e) => handleOnChangeCouponCode(e)}
            />
            <button
              onClick={applyCoupon}
              className={checkApplyCouponBtnDisabled}
            >
              Apply
            </button>
          </div>
          <div className="coupon-list">
            {couponList?.map((coupon) => {
              return (
                <div className="single-coupon-wrapper">
                  <div className="coupon-inner-wrapper">
                    <img src={images.coupon} alt="Coupon icon" />
                    <div className="coupon-details">
                      <div className="coupon">{coupon.coupon_code}</div>
                      <div className="coupon-desc">{coupon.discount_text}</div>
                    </div>
                  </div>

                  <div
                    className="apply-btn"
                    onClick={() => setCouponCode(coupon.coupon_code)}
                  >
                    <button disabled={checkApplyCouponBtnDisabled}>
                      Apply
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="done-btn" onClick={handleCloseCoupon}>
            <button>Done</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CouponList;
