import "./ContactBanner.scss";

const ContactBanner = () => {
  return (
    <div className="contact-banner">
      Get in touch with us: <span>&#128222;</span>
      <span className="phone-number">+91-6373839897</span>
    </div>
  );
};

export default ContactBanner;
