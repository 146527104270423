import { useSelector, useDispatch } from "react-redux";
import uiActions from "../../redux/ui/action";

const useAuthUtils = () => {
  const dispatch = useDispatch();

  const { token, tokenType } = useSelector((state) => ({
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  }));

  const startLoading = () => {
    dispatch(uiActions.startLoading());
  };

  const finishLoading = () => {
    dispatch(uiActions.finishLoading());
  };

  const headers = {
    Authorization: `${tokenType} ${token}`,
  };

  return { headers, startLoading, finishLoading };
};

export default useAuthUtils;
