import React, { useEffect, useState } from "react";
import "./Benifits.scss";
import images from "../../../utilities/images/images";
import { Render } from "../Render";

const Benifits = (props) => {
  const { setIsLoginOpen, isLoginOpen } = props;
  const [activeTab, setActiveTab] = useState("live");
  const [isAnimating, setIsAnimating] = useState(false);

  const handleTabClick = (tabId) => {
    setIsAnimating(true);
    setTimeout(() => {
      setActiveTab(tabId);
      setIsAnimating(false);
    }, 300);
  };

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  }, []);

  return (
    <div className="benifits-inner-wrapper">
      <header className="benifits-heading-wrapper">
        <div className="benifits-heading">
          Explore Our <span>Benefits</span>
        </div>
        <div className="benifits-subheading">
          Learning made easy with features that will help students to analyze
          their concepts and excel in competitive exams
        </div>
      </header>

      <div className="benifits-container">
        <div className="tabs-container">
          <ul className="tabs-list">
            <li
              className={`tab ${activeTab === "live" ? "active" : ""}`}
              id="live"
              onClick={() => handleTabClick("live")}
            >
              Live Classes
            </li>
            <li
              className={`tab ${activeTab === "doubts" ? "active" : ""}`}
              id="doubts"
              onClick={() => handleTabClick("doubts")}
            >
              Doubts
            </li>
            <li
              className={`tab ${
                activeTab === "practice-modules" ? "active" : ""
              }`}
              id="practice-modules"
              onClick={() => handleTabClick("practice-modules")}
            >
              Practice Modules
            </li>
            <li
              className={`tab ${
                activeTab === "performance-report" ? "active" : ""
              }`}
              id="performance-report"
              onClick={() => handleTabClick("performance-report")}
            >
              Performance Report
            </li>
          </ul>
        </div>
        <div
          className={`tabs-content-container ${isAnimating ? "animating" : ""}`}
        >
          <Render if={activeTab === "live"}>
            <div className="left-content">
              <div className="left-heading-wrapper">
                <div className="main-heading">
                  Motion’s Live <span> Classes</span>
                </div>
                <div className="sub-heading">
                  Experience Motion’s live classes from Kota’s top faculty that
                  enhances your exam preparation.
                </div>
              </div>
              <div className="left-text-wrapper">
                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">Scheduled live classes</div>

                    <div className="left-second">
                      Attend interactive live classes at your comfort place.
                    </div>
                  </div>
                </div>
                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">Learn at your own pace</div>

                    <div className="left-second">
                      Recorded video lectures to improve your revision.
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="enrollbtn"
                onClick={() => setIsLoginOpen(!isLoginOpen)}
              >
                Enroll Here
              </div>
            </div>

            <div className="right-content">
              <img src={images.benifitsCommonImg} alt="benifits-img" />
            </div>
          </Render>
          <Render if={activeTab === "doubts"}>
            <div className="left-content">
              <div className="left-heading-wrapper">
                <div className="main-heading">
                  Clarify your <span> unlimited doubts instantly</span>, 24/7
                  anytime
                </div>

                <div className="sub-heading">
                  Ask your unlimited doubts anytime and get detailed solutions
                  from our experienced team
                </div>
              </div>

              <div className="left-text-wrapper">
                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Write your doubt to be answered
                    </div>

                    <div className="left-second">
                      Clarify your doubt in your words and also attach a related{" "}
                      snap if needed.
                    </div>
                  </div>
                </div>

                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Send & receive voice notes of detailed explanation
                    </div>

                    <div className="left-second">
                      Record your doubt and post. Also, listen to your professor
                      explaining your doubt.
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="enrollbtn"
                onClick={() => setIsLoginOpen(!isLoginOpen)}
              >
                Enroll Here
              </div>
            </div>

            <div className="right-content">
              <img src={images.doubtsImg} alt="benifits-img" />
            </div>
          </Render>

          <Render if={activeTab === "practice-modules"}>
            <div className="left-content">
              <div className="left-heading-wrapper">
                <div className="main-heading">
                  Upgrade your test preparation efforts with
                  <span> Motion's Practice corner</span>
                </div>

                <div className="sub-heading">
                  Practice the highly curated tests to simulate the real testing{" "}
                  environment
                </div>
              </div>

              <div className="left-text-wrapper">
                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Previous year question papers & many other test modules
                    </div>

                    <div className="left-second">
                      Practice or take a mock test of the previous year question
                      papers for assured selection.
                    </div>
                  </div>
                </div>

                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Prepare your test paper with our "Question Bank"
                    </div>

                    <div className="left-second">
                      Master your weak topics by preparing your own customized{" "}
                      test paper of any subject & any topic.
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="enrollbtn"
                onClick={() => setIsLoginOpen(!isLoginOpen)}
              >
                Enroll Here
              </div>
            </div>

            <div className="right-content">
              <img src={images.practiceImg} alt="benifits-img" />
            </div>
          </Render>

          <Render if={activeTab === "performance-report"}>
            <div className="left-content">
              <div className="left-heading-wrapper">
                <div className="main-heading">
                  Receive a<span> detailed feedback & analysis</span> of each of
                  your performance
                </div>

                <div className="sub-heading">
                  Every test taken on Motion learning app is evaluated, and
                  detailed reports are generated in the My Space corner.
                </div>
              </div>

              <div className="left-text-wrapper">
                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Student-Parent Report(SPR) shared for each test taken
                    </div>

                    <div className="left-second">
                      SPR generated is displayed in the Progress section of the
                      app and is also shared with the parents regularly.
                    </div>
                  </div>
                </div>

                <div className="left-text">
                  <img src={images.right} alt="right-icon" />
                  <div>
                    <div className="left-first">
                      Question-wise, time-based analysis provided
                    </div>

                    <div className="left-second">
                      Performance analysis is done on a question-by-question
                      basis with respect to the time taken for better
                      performance in future tests.
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="enrollbtn"
                onClick={() => setIsLoginOpen(!isLoginOpen)}
              >
                Enroll Here
              </div>
            </div>

            <div className="right-content">
              <img src={images.reportImg} alt="benifits-img" />
            </div>
          </Render>
        </div>
      </div>
    </div>
  );
};

export default Benifits;
