import React, { Fragment } from "react";
import images from "../../../utilities/images/images";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <Fragment>
      <div className="contact-us-inner-wrapper">
        <div className="content-and-btn-wrapper">
          <img src={images.contactUsBanner} alt="contact-us-banner" />

          <div className="content-wrapper">
            <div className="content">
              <div className="heading">Get in touch with us!</div>

              <div className="sub-heading">
                Empowering Your Educational Journey — We're Here to Assist and
                Guide You.
              </div>
            </div>

            <div className="contact-us-btn-wrapper">
              <img src={images.callIcon} alt="call-icon" />
              <a href="tel:18002121799" className="get-in-touch-mobile-no">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-contact-us-wrapper">
        <div className="mobile-contact-image">
          <div className="content-wrapper">
            <div className="text-content">
              <h2>Get in touch with us!</h2>
              <p>
                Empowering Your Educational Journey — We're Here to Assist and
                Guide You.
              </p>
            </div>
            <div className="contact-btn">
              <img src={images.callIcon} alt="call-icon" />

              <a href="tel:18002121799" className="get-in-touch-mobile-no">
                Contact us
              </a>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={images.mobileContactUsBanner} alt="contact-us-banner" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
