import ShakaSolutionViewVideoPlayer from "../../../../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import "./QueViewVideoSolution.scss";

const ViewVideoSolution = ({
  handleVideoShow,
  videoShow,
  currentSolutionDetails = {},
  lang,
}) => {
  const { video_id_english, video_id_hindi } = currentSolutionDetails;

  const isHindi = !lang && video_id_hindi;
  const selectedVideoId = isHindi ? video_id_hindi : video_id_english;

  const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";

  return (
    <div className="videoWrapper">
      <button className="view_videoSolution_btn" onClick={handleVideoShow}>
        {buttonLabel}
      </button>

      {videoShow && (
        <ShakaSolutionViewVideoPlayer
          id_video={selectedVideoId}
          className="shakaPlayer"
        />
      )}
    </div>
  );
};

export default ViewVideoSolution;
