import { Fragment, useState, useEffect } from "react";
import "./PyqDailyTestContentComponent.scss";
///////
import PyqDailyTestInstructionView from "./PyqDailyTestInstructionView";
import PyqDailyTestQuestionsView from "./PyqDailyTestQuestionsView";
import userTestsExercisesAction from "../../../redux/user_activity/action";
import PyqDailyChallengeAction from "../../../redux/pyq_daily_challenge/action";
import { Analytics } from "aws-amplify";
import {
  url_76,
  url_39,
  url_208,
} from "../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";

const PyqDailyTestContentComponent = (props) => {
  let {
    history,
    userData,
    topicData,
    startLoading,
    finishLoading,
    subjectData,
    questionsList,
    subject,
  } = props;

  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testQuestionsDetails: [],
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: {},
    currentQuestion: 0,
    viewVideo: false,
  });

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: questionsList }));
  };

  const startBtn = async () => {
    let testDetails = { ...state.testDetails };
    if (!isEmpty(testDetails.data.AllQuestionList)) {
      if (testDetails.data.AllQuestionList) {
        const updatedTestDetails = {
          ...testDetails,
          data: {
            ...testDetails.data,
            AllQuestionList: testDetails.data.AllQuestionList.map(
              (element) => ({
                ...element,
                isReview: false,
                isAttempted: false,
                isAnswerGuessed: false,
                totalTimeTaken: 0,
                isAnswerCorrect: 0,
                correctAnswerIdCollection: [],
                wrongAnswerIdCollection: [],
                userAnswerCollection: [],

                q_text: element.q_text
                  .replaceAll(
                    'src="ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'src="/ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="/ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="./ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC= "ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  ),
                solution: element.solution
                  .replaceAll(
                    'src="ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'src="/ckfinder',
                    'src="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="/ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC="./ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  )
                  .replaceAll(
                    'SRC= "ckfinder',
                    'SRC="http://quizmaster.motion.ac.in/ckfinder'
                  ),
                option: element.option.map((opt) => ({
                  ...opt,
                  option: opt.option
                    .replaceAll(
                      'src="ckfinder',
                      'src="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'SRC="ckfinder',
                      'SRC="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'src="/ckfinder',
                      'src="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'SRC="/ckfinder',
                      'SRC="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'SRC="ckfinder',
                      'SRC="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'SRC="./ckfinder',
                      'SRC="http://quizmaster.motion.ac.in/ckfinder'
                    )
                    .replaceAll(
                      'SRC= "ckfinder',
                      'SRC="http://quizmaster.motion.ac.in/ckfinder'
                    ),
                })),
              })
            ),
          },
        };

        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: [...updatedTestDetails.data.AllQuestionList],
        }));
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const handlePreviousQuestionBtn = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    handleCheckQuestion();
    setViewVideoFalse();
    handleSaveQuestion();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));
      window.scrollTo(0, 0);
    }
  };

  //check question
  const handleCheckQuestion = async () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let formatData =
      testDetails.format ===
      "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
        ? "MAIN"
        : testDetails.format === "NEET NEW PATTERN 2021 OTS"
        ? "NEET"
        : "OTHER";
    let data = {
      paper_id: crntQuestionDetail.paper_id,
      qid: crntQuestionDetail.qid,
      subject: crntQuestionDetail.subject,
      format: formatData,
      section: crntQuestionDetail.section_name,
    };
    startLoading();
    const response = await axios.post(url_76, data);
    console.log(response);
    if (response.data.status === 200) {
      setState((prevState) => ({
        ...prevState,
        checkQuestion: response.data.data,
      }));
      finishLoading();
      if (!response.data.data) {
        testQuestionsDetails[currentQuestion].integer_answer = "";
        setState((preState) => ({
          ...preState,
          testQuestionsDetails: testQuestionsDetails,
        }));
        alert(response.data.message);
        return;
      }
      handleSaveQuestion();
    } else if (response.data.status === 422) {
      setState((prevState) => ({
        ...prevState,
        checkQuestion: response.data.data,
      }));
      handleSaveQuestion();
    }
    finishLoading();
  };

  //save prticular question

  const handleSaveQuestion = async () => {
    let { currentQuestion, testQuestionsDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    console.log(crntQuestionDetail);
    let data = {
      schedule: subject.schedule,
      subject: crntQuestionDetail.subject,
      qid: crntQuestionDetail.qid,
      option_id: crntQuestionDetail.userAnswerCollection[0]
        ? crntQuestionDetail.userAnswerCollection[0].option_id
        : crntQuestionDetail.answer_type == "Integer Correct"
        ? crntQuestionDetail.integer_answer
        : "",
      time: 5,
      toughness: crntQuestionDetail.toughness,
      topic: crntQuestionDetail.topic,
      positive_marks: crntQuestionDetail.positive_marks,
      negative_marks: crntQuestionDetail.negative_marks,
      partial_marks: crntQuestionDetail.partial_marks,
    };
    startLoading();
    try {
      const response = await axios.post(url_208, data);
      console.log(response);

      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;

    handleCheckQuestion();
    setViewVideoFalse();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      // initialTime: time,
    }));
    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = {
        option_id: optionId,
      };

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element.option_id === optionId;
    });

    if (findedIndex === -1) {
      let Obj = {
        option_id: optionId,
      };
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        Obj
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let value = event;
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };
  const reviewQuestion = async (currentQuestion) => {
    const { paper_id, qid, isAnswerGuessed } = currentQuestion;

    let data = {
      paper_id: paper_id,
      reference_id: qid,
      status: isAnswerGuessed ? 1 : 0,
      type: 2,
    };
    const response = await axios.post(url_39, data);
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      //bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      //bookmarkApi(data);
    }
  };
  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    //handleSaveQuestion();
    handleCheckQuestion();
    setViewVideoFalse();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      // currentQuestion:0,
    }));
  };
  //view video
  const handleViewVideo = (videoURL) => {
    setState((prevState) => ({
      ...prevState,
      viewVideo: !state.viewVideo,
    }));
    const targetDiv = document.querySelector(".sub_section_wrapper");
    setTimeout(() => {
      targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const setViewVideoFalse = () => {
    setState((prevState) => ({
      ...prevState,
      viewVideo: false,
    }));
  };

  const submitExercise = () => {
    let testDetails = { ...state.testDetails };

    Analytics.record({
      name: "pyq_daily_challenge_submit_test",
      attributes: {
        Exercise_type_Name: testDetails.excersie_type,
        topic_id: topicData.topicId,
        topic_name: topicData.topicName,
        subject_id: subjectData?.subjectId,
        subject_name: subjectData.subjectName,
      },
    });
    handleSaveQuestion();
    history.push("/Pyq_dailyChallenge");
  };

  const handleRedirectBtn = () => {
    history.push("/pyq_cps_exam");
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <PyqDailyTestInstructionView
            testDetails={state.testDetails}
            startBtn={startBtn}
            handleRedirectBtn={handleRedirectBtn}
          />
        );
      case 2:
        return (
          <PyqDailyTestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={submitExercise}
            checkQuestion={state.checkQuestion}
            userData={userData}
            viewVideo={state.viewVideo}
            handleViewVideo={handleViewVideo}
            setViewVideoFalse={setViewVideoFalse}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="Exercise_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.userActivity.exerciseData,
    userPreference: state.userPreference,
    topicData: state.userActivity.topicData,
    subjectData: state.userActivity.subjectData,
    questionsList: state.PyqDailyChallengeReducer.questionsList,
    subject: state.PyqDailyChallengeReducer.subject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
    setQuestionsList: (payload) => {
      dispatch(PyqDailyChallengeAction.setQuestionsList(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PyqDailyTestContentComponent);
