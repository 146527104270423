import { useState, useEffect, Fragment } from "react";
import images from "../../../utilities/images/images";
import axios from "axios";
import { url_234 } from "../../../custom_utilities/api_services";
import "./WhyMotion.scss";

const WhyMotion = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [studentsFeedback, setStudentsFeedback] = useState([]);

  const getStudentsFeedback = async () => {
    const response = await axios.get(url_234);

    if (response.data.status === 200) {
      setStudentsFeedback([...response.data.data.testimonials]);
    }
  };

  useEffect(() => {
    getStudentsFeedback();
  }, []);

  const goToNext = () => {
    if (currentIndex < studentsFeedback.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Fragment>
      <div className="why-motion-inner-wrapper">
        <div className="why-motion-banner">
          <img src={images.whyMotionBanner} alt="why-motion-banner" />
        </div>

        <div className="slider-wrapper">
          <div className="slider-heading">
            Reason Why Student Love and Prefer <span>Motion </span>
          </div>

          <div className="slider mt-5">
            {studentsFeedback?.map((feedback, index) => {
              if (currentIndex === index) {
                return (
                  <div key={index} className="student-feedback-container">
                    <div className="studentReviewIcon">“</div>
                    <div className="studentReview">{feedback.testimonials}</div>
                    <div className="studentDetailsContainer">
                      <img
                        src={feedback.student_image}
                        alt="student-img"
                        className="studentImage"
                      />
                      <div className="studentDetails">
                        <div className="studentName">
                          {feedback.student_name}
                        </div>
                        <div className="studentCourse">{feedback.target}</div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}

            <div className="buttons">
              <button
                onClick={goToPrevious}
                className={`button ${currentIndex > 0 ? "active" : "inactive"}`}
                disabled={currentIndex === 0}
              >
                <img src={images.leftArrowCircle} alt="left arrow icon" />
              </button>
              <button
                onClick={goToNext}
                className={`button ${
                  currentIndex < studentsFeedback.length - 1
                    ? "active"
                    : "inactive"
                }`}
                disabled={currentIndex === studentsFeedback.length - 1}
              >
                <img src={images.rightArrowCircle} alt="right arrow icon" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-why-motion-inner-wrapper">
        <div className="slider-wrapper">
          <div className="slider-heading">
            Reason Why Student Love and Prefer <span>Motion </span>
          </div>

          <div className="slider mt-5">
            {studentsFeedback?.map((feedback, index) => {
              if (currentIndex === index) {
                return (
                  <div key={index} className="student-feedback-container">
                    <div className="studentReviewIcon">“</div>
                    <div className="studentReview">{feedback.testimonials}</div>
                    <div className="studentDetailsContainer">
                      <img
                        src={feedback.student_image}
                        alt="student-img"
                        className="studentImage"
                      />
                      <div className="studentDetails">
                        <div className="studentName">
                          {feedback.student_name}
                        </div>
                        <div className="studentCourse">{feedback.target}</div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}

            <div className="buttons">
              <button
                onClick={goToPrevious}
                className={`button ${currentIndex > 0 ? "active" : "inactive"}`}
                disabled={currentIndex === 0}
              >
                <img src={images.leftArrowCircle} alt="left arrow icon" />
              </button>
              <button
                onClick={goToNext}
                className={`button ${
                  currentIndex < studentsFeedback.length - 1
                    ? "active"
                    : "inactive"
                }`}
                disabled={currentIndex === studentsFeedback.length - 1}
              >
                <img src={images.rightArrowCircle} alt="right arrow icon" />
              </button>
            </div>
          </div>
        </div>

        <div className="why-motion-banner">
          <img src={images.whyMotionBanner} alt="why-motion-banner" />
        </div>
      </div>
    </Fragment>
  );
};

export default WhyMotion;
