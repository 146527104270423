import { React, useState } from "react";
import { useLocation } from "react-router-dom";
import "./OtsAccuracyAttempted.scss";

import ProgressBar from "react-bootstrap/ProgressBar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
//mobileDevice
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import { url_131 } from "../../../../../../../../custom_utilities/api_services";

const OtsAccuracyAttempted = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    userData,
    userPreference,
    testPaperResultData,
    token,
    tokenType,
  } = props;

  const [currentSubject, setCurrentSubject] = useState(null);

  let history = useHistory();
  const backBtn = () => {
    history.push("/ots_test_result");
  };
  const location = useLocation();
  const [state, setState] = useState({
    AccuracyAttempt: {},
  });

  const handleChangeSubject = (event) => {
    let { value } = event.target;
    setCurrentSubject(value);
  };

  useEffect(() => {
    getAccuracyAttempt();
  }, []);

  const getAccuracyAttempt = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
      test_id: testPaperResultData?.PaperData?.testid,
    };
    startLoading();
    try {
      const response = await axios.post(url_131, data, { headers });
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          AccuracyAttempt: response.data.data,
        }));

        response.data.data.SubjectAnalysis[0] &&
          setCurrentSubject(response.data.data.SubjectAnalysis[0]?.Subject);

        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])
  return (
    <>
      <div className="otsAttemtAccuracyWrapper">
        {windowWidth <= 480 ? (
          <>
            <MobileLeftSideBarComponent />
            <MobileHeaderComponent
              userPreference={userPreference}
              userData={userData}
            />
            <MobileRightSideBarComponent history={history} />
          </>
        ) : (
          <HeaderComponent />
        )}
        <div className="attemtAccuracyInrWrapper">
            <div className="attemptAccuracyBox">
              <div className="d-flex">
              <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div>
              <h2>Accuracy and Attempt Percentage</h2>
              </div>

              <div className="subjectsListWrapper">
                <div className="subjectsList">
                  {!isEmpty(state.AccuracyAttempt)
                    ? state.AccuracyAttempt.SubjectAnalysis.length
                      ? state.AccuracyAttempt.SubjectAnalysis.map(
                          (element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_select_subject"
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name="subject"
                                  value={element.Subject}
                                  checked={element.Subject === currentSubject}
                                  onChange={(event) => {
                                    handleChangeSubject(event);
                                  }}
                                />
                                <label htmlFor={index}>{element.Subject}</label>
                              </div>
                            );
                          }
                        )
                      : null
                    : null}
                </div>
              </div>

              <div className="attemptAccuracyResult">
                <div className="resultProgressBar">
                  <div className="resultProgressBox">
                    <h5>{currentSubject}</h5>

                    {state.AccuracyAttempt?.SubjectAnalysis?.map((element) => {
                      if (element.Subject === currentSubject) {
                        return (
                          <div className="progress-container">
                            <div className="accurateBar">
                              <ProgressBar
                                now={element.Accuracy}
                                className="accuracy"
                              />
                              <span>{`${element.Accuracy}%`}</span>
                            </div>
                            <div className="accurateBar">
                              <ProgressBar
                                now={element.Attempt}
                                className="attempt"
                              />
                              <span>{`${element.Attempt}%`}</span>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="resultIndicator">
                  <div className="indicate">
                    <span className="accuracy"></span>Accuracy(in %)
                  </div>
                  <div className="indicate">
                    <span className="attempt"></span>Attempted (in %)
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    testPaperResultData: state?.otsTestsData?.otsPaperResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsAccuracyAttempted);
